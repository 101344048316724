import {connect} from 'react-redux';
import {toTitleCase} from '../../../../../common/constants';
import LocaleStrings from '../../../../languages';
import IconSuccess from '../icons/success';
import IconFailed from '../icons/failed';
import IconInProgress from '../icons/inprogress';
import {convertDateIntoLocal} from '@commonFunction';

function UploadsCell(props) {
  let {
    id,
    name,
    type,
    total_records,
    matched_records,
    duplicate_records,
    status,
    progress,
    downloadpath,
    createdon,
  } = props;

  name = name.replace('.csv', '');

  const renderStatusLabel = () => {
    if (status === 'success') {
      return (
        <div className="flex items-center gap-1 bg-[#ECFDF3] border-2 border-[#ABEFC6] p-1 rounded-md w-20">
          <div>
            <IconSuccess />
          </div>
          <div className="text-xs text-[#079455]">
            {LocaleStrings.enrichment.status.success}
          </div>
        </div>
      );
    } else if (status === 'inprogress') {
      return (
        <div className="flex items-center gap-1 bg-[#EEF4FF] border-2 border-[#C7D7FE] p-1 rounded-md w-24">
          <div>
            <IconInProgress />
          </div>
          <div className="text-xs text-[#6172F3]">
            {LocaleStrings.enrichment.status.inprogress}
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex items-center gap-1 bg-[#FEF3F2] border-2 border-[#FECDCA] p-1 rounded-md w-16">
          <div>
            <IconFailed />
          </div>
          <div className="text-xs text-[#D92D20]">
            {LocaleStrings.enrichment.status.failed}
          </div>
        </div>
      );
    }
  };
  return (
    <tr key={id}>
      <td className="w-1/6 py-3.5 pr-3 text-left text-xs font-normal text-gray-700 relative px-7 truncate text-ellipsis ">
        {name}
      </td>
      <td className="px-3 py-3.5 text-left text-xs font-normal text-gray-700">
        {toTitleCase(type)}
      </td>
      {/* <td className="px-3 py-3.5 text-left text-xs font-normal text-gray-700">
        {progress}
      </td> */}
      <td className="px-3 py-3.5 text-left text-xs font-normal text-gray-700">
        {renderStatusLabel()}
      </td>
      <td className="px-3 py-3.5 text-left text-xs font-normal text-gray-700">
        {total_records === 0 ? '--' : total_records}
      </td>
      <td className="px-3 py-3.5 text-left text-xs font-normal text-gray-700">
        {matched_records === 0 ? '--' : matched_records}
      </td>
      <td className="px-3 py-3.5 text-left text-xs font-normal text-gray-700">
        {duplicate_records === 0 ? '--' : duplicate_records}
      </td>
      <td className="px-3 py-3.5 text-left text-xs font-normal text-gray-700">
        {convertDateIntoLocal(createdon).format('D MMM YYYY HH:mm:ss')}
      </td>
      <td className="px-3 py-3.5 text-left text-xs font-normal text-gray-700">
        {status === 'success' ? (
          <button
            className="btn-primary"
            style={{width: '120px'}}
            onClick={() => {
              window.open(
                `${downloadpath}?api_key=${props.session.apiKey}&session_token=${props.session.sessionToken}`,
                'blank',
              );
            }}>
            {LocaleStrings.enrichment.download}
          </button>
        ) : status === 'inprogress' ? (
          <button className="btn-primary-white" style={{width: '100px'}}>
            {LocaleStrings.enrichment.cancel}
          </button>
        ) : (
          <button className="btn-primary-white" style={{width: '100px'}}>
            {LocaleStrings.enrichment.retry}
          </button>
        )}
      </td>
    </tr>
  );
}

const mapStateToProps = state => ({
  session: state.session,
});

export default connect(mapStateToProps, null)(UploadsCell);
