import {Fragment, useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {XIcon} from '@heroicons/react/outline';
import IconSavedFilter from './icons/savedfilter';
import {connect} from 'react-redux';
import {
  deleteSavedFilter,
  saveFilter,
  updateSavedFilters,
} from '../../../actions';
import SavedSearchCell from './cells';
import AppAlert from '../../../../../../common/appalert';
import LocaleStrings from '../../../../../languages';

function SavedFiltersSidePopup(props) {
  const [open, setOpen] = useState(false);
  const [selectedList, setSelectedList] = useState(undefined);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  useEffect(() => {
    if (open !== props.open) {
      setOpen(props.open);
    }
  }, [props.open]);

  const closeDialog = () => {
    setOpen(false);
    props.onClose();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative"
        style={{zIndex: 9999}}
        onClose={value => {
          closeDialog();
        }}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel className="pointer-events-auto max-w-md">
                  <div className="flex h-full flex-col overflow-hidden bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          <div className="flex gap-4 items-center">
                            <div>
                              <IconSavedFilter />
                            </div>
                            <div>
                              <div className="text-lg">
                                {LocaleStrings.search.savedsearches.title}
                              </div>
                              <div className="text-xs text-gray-500 font-normal">
                                {LocaleStrings.search.savedsearches.subtitle}
                              </div>
                            </div>
                          </div>
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                            onClick={() => {
                              closeDialog();
                            }}>
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6 overflow-auto  scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent scrollbar-thumb-rounded-full">
                      {props.availableFilters &&
                        props.availableFilters.map(filter => (
                          <SavedSearchCell
                            key={filter.filterid}
                            {...filter}
                            onUseFilter={selectedFilter => {
                              props.saveFilter(
                                props.session,
                                {
                                  ...props.savedFilters,
                                  ...selectedFilter,
                                },
                                () => {},
                              );
                              closeDialog();
                            }}
                            onDelete={filterid => {
                              setShowDeleteAlert(true);
                              setSelectedList(filter);
                            }}
                          />
                        ))}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
          <AppAlert
            open={showDeleteAlert}
            title={selectedList && `Delete ${selectedList.filtername}`}
            message="Are you sure you want to delete the saved filter?"
            primaryButton={{title: 'Delete'}}
            secondaryButton={{title: 'Cancel'}}
            primaryButtonAction={() => {
              setShowDeleteAlert(false);

              const newlist = props.availableFilters.filter(
                list => list.filterid !== selectedList.filterid,
              );

              props.updateSavedFilters([...newlist]);

              props.deleteSavedFilter(
                props.session,
                {filterid: selectedList.filterid},
                _ => {},
              );
            }}
            secondaryButtonAction={() => {
              setShowDeleteAlert(false);
            }}
          />
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  //console.log('state.availableFilters => ', state.availableFilters);
  return {
    session,
    user,
    availableFilters: state.availableFilters,
    savedFilters,
  };
}

export default connect(mapStateToProps, {
  saveFilter,
  deleteSavedFilter,
  updateSavedFilters,
})(SavedFiltersSidePopup);
