import {useState} from 'react';
import LocaleStrings from '@language';
import TaggedSearch from '@commonFolder/TaggedSearch';
import {connect} from 'react-redux';

function ContactName(props) {
  const [searchText, setSearchText] = useState('');

  const handleSearch = e => {};
  return (
    <div>
      <TaggedSearch
        placeholder={LocaleStrings.search.peoplesearch.contact_placeholder}
        id="contactname"
        name="contactname"
        showOptions={false}
        takesAnyValue={true}
        results={[]}
        onToggled={_ => {}}
        {...props}
      />
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    savedFilters,
  };
}

export default connect(mapStateToProps, null)(ContactName);
