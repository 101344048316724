import {connect} from 'react-redux';
import PeopleCell from './cells/PeopleCell';
import {people} from '../constants';
import {useEffect, useState} from 'react';
import CreateListPopup from '../components/createlistpopup';
import LocaleStrings from '../../../languages';
import {FILTER_TYPE_FAV_PEOPLE, FILTER_TYPE_PEOPLE} from '../actions';
import {useNavigate} from 'react-router-dom';
import toast, {Toaster} from 'react-hot-toast';

import BlockTechnology, {
  SECTION_PEOPLE,
} from './cells/components/technologyblock';
import ReportContactPopup from '../components/reportcontactpopup';
import {contactmetaAddupdate} from '@myContactsActions';

function ListTable(props) {
  const {selectedPersons, onSelectPerson, filteredPeople, filterType} = props;
  const [openCreateListPopup, setOpenCreateListPopup] = useState(false);
  const [openFlagPopup, setOpenFlagPopup] = useState(false);
  const [selectedContact, setSelectedContact] = useState();
  const [selectedPerson, setSelectedPerson] = useState();
  const navigate = useNavigate();

  const [peopleData, setPeopleData] = useState(props.filteredPeople);

  const updateContactMeta = (val, action, type, linkedinurl, downvotedata) => {
    let obj = {};
    obj.linkedin_url = linkedinurl;
    obj.info = val;
    obj.action = action;
    obj.type = type;

    if (action === 'downvote') {
      obj.downvotereason = downvotedata.downvotereason;
      obj.reasonnote = downvotedata.reasonnote;
    }

    props.contactmetaAddupdate(props.session, obj, callback => {
      toast.success('Thanks! Your feedback has been submitted.');
    });
  };

  useEffect(() => {
    if (peopleData.length !== props.filteredPeople.length) {
      setPeopleData(props.filteredPeople);
    }
  }, [props.filteredPeople]);

  return (
    <div className="relative pr-4 overflow-scroll-y">
      <table className="w-full table-fixed divide-y divide-gray-300 sm:px-6 lg:px-8">
        <thead>
          <tr className="bg-gray-50">
            {filterType === FILTER_TYPE_PEOPLE ? (
              <th
                scope="col"
                className="sticky top-0 relative w-8 bg-gray-50 z-50"
              />
            ) : null}
            <th
              scope="col"
              className="sticky top-0 w-1/6 py-3.5 pr-3 text-left text-xs font-normal text-gray-700 relative px-7 bg-gray-50 z-50">
              {LocaleStrings.search.peoplesearch.tableHeaders.contactname}
            </th>
            <th
              scope="col"
              className="sticky top-0 w-1/6 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
              {LocaleStrings.search.peoplesearch.tableHeaders.jobtitle}
            </th>
            <th
              scope="col"
              className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50 w-[230px]">
              {LocaleStrings.search.peoplesearch.tableHeaders.contactdetails}
            </th>
            <th
              scope="col"
              className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
              {LocaleStrings.search.peoplesearch.tableHeaders.companyname}
            </th>
            <th
              scope="col"
              className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
              {LocaleStrings.search.peoplesearch.tableHeaders.industry}
            </th>

            <th className="sticky top-0 bg-gray-50 w-[80px] z-50" />
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {peopleData &&
            peopleData.map(person => (
              <PeopleCell
                kye={person.peopleid}
                filterType={filterType}
                person={person}
                selected={selectedPersons.includes(person)}
                onCheckUnCheck={checked => {
                  onSelectPerson(person, checked);
                }}
                onOpenCreateList={person => {
                  setOpenCreateListPopup(!openCreateListPopup);
                  setSelectedPerson(person);
                }}
                onUpgradePlan={() => navigate('/upgradeplan')}
                onBuyAddon={() => navigate('/creditaddons')}
                onClickFlag={contact => {
                  if (contact.action === 'downvote') {
                    setOpenFlagPopup(true);
                    setSelectedContact({...contact, ...person});
                  } else {
                    toast.success(LocaleStrings.mycontacts_upvoted_success);
                  }
                }}
                onCopySuccess={() => {
                  toast.success('Copied successfully');
                }}
                onRefresh={props.onRefresh}
                onRemoveFav={props.onRemoveFav}
              />
            ))}
          {props.showTechnologyBlock ? (
            <BlockTechnology
              colSpan={filterType === FILTER_TYPE_FAV_PEOPLE ? 7 : 6}
              totalcount={props.totalcount}
              section={SECTION_PEOPLE}
            />
          ) : null}
        </tbody>
      </table>
      <CreateListPopup
        open={openCreateListPopup}
        filterType={filterType}
        selectedPerson={selectedPerson}
        toggleOpen={open => {
          setOpenCreateListPopup(open);
        }}
      />
      <ReportContactPopup
        open={openFlagPopup}
        {...selectedContact}
        onClose={() => {
          setOpenFlagPopup(false);
        }}
        onSubmit={data => {
          updateContactMeta(
            selectedContact.contact,
            'downvote',
            selectedContact.contacttype,
            selectedContact.linkedin,
            data,
          );
          setOpenFlagPopup(false);
          setSelectedContact(undefined);
        }}
      />
      <Toaster />
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  var {session, user, savedFilters, technologySearch} = state;

  const {totalcount, showTechnologyBlock} = technologySearch;

  let filteredPeople =
    ownProps.filterType === FILTER_TYPE_PEOPLE
      ? state.filteredPeople.data
      : state.filteredFavPeople.data;

  if (showTechnologyBlock) {
    filteredPeople = filteredPeople.slice(0, 5);
  }

  return {
    session,
    user,
    savedFilters,
    showTechnologyBlock,
    searchSelectedSubMenu: state.searchSelectedSubMenu,
    filteredPeople,
    totalcount: totalcount > 1 ? totalcount - 1 : 0, //For technology block
  };
}

export default connect(mapStateToProps, {contactmetaAddupdate})(ListTable);
