import _ from 'lodash';
import moment from 'moment';
import LocaleStrings from '@language';
var voucher_codes = require('voucher-code-generator');

/* Get bilingual predefined values  */
export function getBilangualeText(value) {
  let text = '';
  if (value === 'pcs') {
    text = LocaleStrings.pcs;
  } else if (value === 'nos') {
    text = LocaleStrings.nos;
  } else if (value === 'nos') {
    text = LocaleStrings.nos;
  } else if (value === 'nos') {
    text = LocaleStrings.nos;
  } else if (value === 'card') {
    text = LocaleStrings.card;
  } else if (value === 'cash') {
    text = LocaleStrings.cash;
  } else if (value === 'upi') {
    text = LocaleStrings.upi;
  } else if (value === 'netbanking') {
    text = LocaleStrings.netbanking;
  } else if (value === 'home') {
    text = LocaleStrings.home;
  } else if (value === 'office') {
    text = LocaleStrings.office;
  } else if (value === 'other') {
    text = LocaleStrings.other;
  } else if (value === 'negotiating') {
    text = LocaleStrings.negotiating;
  } else if (value === 'accepted') {
    text = LocaleStrings.accepted;
  } else if (value === 'rejected') {
    text = LocaleStrings.rejected;
  } else {
    text = value;
  }
  return text;
}

export function randomString(xlength) {
  let x = voucher_codes.generate({
    length: xlength,
    count: 1,
    charset: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
  });

  return x[0];
}

export function pad(pad, str, padLeft) {
  if (typeof str === 'undefined') return pad;
  if (padLeft) {
    return (pad + str).slice(-pad.length);
  } else {
    return (str + pad).substring(0, pad.length);
  }
}

/**** Is Empty Check ****/
export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

/**** Integer to decimal convertion [(+num).toFixed(2)] ****/
export function getIntToFloat(num) {
  return Number(num).toFixed(2);
}

/***** GET EXTENSION NAME FROM FILE *****/
export function getExtensionNameFromFile(filename) {
  return filename.slice(
    (Math.max(0, filename.lastIndexOf('.')) || Infinity) + 1,
  );
}

/***** Class Join Function *****/
export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function abbreviateNumber(value) {
  var newValue = value;
  if (value >= 1000) {
    var suffixes = ['', 'k', 'm', 'b', 't'];
    var suffixNum = Math.floor(('' + value).length / 3);
    var shortValue = '';
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision),
      );
      var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
}

/***** Random Number Generator *****/
export function getRandomNum() {
  return _.random(10000);
}

/***** Common Function To do localization *****/

export function ProductTextLocalized(text_en, text_ar, local_lang) {
  let returnText =
    local_lang === 'ar'
      ? text_ar !== undefined && text_ar != null && _.trim(text_ar).length > 0
        ? text_ar
        : text_en
      : text_en !== undefined && text_en != null && _.trim(text_en).length > 0
        ? text_en
        : text_ar;
  return returnText;
}

// Get Discount Price From Item object

export function updateMRP(item, variantid) {
  let all_variants = item.all_variants;
  let price = _.filter(all_variants, function (o) {
    return o.id === variantid;
  });
  let productMRP = price.length > 0 ? price[0].price_unit : 0;
  var discountedPrice = productMRP;
  var discountpercent = 0.0;
  var strProductDiscount = '';
  if (item.discountactive === true || item.discountactive === 1) {
    let mrp = productMRP;

    if (item.discount && item.discount.discount_percentage) {
      discountpercent = item.discount.discount_percentage;
    }

    if (item.discount && item.discount.discounttype) {
      let discounttype = item.discount.discounttype;
      if (discounttype === 'fixed') {
        //strProductDiscount = CURRENCY_RATE + discountpercent + " OFF";
        strProductDiscount = discountpercent + ' OFF';
        discountedPrice = mrp - discountpercent;
      } else {
        strProductDiscount = discountpercent + '% OFF';
        discountedPrice = mrp - mrp * (discountpercent / 100);
      }
    } else {
      strProductDiscount = discountpercent + '% OFF';
      discountedPrice = mrp - mrp * (discountpercent / 100);
    }
  }
  return {
    discountedPrice: discountedPrice,
    strProductDiscount: strProductDiscount,
  };
}

export function timeAgo(dateParam) {
  let now = moment();
  let then = moment.utc(dateParam);
  then = then.local();

  let diff = {};
  diff.unit = 'just now';
  diff.value = now.diff(then, 'seconds');

  if (diff.value > 1) {
    diff.unit = 'secs';
  }

  if (diff.unit === 'secs' && diff.value > 59) {
    diff.unit = 'min';
    diff.value = now.diff(then, 'minutes');

    if (diff.value > 1) {
      diff.unit = 'mins';
    }
  }
  if (diff.unit === 'mins' && diff.value > 59) {
    diff.unit = 'hour';
    diff.value = now.diff(then, 'hours');

    if (diff.value > 1) {
      diff.unit = 'hours';
    }
  }
  if (diff.unit === 'hours' && diff.value > 24) {
    diff.unit = 'day';
    diff.value = now.diff(then, 'days');

    if (diff.value > 1) {
      diff.unit = 'days';
    }
  }
  if (diff.unit === 'days' && diff.value > 7) {
    diff.unit = 'week';
    diff.value = now.diff(then, 'weeks');

    if (diff.value > 1) {
      diff.unit = 'weeks';
    }
  }
  if (diff.unit === 'weeks' && diff.value > 4) {
    diff.unit = 'month';
    diff.value = now.diff(then, 'months');

    if (diff.value > 1) {
      diff.unit = 'months';
    }
  }
  if (diff.unit === 'months' && diff.value > 12) {
    diff.unit = 'year';
    diff.value = now.diff(then, 'years');

    if (diff.value > 1) {
      diff.unit = 'years';
    }
  }
  return diff.unit !== 'just now'
    ? diff.value + ' ' + diff.unit + ' ago'
    : diff.unit;
}

export function convertDateIntoLocal(date) {
  var stillUtc = moment.utc(date).toDate();
  var local = moment(stillUtc).local();
  // .format('YYYY-MM-DD HH:mm:ss');
  return local;
}

export function formattedDateIntoLocal(date) {
  var stillUtc = moment.utc(date).format('DD MMMM YYYY').toString();
  // var local = moment(stillUtc).local().format('DD MMMM YYYY');
  return stillUtc;
}

/*** Replace Tag Finction ***/
export function replaceTags(comment, tags) {
  let tagsArr = tags.split(',');
  let returnText = comment;
  _.map(tagsArr, (item, index) => {
    let sent1 = "<a href='http://" + parseInt(item) + "'>";
    if (returnText.indexOf(sent1) >= 0) {
      returnText = returnText.replace(sent1, '@');
      returnText = returnText.replace('</a>', '');
    }
  });
  let finalArr = returnText.split(' ');

  return finalArr;
}

/*** Clear cache ***/
export function emptyCache() {
  if ('caches' in window) {
    caches.keys().then(names => {
      // Delete all the cache files
      names.forEach(name => {
        caches.delete(name);
      });
    });

    // Makes sure the page reloads. Changes are only visible after you refresh.
    window.location.reload(true);
  }
}

/*** Replace special charecters from phome number including space ***/
export var removePhoneNoSpecialChar = phoneNumber => {
  if (_.size(phoneNumber) > 0) {
    phoneNumber = phoneNumber.replace('+', '');
    phoneNumber = phoneNumber.replace(/ /g, '');
    phoneNumber = phoneNumber.replace(/-/g, '');
    phoneNumber = phoneNumber.replace('(', '');
    phoneNumber = phoneNumber.replace(')', '');
    return phoneNumber;
  } else {
    return null;
  }
};

/**** Get total price to pay [Work zone - product fee & payment for products] ****/
export var getTotalPriceToPay = (settingsMasterStore, tax_n_charges) => {
  let price = 0;
  let {listing_perproduct} = settingsMasterStore;

  // String to float
  listing_perproduct = parseFloat(listing_perproduct);

  tax_n_charges = parseFloat(tax_n_charges);

  let listingPericePercentage = listing_perproduct * (tax_n_charges / 100);

  price = listing_perproduct + listingPericePercentage;
  return price.toFixed(2);
};

/**** get tax value to pay ****/
export var getTotalTaxToPay = (settingsMasterStore, tax_n_charges) => {
  let taxncharges = 0;
  let {listing_perproduct} = settingsMasterStore;

  // String to float
  listing_perproduct = parseFloat(listing_perproduct);

  tax_n_charges = parseFloat(tax_n_charges);

  let listingPericePercentage = listing_perproduct * (tax_n_charges / 100);

  taxncharges = listingPericePercentage;
  return taxncharges.toFixed(2);
};

export var isJSON = str => {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
};

export const formatDate = date => {};
