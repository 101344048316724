import {Fragment, useEffect, useState} from 'react';
import {Listbox, Transition} from '@headlessui/react';
// import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import {CheckIcon} from '@heroicons/react/solid';
import {ChevronDownIcon} from '@heroicons/react/solid';
import {classNames} from '@commonFunction';

export default function SelectCombo(props) {
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (selected !== props.value) setSelected(props.value);
  }, [props.value]);

  return (
    <div>
      <Listbox
        value={selected}
        onChange={e => {
          props.onSelectValue(e);
        }}>
        <div className="mt-1 relative">
          <Listbox.Button
            className={classNames(
              'relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left border text-xs',
              props.hasError
                ? 'border-red-600 border-2'
                : 'border-gray-300 border',
            )}>
            <span
              className={`block truncate ${
                selected ? 'opacity-100' : 'opacity-50'
              }`}>
              {selected ? selected.title : props.placeholder}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-4 w-4 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent scrollbar-thumb-rounded-full">
              {props.values.map((item, index) => (
                <Listbox.Option
                  key={index}
                  className={({active}) =>
                    `relative cursor-default select-none py-2 px-2 ${
                      active
                        ? 'bg-gray-50 text-primary cursor-pointer'
                        : 'text-gray-900'
                    }`
                  }
                  value={item}>
                  {({selected}) => (
                    <span
                      className={`block truncate text-xs ${
                        selected.type === item.type
                          ? 'font-medium'
                          : 'font-normal'
                      }`}>
                      {item.title}
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
