import {Dialog, Transition} from '@headlessui/react';
import {Fragment, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import UploadCSV from './uploadcsv';
import LocaleStrings from '../../../../languages';
import {toggleCSVSelectionPopup} from '../../actions';
import EnrichmentOptions from './enrichmentoption';
import StandardFields from './standardfields';
import Processing from './processing';
import {classNames} from '@commonFunction';
import toast, {Toaster} from 'react-hot-toast';
import {
  companyContactsSettings,
  companyStandardSettings,
  peopleContactsSettings,
  peopleStandardSettings,
} from '../../../../../common/constants';

function SelectionPopup(props) {
  const [step, setStep] = useState(1);

  const closeModal = () => {
    props.toggleCSVSelectionPopup({
      section: props.section,
      open: false,
      file: null,
      originalFile: null,
    });

    setTimeout(() => {
      setStep(1);
    }, 300);
  };

  const renderComponent = () => {
    switch (step) {
      case 2:
        return (
          <EnrichmentOptions
            {...props}
            onOpenUpgrade={() => {
              closeModal();
              props.router.navigate('/upgradeplan');
            }}
          />
        );
      case 3:
        return <StandardFields {...props} />;
      case 4:
        return (
          <Processing
            {...props}
            onClose={() => {
              closeModal();
            }}
          />
        );

      default:
        return <UploadCSV {...props} />;
    }
  };

  let work_email_count = 0;
  let personal_email_count = 0;
  let phone_number_count = 0;
  let technology_count = 0;

  let hasMatchedContacts = false;

  if (props.section === 'people') {
    if (Object.keys(props.csvEnrichInfo).length > 0) {
      work_email_count = props.csvEnrichInfo.work_email_count;
      personal_email_count = props.csvEnrichInfo.personal_email_count;
      phone_number_count = props.csvEnrichInfo.phone_number_count;
    } else {
      //bypass
      work_email_count = 1;
    }

    hasMatchedContacts =
      work_email_count > 0 &&
      personal_email_count > 0 &&
      phone_number_count > 0;
  } else {
    if (Object.keys(props.csvEnrichInfo).length > 0) {
      technology_count = props.csvEnrichInfo.technology_count;
    } else {
      //bypass
      technology_count = 1;
    }

    hasMatchedContacts = technology_count > 0;
  }

  const allFields =
    props.section === 'people'
      ? {...peopleStandardSettings, ...peopleContactsSettings}
      : {...companyStandardSettings, ...companyContactsSettings};

  /** Find out keys which are mandatory */
  const allKeys = Object.keys(allFields);
  const mandatoryKeys = allKeys.filter(key => allFields[key].mandatory);

  /** Find if mandatory fields have been mapped */
  const mappedKeys = mandatoryKeys.filter(
    key => props.selectMappingData[key] !== '',
  );
  let mandatoryFieldsMapped =
    mandatoryKeys.filter(key => props.selectMappingData[key] !== '').length ===
    mandatoryKeys.length;

  if (!mandatoryFieldsMapped && props.section === 'people') {
    if (
      ((mappedKeys.includes('first_name') &&
        mappedKeys.includes('last_name')) ||
        mappedKeys.includes('full_name')) &&
      mappedKeys.includes('companyname')
    ) {
      mandatoryFieldsMapped = true;
    }
  }

  const contactCredits =
    props.appuserData.credits.contactcredits - phone_number_count;
  const secondaryCredits =
    props.appuserData.credits.personal_emailcredits - personal_email_count;
  const businessCredits =
    props.appuserData.credits.business_emailcredits - work_email_count;

  if (props.section === 'people') {
    if (step === 1) {
      hasMatchedContacts = true;
    } else {
      hasMatchedContacts =
        contactCredits > 0 && secondaryCredits > 0 && businessCredits > 0;
    }
  }

  const disableNextButton =
    props.csvAnalyzed || !hasMatchedContacts || !mandatoryFieldsMapped;
  const disablePrevButton = props.csvAnalyzed;

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-[999] inset-0 overflow-y-auto"
        open={props.open}
        onClose={closeModal}>
        <div
          className="flex items-end justify-center pt-4 px-4 text-center sm:block sm:p-0 content-center"
          style={{height: 'calc(100vh / var(--zoom))'}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div
              className="inline-block rounded-xl align-bottom  bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/3"
              style={{
                padding: 10,
                height: 'calc(100vh / var(--zoom) - 20px)',
              }}>
              {renderComponent()}
              {step === 1 ? (
                <div className="px-4 text-gray-400 text-sm">
                  * Please map mandatory fields to proceed
                </div>
              ) : null}
              <div className="flex justify-between p-4">
                <div>
                  <button
                    disabled={disablePrevButton}
                    className={classNames(
                      'btn-primary-white',
                      step === 4
                        ? 'opacity-0'
                        : disablePrevButton
                          ? 'opacity-50 pointer-events-none'
                          : 'opacity-100 pointer-events-auto',
                    )}
                    onClick={() => {
                      console.log('Back Step => ', step);

                      if (step === 1) {
                        closeModal();
                      } else {
                        setStep(
                          props.section === 'company' && step === 3
                            ? step - 2
                            : step - 1,
                        );
                      }
                    }}>
                    {step === 1
                      ? LocaleStrings.enrichment.cancel
                      : LocaleStrings.enrichment.back}
                  </button>
                </div>
                <div></div>
                <div>
                  <button
                    disabled={disableNextButton}
                    className={classNames(
                      'btn-primary',
                      disableNextButton
                        ? 'opacity-50 pointer-events-none'
                        : 'opacity-100 pointer-events-auto',
                    )}
                    onClick={() => {
                      console.log('Forward Step => ', step);
                      if (step <= 3) {
                        setStep(
                          props.section === 'company' && step === 1
                            ? step + 2
                            : step + 1,
                        );
                      } else {
                        closeModal();
                      }
                    }}>
                    {step === 4
                      ? LocaleStrings.enrichment.done
                      : LocaleStrings.enrichment.next}
                  </button>
                </div>
              </div>
              {/* <Toaster /> */}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function mapStateToProps(state) {
  var {session, user} = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    appuserData: state.appuserData,
    selectMappingData:
      state.csvSelectionPopupData.section === 'people'
        ? state.selectMapping
        : state.selectCompanyMapping,
    csvEnrichInfo: state.csvEnrichInfo,
    section: state.csvSelectionPopupData.section,
    csvAnalyzed: state.csvAnalyzed,
  };
}

export default connect(mapStateToProps, {toggleCSVSelectionPopup})(
  SelectionPopup,
);
