import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/solid';
import {useEffect, useState} from 'react';
import {classNames} from '@commonFunction';
import LocaleStrings from '../../../../../languages';

const maxRowPerPage = 10;

export default function PaginationBar(props) {
  const [pages, setPages] = useState([]);
  const [curPage, setCurPage] = useState(props.curPage);
  const [val, setVal] = useState(props.curPage);
  const [paginationIndex, setPaginationIndex] = useState(1);
  const [manualPageIndexChange, setManualPageIndexChange] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  let pageCounts = Math.ceil(props.numPages / 10);
  const diff = props.numPages - pageCounts * 10;
  if (diff > 0) {
    pageCounts = pageCounts + 1;
  }

  const [maxPagination] = useState(pageCounts);

  useEffect(() => {
    const startIndex = (paginationIndex - 1) * maxRowPerPage;
    let endIndex = (paginationIndex - 1) * maxRowPerPage + maxRowPerPage;

    if (endIndex > props.numPages) {
      endIndex = props.numPages;
    }

    var arr = [];
    for (let i = startIndex; i < endIndex; i++) {
      arr.push({page: `${i + 1}`, type: 'number'});
    }
    setPages(arr);
  }, [paginationIndex]);

  useEffect(() => {
    if (!initialLoad) {
      props.onPageChange(curPage);
    }
    setInitialLoad(false);
  }, [curPage]);

  useEffect(() => {
    setCurPage(props.curPage);
  }, [props.curPage]);

  const handleChange = e => {
    const value = e.target.value.replace(/\D/g, '');
    if (parseInt(value) > parseInt(props.numPages)) {
      setVal(props.numPages);
    } else if (parseInt(value) === 0) {
      setVal(1);
    } else {
      setVal(value);
    }
  };

  const processManualPagination = () => {
    if (!props.disabled) {
      const inputVal = parseInt(val);

      if (inputVal <= maxRowPerPage || inputVal >= 1) {
        setTimeout(() => {
          setCurPage(inputVal);
        }, 300);
        setPaginationIndex(Math.floor((inputVal - 1) / maxRowPerPage) + 1);
      }
    }
  };

  return (
    <div
      className={classNames(
        'flex items-center justify-between border-t border-gray-200 bg-white pt-4',
        props.disabled ? 'opacity-30' : 'opacity-100',
      )}>
      <div className="flex flex-1 items-center justify-between">
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination">
            <div
              onClick={() => {
                if (maxPagination !== 1 && paginationIndex > 1) {
                  setPaginationIndex(paginationIndex - 1);
                }
              }}
              className={classNames(
                'relative inline-flex items-center rounded-l-md px-2 w-[30px] h-[30px] py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0',
                maxPagination === 1
                  ? 'opacity-50 hover:cursor-not-allowed'
                  : 'opacity-100 hover:cursor-pointer',
              )}>
              <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
            </div>
            {pages.map(({page, type}) => {
              const thePage = type === 'number' ? parseInt(page) : page;
              return (
                <div
                  key={page}
                  onClick={() => {
                    if (type === 'number' && !props.disabled) {
                      setCurPage(thePage);
                    }
                  }}
                  className={classNames(
                    'relative z-10 inline-flex items-center p-2 text-xs w-[30px] h-[30px] hover:cursor-pointer justify-center',
                    thePage === curPage
                      ? 'bg-primary text-white z-20 outline outline-2 outline-offset-2'
                      : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0',
                  )}>
                  {page}
                </div>
              );
            })}
            <div
              className={classNames(
                'relative inline-flex items-center rounded-r-md p-2 w-[30px] h-[30px] text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0',
                maxPagination === 1
                  ? 'opacity-50 hover:cursor-not-allowed'
                  : 'opacity-100 hover:cursor-pointer',
              )}
              onClick={() => {
                if (maxPagination !== 1 && paginationIndex < maxPagination) {
                  setPaginationIndex(paginationIndex + 1);
                }
              }}>
              <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
            </div>
          </nav>
        </div>
        <div className="flex justify-center items-center gap-2">
          <p className="text-xs text-gray-700">
            {LocaleStrings.search.goToPage}{' '}
            <span className="ml-2">
              <input
                style={{width: 60}}
                type="text"
                max={props.numPages}
                min={1}
                className="rounded-lg border-gray-400 text-center focus:primary text-xs"
                value={val}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    processManualPagination();
                  }
                }}
                onChange={handleChange}
              />
            </span>
          </p>
          {/* <div
            className="btn-primary-small cursor-pointer"
            onClick={() => {
              processManualPagination();
            }}>
            GO
          </div> */}
        </div>
      </div>
    </div>
  );
}
