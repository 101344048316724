import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {connect} from 'react-redux';
import {classNames} from '@commonFunction';
import IconArrowLeft from '../results/cells/icons/arrowleft';
import IconArrowRight from '../results/cells/icons/arrowright';
import IconCardView from '../results/cells/icons/cardview';
import IconListView from '../results/cells/icons/listview';

import ListTable from './ListTable';
import CardTable from './CardTable';

import {FilterIcon} from '@heroicons/react/solid';

// import PaginationBar from '../results/cells/components/paginationbar';
import {FILTER_TYPE_COMPANY, filterCompany} from '../actions';
import PaginationBar from '../results/cells/components/paginationbar';
import NoResult from '../../../../common/noresult';
import SkeletonLoader from '../../../../common/skeletonloader';
import IconToolTip from '../../../../common/icontooltip';

const maxRowPerPage = 25;

function CompanyTable(props) {
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const [cardView, setCardView] = useState(false);

  const [showList, setShowList] = useState(false);

  const [curPage, setCurPage] = useState(1);
  const [rowsPerPage] = useState(maxRowPerPage);

  const [isLoading, setIsLoading] = useState(false);
  const [isPaginating, setIsPaginating] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const applyFilter = resetPage => {
    const page = resetPage ? 1 : curPage;
    props.filterCompany(
      props.session,
      page,
      rowsPerPage,
      sortBy,
      sortDirection,
      props.savedFilters,
      FILTER_TYPE_COMPANY,
      props.appuserData,
      resp => {
        if (resp.success === 1) {
        }
        if (resetPage) {
          setCurPage(1);
          setTimeout(() => {
            setIsLoading(false);
            setIsPaginating(false);
            setFirstLoad(false);
          }, 200);
        } else {
          setIsLoading(false);
          setIsPaginating(false);
          setFirstLoad(false);
        }
      },
    );
  };

  useEffect(() => {
    if (isPaginating) {
      applyFilter(false);
    }
  }, [curPage]);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      setFirstLoad(true);
      applyFilter(true);
    }
  }, [props.savedFilters]);

  useLayoutEffect(() => {
    if (props.filteredCompany && props.filteredCompany.length > 0) {
      const isIndeterminate =
        selectedCompanies.length > 0 &&
        selectedCompanies.length < props.filteredCompany.length;
      setChecked(selectedCompanies.length === props.filteredCompany.length);
      setIndeterminate(isIndeterminate);
      if (checkbox.current) checkbox.current.indeterminate = isIndeterminate;
    } else {
      setChecked(false);
    }
  }, [selectedCompanies, props.filteredCompany]);

  function toggleAll() {
    setSelectedCompanies(checked || indeterminate ? [] : props.filteredCompany);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const renderResultText = () => {
    const startIndex = (curPage - 1) * maxRowPerPage;
    let endIndex = (curPage - 1) * maxRowPerPage + maxRowPerPage;

    if (props.totalcount < maxRowPerPage) {
      endIndex = props.totalcount;
    }

    if (endIndex > props.totalcount) {
      endIndex = props.totalcount;
    }

    if (props.totalcount > 0 && !props.showTechnologyBlock)
      return `${startIndex + 1} - ${endIndex} of ${props.totalcount} companies`;

    return '0 companies';
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {props.totalcount > 0 && !isLoading ? (
        <div className="mt-4 flow-root">
          <div className="flex flex-grow">
            <div className="flex -mx-4 -my-2 mb-6 relative items-center grow shrink basis-0 self-stretch">
              {/* <div>
                <input
                  type="checkbox"
                  className="rounded border-gray-500 text-primary focus:ring-primary hover:cursor-pointer hover:border-primary hover:border-2 hover:bg-gray-100"
                  ref={checkbox}
                  checked={checked}
                  onChange={toggleAll}
                />
              </div> */}
              <div className="ml-2 mt-1 text-sm font-semibold">
                {renderResultText()}
              </div>
              {/* <div
                className={classNames(
                  'transition ease-in duration-300 absolute left-5 -top-3 flex h-12 items-center space-x-3 bg-white sm:left-5',
                  selectedCompanies.length > 0 ? 'opacity-100' : 'opacity-0',
                )}>
                <div className="ml-2 mt-1 text-sm font-semibold">
                  {`${selectedCompanies.length} selected`}
                </div>
                <button
                  type="button"
                  className="inline-flex items-center rounded-lg bg-primary px-3.5 py-2.5 text-sm text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:opacity-80 hover:cursor-pointer">
                  Save Selected
                </button>
                <div className="z-10">
                  <span className="isolate inline-flex rounded-md shadow-sm">
                    <button
                      type="button"
                      className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
                      <IconGroupFav />
                    </button>
                    <div className="relative">
                      <button
                        type="button"
                        onClick={() => {
                          setShowList(!showList);
                        }}
                        className="relative inline-flex items-center bg-white px-3 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
                        <IconGroupAddToList />
                        &nbsp;Add to List&nbsp;
                        <ChevronDownIcon
                          className="h-6 w-6 text-gray-400"
                          aria-hidden="true"
                        />
                      </button>
                      <div
                        className={classNames(
                          'absolute left-0 top-12 transition duration-500 ease-in z-50',
                          showList ? 'visible' : 'invisible',
                        )}>
                        <AddToList
                          global
                          onCreateNewList={() => {
                            setShowList(false);
                            setOpenCreateListPopup(true);
                          }}
                        />
                      </div>
                    </div>
                    <button
                      type="button"
                      className="relative -ml-px inline-flex items-center bg-white px-3 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
                      <IconGroupEnrich />
                      &nbsp;Enrich&nbsp;
                      <ChevronDownIcon
                        className="h-6 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                    </button>
                    <button
                      type="button"
                      className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
                      <IconExport />
                    </button>
                  </span>
                </div>
              </div> */}
            </div>
            <div className="flex -mx-4 -my-5 mb-4 relative gap-6 items-center">
              <div
                className={classNames(
                  'w-22 h-8 rounded-lg shadow border border-gray-300 justify-start items-start inline-flex overflow-hidden',
                  props.showTechnologyBlock ? 'opacity-50' : 'opacity-100',
                )}>
                <div
                  className="px-3 py-2 bg-white border-r border-gray-300 justify-center items-center gap-2 flex hover:bg-gray-100 hover:cursor-pointer"
                  onClick={() => {
                    if (curPage > 1 && !props.showTechnologyBlock) {
                      setIsPaginating(true);
                      setCurPage(curPage - 1);
                    }
                  }}>
                  <div className="w-4 h-4 relative rounded-lg">
                    <IconArrowLeft />
                  </div>
                </div>
                <div
                  className="px-3 py-2 bg-white justify-center items-center gap-2 flex hover:bg-gray-100 hover:cursor-pointer"
                  onClick={() => {
                    if (
                      curPage < props.numPages &&
                      !props.showTechnologyBlock
                    ) {
                      setIsPaginating(true);
                      setCurPage(curPage + 1);
                    }
                  }}>
                  <div className="w-5 h-4 relative rounded-lg">
                    <IconArrowRight />
                  </div>
                </div>
              </div>

              <div className="flex gap-2">
                <IconToolTip
                  type="cardview"
                  onSelect={() => {
                    setCardView(true);
                  }}
                  selected={cardView}
                />
                <IconToolTip
                  type="listview"
                  onSelect={() => {
                    setCardView(false);
                  }}
                  selected={!cardView}
                />
              </div>
            </div>
          </div>
          {props.filteredCompany ? (
            <div
              className={classNames(
                '-my-2 overflow-x-auto -ml-16 overflow-visible',
                cardView ? '-mr-16' : '-mr-20',
              )}>
              <div
                className="inline-block min-w-full py-2 align-middle pl-8"
                style={{height: 'calc(100vh / var(--zoom) - 250px)'}}>
                {isPaginating ? (
                  <div className="px-5">
                    <SkeletonLoader pagination />
                  </div>
                ) : cardView ? (
                  <CardTable
                    calculatedHeight={props.calculatedHeight}
                    filterType={FILTER_TYPE_COMPANY}
                    selectedCompanies={selectedCompanies}
                    onSelectCompany={(company, checked) => {
                      setSelectedCompanies(
                        checked
                          ? [...selectedCompanies, company]
                          : selectedCompanies.filter(p => p !== company),
                      );
                    }}
                  />
                ) : (
                  <ListTable
                    calculatedHeight={props.calculatedHeight}
                    filterType={FILTER_TYPE_COMPANY}
                    selectedCompanies={selectedCompanies}
                    onSelectCompany={(company, checked) => {
                      setSelectedCompanies(
                        checked
                          ? [...selectedCompanies, company]
                          : selectedCompanies.filter(p => p !== company),
                      );
                    }}
                  />
                )}
              </div>
            </div>
          ) : null}
          {props.filteredCompany && (
            <div className="pt-4">
              <PaginationBar
                disabled={props.showTechnologyBlock}
                curPage={curPage}
                numPages={props.numPages}
                onPageChange={curPage => {
                  if (!firstLoad) {
                    setIsPaginating(true);
                  }
                  setCurPage(curPage);
                }}
              />
            </div>
          )}
        </div>
      ) : isLoading && !isPaginating ? (
        <SkeletonLoader />
      ) : !isPaginating ? (
        <NoResult hasFilters />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters, technologySearch, appuserData} = state;
  const {totalcount, pages, data} = state.filteredCompany;
  return {
    session,
    user,
    savedFilters,
    showTechnologyBlock: technologySearch.showTechnologyBlock,
    searchSelectedSubMenu: state.searchSelectedSubMenu,
    totalcount,
    numPages: pages,
    filteredCompany: data,
    appuserData,
  };
}

export default connect(mapStateToProps, {filterCompany})(CompanyTable);
