import React, {Fragment, useState} from 'react';
import Portal from '@/common/Portal';
import TooltipPopover from '@/common/TooltipPopover';
import {PortalWithState} from 'react-portal';

import ContactInfo from './contactinfo';
import IconPhone from '../icons/phone';
import IconEmail from '../icons/email';
import {FILTER_TYPE_PEOPLE} from '../../../actions';
import IconAddedToList from '../icons/addedtolist';
import IconSaveList from '../icons/save';
import AddToList from './addtolist';
import IconSavedToList from '../icons/savedtolist';
import IconSaveToList from '../icons/savetolist';

export default function AddToListBlock(props) {
  const [showInfo, setShowInfo] = useState(false); // toggles dropdown visibility
  const [coords, setCoords] = useState({}); // takes current button coordinates

  const btnRef = React.createRef();

  const updateTooltipCoords = button => {
    const rect = btnRef.current.getBoundingClientRect();
    setCoords({
      left: rect.x + rect.width / 2 + 30, // add half the width of the button for centering
      top: rect.y + window.scrollY, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
    });
  };

  const {revealed, addedtolist, listid, person} = props;

  const renderAddToListIcon = () => {
    if (props.integrated) {
      if (addedtolist) {
        return <IconSavedToList />;
      } else {
        return <IconSaveToList />;
      }
    } else if (revealed) {
      if (addedtolist) {
        return <IconAddedToList />;
      } else {
        return <IconSaveList />;
      }
    }
  };

  return (
    <div>
      <PortalWithState closeOnOutsideClick closeOnEsc>
        {({openPortal, closePortal, isOpen, portal}) => (
          <Fragment>
            <div
              ref={btnRef}
              onMouseEnter={e => {
                updateTooltipCoords(e.target);
                if (revealed && props.availableLists.length > 0) {
                  openPortal(e);
                }
              }}
              onMouseLeave={e => {
                updateTooltipCoords(e.target);
                if (revealed && props.availableLists.length > 0) {
                  closePortal();
                }
              }}
              onClick={e => {
                updateTooltipCoords(e.target);
                if (props.availableLists.length === 0) {
                  props.onOpenCreateList(person);
                }
              }}>
              <div>{renderAddToListIcon()}</div>

              {portal(
                <TooltipPopover
                  width={200}
                  coords={coords}
                  direction={'left'}
                  updateTooltipCoords={() =>
                    updateTooltipCoords(btnRef.current.buttonNode)
                  }>
                  {props.availableLists.length > 0 && revealed ? (
                    <AddToList
                      addedListId={listid}
                      onCreateNewList={() => {
                        closePortal();
                        props.onOpenCreateList(person);
                      }}
                      onChangeList={list => {
                        props.onChangeList(list);
                        closePortal();
                      }}
                    />
                  ) : null}
                </TooltipPopover>,
              )}
            </div>
          </Fragment>
        )}
      </PortalWithState>
    </div>
  );
}
