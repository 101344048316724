import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import toast, {Toaster} from 'react-hot-toast';
import LocaleStrings from '@language';
import {BaseComponent, LABEL_POSITION_TOP} from '@baseComponent';
import {classNames} from '@commonFunction';
import {
  mainLogin,
  resetStore,
  isForgotPasswordOpen,
  validateSocialId,
  socialSignupAvailable,
  logout,
} from '@mainActions';
import _ from 'lodash';
import SocialLogin from '../sociallogin/index';
import LoginSideBar from '../../common/loginsidebar';
import {logGTMEvent} from '@commonFolder/gtm-event';
import {withCookies} from 'react-cookie';
import {sessionCookieName, sessionLoggedOut} from '../../common/constants';
import BtnLoaderSvg from '@btnLoaderSvg';
class Login extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      btnLoading: false,
      showerror: false,
      show: false,
      fbloader: false,
      signup: false,
      btnLoader: false,
      isBtnDisable: false,
    };

    // All Bind Functions
    this.forGotPassword_click = this.forGotPassword_click.bind(this);
    this.changeShow = this.changeShow.bind(this);
  }

  UNSAFE_componentWillMount() {
    let {session, user} = this.props;
    // console.log("isLoggedIn", this.props.isLoggedIn);
    if (this.props.isLoggedIn === true) {
      const {cookies} = this.props;
      cookies.set(sessionCookieName, this.props.session.sessionToken);
      this.props.router.navigate('/dashboard');
    } else {
      this.props.router.navigate('/');
      const {cookies} = this.props;
      cookies.set(sessionCookieName, sessionLoggedOut);
    }
  }

  changeShow() {
    let {show} = this.state;
    this.setState({show: !show});
  }

  // Forgot Password Button Click
  forGotPassword_click() {
    this.props.router.navigate('/forgotpassword');
  }

  onFormSubmit(values) {
    let {session, user} = this.props;
    this.setState({btnLoader: true});
    this.setState({isBtnDisable: true});
    values.comesFrom = 'loginpage';

    if (values.hasOwnProperty('email')) {
      values.email = values.email.toLowerCase();
    }

    this.props.mainLogin(values, this.props.session, response => {
      if (response.success === 0) {
        toast.error(LocaleStrings.invalid_email_phone_password);
        this.setState({btnLoader: false});
        this.setState({isBtnDisable: false});
        // this.props.resetStore();
      } else if (response.success === 2) {
        // this.props.logout(this.props.session);
        toast.error(LocaleStrings.unverified_user_error);
        this.setState({isBtnDisable: false});
        this.setState({btnLoader: false});
        this.props.router.navigate('/verifyemail');
      } else if (response.success === 3) {
        this.props.logout(this.props.session);
        toast.error(LocaleStrings.removed_by_admin_error);
        this.setState({isBtnDisable: false});
        this.setState({btnLoader: false});
      } else if (response.success === 4) {
        this.props.logout(this.props.session);
        toast.error(LocaleStrings.disabled_by_admin_error);
        this.setState({isBtnDisable: false});
        this.setState({btnLoader: false});
      } else if (response.success === 5) {
        this.props.logout(this.props.session);
        toast.error(LocaleStrings.invitationaccept_by_admin_error);
        this.setState({isBtnDisable: false});
        this.setState({btnLoader: false});
      } else {
        this.setState({isBtnDisable: false});
        this.setState({btnLoader: false});
        toast.success(LocaleStrings.login_success);
        // this.props.history.push('/dashboard/main/dashboardmain');
        this.props.router.navigate('/dashboard');

        const {cookies} = this.props;

        cookies.set(sessionCookieName, this.props.session.sessionToken);

        logGTMEvent({
          event: 'platformSignIn',
          method: 'email',
        });
      }
    });
  }

  loginsignupToggle = () => {
    this.props.router.navigate('/register');
  };

  componentWillReceiveProps(nextProps) {
    // if (nextProps.showModal) {
    //   this.setState({ ...this.state, loading: false });
    // }
  }

  render() {
    var {handleSubmit} = this.props;
    var {signup} = this.state;

    return (
      <div style={{height: 'calc(100vh / var(--zoom))'}}>
        <div className="relative">
          <div className="flex flex-row">
            <div className="main-login justify-center flex flex-col px-10 w-5/6 items-center">
              <div className="w-1/2">
                <form
                  className="space-y-2"
                  onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                  <div className="mb-2">
                    <div className="text-2xl mt-10 font-medium">
                      {LocaleStrings.mainlogin_title}
                    </div>
                    <div className="mt-2 text-xs text-ternary">
                      {LocaleStrings.mainlogin_subtitle}
                    </div>
                  </div>
                  <div className="pb-2">
                    <Field
                      name="email"
                      label="Business Email"
                      placeholder="Enter your business email"
                      type="text"
                      component={this.renderFieldText}
                      mandatory="true"
                      labelposition={LABEL_POSITION_TOP}
                      classNameLabel="text-sm font-normal text-secondary"
                    />
                  </div>
                  <Field
                    name="password"
                    label={LocaleStrings.password}
                    placeholder={LocaleStrings.password}
                    type="password"
                    component={this.renderFieldText}
                    mandatory="true"
                    show={this.state.show}
                    setShow={this.changeShow}
                    labelposition={LABEL_POSITION_TOP}
                    classNameLabel="text-sm font-normal text-secondary"
                    className="cust-pr-20"
                  />
                  <div className="flex items-center justify-between pt-6">
                    <div className="text-sm">
                      <a
                        onClick={this.forGotPassword_click}
                        className="font-medium text-primary cursor-pointer">
                        {LocaleStrings.forgot_password}
                      </a>
                    </div>
                  </div>
                  <div className="pt-2">
                    <button
                      type="submit"
                      disabled={this.state.isBtnDisable}
                      className={classNames(
                        this.state.isBtnDisable ? 'cursor-not-allowed' : '',
                        'btn-primary',
                      )}>
                      <BtnLoaderSvg loader={this.state.btnLoader} />
                      {LocaleStrings.signin}
                    </button>
                  </div>
                  <SocialLogin comesFrom="login" router={this.props.router} />
                  {/* <div className="pt-4">
                  <button type="button" className="btn-white">
                    <img className="pr-2" src="../../images/google.png"></img> {LocaleStrings.mainlogin_signingoogle}
                  </button>
                </div>
                <div className="pt-4">
                  <button type="button" className="btn-white">
                    <img className="pr-2" src="../../images/linkedin.png"></img> {LocaleStrings.mainlogin_signinlinkedin}
                  </button>
                </div> */}
                  <div className="pt-2 justify-center flex">
                    <div className="mt-2 font-normal text-ternary">
                      {LocaleStrings.mainlogin_donthaveacc}
                    </div>
                    <div
                      className="mt-2 ml-1 font-normal text-primary cursor-pointer"
                      onClick={this.loginsignupToggle}>
                      {LocaleStrings.signup}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <LoginSideBar />
          </div>
        </div>
        <Toaster limit={1} />
        <div className="absolute top-2 left-4">
          <img
            // className="h-6"
            className="w-40"
            // className="h-12 w-52"
            // src="../../images/mainlogo.png"
            src="../../images/kipplo-logo.webp"></img>
        </div>
      </div>
    );
  }
}

function validate(values) {
  var errors = {};

  if (!values.email) {
    errors.email = LocaleStrings.login_form_validation_email_required;
  }

  if (!values.password) {
    errors.password = LocaleStrings.login_form_validation_password_required;
  }
  return errors;
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  showModal: state.openLoginOTPModal,
  isforgotfasswordopen: state.isForgotPasswordOpen,
  deviceSize: state.deviceSize,
});

export default reduxForm({
  validate,
  form: 'LoginForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(mapStateToProps, {
    mainLogin,
    resetStore,
    isForgotPasswordOpen,
    validateSocialId,
    socialSignupAvailable,
    logout,
  })(withCookies(Login)),
);
