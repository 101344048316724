import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Fragment} from 'react';
import _ from 'lodash';
import LocaleStrings from '@language';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {Dialog, Menu, Transition} from '@headlessui/react';
import {showNotificatonCount, isNotificationModalOpen} from '@sidebarActions';
import {
  onsettingsSidebarMenuSelected,
  fetchUserexistingplan,
  fetchClient,
} from '@mainSettingsActions';
import {fetchAppuser} from '@sidebarActions';
import {MenuAlt2Icon, XIcon} from '@heroicons/react/outline';
// Common Functions
import {isEmpty, classNames} from '@commonFunction';
import {Routes, Route} from 'react-router-dom';
import Myaccounts from '@settingsMyaccount';
import Password from '@settingsPassword';
import Manageseats from '@settingsManageseats';
import Billing from '@settingsBilling';
import Plans from '@settingsPlans';
import Usage from '@settingsUsage';
import Api from '@settingsApi';
import Privacy from '@settingsPrivacy';

const DONTSHOWFORADMINORMEMBER = [
  'manageseats',
  'billing',
  'plans',
  'usage',
  'api',
];

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: false,
    };
    // All Binded Functions
  }

  componentDidMount() {
    let {session, user} = this.props;
    this.props.fetchAppuser(session, user.appuserid, callback => {});
    let {pathname} = this.props.router.location;

    const parts = pathname.split('/');

    const lastComponent = parts.length > 0 ? parts[parts.length - 1] : '';

    //Preselect
    if (lastComponent !== '') {
      const filters = this.props.navigation.filter(
        item => item.href === lastComponent,
      );
      if (filters.length > 0) {
        const selectedMenuItem = filters[0];
        this.props.onsettingsSidebarMenuSelected(selectedMenuItem.position);
      }
    }

    pathname = pathname.replaceAll('/', '');

    // console.log('pathname => ', pathname);
    // var parts = this.props.history.location.pathname.substring(20).split('/');
    // var answer = parts[0];
    // var singleNavigationRow = _.find(this.props.navigation, {href: answer});
    // this.props.onsettingsSidebarMenuSelected(singleNavigationRow.position);

    let usercurrentplanid = 0;

    if (
      user &&
      user.usersubscription_by_clientid &&
      user.usersubscription_by_clientid.length > 0
    ) {
      usercurrentplanid =
        user.usersubscription_by_clientid[0]?.subscriptionplan_by_planid
          ?.planid;
    }
    if (usercurrentplanid !== 0) {
      this.props.fetchUserexistingplan(session, usercurrentplanid);
    }
  }

  onMenuSelect = item => {
    this.props.onsettingsSidebarMenuSelected(item.position);
    this.props.router.navigate('/settings/' + item.href);
    this.setState({sidebarOpen: false});
  };
  _handelback = () => {
    this.props.router.navigate('/dashboard');
  };
  setSidebarOpen() {
    this.setState({sidebarOpen: false});
  }

  render() {
    let {appuserData, deviceSize} = this.props;
    let role = 'client';
    if (appuserData && appuserData.data && appuserData.data.length > 0) {
      role = appuserData.data[0].role;
    }

    var menuItems = this.props.navigation;
    //Hide Password option Social option used
    if (this.props.socialLoginUsed) {
      menuItems = menuItems.filter(menu => menu.href !== 'password');
    }

    return (
      <>
        <div className="xs:px-6 sm:px-12 lg:px-10 pt-4 cursor-pointer bg-white">
          {/* <div className="flex">
            <div className="cursor-pointer" onClick={e => this._handelback()}>
              <img className="pl-2 pt-1" src="../../images/backicon.png" />
            </div>
            <div
              className="pl-2 cursor-pointer text-sm text-gray500 font-medium"
              onClick={e => this._handelback()}>
              {LocaleStrings.settings_backtodashboard}
            </div>
          </div> */}
          <div className="pl-2 text-base font-semibold pb-4 border-b border-gray-200">
            {LocaleStrings.settings}
          </div>
          <br />
        </div>
        <div className="xs:px-4 sm:px-8 lg:px-18 bg-white">
          <div className="flex overflow-hidden -bg-gray-100">
            <Transition.Root show={this.state.sidebarOpen} as={Fragment}>
              <Dialog
                as="div"
                static
                className="fixed inset-0 flex z-40 md:hidden"
                open={this.state.sidebarOpen}
                onClose={this.setSidebarOpen}>
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                </Transition.Child>
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full">
                  <div className="relative flex-1 flex flex-col max-w-xs w-full pb-4 bg-custWhite">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0">
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={() => this.setState({sidebarOpen: false})}>
                          <span className="sr-only">Close sidebar</span>
                          <XIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 flex items-center px-4 mt-5">
                      {/* <img
                        className="h-8 w-auto"
                        src="../../images/mainlogo.png"
                        alt="Workflow"
                      /> */}
                      <img
                        // className="h-6"
                        // className="h-8 w-32"
                        className="h-12"
                        // src="../../images/mainlogo.png"
                        src="../../images/kipplo-logo.webp"></img>
                    </div>
                    <div className="mt-5 flex-1 h-0 overflow-y-auto">
                      <nav className="px-2 space-y-1 cursor-pointer">
                        {menuItems.map(item =>
                          _.indexOf(DONTSHOWFORADMINORMEMBER, item.href) > -1 &&
                          role !== 'client' ? (
                            ''
                          ) : (
                            <span
                              key={item.name}
                              onClick={e => this.onMenuSelect(item)}
                              className={classNames(
                                this.props.SettingsidebarSelectedMenu ===
                                  item.position
                                  ? 'bg-gray-100 text-secondary'
                                  : 'text-ternary hover:bg-gray-50 hover:text-secondary',
                                'group flex items-center px-2 py-2 text-xs font-medium rounded-md',
                              )}>
                              {item.name}
                            </span>
                          ),
                        )}
                      </nav>
                    </div>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
              </Dialog>
            </Transition.Root>
            {/* button for small scrren */}
            <div className="border-r border-trenary md:hidden">
              <button onClick={() => this.setState({sidebarOpen: true})}>
                <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            {/* Static sidebar for desktop */}
            <div className="hidden md:flex md:flex-shrink-0">
              <div className="flex flex-col w-44">
                <div className="flex flex-col flex-grow pb-4 bg-custWhite overflow-y-auto">
                  <div className="mt-2 flex-grow flex flex-col overflow-y-auto scrollbar-hide">
                    <nav className="flex-1 px-2 bg-custWhite space-y-1 cursor-pointer">
                      {menuItems.map(item =>
                        _.indexOf(DONTSHOWFORADMINORMEMBER, item.href) > -1 &&
                        role !== 'client' ? (
                          ''
                        ) : (
                          <span
                            key={item.name}
                            onClick={e => this.onMenuSelect(item)}
                            className={classNames(
                              this.props.SettingsidebarSelectedMenu ===
                                item.position
                                ? 'bg-primaryLight text-secondary'
                                : 'text-ternary hover:bg-gray-50 hover:text-secondary',
                              'group flex items-center px-2 py-2 text-xs font-medium rounded-md',
                            )}>
                            <span
                              // className=""
                              className={classNames(
                                this.props.SettingsidebarSelectedMenu ===
                                  item.position
                                  ? 'text-primary'
                                  : 'text-gray500',
                                'pl-2 font-normal',
                              )}>
                              {item.name}
                            </span>
                          </span>
                        ),
                      )}
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            {/* <main className="sm:pl-12 py-4 w-full overflow-y-auto scrollbar-hide h-4/6"> */}
            {/* <main className="sm:pl-12 py-4 w-full overflow-y-auto scrollbar-hide h-3/4 2xl:h-4/5"> */}
            <main
              className="sm:pl-12 py-4 w-full overflow-y-auto scrollbar-hide pb-6"
              style={{height: 'calc(100vh - 132px)'}}>
              {this.props.component}
            </main>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  var {session, user, socialLoginUsed} = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    SettingsidebarSelectedMenu: state.SettingsidebarSelectedMenu,
    language: state.language,
    navigation: state.SettingsSideBarMenus,
    notificationCount: !_.isEmpty(state.appcounters)
      ? !_.isEmpty(state.appcounters.data)
        ? state.appcounters.data.notifications_count
        : 0
      : 0,
    messageCount: !_.isEmpty(state.appcounters)
      ? !_.isEmpty(state.appcounters.data)
        ? state.appcounters.data.message_count
        : 0
      : 0,
    appuserData: state.appuserData,
    deviceSize: state.deviceSize,
    socialLoginUsed,
  };
}

export default connect(mapStateToProps, {
  onsettingsSidebarMenuSelected,
  showNotificatonCount,
  isNotificationModalOpen,
  fetchUserexistingplan,
  fetchClient,
  fetchAppuser,
  // selectedProfileSettingsMenu,
})(Settings);
