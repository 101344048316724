import {useState} from 'react';
import LocaleStrings from '@language';
import TaggedSearch from '@commonFolder/TaggedSearch';
import {connect} from 'react-redux';
import {fieldSearch} from '../../../../actions';
import debounce from 'lodash/debounce';

function JobTitle(props) {
  const [results, setResults] = useState([]);

  // const handleSearch = val => {
  //   if (val !== '' && val.length > 1) {
  //     props.fieldSearch(
  //       props.session,
  //       {action: 'search', searchfield: 'jobtitle', searchtext: val},
  //       res => {
  //         setResults(res.data);
  //       },
  //     );
  //   }
  // };

  // const debouncedSearch = debounce(handleSearch, 100);

  return (
    <div>
      <TaggedSearch
        placeholder={LocaleStrings.search.peoplesearch.job_placeholder}
        id="jobtitle"
        name="jobtitle"
        takesAnyValue={true}
        showOptions={false}
        results={[]}
        onToggled={_ => {}}
        {...props}
      />
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    savedFilters,
  };
}

export default connect(mapStateToProps, {fieldSearch})(JobTitle);
