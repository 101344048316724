import {
  getRequest,
  postRequest,
  deleteRequest,
  fileUploadRequest,
  patchRequest,
  deleteBulkRequest,
} from '@networkCall';

import {
  APP_API_KEY,
  INSTANCE_URL,
  itemCount,
  DEVELOPMENT_TYPE,
} from '@constant';

import LocaleStrings from '@language';

// All Exports
export var ALL_MYCONTACTS = 'ALL_MYCONTACTS';

export const fetchmycontacts = (
  session,
  perpage,
  pageCount = 0,
  search = '',
  callback,
) => {
  let perPage = parseInt(perpage),
    offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage,
    offsetQuery = `limit=${perPage}&offset=${offset}`,
    orderBy = `createdon%20DESC`,
    filter = search == '' ? '' : encodeURI(`(name LIKE '%${search}%')`);
  // let baseFilter = encodeURI('revealmethod=reveal');
  let url = `${INSTANCE_URL}/api/v2/thomsondata/_table/usercontact?include_count=true&order=${orderBy}&${offsetQuery}`;
  if (search !== '') {
    url = `${INSTANCE_URL}/api/v2/thomsondata/_table/usercontact?include_count=true&order=${orderBy}&${offsetQuery}`;
  }

  return dispatch => {
    getRequest(
      url,
      session,
      dispatch,
      res => {
        // console.log('res: ', res.resource[0]);
        dispatch({
          type: ALL_MYCONTACTS,
          payload: {
            count: res.meta.count,
            data: res.resource,
          },
        });
        callback({
          success: 1,
          count: res.meta.count,
          data: res.resource,
        });
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export function contactmetaAddupdate(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/contactmeta_addupdate`;
  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      response => {
        // console.log('res',response)
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        callback({success: 0, message: error});
      },
    );
  };
}

export function revealEmail(session, params, callback) {
  let url = `${INSTANCE_URL}/api/v2/getcontact`;
  return dispatch => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      response => {
        callback({success: 1, result: response});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
}
