import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  CardElement,
} from 'react-stripe-elements';

import LocaleStrings from '@language';

import {fetchpaymentIntent} from '@creditAddonActions';
import BtnLoaderSvg from '@btnLoaderSvg';

const handleBlur = () => {};
const handleChange = change => {};
const handleFocus = () => {};
const handleReady = () => {};

class AddPayfastForm extends Component {
  constructor(props) {
    super(props);
    this.state = {loading: false};
  }

  componentDidMount() {}

  handleSubmit = ev => {
    var that = this;
    ev.preventDefault();

    // *********************** 3D SECURITY ********************

    // const cardElement = this.props.elements.getElement("card");
    // console.log("cardElement", cardElement);
    // let obj = {};
    // obj.planid = that.props.values.planid;
    // obj.clientid = that.props.user.clientid;

    // this.props.fetchpaymentIntent(this.props.session, obj, (callback) => {
    //   console.log("callback", callback);

    //   if (callback.success == 1 && callback.result.paymentintent_id) {
    //     let paymentintent_id = callback.result.paymentintent_id;
    //     this.props.stripe.confirmCardPayment(paymentintent_id, {
    //       payment_method: {
    //         card: cardElement,
    //       },
    //     });
    //   }
    // });
    this.setState({loading: true});
    this.props.stripe.createToken().then(({error, token}) => {
      if (error) {
        // console.log("error", error);
        that.props.onTokenReceived(null, error);
        this.setState({loading: false});
      } else {
        if (token) that.props.onTokenReceived(token, null);
        setTimeout(() => {
          that.setState({loading: false});
        }, 5000);

        // that.setState({loading: false});
      }
    });
  };

  closeModal = () => {
    this.props.onCloseModal();
  };

  render() {
    let {values} = this.props;
    let price = 0;
    if (values && values.price) {
      price = values.price;
    }
    // console.log("this.props.values", values);
    return (
      <div className="basicform">
        <form onSubmit={this.handleSubmit}>
          <>
            {/* <CardElement> */}
            <label className="customStripeLabelCss">
              {LocaleStrings.addons_label_cardno}
              <CardNumberElement
                className="customStripeCss"
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                onReady={handleReady}
                // {...createOptions(this.props.fontSize)}
              />
            </label>
            <label className="customStripeLabelCss">
              {LocaleStrings.addons_label_expirydate}
              <CardExpiryElement
                className="customStripeCss"
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                onReady={handleReady}
                // {...createOptions(this.props.fontSize)}
              />
            </label>
            <label className="customStripeLabelCss">
              {LocaleStrings.addons_label_cvc}
              <CardCVCElement
                className="customStripeCss"
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                onReady={handleReady}
                // {...createOptions(this.props.fontSize)}
              />
            </label>
            {/* </CardElement> */}
            <div className="flex gap-4">
              <button
                type="button"
                onClick={this.closeModal}
                className="btn-white text-primary cursor-pointer">
                {LocaleStrings.cancel}
              </button>

              <button
                className="btn-primary"
                type="submit"
                loading={this.state.loading}>
                <BtnLoaderSvg loader={this.state.loading} />
                {LocaleStrings.pay} {LocaleStrings.dollar}
                {price}
              </button>
            </div>
          </>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  var {session, user} = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    appuserData: state.appuserData,
    creditaddonList: state.creditaddonList,
  };
}

export default connect(mapStateToProps, {fetchpaymentIntent})(
  injectStripe(AddPayfastForm),
);
// export default injectStripe(AddPayfastForm);
// export default (mapStateToProps,
// {
//   fetchpaymentIntent,
//   injectStripe,
// })(AddPayfastForm);

// const Component = connect(
//     mapStateToProps,
//     mapDispatchToProps,
//     {
//       pure: false,
//     }
//   )(injectStripe(AddPayfastForm));
