export default function IconGroupEnrich() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.12246 16.4728C9.12174 17.3598 11.503 17.3116 13.5421 16.1343C16.93 14.1783 18.0908 9.84618 16.1348 6.45828L15.9265 6.09743M3.86571 13.5417C1.9097 10.1538 3.07048 5.8217 6.45839 3.8657C8.49754 2.68839 10.8788 2.64022 12.878 3.52719M2.07812 13.6114L4.35483 14.2215L4.96488 11.9448M15.0358 8.05479L15.6459 5.77808L17.9226 6.38813"
        stroke="#667085"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
