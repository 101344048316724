import {Fragment, useEffect, useRef, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {connect} from 'react-redux';
import IconManageLabel from '../../icons/managelabel';
import LocaleStrings from '../../../../languages';
import {
  LABEL_TYPE_MYLIST,
  createLabel,
  deleteLabel,
  fetchLabels,
  updateListPerson,
} from '../../actions';
import {TwitterPicker} from 'react-color';
import {classNames} from '@commonFunction';
import {XIcon} from '@heroicons/react/solid';
import {updateRevealedPeople} from '../../../search/actions';

function ManageLabelsPopups(props) {
  const [open, setOpen] = useState(false);

  const [newListText, setNewListText] = useState('');

  const cancelButtonRef = useRef(null);

  const [wait, setWait] = useState(false);

  const [showColor, setShowColor] = useState(false);

  const [available, setAvailable] = useState(true);

  const [color, setColor] = useState('#FF6900');

  useEffect(() => {
    if (open !== props.open) {
      setOpen(props.open);
    }
  }, [props.open]);

  const createLabel = () => {
    if (newListText.length > 0) {
      const person = props.selectedList;
      const label = {title: newListText, color};
      const params =
        props.type === LABEL_TYPE_MYLIST
          ? {
              tag: label,
              listpeopleid: person.listpeopleid,
            }
          : {
              tag: label,
              contactid: person.contactid,
            };
      props.createLabel(props.session, params, res => {
        if (res.data.message && res.data.message === 'notavailable') {
          setAvailable(false);

          setTimeout(() => {
            setAvailable(true);
          }, 2000);
        } else {
          props.fetchLabels(
            props.session,
            {action: 'availablelabels'},
            _ => {},
          );
          const newpeople = {...person, tag: {...label, custom: true}};
          const index = props.filteredListPeople.data.findIndex(
            t => t.peopleid === person.peopleid,
          );
          props.filteredListPeople.data[index] = newpeople;
          if (props.type === LABEL_TYPE_MYLIST) {
            props.updateListPerson(props.filteredListPeople);
          } else {
            props.updateRevealedPeople(props.filteredListPeople);
          }
          props.toggleOpen(false);
          setNewListText('');
          setColor('#FF6900');
        }
      });
    }
  };

  const deleteLabel = label => {
    const person = props.selectedList;
    const params =
      props.type === LABEL_TYPE_MYLIST
        ? {
            tag: label.title,
          }
        : {
            tag: label.title,
            contactid: person.contactid,
          };
    props.deleteLabel(props.session, ...params, res => {
      props.fetchLabels(props.session, {action: 'availablelabels'}, _ => {});
      const newpeople = {...person, tag: null};
      const index = props.filteredListPeople.data.findIndex(
        t => t.peopleid === person.peopleid,
      );
      props.filteredListPeople.data[index] = newpeople;
      if (props.type === LABEL_TYPE_MYLIST) {
        props.updateListPerson(props.filteredListPeople);
      } else {
        props.updateRevealedPeople(props.filteredListPeople);
      }
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[99]"
        initialFocus={cancelButtonRef}
        onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="items-start justify-start">
                    <IconManageLabel />
                  </div>
                  <div className="mt-3 text-left sm:mt-5">
                    <Dialog.Title
                      as="h2"
                      className="text-lg font-semibold leading-6 text-gray-900">
                      {LocaleStrings.listpeople.manageLabelPopup.title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {LocaleStrings.listpeople.manageLabelPopup.subtitle}
                      </p>
                    </div>
                  </div>
                  <div className="py-4 flex">
                    <div className="space-y-2 w-4/5">
                      <div className="relative flex items-start">
                        <div className="text-base leading-6">
                          <label htmlFor="new" className="text-gray-900">
                            {
                              LocaleStrings.listpeople.manageLabelPopup
                                .inputTitle
                            }
                          </label>
                        </div>
                      </div>
                      <div>
                        <input
                          autoComplete="off"
                          name="newlistname"
                          className="cust-input-field w-full text-xl"
                          placeholder={
                            LocaleStrings.listpeople.manageLabelPopup
                              .inputPlaceholder
                          }
                          value={newListText}
                          onChange={e => setNewListText(e.target.value)}
                        />
                        <div className="py-2 text-sm text-red-600 h-4">
                          {available ? '' : 'Already used'}
                        </div>
                      </div>
                    </div>
                    <div className="space-y-2 mt-7 ml-4 relative">
                      <div
                        className="drop-shadow-lg border-[6px] border-white w-11 h-11 rounded hover:cursor-pointer"
                        style={{backgroundColor: color}}
                        onClick={() => {
                          setShowColor(!showColor);
                        }}
                      />
                      <div
                        className={classNames(
                          'absolute top-[60px] -left-[240px] z-50',
                          showColor ? 'visible' : 'invisible',
                        )}>
                        <TwitterPicker
                          triangle={'top-right'}
                          width="285px"
                          styles={{
                            input: {width: '10px', border: 'none'},
                          }}
                          color={color}
                          onChange={color => {
                            setColor(color.hex);
                            setShowColor(false);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    {props.availableLabels.filter(
                      label => label.custom === true,
                    ).length > 0 ? (
                      <div>
                        {
                          LocaleStrings.listpeople.manageLabelPopup
                            .existingLabel
                        }
                      </div>
                    ) : null}
                    <div className="py-4 justify-start items-start gap-1 inline-flex relative">
                      {props.availableLabels
                        .filter(label => label.custom === true)
                        .map(label => {
                          const {title, color} = label;
                          return (
                            <div
                              key={title}
                              className="px-3 py-2 rounded-2xl justify-start items-center flex gap-1 transition ease-in duration-100 hover:cursor-pointer hover:scale-105"
                              style={{
                                backgroundColor: `${color}20`,
                              }}
                              onClick={() => {
                                deleteLabel(label);
                              }}>
                              <div
                                className="text-center text-xs font-medium font-['Inter'] leading-none"
                                style={{
                                  color: `${color}`,
                                }}>
                                {title}
                              </div>
                              <div className="w-5 h-5 px-0.5 justify-center items-center flex">
                                <XIcon
                                  className="h-4 w-4"
                                  aria-hidden="true"
                                  style={{
                                    color: `${color}`,
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div className="mt-6 grid grid-flow-row-dense grid-cols-2 gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-white px-3.5 py-3 text-base text-gray-700 shadow-sm border-2 border-gray-200  hover:bg-gray-50"
                    onClick={() => props.toggleOpen(false)}
                    ref={cancelButtonRef}>
                    {LocaleStrings.listpeople.manageLabelPopup.cancel}
                  </button>
                  <button
                    type="button"
                    disabled={newListText.length === 0}
                    className={classNames(
                      'inline-flex w-full justify-center rounded-md bg-primary px-3.5 py-3 text-base text-white shadow-sm hover:opacity-80 disabled:opacity-50',
                      newListText.length === 0 ? 'disabled' : '',
                    )}
                    onClick={() => createLabel()}>
                    {LocaleStrings.listpeople.manageLabelPopup.save}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function mapStateToProps(state, ownProps) {
  const {session, user} = state;
  return {
    session,
    user,
    filteredListPeople:
      ownProps.type === LABEL_TYPE_MYLIST
        ? state.filteredListPeople
        : state.filteredRevealedContacts,
    availableLabels: state.availableLabels,
  };
}

export default connect(mapStateToProps, {
  createLabel,
  updateListPerson,
  fetchLabels,
  deleteLabel,
  updateRevealedPeople,
})(ManageLabelsPopups);
