import React, {Component} from 'react';
import {connect} from 'react-redux';
import LocaleStrings from '@language';
import Api from '@settingsApi';
import CSVEnrich from './csvenrich';

class EnrichHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    // All Binded Functions
  }

  render() {
    return (
      <div className="px-12 py-4 h-full">
        {this.props.enrichSelectedSubMenu === 'apienrichment' ? (
          <Api {...this.props} />
        ) : (
          <CSVEnrich {...this.props} />
        )}
      </div>
    );
  }
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  enrichSelectedSubMenu: state.enrichSelectedSubMenu,
});

export default connect(mapStateToProps, {})(EnrichHome);
