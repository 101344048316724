export default function IconPeople() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.5 21.5V19.5C22.5 17.6362 21.2252 16.0701 19.5 15.626M16 3.79076C17.4659 4.38415 18.5 5.82131 18.5 7.5C18.5 9.17869 17.4659 10.6159 16 11.2092M17.5 21.5C17.5 19.6362 17.5 18.7044 17.1955 17.9693C16.7895 16.9892 16.0108 16.2105 15.0307 15.8045C14.2956 15.5 13.3638 15.5 11.5 15.5H8.5C6.63623 15.5 5.70435 15.5 4.96927 15.8045C3.98915 16.2105 3.21046 16.9892 2.80448 17.9693C2.5 18.7044 2.5 19.6362 2.5 21.5M14 7.5C14 9.70914 12.2091 11.5 10 11.5C7.79086 11.5 6 9.70914 6 7.5C6 5.29086 7.79086 3.5 10 3.5C12.2091 3.5 14 5.29086 14 7.5Z"
        stroke="#8721DE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
