import React, {useState} from 'react';
import {connect} from 'react-redux';
import {classNames} from '@commonFunction';

import IconFav from './icons/fav';

import SubCompany from './components/subcompany';
import SocialLinks from './components/sociallinks';
import IconFaved from './icons/faved';
import {
  FILTER_TYPE_FAV_PEOPLE,
  FILTER_TYPE_PEOPLE,
  addCRMLead,
  addPersonTolist,
  revealAllContact,
  tooglePeopleFav,
  updateFavPeople,
  updatePeople,
  zapierHook,
} from '../../actions';

import {toTitleCase} from '@/common/constants';
import CompositeSubContact from './components/compositesubcontact';
import IconDelete from '../../../mylist/icons/delete';
import Combinedicons from './components/combinedicons';

function PeopleCardCell(props) {
  const {person, selected, onCheckUnCheck, onOpenCreateList, filterType} =
    props;
  const {
    contactname,
    jobtitle,
    company,
    location,
    contactdetails,
    linkedin,
    facebook,
    twitter,
    isfav,
    revealed,
    addedtolist,
    listid,
    new_contact_data_available,
    new_company_data_available,
  } = person;

  const [showList, setShowList] = useState(false);

  return (
    <tr key={contactname} className={selected ? 'bg-gray-50' : undefined}>
      {filterType === FILTER_TYPE_PEOPLE ? (
        <td className="group relative py-2">
          {selected && (
            <div className="absolute inset-y-0 left-0 w-0.5 bg-primary" />
          )}
          <input
            type="checkbox"
            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-500 text-primary focus:ring-primary group-hover:cursor-pointer"
            value={contactname}
            checked={selected}
            onChange={e => onCheckUnCheck(e.target.checked)}
          />
        </td>
      ) : null}
      <td
        className={classNames('group py-2 pr-3 relative px-7 space-y-2')}
        onClick={() => {
          onCheckUnCheck(!selected);
        }}>
        <div className="relative flex gap-2">
          <div
            className={classNames(
              'whitespace-nowrap text-xs font-medium w-fit truncate',
              selected ? 'text-primary' : 'text-gray-900',
            )}>
            {contactname}
          </div>
          {!linkedin && !twitter && !facebook ? null : (
            <div>
              <SocialLinks
                linkedin={linkedin}
                twitter={twitter}
                facebook={facebook}
              />
            </div>
          )}
        </div>
        <div className="text-xs text-gray-500">{toTitleCase(jobtitle)}</div>

        <div className="text-xs text-gray-500">{location}</div>
        {/* <div
          className="absolute right-2 top-1/2 group-hover:opacity-100 opacity-0"
          onClick={() => {
            navigator.clipboard.writeText(contactname);
            props.onCopySuccess();
          }}>
          <IconCopy />
        </div> */}
      </td>
      <td className="group whitespace-nowrap px-3 py-2 hover:bg-gray-50 hover:cursor-pointer relative">
        <div className="py-2 text-[10px]">Contact Emails</div>
        {contactdetails
          .filter(
            contact =>
              contact.type === 'personalemail' ||
              contact.type === 'businessemail',
          )
          .map(contact => (
            <div key={`${contactname}-${contact.type}`}>
              <CompositeSubContact
                {...props}
                {...contact}
                {...person}
                contactname={contactname}
                contacttype="email"
                onUpgradePlan={props.onUpgradePlan}
                onBuyAddon={props.onBuyAddon}
                onClickFlag={props.onClickFlag}
                onRevealCompletion={updatedPerson => {
                  const contactIndex = props.filteredPeople.data.findIndex(
                    person => person.peopleid === updatedPerson.peopleid,
                  );
                  props.filteredPeople.data[contactIndex] = updatedPerson;
                  if (filterType === FILTER_TYPE_PEOPLE) {
                    props.updatePeople(props.filteredPeople);
                  } else {
                    props.updateFavPeople(props.filteredPeople);
                  }
                }}
              />
            </div>
          ))}
        <div>
          <div className="py-2 text-[10px]">Contact Phones</div>
          {contactdetails
            .filter(contact => contact.type === 'directdial')
            .map(contact => (
              <div key={`${contactname}-${contact.type}`}>
                <CompositeSubContact
                  {...props}
                  {...contact}
                  {...person}
                  contactname={contactname}
                  contacttype="phone"
                  onUpgradePlan={props.onUpgradePlan}
                  onBuyAddon={props.onBuyAddon}
                  onClickFlag={props.onClickFlag}
                  onRevealCompletion={updatedPerson => {
                    const contactIndex = props.filteredPeople.data.findIndex(
                      person => person.peopleid === updatedPerson.peopleid,
                    );
                    props.filteredPeople.data[contactIndex] = updatedPerson;
                    if (filterType === FILTER_TYPE_PEOPLE) {
                      props.updatePeople(props.filteredPeople);
                    } else {
                      props.updateFavPeople(props.filteredPeople);
                    }
                  }}
                />
              </div>
            ))}
        </div>
      </td>
      <td className="group px-3 py-2 relative">
        <div className="group/company relative flex">
          <div className="text-sm text-primary">
            <SubCompany {...company} cardView expanded />
          </div>
          {/* {new_company_data_available ? (
            <div className="w-2 h-2 ml-2 mt-1 hover:cursor-pointer tooltip">
              <IconUpdate />
              <span className="tooltiptext tooltip-right">
                {LocaleStrings.revealedContacts.updateavailable}
              </span>
            </div>
          ) : null} */}
        </div>
        {/* <div
          className="absolute right-2 top-6 group-hover:opacity-100 opacity-0"
          onClick={() => {
            navigator.clipboard.writeText(company.title);
            props.onCopySuccess();
          }}>
          <IconCopy />
        </div> */}
      </td>

      <td className="whitespace-nowrap py-2 pl-3 pr-4">
        <div className="group relative flex gap-2 justify-end items-center pr-2">
          <Combinedicons {...props} />
          <div
            className="flex justify-center items-center"
            onClick={() => {
              const newpeople = {...props.person, isfav: !isfav};
              if (filterType === FILTER_TYPE_PEOPLE) {
                const index = props.filteredPeople.data.findIndex(
                  t => t.peopleid === props.person.peopleid,
                );
                props.filteredPeople.data[index] = newpeople;
                props.updatePeople(props.filteredPeople);
                const params = {
                  action: isfav ? 'removefav' : 'addfav',
                  peopleid: newpeople.peopleid,
                };
                props.tooglePeopleFav(props.session, params, res => {
                  // console.log('Fav => ', res);
                });
              } else {
                props.onRemoveFav(newpeople);
              }
            }}>
            {isfav ? (
              filterType === FILTER_TYPE_FAV_PEOPLE ? (
                <IconDelete />
              ) : (
                <IconFaved />
              )
            ) : (
              <IconFav />
            )}
          </div>
        </div>
      </td>
    </tr>
  );
}

function mapStateToProps(state, ownProps) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    savedFilters,
    availableLists: state.availableLists,
    searchSelectedSubMenu: state.searchSelectedSubMenu,
    filteredPeople:
      ownProps.filterType === FILTER_TYPE_PEOPLE
        ? state.filteredPeople
        : state.filteredFavPeople,
    crmDetails: state.crmDetails,
  };
}

export default connect(mapStateToProps, {
  updatePeople,
  tooglePeopleFav,
  addPersonTolist,
  updateFavPeople,
  addCRMLead,
  revealAllContact,
  zapierHook,
})(PeopleCardCell);
