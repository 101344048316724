export default function IconUpdate() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="18" height="18" rx="9" fill="#15C26F" />
      <path
        d="M13.5791 9.48378C13.4287 10.8975 12.6277 12.2222 11.3022 12.9875C9.10007 14.2589 6.2842 13.5044 5.0128 11.3022L4.87738 11.0677M4.42127 8.51634C4.57174 7.10265 5.37275 5.77788 6.69821 5.01263C8.90034 3.74122 11.7162 4.49573 12.9876 6.69787L13.123 6.93242M4.39258 12.2858L4.78911 10.806L6.26897 11.2025M11.7317 6.79763L13.2116 7.19416L13.6081 5.7143"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
