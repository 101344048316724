import {PlusIcon} from '@heroicons/react/solid';

export default function SubLabel(props) {
  const {title, color, addnew} = props;
  return (
    <div
      className="my-1 mx-1 justify-start items-start gap-1 inline-flex hover:cursor-pointer hover:scale-105"
      onClick={() => {
        props.onLabelSelection(props);
      }}>
      <div
        className="px-3 py-2 rounded-2xl justify-start items-center gap-1 flex"
        style={{backgroundColor: `${color}20`}}>
        {addnew ? (
          <PlusIcon className="h-3 w-3 text-gray-500" aria-hidden="true" />
        ) : null}
        <div
          className="text-center text-xs font-medium leading-none"
          style={{color: `${color}`}}>
          {title}
        </div>
      </div>
    </div>
  );
}
