import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

function getWindowDimensions(settings) {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const settings = useSelector(state => state.deviceSize.settings);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(settings),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions(settings));
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
// 754;
