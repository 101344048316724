import React, {Component} from 'react';
import {connect} from 'react-redux';
import blankImg from '../../assets/images/error_placeholder.svg';
import LocaleStrings from '@language';

class PayFail extends Component {
  constructor(props) {
    super(props);

    this.state = {elapsedTime: 15};
    // All Binded Functions
  }

  componentDidMount() {
    const interVal = setInterval(() => {
      const {elapsedTime} = this.state;
      const newTime = elapsedTime - 1;
      this.setState({elapsedTime: newTime});
      if (newTime === 0) {
        clearInterval(interVal);
        this.handlePlanPage();
      }
    }, 1000);
  }

  handlePlanPage() {
    this.props.router.navigate('/upgradeplan');
  }

  render() {
    const {elapsedTime} = this.state;
    return (
      <>
        <main className="flex items-center justify-center">
          <div
            className="flex items-center justify-center"
            style={{marginTop: '10%'}}>
            <div className="justify-center items-center flex flex-col">
              <img className="inline mb-3" src={blankImg} />
              <h2 className="text-lg font-normal w-60 text-center">
                <span className="text-gray-800">
                  {LocaleStrings.pay_fail_title1}
                </span>
              </h2>
              <div className=" grid grid-cols-1 md:grid-cols-3 ">
                <div></div>
                <div>
                  <button
                    className="btn-primary mt-6 w-64"
                    onClick={() => this.handlePlanPage()}>
                    {LocaleStrings.pay_btn_gotoupgrade}
                  </button>
                </div>
                <div></div>
              </div>
              <div className="mt-2 text-gray-400">
                {`Redirecting to upgrade in ${elapsedTime} secs..`}
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

function mapStateToProps(state) {
  var {session, user} = state;
  return {
    session,
    user,
    subscriptionplanList: state.subscriptionplanList,
    appuserData: state.appuserData,
  };
}
export default connect(mapStateToProps, {})(PayFail);
