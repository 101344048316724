import {getRequest, postRequest} from '@networkCall';

import {INSTANCE_URL} from '@constant';

export const MYLISTS = 'MYLISTS';
export const LIST_PEOPLE = 'LIST_PEOPLE';
export const OPEN_LIST = 'OPEN_LIST';
export const CLOSE_LIST = 'CLOSE_LIST';
export const PEOPLE_LIST_FILTERS = 'PEOPLE_LIST_FILTERS';
export const AVAILABLE_LABELS = 'AVAILABLE_LABELS';

export const LIST_COMPANY = 'LIST_COMPANY';
export const OPEN_COMPANY_LIST = 'OPEN_COMPANY_LIST';
export const CLOSE_COMPANY_LIST = 'CLOSE_COMPANY_LIST';
export const COMPANY_LIST_FILTERS = 'COMPANY_LIST_FILTERS';

export const LABEL_TYPE_MYLIST = 'LABEL_TYPE_MYLIST';
export const LABEL_TYPE_REVEALED = 'LABEL_TYPE_REVEALED';

export function savePeopleListFilter(filters) {
  return dispatch => {
    dispatch({
      type: PEOPLE_LIST_FILTERS,
      payload: {...filters},
    });
  };
}

export const fetchMyLists = (
  session,
  page,
  limit,
  sortby,
  sortdirection,
  params,
  callback,
) => {
  const url = `${INSTANCE_URL}/api/v2/pagedataprovider`;

  const values = {
    ...params,
    limit,
    page,
    sortby,
    sortdirection,
  };

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        dispatch({
          type: MYLISTS,
          payload: {
            totalcount: res.totalcount,
            pages: res.numpages,
            data: res.data,
          },
        });
        callback({success: 1});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const fetchListPeople = (
  session,
  page,
  limit,
  sortby,
  sortdirection,
  params,
  callback,
) => {
  const url = `${INSTANCE_URL}/api/v2/people_search/mylist_people`;

  const values = {
    ...params,
    limit,
    page,
    sortby,
    sortdirection,
  };
  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        dispatch({
          type: LIST_PEOPLE,
          payload: {
            totalcount: res.totalcount,
            pages: res.numpages,
            data: res.data,
          },
        });
        callback({success: 1});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const openList = data => {
  return dispatch => {
    dispatch({
      type: OPEN_LIST,
      payload: data,
    });
  };
};

export const closeList = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_LIST,
    });
  };
};

export const fetchLabels = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/pagedataprovider`;

  return dispatch => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      res => {
        dispatch({
          type: AVAILABLE_LABELS,
          payload: res.results,
        });
        callback({success: 1});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const updateListPerson = data => {
  return dispatch => {
    dispatch({
      type: LIST_PEOPLE,
      payload: data,
    });
  };
};

export const createLabel = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/field_actions`;

  const values = {...params, action: 'createlabel'};

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        callback({success: 1, data: res});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const updateLabel = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/field_actions`;

  const values = {...params, action: 'updatelabel'};

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        callback({success: 1, data: res});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const deleteLabel = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/field_actions`;

  const values = {...params, action: 'removelabel'};

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        callback({success: 1, data: res});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const deleteMyList = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/field_actions`;

  const values = {...params, action: 'deletemylist'};

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        callback({success: 1, data: res});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const updateMyList = data => {
  return dispatch => {
    dispatch({
      type: MYLISTS,
      payload: data,
    });
  };
};

export const removePersonFromlist = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/field_actions`;

  console.log('removefromlist => ', params);

  const values = {...params, action: 'removefromlist'};

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        callback({success: 1, data: res});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

//Company

export function saveCompanyListFilter(filters) {
  return dispatch => {
    dispatch({
      type: COMPANY_LIST_FILTERS,
      payload: {...filters},
    });
  };
}

export const fetchListCompany = (
  session,
  page,
  limit,
  sortby,
  sortdirection,
  params,
  callback,
) => {
  const url = `${INSTANCE_URL}/api/v2/company_search/mylist_company`;

  const values = {
    ...params,
    limit,
    page,
    sortby,
    sortdirection,
  };
  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        dispatch({
          type: LIST_COMPANY,
          payload: {
            totalcount: res.totalcount,
            pages: res.numpages,
            data: res.data,
          },
        });
        callback({success: 1});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const openCompanyList = data => {
  return dispatch => {
    dispatch({
      type: OPEN_COMPANY_LIST,
      payload: data,
    });
  };
};

export const closeCompanyList = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_COMPANY_LIST,
    });
  };
};

export const updateListCompany = data => {
  return dispatch => {
    dispatch({
      type: LIST_COMPANY,
      payload: data,
    });
  };
};

export const removeCompanyFromlist = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/field_actions`;
  const values = {...params, action: 'removefromcompanylist'};

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        callback({success: 1, data: res});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const deleteMyCompanyList = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/field_actions`;

  const values = {...params, action: 'deletemycompanylist'};

  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        callback({success: 1, data: res});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};
