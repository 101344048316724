import {useEffect, useState} from 'react';
import LocaleStrings from '@language';
import {connect} from 'react-redux';
import {saveFilter} from '../../../../actions';
import {headcount} from '../../../../constants';

function HeadCount(props) {
  const [selectedValues, setSelectedValues] = useState(
    props.savedFilters.headcount.map(({value}) => value),
  );

  const updateFilterReducer = vals => {
    let filters = props.savedFilters;
    let filterObject = filters;
    filterObject['headcount'] = vals;
    filters = {...filters, ...filterObject};
    props.saveFilter(props.session, filters, () => {});
  };

  useEffect(() => {
    if (selectedValues.length !== props.savedFilters['headcount'].length) {
      updateFilterReducer(
        selectedValues.map(val => ({title: val, value: val})),
      );
    }
  }, [selectedValues]);

  useEffect(() => {
    setSelectedValues(
      props.savedFilters['headcount'].map(filter => filter.title),
    );
  }, [props.savedFilters]);

  useEffect(() => {
    let filters = props.savedFilters;
    let filterObject = filters;

    if (filterObject['headcount'].length === 0 && selectedValues.length !== 0) {
      setSelectedValues([]);
    }
  }, [props.savedFilters]);

  return (
    <fieldset>
      <legend className="text-xs leading-6 text-gray-900 pt-2">
        {LocaleStrings.search.companysearch.headcount_subtitle}
      </legend>
      <div className="mt-2">
        {headcount.map(({title, type}, index) => (
          <div
            key={type}
            className="flex flex-row justify-between items-center">
            <div className="group relative flex justify-start py-1 items-center">
              <div className="ml-2 mr-2 flex h-6 items-center">
                <input
                  id={`headcount-${type}`}
                  name={`headcount-${type}`}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-primary focus:primary"
                  onChange={e =>
                    setSelectedValues(
                      e.target.checked
                        ? [...selectedValues, type]
                        : selectedValues.filter(p => p !== type),
                    )
                  }
                  checked={selectedValues.includes(type)}
                />
              </div>
              <div className="min-w-0 flex-1 text-sm leading-6">
                <label
                  htmlFor={`headcount-${type}`}
                  className="select-none text-xs text-gray-900 group-hover:text-primary">
                  {title}
                </label>
              </div>
            </div>
            <div className="relative flex items-end py-1">
              {props.headcount ? props.headcount[type] : ''}
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    savedFilters,
    seniorities: state.preloadedPeopleFilterSupportData.seniorities,
  };
}

export default connect(mapStateToProps, {saveFilter})(HeadCount);
