import React, {useState, Fragment} from 'react';
import TooltipPopover from '@/common/TooltipPopover';
import {PortalWithState} from 'react-portal';
import IconCardView from '../components/dashboard/search/results/cells/icons/cardview';
import IconListView from '../components/dashboard/search/results/cells/icons/listview';
import {classNames} from '@commonFunction';

export default function IconToolTip(props) {
  const {type, selected} = props;
  const [coords, setCoords] = useState({}); // takes current button coordinates

  const title = type === 'cardview' ? 'Card View' : 'List View';

  const updateTooltipCoords = button => {
    const rect = btnRef.current.getBoundingClientRect();
    setCoords({
      left: rect.x + rect.width + 20, // add half the width of the button for centering
      top: rect.y - 55, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
    });
  };

  const btnRef = React.createRef();
  return (
    <div>
      <PortalWithState closeOnOutsideClick closeOnEsc>
        {({openPortal, closePortal, isOpen, portal}) => (
          <Fragment>
            <div
              className={classNames(
                'w-10 h-10 p-2.5 rounded-lg justify-center items-center gap-2 inline-flex hover:cursor-pointer',
                selected ? 'bg-slate-100' : 'bg-white',
              )}
              onClick={() => {
                props.onSelect();
              }}
              ref={btnRef}
              onMouseEnter={e => {
                updateTooltipCoords(e.target);
                openPortal(e);
              }}
              onMouseLeave={e => {
                updateTooltipCoords(e.target);
                closePortal();
              }}>
              <div>
                {type === 'cardview' ? <IconCardView /> : <IconListView />}
              </div>
            </div>
            {portal(
              <TooltipPopover
                width={80}
                coords={coords}
                direction={'bottom'}
                updateTooltipCoords={() =>
                  updateTooltipCoords(btnRef.current.buttonNode)
                }>
                <div className="text-center w-max absolute bg-gray-800 text-white text-xs py-1 px-2 rounded-lg">
                  {title}
                </div>
              </TooltipPopover>,
            )}
          </Fragment>
        )}
      </PortalWithState>
    </div>
  );
}
