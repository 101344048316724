export default function IconFaved() {
  return (
    <div className="p-1.5 px-2 bg-white rounded-md border border-gray-200 justify-center items-center gap-1 inline-flex hover:bg-gray-50 hover:cursor-pointer">
      <div className="justify-center items-center flex">
        <svg
          width="14"
          height="14"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
          className="stroke-primary stroke-2 fill-current">
          <path
            d="M9.40156 2.8778C9.59363 2.48869 9.68967 2.29413 9.82004 2.23197C9.93347 2.17789 10.0653 2.17789 10.1787 2.23197C10.3091 2.29413 10.4051 2.48869 10.5972 2.8778L12.4194 6.56944C12.4761 6.68432 12.5045 6.74176 12.5459 6.78635C12.5826 6.82584 12.6266 6.85783 12.6754 6.88056C12.7306 6.90623 12.794 6.91549 12.9208 6.93402L16.9968 7.5298C17.4261 7.59253 17.6407 7.6239 17.74 7.72874C17.8264 7.81995 17.8671 7.94529 17.8506 8.06985C17.8317 8.21302 17.6763 8.36436 17.3656 8.66702L14.4172 11.5387C14.3253 11.6282 14.2794 11.673 14.2497 11.7263C14.2235 11.7734 14.2066 11.8252 14.2001 11.8788C14.1928 11.9393 14.2036 12.0025 14.2253 12.129L14.921 16.1851C14.9944 16.6129 15.031 16.8269 14.9621 16.9538C14.9021 17.0642 14.7955 17.1417 14.6719 17.1646C14.5299 17.1909 14.3378 17.0899 13.9536 16.8879L10.3097 14.9716C10.1961 14.9119 10.1394 14.882 10.0796 14.8703C10.0266 14.8599 9.97213 14.8599 9.91918 14.8703C9.85937 14.882 9.8026 14.9119 9.68906 14.9716L6.04512 16.8879C5.66095 17.0899 5.46886 17.1909 5.32683 17.1646C5.20325 17.1417 5.09662 17.0642 5.03663 16.9538C4.96768 16.8269 5.00437 16.6129 5.07774 16.1851L5.77342 12.129C5.79511 12.0025 5.80595 11.9393 5.79862 11.8788C5.79212 11.8252 5.77528 11.7734 5.74902 11.7263C5.71937 11.673 5.67341 11.6282 5.5815 11.5387L2.63315 8.66702C2.3224 8.36436 2.16703 8.21302 2.14812 8.06985C2.13167 7.94529 2.17231 7.81995 2.25872 7.72874C2.35804 7.6239 2.57266 7.59253 3.00189 7.5298L7.07794 6.93402C7.2047 6.91549 7.26808 6.90623 7.32328 6.88056C7.37215 6.85783 7.41615 6.82584 7.45284 6.78635C7.49427 6.74176 7.52262 6.68432 7.57933 6.56944L9.40156 2.8778Z"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
}
