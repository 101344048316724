export default function IconCompanySearch(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="briefcase">
        <path
          className="group-hover:stroke-[#5E2CED]"
          id="Icon"
          d="M14.6673 19.25V4.58333C14.6673 4.0971 14.4742 3.63079 14.1303 3.28697C13.7865 2.94315 13.3202 2.75 12.834 2.75H9.16732C8.68109 2.75 8.21477 2.94315 7.87096 3.28697C7.52714 3.63079 7.33398 4.0971 7.33398 4.58333V19.25M3.66732 6.41667H18.334C19.3465 6.41667 20.1673 7.23748 20.1673 8.25V17.4167C20.1673 18.4292 19.3465 19.25 18.334 19.25H3.66732C2.6548 19.25 1.83398 18.4292 1.83398 17.4167V8.25C1.83398 7.23748 2.6548 6.41667 3.66732 6.41667Z"
          stroke={props.stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
