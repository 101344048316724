import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from 'react-router-dom';
import Login from './components/login/index';
import Register from './components/register/index';
import ForgotPassword from './components/forgotpassword/index';
import ResetPassword from './components/resetpassword/index';
import Main from './components/dashboard/sidebar/components/main';
import Tellusmore from './components/dashboard/sidebar/components/tellusmore';
import VerifyEmail from './components/verifyemail/index';
import Emailverification from './components/verifyemail/emailverification';
import AppuserInviteAccept from './components/appuseracceptinvite/index';

import DashboardMain from '@maindashBoard';
import MainSearch from '@mainSearch';
import Mycontacts from '@myContacts';
import MyListHome from '@mainMyList';
import EnrichHome from '@mainEnrich';
import RevealedContactsHome from '@revealedContacts';

import Topbar from './components/dashboard/topbar';
import Sidebar from '@sidebar';
import UpgradePlan from '@upgradePlan';
import PayFail from '@payfail';
import PlanSuccess from '@paysuccess';
import CreditAddons from '@creditAddon';
import Settings from '@mainSettings';
import {useEffect} from 'react';

import Myaccounts from '@settingsMyaccount';
import Password from '@settingsPassword';
import Manageseats from '@settingsManageseats';
import Billing from '@settingsBilling';
import Plans from '@settingsPlans';
import Usage from '@settingsUsage';
import Api from '@settingsApi';
import Privacy from '@settingsPrivacy';
import Crm from '@settingsCrm';
import {classNames} from '@commonFunction';
// import Companydetails from './components/dashboard/search/companydetails';

const excludeTopBarPaths = [
  'upgradeplan',
  'settings',
  'paysuccess',
  'payfail',
  'creditaddons',
  'settingsprivacy',
  'settingsapi',
  'settingsusage',
  'settingsplans',
  'settingsbilling',
  'settingsmanageseats',
  'settingspassword',
  'settingsmyaccount',
  'settings*',
  'settingscrm',
  'tellusmore',
];

const includeSubBarPaths = ['search', 'enrich'];

export default function AppRoutes(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  let {pathname} = location;
  const paths = pathname.split('/').slice(1);
  pathname = paths[0].replaceAll('/', '');

  const hideTopBar = excludeTopBarPaths.includes(pathname);
  const hasSubBar = includeSubBarPaths.includes(pathname);

  useEffect(() => {
    // Should not proceed if LinkedIn clicked from Register page
    if (!props.isLinkedInClickedFromRegister) {
      //Exception email verification should get called even if logged out
      const directAccessWithoutLogin =
        pathname.indexOf('emailverification') !== -1 ||
        pathname.indexOf('acceptinvite') !== -1 ||
        pathname.indexOf('resetpassword') !== -1;

      // console.log('directAccessWithoutLogin => ', directAccessWithoutLogin);

      if (!props.isLoggedIn && pathname !== '' && !directAccessWithoutLogin) {
        navigate('/');
      } else if (pathname.indexOf('register') >= 0 && props.isLoggedIn) {
        console.log('else if for redirect to tellusmore');
        navigate('/tellusmore');
      } else if (
        pathname.indexOf('tellusmore') >= 0 &&
        props.openSocialSignupTellusMore
      ) {
        console.log('else if for redirect to tellusmore');
        navigate('/tellusmore');
      } else if (pathname === '' && props.isLoggedIn) {
        console.log('else if for redirect to dashboard');
        navigate('/dashboard');
      }
    }
  }, [
    props.isLoggedIn,
    props.isLinkedInClickedFromRegister,
    props.openSocialSignupTellusMore,
  ]);

  return props.isLoggedIn ? (
    <div style={{height: 'calc(100vh / var(--zoom))'}}>
      <Topbar hideTopBar={hideTopBar} router={{location, navigate, params}} />
      {hasSubBar ? <Sidebar router={{location, navigate, params}} /> : null}
      <div
        style={{
          height: !hasSubBar
            ? 'calc(100vh / var(--zoom) - 56px)'
            : 'calc(100vh / var(--zoom) - 106px)',
        }}
        className={classNames(
          'absolute w-full overflow-auto',
          !hasSubBar ? 'top-[56px]' : 'top-[106px]',
        )}>
        <Routes>
          <Route
            element={<DashboardMain router={{location, navigate, params}} />}
            path="/dashboard"
          />
          <Route
            element={<MainSearch router={{location, navigate, params}} />}
            path="/search/:query"
          />
          <Route
            element={<MainSearch router={{location, navigate, params}} />}
            path="/search"
          />
          <Route
            element={<MyListHome router={{location, navigate, params}} />}
            path="/mylists"
          />
          <Route
            element={<EnrichHome router={{location, navigate, params}} />}
            path="/enrich"
          />
          <Route
            element={
              <RevealedContactsHome router={{location, navigate, params}} />
            }
            path="/revealedcontacts"
          />
          <Route
            element={<UpgradePlan router={{location, navigate, params}} />}
            path="/upgradeplan"
          />
          {/* <Route
              element={<Companydetails router={{location, navigate, params}} />}
              path="/companydetails"
            />
            <Route
              element={<Companydetails router={{location, navigate, params}} />}
              path="/contactdetails"
            /> */}
          <Route
            element={
              <Settings
                router={{location, navigate, params}}
                component={
                  <Routes>
                    {/* Inside Settings Route */}
                    <Route path="myaccount">
                      <Route
                        element={
                          <Myaccounts router={{location, navigate, params}} />
                        }
                        path=""
                      />
                    </Route>
                    <Route
                      element={
                        <Password router={{location, navigate, params}} />
                      }
                      path="password"
                    />
                    <Route
                      element={
                        <Manageseats router={{location, navigate, params}} />
                      }
                      path="manageseats"
                    />
                    <Route
                      element={
                        <Billing router={{location, navigate, params}} />
                      }
                      path="billing"
                    />
                    <Route
                      element={<Plans router={{location, navigate, params}} />}
                      path="plans"
                    />
                    <Route
                      element={<Usage router={{location, navigate, params}} />}
                      path="usage"
                    />
                    <Route
                      element={<Api router={{location, navigate, params}} />}
                      path="api"
                    />
                    <Route
                      element={
                        <Privacy router={{location, navigate, params}} />
                      }
                      path="privacy"
                    />
                    <Route
                      element={<Crm router={{location, navigate, params}} />}
                      path="crm"
                    />
                  </Routes>
                }
              />
            }
            path="/settings/*"
          />
          <Route
            element={<PlanSuccess router={{location, navigate, params}} />}
            path="/paysuccess"
          />
          <Route
            element={<PayFail router={{location, navigate, params}} />}
            path="/payfail"
          />
          <Route
            element={<CreditAddons router={{location, navigate, params}} />}
            path="/creditaddons"
          />
        </Routes>
      </div>
    </div>
  ) : (
    <div style={{height: 'calc(100vh / var(--zoom))'}}>
      <div
        style={{
          height: 'calc(100vh / var(--zoom))',
        }}
        className={classNames('absolute w-full overflow-auto top-0')}>
        <Routes>
          <Route
            path="/"
            element={<Login key="1" router={{location, navigate, params}} />}
          />
          <Route path="/acceptinvite">
            <Route
              path=""
              element={
                <AppuserInviteAccept
                  key="9"
                  router={{location, navigate, params}}
                />
              }
            />
          </Route>
          <Route path="/emailverification">
            <Route
              path=""
              element={
                <Emailverification
                  key="8"
                  router={{location, navigate, params}}
                />
              }
            />
          </Route>

          <Route
            path="/verifyemail"
            element={
              <VerifyEmail key="7" router={{location, navigate, params}} />
            }
          />
          <Route path="/resetpassword">
            <Route
              path=""
              element={
                <ResetPassword key="5" router={{location, navigate, params}} />
              }
            />
          </Route>
          <Route
            path="/forgotpassword"
            element={
              <ForgotPassword key="3" router={{location, navigate, params}} />
            }
          />
          <Route
            path="/register"
            element={<Register key="2" router={{location, navigate, params}} />}
          />

          <Route
            path="/tellusmore"
            element={
              <Tellusmore key="6" router={{location, navigate, params}} />
            }
          />
        </Routes>
      </div>
    </div>
  );
}
