import {connect} from 'react-redux';
import {useState} from 'react';
import LocaleStrings from '../../../languages';
import Createlistpopup from '../../search/components/createlistpopup';
import ContactCell from './cells/ContactCell';
import {
  FILTER_TYPE_REVEALED_PEOPLE,
  updateRevealedPeople,
  postEnrich,
} from '../../search/actions';
import _ from 'lodash';
import toast, {Toaster} from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import ManageLabelsPopups from '../../mylist/components/managelabels';
import {LABEL_TYPE_REVEALED, updateLabel} from '../../mylist/actions';
import ReportContactPopup from '../../search/components/reportcontactpopup';
import {contactmetaAddupdate} from '@myContactsActions';

function ListTable(props) {
  const {selectedPersons, onSelectPerson, filteredPeople} = props;
  const [openCreateListPopup, setOpenCreateListPopup] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState();
  const [openFlagPopup, setOpenFlagPopup] = useState(false);
  const [selectedContact, setSelectedContact] = useState();

  const [openManageLabelsPopup, setOpenManageLabelsPopup] = useState(false);

  const [selectedList, setSelectedList] = useState(undefined);
  const navigate = useNavigate();

  const updateContactMeta = (val, action, type, linkedinurl, downvotedata) => {
    let obj = {};
    obj.linkedin_url = linkedinurl;
    obj.info = val;
    obj.action = action;
    obj.type = type;

    if (action === 'downvote') {
      obj.downvotereason = downvotedata.downvotereason;
      obj.reasonnote = downvotedata.reasonnote;
    }

    props.contactmetaAddupdate(props.session, obj, callback => {
      toast.success('Thanks! Your feedback has been submitted.');
    });
  };

  return (
    <div className="relative pr-4">
      <table className="w-full table-fixed divide-y divide-gray-300  sm:px-6 lg:px-8">
        <thead>
          <tr className="bg-gray-50">
            <th
              scope="col"
              className="relative w-10 bg-gray-50 z-50 sticky top-0"
            />
            <th
              scope="col"
              className="w-1/6 py-3.5 pr-3 text-left text-xs font-normal text-gray-700 relative px-7 bg-gray-50 z-50 sticky top-0">
              {LocaleStrings.search.peoplesearch.tableHeaders.contactname}
            </th>
            <th
              scope="col"
              className="w-1/6 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50 sticky top-0">
              {LocaleStrings.search.peoplesearch.tableHeaders.jobtitle}
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50 sticky top-0">
              {LocaleStrings.search.peoplesearch.tableHeaders.contactdetails}
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50 sticky top-0">
              {LocaleStrings.search.peoplesearch.tableHeaders.companyname}
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50 sticky top-0">
              {LocaleStrings.search.peoplesearch.tableHeaders.label}
            </th>
            <th className="relative w-20 bg-gray-50 z-50 sticky top-0" />
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {filteredPeople &&
            filteredPeople.map(person => {
              const filtered = selectedPersons.filter(
                item => item.peopleid === person.peopleid,
              );
              const selected = filtered.length > 0;
              return (
                <ContactCell
                  person={person}
                  selected={selected}
                  onCheckUnCheck={checked => {
                    onSelectPerson(person, checked);
                  }}
                  onOpenCreateList={person => {
                    setOpenCreateListPopup(!openCreateListPopup);
                    setSelectedPerson(person);
                  }}
                  onUpgradePlan={() => navigate('/upgradeplan')}
                  onBuyAddon={() => navigate('/creditaddons')}
                  onCopySuccess={() => {
                    toast.success('Copied successfully');
                  }}
                  onClickFlag={contact => {
                    if (contact.action === 'downvote') {
                      setOpenFlagPopup(true);
                      setSelectedContact({...contact, ...person});
                    } else {
                      toast.success(LocaleStrings.mycontacts_upvoted_success);
                    }
                  }}
                  onLabelSelection={label => {
                    if (!label.addnew) {
                      const newpeople = {...person, contacttag: label};
                      const index = props.filteredPeople.findIndex(
                        t => t.peopleid === person.peopleid,
                      );
                      props.filteredPeople[index] = newpeople;
                      props.updateRevealedPeople({
                        totalcount: props.totalcount,
                        pages: props.numPages,
                        data: props.filteredPeople,
                      });

                      props.updateLabel(
                        props.session,
                        {
                          contactid: person.contactid,
                          tag: label.title,
                        },
                        _ => {},
                      );
                    } else {
                      setSelectedList(person);
                      setOpenManageLabelsPopup(true);
                    }
                  }}
                />
              );
            })}
        </tbody>
      </table>
      <Createlistpopup
        open={openCreateListPopup}
        selectedPerson={selectedPerson}
        filterType={FILTER_TYPE_REVEALED_PEOPLE}
        toggleOpen={open => {
          setOpenCreateListPopup(open);
        }}
      />
      <Toaster />
      <ManageLabelsPopups
        type={LABEL_TYPE_REVEALED}
        open={openManageLabelsPopup}
        selectedList={selectedList}
        toggleOpen={open => {
          setOpenManageLabelsPopup(open);
          setSelectedList(undefined);
        }}
      />
      <ReportContactPopup
        open={openFlagPopup}
        {...selectedContact}
        onClose={() => {
          setOpenFlagPopup(false);
        }}
        onSubmit={data => {
          updateContactMeta(
            selectedContact.contact,
            'downvote',
            selectedContact.contacttype,
            selectedContact.linkedin,
            data,
          );
          setOpenFlagPopup(false);
          setSelectedContact(undefined);
        }}
      />
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  const {totalcount, pages, data} = state.filteredRevealedContacts;
  return {
    session,
    user,
    savedFilters,
    totalcount,
    numPages: pages,
    searchSelectedSubMenu: state.searchSelectedSubMenu,
    filteredPeople: data,
  };
}

export default connect(mapStateToProps, {
  updateRevealedPeople,
  updateLabel,
  contactmetaAddupdate,
  postEnrich,
})(ListTable);
