import {Fragment, useEffect, useRef, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {connect} from 'react-redux';
import {classNames} from '@commonFunction';
import IconReportEmail from './icons/reportemail';
import IconReportNumber from './icons/reportcontact';
import LocaleStrings from '../../../../languages';
import toast from 'react-hot-toast';

export const REPORT_TYPE_EMAIL = 'REPORT_TYPE_EMAIL';
export const REPORT_TYPE_NUMBER = 'REPORT_TYPE_NUMBER';

function ReportContactPopup(props) {
  let {contacttype, contact} = props;

  if (!contacttype) {
    contacttype = 'email';
  }

  const [open, setOpen] = useState(false);

  const [selected, setSelected] = useState(
    contacttype === 'directdial' ? 'outofservice' : 'invalid',
  );

  const cancelButtonRef = useRef(null);

  const [correctEmail, setCorrectEmail] = useState('');
  const [others, setOthers] = useState('');
  const [correctPhone, setCorrectPhone] = useState('');

  const type =
    contacttype === 'directdial' ? REPORT_TYPE_NUMBER : REPORT_TYPE_EMAIL;

  const clearAll = () => {
    setCorrectEmail('');
    setOthers('');
    setCorrectPhone('');
  };

  useEffect(() => {
    if (open !== props.open) {
      setOpen(props.open);
      setSelected(contacttype === 'directdial' ? 'outofservice' : 'invalid');
      clearAll();
    }
  }, [props.open]);

  const isValid = () => {
    let validated = true;
    if (selected === 'others' && others === '') {
      toast.error(LocaleStrings.flag.email.othersPlaceholder);
      validated = false;
    } else if (
      selected === 'invalid' &&
      type === REPORT_TYPE_EMAIL &&
      correctEmail === ''
    ) {
      toast.error(LocaleStrings.flag.email.invalidPlaceholder);
      validated = false;
    } else if (
      selected === 'invalid' &&
      correctPhone === '' &&
      type === REPORT_TYPE_NUMBER
    ) {
      toast.error(LocaleStrings.flag.phone.invalidPlaceholder);
      validated = false;
    }

    return validated;
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[9999]"
        initialFocus={cancelButtonRef}
        onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="flex items-center gap-4">
                    <div className="items-start justify-center">
                      {type === REPORT_TYPE_NUMBER ? (
                        <IconReportEmail />
                      ) : (
                        <IconReportNumber />
                      )}
                    </div>
                    <div className="text-left">
                      <div className="text-xs font-semibold leading-6 text-gray-900">
                        {type === REPORT_TYPE_EMAIL
                          ? LocaleStrings.flag.email.title
                          : LocaleStrings.flag.phone.title}
                      </div>
                      <div>
                        <p className="text-xs text-gray-500">
                          {type === REPORT_TYPE_EMAIL
                            ? LocaleStrings.flag.email.subtitle
                            : LocaleStrings.flag.phone.subtitle}
                        </p>
                        <p className="text-xs text-gray-500">
                          {type === REPORT_TYPE_EMAIL
                            ? LocaleStrings.flag.email.subtitle2
                            : LocaleStrings.flag.phone.subtitle2}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="py-4 mt-4">
                    {type === REPORT_TYPE_EMAIL ? (
                      <fieldset className="mt-2">
                        <div className="space-y-2">
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="invalid"
                                aria-describedby={`new-description`}
                                name="options"
                                type="radio"
                                value={'invalid'}
                                defaultChecked={selected === 'invalid'}
                                className="h-3 w-3 border-gray-300 text-primary focus:ring-0"
                                onChange={e => {
                                  setSelected(e.target.value);
                                }}
                              />
                            </div>
                            <div className="ml-3 text-xs leading-6">
                              <label
                                htmlFor="invalid"
                                className="text-gray-900">
                                {LocaleStrings.flag.email.invalid}
                              </label>
                            </div>
                          </div>
                          <div
                            className={classNames(
                              selected === 'invalid'
                                ? 'opacity-100'
                                : 'opacity-50',
                            )}>
                            <input
                              autoComplete="off"
                              disabled={selected !== 'invalid'}
                              name="email"
                              className="cust-input-field w-full text-xs"
                              placeholder={
                                LocaleStrings.flag.email.invalidPlaceholder
                              }
                              value={correctEmail}
                              onChange={e => setCorrectEmail(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className={classNames('py-4')}>
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="others"
                                name="options"
                                type="radio"
                                value={'others'}
                                defaultChecked={selected === 'others'}
                                className="h-3 w-3 border-gray-300 text-primary focus:ring-0"
                                onChange={e => {
                                  setSelected(e.target.value);
                                }}
                              />
                            </div>
                            <div className="ml-3 text-xs leading-6">
                              <label htmlFor="new" className="text-gray-900">
                                {LocaleStrings.flag.email.others}
                              </label>
                            </div>
                          </div>
                          <div
                            className={classNames(
                              selected === 'others'
                                ? 'opacity-100'
                                : 'opacity-50',
                            )}>
                            <input
                              autoComplete="off"
                              disabled={selected !== 'others'}
                              name="others"
                              className="cust-input-field w-full text-xs"
                              placeholder={
                                LocaleStrings.flag.email.othersPlaceholder
                              }
                              value={others}
                              onChange={e => setOthers(e.target.value)}
                            />
                          </div>
                        </div>
                      </fieldset>
                    ) : (
                      <fieldset>
                        <div className="space-y-2">
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="outofservice"
                                aria-describedby={`new-description`}
                                name="options"
                                type="radio"
                                value={'outofservice'}
                                defaultChecked={selected === 'outofservice'}
                                className="h-3 w-3 border-gray-300 text-primary focus:ring-0"
                                onChange={e => {
                                  setSelected(e.target.value);
                                }}
                              />
                            </div>
                            <div className="ml-3 text-xs leading-6">
                              <label
                                htmlFor="outofservice"
                                className="text-gray-900">
                                {LocaleStrings.flag.phone.outofservice}
                              </label>
                            </div>
                          </div>
                          <div className="relative flex items-start pt-2">
                            <div className="flex h-6 items-center">
                              <input
                                id="invalid"
                                aria-describedby={`new-description`}
                                name="options"
                                type="radio"
                                value={'invalid'}
                                defaultChecked={selected === 'invalid'}
                                className="h-3 w-3 border-gray-300 text-primary focus:ring-0"
                                onChange={e => {
                                  setSelected(e.target.value);
                                }}
                              />
                            </div>
                            <div className="ml-3 text-xs leading-6">
                              <label
                                htmlFor="invalid"
                                className="text-gray-900 text-xs">
                                {LocaleStrings.flag.phone.invalid}
                              </label>
                            </div>
                          </div>
                          <div
                            className={classNames(
                              selected === 'invalid'
                                ? 'opacity-100'
                                : 'opacity-50',
                            )}>
                            <input
                              autoComplete="off"
                              disabled={selected !== 'invalid'}
                              name="phone"
                              className="cust-input-field w-full text-xs"
                              placeholder={
                                LocaleStrings.flag.phone.invalidPlaceholder
                              }
                              value={correctPhone}
                              onChange={e => setCorrectPhone(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className={classNames('py-3')}>
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="others"
                                name="options"
                                type="radio"
                                value={'others'}
                                defaultChecked={selected === 'others'}
                                className="h-3 w-3 border-gray-300 text-primary focus:ring-0"
                                onChange={e => {
                                  setSelected(e.target.value);
                                }}
                              />
                            </div>
                            <div className="ml-3 text-xs leading-6">
                              <label htmlFor="new" className="text-gray-900">
                                {LocaleStrings.flag.email.others}
                              </label>
                            </div>
                          </div>
                          <div
                            className={classNames(
                              selected === 'others'
                                ? 'opacity-100'
                                : 'opacity-50',
                            )}>
                            <input
                              autoComplete="off"
                              disabled={selected !== 'others'}
                              name="others"
                              className="cust-input-field w-full text-xs"
                              placeholder={
                                LocaleStrings.flag.email.othersPlaceholder
                              }
                              value={others}
                              onChange={e => setOthers(e.target.value)}
                            />
                          </div>
                        </div>
                      </fieldset>
                    )}
                  </div>
                </div>
                <div className="mt-4 grid grid-flow-row-dense grid-cols-2 gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-white px-3.5 py-2 text-xs text-gray-900 border border-gray-200 shadow-sm hover:bg-gray-50"
                    onClick={() => props.onClose()}
                    ref={cancelButtonRef}>
                    {LocaleStrings.flag.cancel}
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-primary px-3.5 py-2 text-xs text-white shadow-sm hover:opacity-80"
                    onClick={() => {
                      if (isValid()) {
                        let data = {};
                        data.downvotereason = selected;
                        data.reasonnote =
                          selected === 'others'
                            ? others
                            : type === REPORT_TYPE_EMAIL
                              ? correctEmail
                              : correctPhone;

                        props.onSubmit(data);
                      }
                    }}>
                    {LocaleStrings.flag.email.submit}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function mapStateToProps(state, ownProps) {
  var {session, user} = state;
  return {
    session,
    user,
  };
}

export default connect(mapStateToProps, null)(ReportContactPopup);
