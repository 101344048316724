import {useState} from 'react';

function BasicSearch() {
  const [searchText, setSearchText] = useState('');

  const handleSearch = e => {};
  return (
    <div>
      <div className="flex">
        <div className="justify-start"></div>
        <div className="justify-end"></div>
      </div>
      <div>
        <input
          id="search-field"
          className="border-gray-300 w-auto px-10 py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
          placeholder={'Search by name'}
          type="search"
          name="search"
          value={searchText}
          onChange={handleSearch}
        />
      </div>
    </div>
  );
}

export default BasicSearch;
