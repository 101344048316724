import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {BaseComponent} from '@baseComponent';
import {classNames} from '@commonFunction';
import LocaleStrings from '@language';
import {
  revealedbyappuserdata,
  revealedbyappusername,
} from '@settingsUsageActions';

class UsagePlatformItem extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: '',
      btnLoader: false,
      endDate: props.endDate,
      startDate: props.startDate,
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  openRevealedmodal(e, values) {
    this.props.openRevealedmodal(values);
  }

  handleindex = nmbr => {
    if (nmbr % 2 == 0) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    var {values, keyindex} = this.props;

    let revealedcount = values.totalrevealedcount;
    let businessRevealedCount = values.busemailcount;
    let personalRevealedCount = values.personalemailcount;
    let phoneRevealedCount = values.directdialscount;
    let indexisodd1 = this.handleindex(keyindex);
    return (
      <>
        <tr className={classNames(indexisodd1 ? 'bg-white' : 'chatbg', '')}>
          <td className="whitespace-nowrap py-2 pl-4 pr-3 text-xs ">
            <div>{values.name}</div>
            <div className="text-gray-500">{values.email}</div>
          </td>
          <td
            className={classNames(
              'whitespace-nowrap py-2 px-4 pr-3 text-xs font-medium text-center',
              revealedcount > 0 ? 'cursor-pointer' : 'cursor-default',
            )}>
            {revealedcount}
            {revealedcount > 0 ? (
              <div
                className="text-primary"
                onClick={e => this.openRevealedmodal(e, values)}>
                {LocaleStrings.setting_usage_view_contact}
              </div>
            ) : null}
          </td>
          <td className="whitespace-nowrap py-2  px-4 pr-3 text-xs font-medium cursor-pointer text-center">
            {personalRevealedCount}
          </td>
          <td className="whitespace-nowrap py-2  px-4 pr-3 text-xs font-medium cursor-pointer text-center">
            {businessRevealedCount}
          </td>
          <td className="whitespace-nowrap py-2  px-4 pr-3 text-xs font-medium cursor-pointer text-center">
            {phoneRevealedCount}
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var {session, user} = state;
  return {
    session,
    user,
  };
}

export default connect(mapStateToProps, {
  revealedbyappuserdata,
  revealedbyappusername,
})(UsagePlatformItem);
