import {useEffect, useState} from 'react';

import {connect} from 'react-redux';
import {savePeopleListFilter} from '../actions';
import {labelNotAssigned} from '../constants';
import {CheckIcon} from '@heroicons/react/solid';

function FilterLabelsDropdown(props) {
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    const filters = props.savedFilters;
    const tags = selectedValues
      .join(',')
      .replace(labelNotAssigned.title, labelNotAssigned.value);
    filters.tags = tags;
    props.savePeopleListFilter(filters);
  }, [selectedValues]);

  const allLabels = [{...labelNotAssigned}, ...props.availableLabels];

  return (
    <div className="w-[30rem] h-auto p-3 bg-white rounded-lg drop-shadow-xl border border-gray-200 flex-col justify-start items-start gap-3 inline-flex">
      <div className="self-stretch justify-start items-start gap-6">
        <div className="justify-start items-center flex gap-2 flex-wrap">
          {allLabels.map(label => {
            const {title, color} = label;
            const selected = selectedValues.includes(title);
            return (
              <div
                key={title}
                className="px-2 py-1 rounded-2xl inline-flex justify-start items-center flex gap-1 transition ease-in duration-100 hover:cursor-pointer hover:scale-105"
                style={{
                  backgroundColor: `${color}20`,
                }}
                onClick={() => {
                  setSelectedValues(
                    !selected
                      ? [...selectedValues, title]
                      : selectedValues.filter(p => p !== title),
                  );
                }}>
                <div
                  className="text-center text-xs"
                  style={{
                    color: `${color}`,
                  }}>
                  {title}
                </div>
                {selected ? (
                  <div className="w-5 h-5 px-0.5 justify-center items-center flex">
                    <CheckIcon
                      className="h-4 w-4"
                      aria-hidden="true"
                      style={{
                        color: `${color}`,
                      }}
                    />
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
        <div
          className="py-2 px-2 pt-4 text-primary hover:cursor-pointer"
          onClick={() => {
            setSelectedValues([]);
            props.onClose();
          }}>
          Clear All
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    availableLabels: state.availableLabels,
    savedFilters: state.mylistPeopleFilters,
  };
}

export default connect(mapStateToProps, {savePeopleListFilter})(
  FilterLabelsDropdown,
);
