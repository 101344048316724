import {classNames} from '@commonFunction';

export const ExportMenuTypes = {
  CSV: 'csv',
  ZOHO: 'zoho',
  SALESFORCE: 'salesforce',
  ZAPIER: 'zapier',
};

export const LimitedExportMenuList = [
  {title: 'Export to CSV', type: ExportMenuTypes.CSV},
];

export const ExportMenuList = [
  {title: 'Export to CSV', type: ExportMenuTypes.CSV},
  {title: 'Export to Zoho', type: ExportMenuTypes.ZOHO},
  {title: 'Export to SalesForce', type: ExportMenuTypes.SALESFORCE},
  {title: 'Export to Webhook', type: ExportMenuTypes.ZAPIER},
];

function MenuLabel(props) {
  let {title, connected} = props;
  if (!connected) {
    title = title.replace('Export to', 'Connect to');
  }
  return (
    <div
      className={classNames(
        'group w-full rounded-lg p-1 ',
        'hover:bg-gray-50 hover:cursor-pointer hover:scale-105 transition ease-in duration-100',
      )}
      onClick={() => {
        props.onSelectedMenu(props.type);
      }}>
      <div
        className={classNames('justify-start items-start gap-1 inline-flex')}>
        <div className="px-2 py-1 rounded-2xl justify-start items-center gap-1 flex">
          <div
            className={classNames(
              'text-center text-xs leading-none',
              'group-hover:text-primary',
            )}>
            {title}
          </div>
        </div>
      </div>
    </div>
  );
}

function MenuDropdown(props) {
  const list = props.limited ? LimitedExportMenuList : ExportMenuList;
  return (
    <div className="h-auto p-2 bg-white rounded-lg shadow-md border border-gray-100 flex-col justify-start items-start gap-3 inline-flex">
      <div className="self-stretch justify-start items-start gap-6 inline-flex">
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
          {list.map(val => (
            <MenuLabel
              connected={
                val.type === ExportMenuTypes.CSV ? true : props[val.type]
              }
              key={val.type}
              {...val}
              onSelectedMenu={props.onSelectedMenu}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default MenuDropdown;
