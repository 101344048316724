import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {connect} from 'react-redux';
import {classNames} from '@commonFunction';
import CompanyInfo from '../../search/results/cells/components/companyinfo';
import ContactInfo from '../../search/results/cells/components/contactinfo';
import IconUpdate from '../../search/results/cells/icons/update';
import SocialLinks from '../../search/results/cells/components/sociallinks';
import IconCopy from '../../search/results/cells/icons/copy';
import IconExport from '../icons/export';
import {formattedDateIntoLocal} from '@commonFunction';
import IconDelete from '../icons/delete';
import {
  ENTERPRISE_MONTHLY,
  ENTERPRISE_YEARLY,
  toTitleCase,
} from '../../../../common/constants';
import IconLock from '../../search/results/cells/icons/lock';
import {useNavigate} from 'react-router-dom';
import LocaleStrings from '../../../languages';
import CompanyBlock from '../../search/results/cells/components/companyblock';
import Details from '../../search/companyresults/details';
import IconChevronDown from '../../search/companyresults/cells/icons/chevrondown';

function MyListCompanyCell(props) {
  const {selected, onCheckUnCheck} = props;
  const {
    companyname,
    industry,
    location,
    company,
    hqnumbers,
    technologies,
    isfav,
    addedtolist,
    listid,
    new_contact_data_available,
    new_company_data_available,
    createdon,
  } = props.company;

  const [expand, setExpand] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const revealed = true;

  const {headcount, revenue} = company;

  const techsToShow = technologies.length > 0 ? technologies[0] : '';

  const [showList, setShowList] = useState(false);

  const navigate = useNavigate();

  return (
    <Fragment>
      <tr
        key={companyname}
        className={classNames('', selected ? 'bg-gray-50' : undefined)}>
        <td className="group relative px-7 py-2">
          {selected && (
            <div className="absolute inset-y-0 left-0 w-0.5 bg-primary" />
          )}
          <input
            type="checkbox"
            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-500 text-primary group-hover:cursor-pointer"
            value={companyname}
            checked={selected}
            onChange={e => onCheckUnCheck(e.target.checked)}
          />
        </td>
        <td
          className={classNames(
            'group pr-3 hover:bg-gray-50 hover:cursor-pointer relative  px-7 py-2',
          )}
          onClick={e => {
            //Dont allow to consume click on icons
            if (e.target.tagName !== 'svg' || e.target.tagName !== 'path') {
              if (expand === expanded) {
                //Call click only both are same to avoid multiple clicks
                if (!expand) {
                  setExpand(true);
                  setTimeout(() => {
                    setExpanded(true);
                  }, 10);
                } else {
                  setExpanded(false);
                  setTimeout(() => {
                    setExpand(false);
                  }, 400);
                }
              }
            }
          }}>
          <CompanyBlock company={props.company.company} />
        </td>
        <td
          className="group whitespace-wrap px-3 py-2 relative hover:cursor-pointer"
          onClick={() => {
            if (expand === expanded) {
              //Call click only both are same to avoid multiple clicks
              if (!expand) {
                setExpand(true);
                setTimeout(() => {
                  setExpanded(true);
                }, 10);
              } else {
                setExpanded(false);
                setTimeout(() => {
                  setExpand(false);
                }, 400);
              }
            }
          }}>
          <div className="text-xs text-gray-500 truncate">
            {toTitleCase(industry)}
          </div>
          {/* <div
          className="absolute right-2 top-7 group-hover:opacity-100 opacity-0"
          onClick={() => {
            navigator.clipboard.writeText(industry);
            props.onCopySuccess();
          }}>
          <IconCopy />
        </div> */}
        </td>
        <td
          className="group px-3 py-2 relative hover:cursor-pointer"
          onClick={e => {
            if (expand === expanded) {
              //Call click only both are same to avoid multiple clicks
              if (!expand) {
                setExpand(true);
                setTimeout(() => {
                  setExpanded(true);
                }, 10);
              } else {
                setExpanded(false);
                setTimeout(() => {
                  setExpand(false);
                }, 400);
              }
            }
          }}>
          <div className="relative flex">
            <div className="text-xs mb-2 text-gray-500 w-fit truncate">
              {location}
            </div>
          </div>
          {/* <div
          className="absolute right-2 top-6 group-hover:opacity-100 opacity-0"
          onClick={() => {
            navigator.clipboard.writeText(location);
            props.onCopySuccess();
          }}>
          <IconCopy />
        </div> */}
        </td>
        <td
          className="w-1/3 group px-3 py-2 relative hover:cursor-pointer"
          onClick={() => {
            if (expand === expanded) {
              //Call click only both are same to avoid multiple clicks
              if (!expand) {
                setExpand(true);
                setTimeout(() => {
                  setExpanded(true);
                }, 10);
              } else {
                setExpanded(false);
                setTimeout(() => {
                  setExpand(false);
                }, 400);
              }
            }
          }}>
          <div className="text-xs mb-2 text-gray-500">
            {' '}
            {headcount === '-1'
              ? 'Self Employed'
              : headcount === '0'
                ? 'Unknown'
                : headcount}
          </div>
        </td>

        {/* <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500 justify-items-center">
        <div className="text-xs mb-2 hover:font-medium">{revenue}</div>
      </td> */}
        <td className="group whitespace-nowrap px-3 py-2 text-xs text-gray-500 justify-items-center relative hover:cursor-pointer">
          <div
            className={classNames(
              'text-xs mb-2',
              props.credits.role === 'client' && !props.onEnterprisePlan
                ? 'blur-sm hover:font-medium'
                : '',
            )}
            onClick={e => {
              if (e.currentTarget === e.target) {
                if (expand === expanded) {
                  //Call click only both are same to avoid multiple clicks
                  if (!expand) {
                    setExpand(true);
                    setTimeout(() => {
                      setExpanded(true);
                    }, 10);
                  } else {
                    setExpanded(false);
                    setTimeout(() => {
                      setExpand(false);
                    }, 400);
                  }
                }
              }
            }}>
            {techsToShow}
            {props.credits.role === 'client' && props.onEnterprisePlan ? (
              <div
                className="text-primary hover:cursor-pointer"
                onClick={e => {
                  console.log(e.currentTarget, '===', e.target);
                  if (e.currentTarget === e.target) {
                    props.onShowTechs(props.company);
                  }
                }}>
                View All
              </div>
            ) : null}
          </div>
          {props.credits.role === 'client' && !props.onEnterprisePlan ? (
            <div
              className="absolute top-2 left-0 flex bg-primaryHover justify-center items-center px-10 py-2 rounded-lg transition-opacity ease-in group-hover:opacity-60 opacity-0"
              onClick={() => {
                navigate('/upgradeplan');
              }}>
              <IconLock size={18} />{' '}
              <span className="text-primary ml-2">{LocaleStrings.upgrade}</span>
            </div>
          ) : null}
        </td>
        <td
          className="px-3 py-2 text-xs text-gray-500 justify-items-center hover:cursor-pointer"
          onClick={() => {
            if (expand === expanded) {
              //Call click only both are same to avoid multiple clicks
              if (!expand) {
                setExpand(true);
                setTimeout(() => {
                  setExpanded(true);
                }, 10);
              } else {
                setExpanded(false);
                setTimeout(() => {
                  setExpand(false);
                }, 400);
              }
            }
          }}>
          {formattedDateIntoLocal(createdon)}
        </td>
        <td className="py-2 pl-3 justify-items-center pr-4">
          <div className="flex justify-end mr-12 gap-2">
            {/* <div onClick={() => {}}>
              <IconExport />
            </div> */}
            <div
              onClick={() => {
                props.onDeleteFromList(props.company);
              }}>
              <IconDelete />
            </div>
            <div
              className="ml-4 flex items-center"
              onClick={() => {
                if (expand === expanded) {
                  //Call click only both are same to avoid multiple clicks
                  if (!expand) {
                    setExpand(true);
                    setTimeout(() => {
                      setExpanded(true);
                    }, 10);
                  } else {
                    setExpanded(false);
                    setTimeout(() => {
                      setExpand(false);
                    }, 400);
                  }
                }
              }}>
              <div
                className={classNames(
                  'hover:cursor-pointer transition-all transform',
                  expand ? 'rotate-180' : 'rotate-0',
                )}>
                <IconChevronDown />
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr
        className={classNames(
          expand ? 'bg-primaryHover' : '',
          !expand ? 'hidden' : '',
        )}>
        <td colSpan={8}>
          <div
            className={classNames(
              ' transition-all duration-500 h-0 overflow-hidden',
              expanded ? 'h-[380px]' : 'h-0',
            )}>
            <div className="px-7 py-2 flex">
              <Details
                {...props}
                company={{...company, ...props.company}}
                credits={props.credits}
                mylist
              />
            </div>
          </div>
        </td>
      </tr>
    </Fragment>
  );
}

function mapStateToProps(state, ownProps) {
  var {session, user, savedFilters, appuserData} = state;
  let onEnterprisePlan = false;
  if (appuserData.credits) {
    onEnterprisePlan =
      appuserData.credits.planid === ENTERPRISE_YEARLY ||
      appuserData.credits.planid === ENTERPRISE_MONTHLY;
  }
  return {
    session,
    user,
    onEnterprisePlan,
    credits: !appuserData.credits ? {role: ''} : appuserData.credits,
    savedFilters,
    searchSelectedSubMenu: state.searchSelectedSubMenu,
  };
}

export default connect(mapStateToProps, null)(MyListCompanyCell);
