export default function IconPeopleSearch(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="user">
        <path
          className="group-hover:stroke-[#5E2CED]"
          id="Icon"
          d="M18.3327 19.25V17.4167C18.3327 16.4442 17.9464 15.5116 17.2587 14.8239C16.5711 14.1363 15.6385 13.75 14.666 13.75H7.33268C6.36022 13.75 5.42759 14.1363 4.73996 14.8239C4.05232 15.5116 3.66602 16.4442 3.66602 17.4167V19.25M14.666 6.41667C14.666 8.44171 13.0244 10.0833 10.9993 10.0833C8.9743 10.0833 7.33268 8.44171 7.33268 6.41667C7.33268 4.39162 8.9743 2.75 10.9993 2.75C13.0244 2.75 14.666 4.39162 14.666 6.41667Z"
          stroke={props.stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
