import {FilterIcon} from '@heroicons/react/solid';
export default function NoResult(props) {
  return (
    <div>
      <table className="w-full h-full table-fixed divide-y divide-gray-300 sm:px-6 lg:px-8" />
      <div className="flex justify-center items-center flex-col">
        <FilterIcon className="h-8 w-8 text-primary" aria-hidden="true" />
        <div className="text-base text-gray-500">No Result(s)</div>
        {props.hasFilters ? (
          <div className="text-sm text-gray-400">
            Expand or, change your filters
          </div>
        ) : null}
      </div>
    </div>
  );
}
