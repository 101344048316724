import React, {useCallback, useEffect, useState} from 'react';
import {EXTENSION_LINK} from './constants';
import {classNames} from '@commonFunction';

const texts = [
  'The only solution you need to run a world-class sales organization! Explore the most accurate B2B data.',
  'Export revealed contact details of decision-makers from a variety of industries globally with just one click.',
  'Our LinkedIn extension gives you convenient access to the B2B contact database and other information of the prospects in a single view.',
  'Enrich your prospecting at scale, and update missing or outdated data into new business opportunities in seconds.',
];

export default function LoginSideBar() {
  const [newText, setNewText] = useState(texts[0]);
  const [fading, setFading] = useState(false);

  const shuffle = useCallback(() => {
    setFading(true);
    setTimeout(() => {
      const index = Math.floor(Math.random() * texts.length);
      setNewText(texts[index]);
      setFading(false);
    }, 400);
  }, []);

  useEffect(() => {
    const intervalID = setInterval(shuffle, 6000);
    return () => clearInterval(intervalID);
  }, [shuffle]);

  return (
    <div
      className="w-2/6 bg-gradient-to-b from-[#5E2CED] to-[#303E4B] flex-col justify-center items-center inline-flex"
      style={{height: 'calc(100vh / var(--zoom))'}}>
      <div className="self-stretch px-6 flex-col justify-start items-center flex">
        <div className="self-stretch h-64 flex-col justify-center items-center gap-8 flex">
          <div
            className={classNames(
              "self-stretch text-white text-lg font-medium font-['Inter'] leading-9 text-center h-80 items-center flex transition duration-300 ease-in",
              fading ? 'opacity-0' : 'opacity-100',
            )}>
            {newText}
          </div>
          {/* <div className="self-stretch justify-center items-center gap-16 inline-flex">
            <div className="justify-center items-center gap-4 flex">
              <div className="w-2.5 h-2.5 relative bg-white/opacity-50 rounded-md" />
              <div className="w-2.5 h-2.5 relative bg-white rounded-md" />
              <div className="w-2.5 h-2.5 relative bg-white/opacity-50 rounded-md" />
              <div className="w-2.5 h-2.5 relative bg-white/opacity-50 rounded-md" />
            </div>
          </div> */}
          <div className="px-4 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-center items-center gap-1.5 inline-flex">
            <div className="px-0.5 justify-center items-center flex">
              <div
                className="text-slate-700 text-sm  leading-normal cursor-pointer hover:bg-gray-100"
                onClick={() => {
                  window.open(EXTENSION_LINK, '_blank');
                }}>
                Download Kipplo Chrome Extension
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-4 px-8">
        <div className="text-white text-sm font-normal font-['Inter'] leading-tight">
          © Kipplo 2024 - All Rights Reserved
        </div>
      </div>
    </div>
  );
}
