import {Dialog, Transition} from '@headlessui/react';
import {Fragment, useEffect, useState} from 'react';
import Dialogentry from './dialogentry';
import {connect} from 'react-redux';

function PreSelectionPopup(props) {
  const closeModal = () => {
    props.onCloseModal();
  };

  const heightDiff = 300 * (window.innerHeight / 754);

  console.log('window.innerHeight => ', window.innerHeight);

  const [popupHeight] = useState(`calc(100vh / var(--zoom) - ${heightDiff}px)`);

  useEffect(() => {}, [props.section]);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-[999] inset-0 overflow-y-auto"
        open={props.open}
        onClose={closeModal}>
        <div
          className="flex items-end justify-center pt-4 px-4 text-center sm:block sm:p-0 content-center"
          style={{height: 'calc(100vh / var(--zoom))'}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div
              className="inline-block rounded-xl align-bottom  bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-3/5"
              style={{padding: 10}}>
              <div
                className="flex justify-center content-center h-full"
                style={{height: popupHeight}}>
                <Dialogentry embedded />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function mapStateToProps(state) {
  return {
    section: state.csvSelectionPopupData.section,
  };
}
export default connect(mapStateToProps, null)(PreSelectionPopup);
