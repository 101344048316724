import React from 'react';

export default function IconBusinessEmail(props) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="briefcase-01">
        <path
          id="Icon"
          d="M13.3337 5.83333C13.3337 5.05836 13.3337 4.67087 13.2485 4.35295C13.0173 3.49022 12.3434 2.81635 11.4807 2.58519C11.1628 2.5 10.7753 2.5 10.0003 2.5C9.22535 2.5 8.83786 2.5 8.51995 2.58519C7.65721 2.81635 6.98335 3.49022 6.75218 4.35295C6.66699 4.67087 6.66699 5.05836 6.66699 5.83333M4.33366 17.5H15.667C16.6004 17.5 17.0671 17.5 17.4236 17.3183C17.7372 17.1586 17.9922 16.9036 18.152 16.59C18.3337 16.2335 18.3337 15.7668 18.3337 14.8333V8.5C18.3337 7.56658 18.3337 7.09987 18.152 6.74335C17.9922 6.42975 17.7372 6.17478 17.4236 6.01499C17.0671 5.83333 16.6004 5.83333 15.667 5.83333H4.33366C3.40024 5.83333 2.93353 5.83333 2.57701 6.01499C2.2634 6.17478 2.00844 6.42975 1.84865 6.74335C1.66699 7.09987 1.66699 7.56658 1.66699 8.5V14.8333C1.66699 15.7668 1.66699 16.2335 1.84865 16.59C2.00844 16.9036 2.2634 17.1586 2.57701 17.3183C2.93353 17.5 3.40024 17.5 4.33366 17.5Z"
          stroke={props.selected ? '#17B26A' : '#667085'}
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
