import React, {useEffect, useState} from 'react';
import IconPlus from '@assets/images/searchmodule/filter/plus.svg';
import IconMinus from '@assets/images/searchmodule/filter/minus.svg';
import Chip from './Chip';
import {classNames} from '@commonFunction';
import {connect} from 'react-redux';
import {saveFilter} from '../components/dashboard/search/actions';
import {toUpper} from 'lodash';

function Accordion(props) {
  const {
    title,
    icon,
    content,
    type,
    filtersCount,
    onClear,
    savedFilters,
    minimized,
    collapsed,
  } = props;

  const [expanded, setExpanded] = useState(!collapsed);

  const toggleExpanded = toggled => {
    if (toggled) {
      props.onOpenAccordian({title, type, icon, collapsed});
    } else {
      props.onCloseAccordian();
    }
  };
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (expanded === collapsed) {
      setExpanded(!collapsed);
    }
  }, [collapsed]);

  const updateFilterReducer = (vals, subtype) => {
    let filters = props.appliedFilters;
    let searchObject = filters;
    if (type === 'location') {
      searchObject[type][subtype] = vals;
    } else if (type === 'revenue') {
      searchObject[type] = vals.map(val => val.title);
    } else if (type === 'foundedyear') {
      searchObject[type] = vals.map(val => val.title);
    } else if (type === 'industry' && subtype === 'industrycode') {
      searchObject['industrycode'] = vals.map(val => ({
        type: val.title.split(' - ')[0],
        code: val.title.split(' - ')[1],
      }));
    } else {
      searchObject[type] = vals;
    }
    filters = {...filters, ...searchObject};
    props.saveFilter(props.session, {...filters}, () => {});
  };

  const parts = window.location.href.split('/');
  let answer = '';
  if (parts.length > 1) {
    answer = parts[parts.length - 1];
  }
  const toModifyOverflow =
    type === 'foundedyear' || type === 'revenue' || type === 'industry';
  return (
    <div
      className={classNames(
        'py-3 hover:cursor-pointer pl-2',
        expanded ? 'bg-[#F7F8FF]' : '',
      )}>
      <div
        className={classNames(
          'px-2 text-left items-center h-6 select-none flex justify-between flex-row relative',
          expanded ? 'bg-[#F7F8FF]' : '',
        )}
        onClick={() => {
          toggleExpanded(!expanded);
        }}>
        {minimized ? (
          <div className="text-left flex flex-row">
            <img className="absolute w-4 top-2" src={icon} />
          </div>
        ) : (
          <div className="text-left flex flex-row items-center mt-1">
            <img className="absolute w-4 left-2" src={icon} />
            <span className="absolute left-8 text-left text-xs text-gray-600 font-medium">
              {title}
            </span>
          </div>
        )}
        {filtersCount > 0 && minimized ? (
          <div
            className={classNames(
              'absolute bg-primary flex',
              answer === 'revealedcontacts' ? 'top-0 right-3' : 'top-0 right-2',
            )}
            style={{
              borderRadius: '50%',
              width: 8,
              height: 8,
            }}></div>
        ) : null}
        {!minimized ? (
          <div className="flex">
            {filtersCount > 0 ? (
              <div
                className="flex-none text-xs text-primary text-right mt-1"
                onClick={() => {
                  onClear(type);
                }}>{`Clear(${filtersCount})`}</div>
            ) : null}
            <div
              className="flex-none pl-2"
              onClick={() => {
                toggleExpanded(false);
                setTimeout(() => {
                  setOpen(false);
                }, 100);
              }}>
              {expanded ? (
                <img src={IconMinus} width={18} />
              ) : (
                <img src={IconPlus} width={18} />
              )}
            </div>
          </div>
        ) : null}
      </div>
      {!minimized ? (
        <div
          className={classNames(
            'px-2 pt-0 overflow-hidden',
            expanded ? 'max-h-[50rem] ease-in overflow-visible' : 'max-h-0',
            !open && toModifyOverflow ? 'overflow-hidden' : '',
          )}>
          <div className="text-left">{content}</div>
        </div>
      ) : null}
      {!expanded && !minimized && filtersCount > 0 ? (
        type === 'location' ? (
          <div className="flex flex-col">
            {savedFilters['contact'].length > 0 ? (
              <div className="justify-start px-4 pt-2 items-center gap-2 inline-flex">
                <div className="justify-start items-center gap-1.5 flex flex-wrap">
                  {savedFilters['contact'].map((val, index) => (
                    <Chip
                      key={`${index}`}
                      {...val}
                      onRemove={item => {
                        const filtered = props.appliedFilters['location'][
                          'contact'
                        ].filter(theVal => theVal.title !== item.title);
                        updateFilterReducer(filtered, 'contact');
                      }}
                    />
                  ))}
                </div>
              </div>
            ) : null}
            {savedFilters['contact'].length > 0 &&
            savedFilters['hq'].length > 0 ? (
              <hr className="mt-2 mx-4" />
            ) : null}
            {savedFilters['hq'].length > 0 ? (
              <div className="justify-start px-4 pt-2 items-center gap-2 inline-flex">
                <div className="justify-start items-center gap-1.5 flex flex-wrap">
                  {savedFilters['hq'].map((val, index) => (
                    <Chip
                      key={`${index}`}
                      {...val}
                      onRemove={item => {
                        const filtered = props.appliedFilters['location'][
                          'hq'
                        ].filter(theVal => theVal.title !== item.title);
                        updateFilterReducer(filtered, 'hq');
                      }}
                    />
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="justify-start px-4 pt-2 items-center gap-2 inline-flex">
            <div className="justify-start items-center gap-1.5 flex flex-wrap">
              {savedFilters.map((val, index) => {
                let filterVal = {...val};
                if (type === 'revenue') {
                  filterVal = {
                    ...filterVal,
                    title: toUpper(filterVal['title']),
                  };
                }
                return (
                  <Chip
                    key={`${index}`}
                    {...filterVal}
                    onRemove={item => {
                      let filters = [];
                      if (type === 'revenue') {
                        filters = props.appliedFilters[type].map(val => ({
                          title: toUpper(val),
                          action: 'include',
                        }));
                      } else if (type === 'foundedyear') {
                        filters = props.appliedFilters[type].map(val => ({
                          title: val,
                          action: 'include',
                        }));
                      } else if (type === 'industry') {
                        if (
                          item.title.includes('SIC') ||
                          item.title.includes('NASIC')
                        ) {
                          filters = props.appliedFilters['industrycode'].map(
                            ({type, code}) => ({
                              title: `${type} - ${code}`,
                              action: 'include',
                            }),
                          );
                          const filtered = filters.filter(
                            theVal => theVal.title !== item.title,
                          );
                          updateFilterReducer(filtered, 'industrycode');
                        } else {
                          filters = props.appliedFilters[type];

                          const filtered = filters.filter(
                            theVal => theVal.title !== item.title,
                          );
                          updateFilterReducer(filtered);
                        }
                      } else {
                        filters = props.appliedFilters[type];
                      }

                      if (type !== 'exclude' && type !== 'industry') {
                        const filtered = filters.filter(
                          theVal => theVal.title !== item.title,
                        );
                        updateFilterReducer(filtered);
                      } else if (type === 'exclude') {
                        let {
                          revealedbusinessemails,
                          revealedpersonalemails,
                          revealedphonenumbers,
                          revealedcontacts,
                          revealedtech,
                        } = props.appliedFilters[type];

                        if (item.title === 'Revealed business emails') {
                          revealedbusinessemails = false;
                        }
                        if (item.title === 'Revealed personal emails') {
                          revealedpersonalemails = false;
                        }
                        if (item.title === 'Revealed phone number') {
                          revealedphonenumbers = false;
                        }
                        if (item.title === 'Revealed technologies') {
                          revealedtech = false;
                        }
                        if (item.title === 'Revealed contact numbers') {
                          revealedcontacts = false;
                        }

                        updateFilterReducer({
                          revealedbusinessemails,
                          revealedpersonalemails,
                          revealedphonenumbers,
                          revealedcontacts,
                          revealedtech,
                        });
                      }
                    }}
                  />
                );
              })}
            </div>
          </div>
        )
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    appliedFilters: savedFilters,
  };
}

export default connect(mapStateToProps, {saveFilter})(Accordion);
