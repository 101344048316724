export default function IconFav(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="star-01">
        <path
          className="group-hover:stroke-[#5E2CED]"
          id="Icon"
          d="M10.3421 3.16544C10.5534 2.73741 10.659 2.5234 10.8024 2.45502C10.9272 2.39553 11.0722 2.39553 11.1969 2.45502C11.3404 2.5234 11.446 2.73741 11.6573 3.16544L13.6617 7.22624C13.7241 7.3526 13.7553 7.41579 13.8009 7.46484C13.8412 7.50828 13.8896 7.54347 13.9434 7.56847C14.0041 7.5967 14.0738 7.60689 14.2133 7.62727L18.6969 8.28263C19.1691 8.35164 19.4051 8.38615 19.5144 8.50147C19.6094 8.6018 19.6542 8.73967 19.6361 8.87669C19.6153 9.03418 19.4443 9.20065 19.1025 9.53358L15.8593 12.6924C15.7582 12.7909 15.7077 12.8402 15.6751 12.8987C15.6462 12.9506 15.6277 13.0076 15.6205 13.0665C15.6124 13.1331 15.6244 13.2026 15.6482 13.3417L16.4135 17.8035C16.4942 18.2741 16.5345 18.5094 16.4587 18.649C16.3927 18.7705 16.2754 18.8557 16.1395 18.8809C15.9832 18.9099 15.7719 18.7988 15.3494 18.5765L11.341 16.4686C11.2161 16.4029 11.1537 16.3701 11.0879 16.3572C11.0296 16.3457 10.9697 16.3457 10.9115 16.3572C10.8457 16.3701 10.7832 16.4029 10.6584 16.4686L6.65002 18.5765C6.22743 18.7988 6.01613 18.9099 5.8599 18.8809C5.72397 18.8557 5.60667 18.7705 5.54068 18.649C5.46484 18.5094 5.5052 18.2741 5.58591 17.8035L6.35115 13.3417C6.37501 13.2026 6.38694 13.1331 6.37887 13.0665C6.37172 13.0076 6.35319 12.9506 6.32431 12.8987C6.29169 12.8402 6.24114 12.7909 6.14004 12.6924L2.89685 9.53358C2.55503 9.20065 2.38412 9.03418 2.36332 8.87669C2.34523 8.73967 2.38993 8.6018 2.48499 8.50147C2.59424 8.38615 2.83031 8.35164 3.30246 8.28263L7.78613 7.62727C7.92556 7.60689 7.99528 7.5967 8.056 7.56847C8.10976 7.54347 8.15816 7.50828 8.19851 7.46484C8.24409 7.41579 8.27528 7.3526 8.33765 7.22624L10.3421 3.16544Z"
          stroke={props.stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
