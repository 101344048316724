import {toUpper} from 'lodash';
import LocaleStrings from '../../../../../../languages';
import IconDelete from '../icons/delete';
import {classNames} from '@commonFunction';
import {toTitleCase} from '../../../../../../../common/constants';

function SubCell(props) {
  return (
    <div className="self-stretch justify-start items-center gap-4 mb-2">
      <div className="text-gray-500 text-xs">{props.title}</div>
      <div className="grow shrink basis-0 justify-start items-center gap-1.5 inline-flex flex-wrap mt-2">
        {props.values.map(val => (
          <div className="px-2 py-0.5 bg-white rounded-md border border-gray-300 justify-center items-center gap-0.5 flex">
            <div className="justify-start items-center gap-1 flex">
              <div
                className={classNames(
                  'text-center text-xs leading-none',
                  val.action && val.action === 'exclude'
                    ? 'text-red-400'
                    : 'text-slate-700',
                )}>
                {val.title}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default function SavedSearchCell(props) {
  const {filterid, filtername, jsondata} = props;

  const excluded = [];
  if (jsondata.exclude.revealedbusinessemails) {
    excluded.push({title: 'Revealed business Emails', action: 'exclude'});
  }
  if (jsondata.exclude.revealedpersonalemails) {
    excluded.push({title: 'Revealed personal Emails', action: 'exclude'});
  }
  if (jsondata.exclude.revealedphonenumbers) {
    excluded.push({title: 'Revealed Cell Numbers', action: 'exclude'});
  }
  return (
    <div className="w-96 bg-white rounded-xl border border-gray-200 flex-col justify-start items-start inline-flex mt-2">
      <div className="self-stretch h-12 border-b border-gray-200 flex-col justify-start items-center flex">
        <div className="self-stretch p-3 justify-end items-center gap-1.5 inline-flex">
          <div className="grow shrink basis-0 self-stretch text-gray-900 text-xs font-medium leading-tight mt-1">
            {filtername}
          </div>
          <div
            className="px-2.5 py-1 bg-[#F0ECFF]  rounded-lg border border-indigo-50 justify-center items-center gap-2 flex hover:cursor-pointer"
            onClick={() => {
              props.onUseFilter(jsondata);
            }}>
            <div className="text-primary text-xs leading-tight">
              {LocaleStrings.search.savedsearches.usesearch}
            </div>
          </div>
          <div
            className="justify-center items-center flex hover:cursor-pointer ml-2"
            onClick={() => {
              props.onDelete(filterid);
            }}>
            <IconDelete />
          </div>
          {/* <div className="flex-col justify-start items-start inline-flex">
            <div className="w-5 h-5 relative" />
          </div> */}
        </div>
      </div>
      <div className="self-stretch p-2 flex-col justify-start items-start gap-2 flex">
        {jsondata['contactname'].length > 0 ? (
          <SubCell
            key="contactname"
            title="Contact Name"
            values={jsondata['contactname']}
          />
        ) : null}
        {jsondata['department'].length > 0 ? (
          <SubCell
            key="department"
            title="Department"
            values={jsondata['department']}
          />
        ) : null}
        {jsondata['jobtitle'].length > 0 ? (
          <SubCell
            key="jobtitle"
            title="JobTitle"
            values={jsondata['jobtitle']}
          />
        ) : null}
        {jsondata['companyname'].length > 0 ? (
          <SubCell
            key="companyname"
            title="Company Name"
            values={jsondata['companyname']}
          />
        ) : null}
        {jsondata['location']['contact'].length > 0 ? (
          <SubCell
            key="location-contact"
            title="Contact Location"
            values={jsondata['location']['contact']}
          />
        ) : null}
        {jsondata['location']['hq'].length > 0 ? (
          <SubCell
            key="location-hq"
            title="HQ Location"
            values={jsondata['location']['hq']}
          />
        ) : null}
        {jsondata['searchtype'].length > 0 ? (
          <SubCell
            key="searchtype"
            title="Search Type"
            values={jsondata['searchtype']}
          />
        ) : null}
        {jsondata['seniority'].length > 0 ? (
          <SubCell
            key="seniority"
            title="Senority"
            values={jsondata['seniority']}
          />
        ) : null}

        {jsondata['industry'].length > 0 ? (
          <SubCell
            key="industry"
            title="Industry"
            values={jsondata['industry']}
          />
        ) : null}

        {jsondata['foundedyear'].length > 0 ? (
          <SubCell
            key="foundedyear"
            title="Founded Year"
            values={jsondata['foundedyear'].map(val => ({
              title: val,
              action: 'include',
            }))}
          />
        ) : null}

        {jsondata['revenue'].length > 0 ? (
          <SubCell
            key="revenue"
            title="Revenue"
            values={jsondata['revenue'].map(val => ({
              title: toUpper(val),
              action: 'include',
            }))}
          />
        ) : null}

        {jsondata['headcount'].length > 0 ? (
          <SubCell
            key="headcount"
            title="Employee Headcount"
            values={jsondata['headcount'].map(val => ({
              title: toTitleCase(val.title),
              action: 'include',
            }))}
          />
        ) : null}

        {jsondata['industrycode'].length > 0 ? (
          <SubCell
            key="industrycode"
            title="Industry Classification Codes"
            values={jsondata['industrycode'].map(item => {
              return {title: `${item.type}-${item.code}`, action: 'include'};
            })}
          />
        ) : null}

        {excluded.length > 0 ? (
          <SubCell key="exclude" title="Exclude" values={excluded} />
        ) : null}

        {
          // people.exclude.revealedbusinessemails = false;
          // people.exclude.revealedpersonalemails = false;
          // people.exclude.revealedphonenumbers = false;
          // jsondata['contactname'] = [];
          // people['department'] = [];
          // people['jobtitle'] = [];
          // people['peoplecompanyname'] = [];
          // people['location'] = [];
          // people['searchtype'] = [];
          // people['seniority'] = [];
        }
        {/* <div className="justify-start items-center gap-1.5 inline-flex">
          <div className="text-blue-500 text-xs font-normal font-['Inter']">
            View More
          </div>
          <div className="justify-start items-center gap-1.5 flex">
            <div className="w-4 h-4 relative" />
          </div>
        </div> */}
      </div>
    </div>
  );
}
