import {Fragment, useEffect, useRef, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import LocaleStrings from '../../../../languages';
import Datepicker from 'react-tailwindcss-datepicker';

function DateRangePopup(props) {
  const [open, setOpen] = useState(false);

  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    if (open !== props.open) {
      setOpen(props.open);
      setValue({startDate: null, endDate: null});
    }
  }, [props.open]);

  const handleValueChange = newValue => {
    setValue(newValue);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[9999]"
        initialFocus={cancelButtonRef}
        onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 text-left sm:mt-5">
                    <Dialog.Title
                      as="h2"
                      className="text-lg font-semibold leading-6 text-gray-900">
                      {'Choose date range'}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {'Pick start and end date to filter results'}
                      </p>
                    </div>
                  </div>
                  <div className="py-4 pb-8">
                    <div>
                      <Datepicker
                        value={value}
                        onChange={handleValueChange}
                        popoverDirection="down"
                        containerClassName="z-30"
                        primaryColor="primary"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-6 grid grid-flow-row-dense grid-cols-2 gap-3">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => props.toggleOpen(false)}
                    ref={cancelButtonRef}>
                    {LocaleStrings.search.savesearch.cancel}
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:opacity-80"
                    onClick={() => {
                      props.onApplyCustom({
                        start: value.startDate,
                        end: value.endDate,
                      });
                    }}>
                    {LocaleStrings.search.apply}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default DateRangePopup;
