import LinkedinGray from '../icons/linkedingray';
import FacebookGray from '../icons/facebookgray';
import TwitterGray from '../icons/twittergray';

import LinkedinColor from '../icons/linkedincolor';
import TwitterColor from '../icons/twittercolor';
import FacebookColor from '../icons/facebookcolor';

export default function SocialLinks(props) {
  const {linkedin, twitter, facebook} = props;

  const normalizeUrl = url => {
    // console.log('URL -> ', url);
    if (url.indexOf('https://') === -1 || url.indexOf('http://') === -1) {
      return `https://${url}`;
    }
    return url;
  };
  return (
    <div className="my-0.2">
      {/* <div className="flex gap-2 absolute">
        <span>{<LinkedinGray />}</span>
        <span>{<FacebookGray />}</span>
        <span>{<TwitterGray />}</span>
      </div> */}
      <div className="flex gap-1 hover:cursor-pointer">
        {linkedin ? (
          <span
            onClick={() => {
              window.open(normalizeUrl(linkedin), '_blank');
            }}>
            {<LinkedinColor />}
          </span>
        ) : null}
        {facebook ? (
          <span
            onClick={() => {
              window.open(normalizeUrl(facebook), '_blank');
            }}>
            {<FacebookColor />}
          </span>
        ) : null}
        {twitter ? (
          <span
            onClick={() => {
              window.open(normalizeUrl(twitter), '_blank');
            }}>
            {<TwitterColor />}
          </span>
        ) : null}
      </div>
    </div>
  );
}
