import {combineReducers} from 'redux';
import {loadState, reset} from '../localStorage';
import {reducer as formReducer} from 'redux-form';
import {LOGIN, APP_API_KEY} from '@constant';

import {
  ACTION_LOGOUT,
  SESSION_TOKEN,
  CLIENT_ID,
  APPUSERID,
  USER_NAME,
  USER_EMAIL,
  AVATAR,
  IS_ACTIVE,
  IS_DELETED,
  IS_VERIFIED,
  USERCREDIT_BY_CLIENTID,
  USERSUBSCRIPTION_BY_CLIENTID,
  IS_FORGOT_PASSWORD_OPEN,
  LOCALE_LANGUAGE,
  SET_CURRENCY,
  IS_VERFICATION_MODAL_OPEN,
  STRIPE_CUST_ID,
  ROLE,
  DEVICE_SIZE,
  IS_LINKEDIN_CLICKED,
  SIGNUP_TELLUS_MORE,
  LOGIN_TYPE,
} from '../actions';
import {SESSION_TOKEN_PASSWORD} from '@settingsPasswordAction';
import {SESSION_TOKEN_ACCOUNTSETTINGS} from '@settingsMyaccountActions';

// Side BAr
import {
  SidebarSelectedMenu,
  SearchSelectedSubMenu,
  SideBarMenus,
  isNotificationOpen,
  appcounters,
  notificationList,
  messageList,
  isMessageOpen,
  storeMessageList,
  notificationSingleObjectStore,
  notificationDetailsStore,
  selectedMessageTab,
  orderMessageList,
  currentOrderStep,
  storeOrdMessageList,
  basicOrderThreadData,
  appuserData,
  invitedappuserData,
} from '@sidebarReducers';
import {
  revealcontactDatalist,
  revealcontactDirectDials,
  revealedcontacts,
} from '@maindashBoardReducers';
import {mycontactsDataList} from '@myContactsReducers';
import {
  subscriptionplanList,
  memberadminListcount,
  apikeysListcount,
} from '@upgradePlanReducers';

import {
  SettingsSideBarMenus,
  SettingsidebarSelectedMenu,
  userexistingplanData,
} from '@mainSettingsReducers';
import {
  iscreateapimodalOpen,
  apikeysDatalist,
  editapiDatalist,
  istryoutapimodalOpen,
  isapidocumentationmodalOpen,
  apiEnrichSample,
} from '@settingsApiReducers';
import {
  myaccountdataList,
  countryList,
  iseditemailmodalOpen,
  iseditemailcode,
} from '@settingsMyaccountReducers';
import {subscriptionplanListsettings} from '@settingsPlansReducers';
import {isaddmembermodalOpen, memberList, roles} from '@manageseatReducers';
import {transactionhistorydataList} from '@settingsBillingReducers';
import {
  allappuserdataList,
  isrevealedbyOpen,
  revealedcontactdataList,
  storedrevealbyappuserdata,
  storedrevealbyappusername,
  apiusagedataList,
} from '@settingsUsageReducers';

import {
  creditaddonList,
  isaddpaymentmodalOpen,
  selectedaddOnData,
} from '@creditAddonReducers';
import {
  CRM_SELECTED_PEOPLE,
  CRM_TYPE,
} from '../components/dashboard/search/actions';
import {
  filteredPeople,
  filters,
  preloadedPeopleFilterSupportData,
  recentSearches,
  filteredFavPeople,
  availableLists,
  filteredRevealedContacts,
  availableFilters,
  filtersSidePopup,
  filteredCompany,
  filteredFavCompany,
  availableCompanyLists,
  toggleMinimizeFilters,
  technologySearch,
  crmDetails,
  companyDetails,
  contactDetails,
  ismapFieldModalOpen,
  zohoFields,
  salesforceFields,
  iswebHookModalOpen,
  togglingFilters,
} from '../components/dashboard/search/reducers';
import {
  availableLabels,
  filteredListCompany,
  filteredListPeople,
  filteredMyLists,
  mylistCompanyFilters,
  mylistPeopleFilters,
  toggleListPeopleScreen,
} from '../components/dashboard/mylist/reducers';
import {EnrichSelectedSubMenu} from '../components/dashboard/sidebar/reducers';
import {
  csvEnrichList,
  toggleCSVSelectionPopupReducer,
  selectMapping,
  peopleStandardFieldsReducer,
  csvEnrichInfo,
  companyStandardFieldsReducer,
  csvUploadInfo,
  selectCompanyMapping,
  toggleCSVAnalysisReducer,
} from '../components/dashboard/enrich/reducers';
import {searchedData} from '../components/dashboard/search/linkedinsearch/reducers';
import {newEmailRequestModalStatus} from '../components/dashboard/settings/components/myaccounts/reducers';

var allcountryList = (state = [], action) => {
  //iconName is optional
  var state = [
    {
      value: 'AFG',
      name: 'Afghanistan',
      displayfields: '',
    },
    {
      value: 'ALA',
      name: 'Aland Islands',
      displayfields: '',
    },
    {
      value: 'ALB',
      name: 'Albania',
      displayfields: '',
    },
    {
      value: 'DZA',
      name: 'Algeria',
      displayfields: '',
    },
    {
      value: 'ASM',
      name: 'American Samoa',
      displayfields: '',
    },
    {
      value: 'AND',
      name: 'Andorra',
      displayfields: '',
    },
    {
      value: 'AGO',
      name: 'Angola',
      displayfields: '',
    },
    {
      value: 'AIA',
      name: 'Anguilla',
      displayfields: '',
    },
    {
      value: 'ATA',
      name: 'Antarctica',
      displayfields: '',
    },
    {
      value: 'ATG',
      name: 'Antigua and Barbuda',
      displayfields: '',
    },
    {
      value: 'ARG',
      name: 'Argentina',
      displayfields: '',
    },
    {
      value: 'ARM',
      name: 'Armenia',
      displayfields: '',
    },
    {
      value: 'ABW',
      name: 'Aruba',
      displayfields: '',
    },
    {
      value: 'AUS',
      name: 'Australia',
      displayfields: '',
    },
    {
      value: 'AUT',
      name: 'Austria',
      displayfields: '',
    },
    {
      value: 'AZE',
      name: 'Azerbaijan',
      displayfields: '',
    },
    {
      value: 'BHS',
      name: 'Bahamas',
      displayfields: '',
    },
    {
      value: 'BHR',
      name: 'Bahrain',
      displayfields: '',
    },
    {
      value: 'BGD',
      name: 'Bangladesh',
      displayfields: '',
    },
    {
      value: 'BRB',
      name: 'Barbados',
      displayfields: '',
    },
    {
      value: 'BLR',
      name: 'Belarus',
      displayfields: '',
    },
    {
      value: 'BEL',
      name: 'Belgium',
      displayfields: '',
    },
    {
      value: 'BLZ',
      name: 'Belize',
      displayfields: '',
    },
    {
      value: 'BEN',
      name: 'Benin',
      displayfields: '',
    },
    {
      value: 'BMU',
      name: 'Bermuda',
      displayfields: '',
    },
    {
      value: 'BTN',
      name: 'Bhutan',
      displayfields: '',
    },
    {
      value: 'BOL',
      name: 'Bolivia',
      displayfields: '',
    },
    {
      value: 'BIH',
      name: 'Bosnia and Herzegovina',
      displayfields: '',
    },
    {
      value: 'BWA',
      name: 'Botswana',
      displayfields: '',
    },
    {
      value: 'BVT',
      name: 'Bouvet Island',
      displayfields: '',
    },
    {
      value: 'BRA',
      name: 'Brazil',
      displayfields: '',
    },
    {
      value: 'IOT',
      name: 'British Indian Ocean Territory',
      displayfields: '',
    },
    {
      value: 'BRN',
      name: 'Brunei Darussalam',
      displayfields: '',
    },
    {
      value: 'BGR',
      name: 'Bulgaria',
      displayfields: '',
    },
    {
      value: 'BFA',
      name: 'Burkina Faso',
      displayfields: '',
    },
    {
      value: 'BDI',
      name: 'Burundi',
      displayfields: '',
    },
    {
      value: 'KHM',
      name: 'Cambodia',
      displayfields: '',
    },
    {
      value: 'CMR',
      name: 'Cameroon',
      displayfields: '',
    },
    {
      value: 'CAN',
      name: 'Canada',
      displayfields: '',
    },
    {
      value: 'CPV',
      name: 'Cape Verde',
      displayfields: '',
    },
    {
      value: 'CYM',
      name: 'Cayman Islands',
      displayfields: '',
    },
    {
      value: 'CAF',
      name: 'Central African Republic',
      displayfields: '',
    },
    {
      value: 'TCD',
      name: 'Chad',
      displayfields: '',
    },
    {
      value: 'CHL',
      name: 'Burundi',
      displayfields: '',
    },
    {
      value: 'CHN',
      name: 'China',
      displayfields: '',
    },
    {
      value: 'CXR',
      name: 'Christmas Island',
      displayfields: '',
    },
    {
      value: 'CCK',
      name: 'Cocos (Keeling) Islands',
      displayfields: '',
    },
    {
      value: 'COL',
      name: 'Colombia',
      displayfields: '',
    },
    {
      value: 'COM',
      name: 'Comoros',
      displayfields: '',
    },
    {
      value: 'COG',
      name: 'Congo',
      displayfields: '',
    },
    {
      value: 'COD',
      name: 'Congo, The Democratic Republic of The',
      displayfields: '',
    },
    {
      value: 'COK',
      name: 'Cook Islands',
      displayfields: '',
    },
    {
      value: 'CRI',
      name: 'Costa Rica',
      displayfields: '',
    },
    {
      value: 'CIV',
      name: 'Cote Divoire',
      displayfields: '',
    },
    {
      value: 'HRV',
      name: 'Croatia',
      displayfields: '',
    },
    {
      value: 'CUB',
      name: 'Cuba',
      displayfields: '',
    },
    {
      value: 'CYP',
      name: 'Cyprus',
      displayfields: '',
    },
    {
      value: 'CZE',
      name: 'Czech Republic',
      displayfields: '',
    },
    {
      value: 'DNK',
      name: 'Denmark',
      displayfields: '',
    },
    {
      value: 'DJI',
      name: 'Djibouti',
      displayfields: '',
    },
    {
      value: 'DMA',
      name: 'Dominica',
      displayfields: '',
    },
    {
      value: 'DOM',
      name: 'Dominican Republic',
      displayfields: '',
    },
    {
      value: 'ECU',
      name: 'Ecuador',
      displayfields: '',
    },
    {
      value: 'EGY',
      name: 'Egypt',
      displayfields: '',
    },
    {
      value: 'SLV',
      name: 'El Salvador',
      displayfields: '',
    },
    {
      value: 'GNQ',
      name: 'Equatorial Guinea',
      displayfields: '',
    },
    {
      value: 'ERI',
      name: 'Eritrea',
      displayfields: '',
    },
    {
      value: 'EST',
      name: 'Estonia',
      displayfields: '',
    },
    {
      value: 'ETH',
      name: 'Ethiopia',
      displayfields: '',
    },
    {
      value: 'FLK',
      name: 'Falkland Islands (Malvinas)',
      displayfields: '',
    },
    {
      value: 'FRO',
      name: 'Faroe Islands',
      displayfields: '',
    },
    {
      value: 'FJI',
      name: 'Fiji',
      displayfields: '',
    },
    {
      value: 'FIN',
      name: 'Finland',
      displayfields: '',
    },
    {
      value: 'FRA',
      name: 'France',
      displayfields: '',
    },
    {
      value: 'GUF',
      name: 'French Guiana',
      displayfields: '',
    },
    {
      value: 'PYF',
      name: 'French Polynesia',
      displayfields: '',
    },
    {
      value: 'ATF',
      name: 'French Southern Territories',
      displayfields: '',
    },
    {
      value: 'GAB',
      name: 'Gabon',
      displayfields: '',
    },
    {
      value: 'GMB',
      name: 'Gambia',
      displayfields: '',
    },
    {
      value: 'GEO',
      name: 'Georgia',
      displayfields: '',
    },
    {
      value: 'DEU',
      name: 'Germany',
      displayfields: '',
    },
    {
      value: 'GHA',
      name: 'Ghana',
      displayfields: '',
    },
    {
      value: 'GIB',
      name: 'Gibraltar',
      displayfields: '',
    },
    {
      value: 'GRC',
      name: 'Greece',
      displayfields: '',
    },
    {
      value: 'GRL',
      name: 'Greenland',
      displayfields: '',
    },
    {
      value: 'GRD',
      name: 'Grenada',
      displayfields: '',
    },
    {
      value: 'GLP',
      name: 'Guadeloupe',
      displayfields: '',
    },
    {
      value: 'GUM',
      name: 'Guam',
      displayfields: '',
    },
    {
      value: 'GTM',
      name: 'Guatemala',
      displayfields: '',
    },
    {
      value: 'GGY',
      name: 'Guernsey',
      displayfields: '',
    },
    {
      value: 'GIN',
      name: 'Guinea',
      displayfields: '',
    },
    {
      value: 'GNB',
      name: 'Guinea-bissau',
      displayfields: '',
    },
    {
      value: 'GUY',
      name: 'Guyana',
      displayfields: '',
    },
    {
      value: 'HTI',
      name: 'Haiti',
      displayfields: '',
    },
    {
      value: 'HMD',
      name: 'Heard Island and Mcdonald Islands',
      displayfields: '',
    },
    {
      value: 'VAT',
      name: 'Holy See (Vatican City State)',
      displayfields: '',
    },
    {
      value: 'HND',
      name: 'Honduras',
      displayfields: '',
    },
    {
      value: 'HUN',
      name: 'Hong Kong',
      displayfields: '',
    },
    {
      value: 'Hungary',
      name: 'Hungary',
      displayfields: '',
    },
    {
      value: 'ISL',
      name: 'Iceland',
      displayfields: '',
    },
    {
      value: 'IND',
      name: 'India',
      displayfields: '',
    },
    {
      value: 'IDN',
      name: 'Indonesia',
      displayfields: '',
    },
    {
      value: 'IRN',
      name: 'Iran, Islamic Republic of',
      displayfields: '',
    },
    {
      value: 'IRQ',
      name: 'Iraq',
      displayfields: '',
    },
    {
      value: 'IRL',
      name: 'Ireland',
      displayfields: '',
    },
    {
      value: 'IMN',
      name: 'Isle of Man',
      displayfields: '',
    },
    {
      value: 'ISR',
      name: 'Israel',
      displayfields: '',
    },
    {
      value: 'ITA',
      name: 'Italy',
      displayfields: '',
    },
    {
      value: 'JAM',
      name: 'Jamaica',
      displayfields: '',
    },
    {
      value: 'JPN',
      name: 'Japan',
      displayfields: '',
    },
    {
      value: 'JEY',
      name: 'Jersey',
      displayfields: '',
    },
    {
      value: 'JOR',
      name: 'Jordan',
      displayfields: '',
    },
    {
      value: 'KAZ',
      name: 'Kazakhstan',
      displayfields: '',
    },
    {
      value: 'KEN',
      name: 'Kenya',
      displayfields: '',
    },
    {
      value: 'KIR',
      name: 'Kiribati',
      displayfields: '',
    },
    {
      value: 'KWT',
      name: 'Kuwait',
      displayfields: '',
    },
    {
      value: 'KGZ',
      name: 'Kyrgyzstan',
      displayfields: '',
    },
    {
      value: 'LAO',
      name: 'Laos',
      displayfields: '',
    },
    {
      value: 'LVA',
      name: 'Latvia',
      displayfields: '',
    },
    {
      value: 'LBN',
      name: 'Lebanon',
      displayfields: '',
    },
    {
      value: 'LSO',
      name: 'Lesotho',
      displayfields: '',
    },
    {
      value: 'LBR',
      name: 'Liberia',
      displayfields: '',
    },
    {
      value: 'LBY',
      name: 'Libyan Arab Jamahiriya',
      displayfields: '',
    },
    {
      value: 'LIE',
      name: 'Liechtenstein',
      displayfields: '',
    },
    {
      value: 'LTU',
      name: 'Lithuania',
      displayfields: '',
    },
    {
      value: 'LUX',
      name: 'Luxembourg',
      displayfields: '',
    },
    {
      value: 'MCO',
      name: 'Macao',
      displayfields: '',
    },
    {
      value: 'MKD',
      name: 'Macedonia, The Former Yugoslav Republic of',
      displayfields: '',
    },
    {
      value: 'MDG',
      name: 'Madagascar',
      displayfields: '',
    },
    {
      value: 'MWI',
      name: 'Malawi',
      displayfields: '',
    },
    {
      value: 'MYS',
      name: 'Malaysia',
      displayfields: '',
    },
    {
      value: 'MDV',
      name: 'Maldives',
      displayfields: '',
    },
    {
      value: 'MLI',
      name: 'Mali',
      displayfields: '',
    },
    {
      value: 'MLT',
      name: 'Malta',
      displayfields: '',
    },
    {
      value: 'MHL',
      name: 'Marshall Islands',
      displayfields: '',
    },
    {
      value: 'MTQ',
      name: 'Martinique',
      displayfields: '',
    },
    {
      value: 'MRT',
      name: 'Mauritania',
      displayfields: '',
    },
    {
      value: 'MUS',
      name: 'Mauritius',
      displayfields: '',
    },
    {
      value: 'MYT',
      name: 'Mayotte',
      displayfields: '',
    },
    {
      value: 'MEX',
      name: 'Mexico',
      displayfields: '',
    },
    {
      value: 'FSM',
      name: 'Micronesia, Federated States of',
      displayfields: '',
    },
    {
      value: 'MDA',
      name: 'Moldova, Republic of',
      displayfields: '',
    },
    {
      value: 'MCO',
      name: 'Monaco',
      displayfields: '',
    },
    {
      value: 'MNG',
      name: 'Mongolia',
      displayfields: '',
    },
    {
      value: 'MNE',
      name: 'Montenegro',
      displayfields: '',
    },
    {
      value: 'MSR',
      name: 'Montserrat',
      displayfields: '',
    },
    {
      value: 'MAR',
      name: 'Morocco',
      displayfields: '',
    },
    {
      value: 'MOZ',
      name: 'Mozambique',
      displayfields: '',
    },
    {
      value: 'MMR',
      name: 'Myanmar',
      displayfields: '',
    },
    {
      value: 'NAM',
      name: 'Namibia',
      displayfields: '',
    },
    {
      value: 'NRU',
      name: 'Nauru',
      displayfields: '',
    },
    {
      value: 'NPL',
      name: 'Nepal',
      displayfields: '',
    },
    {
      value: 'NLD',
      name: 'Netherlands',
      displayfields: '',
    },
    {
      value: 'ANT',
      name: 'Netherlands Antilles',
      displayfields: '',
    },
    {
      value: 'NCL',
      name: 'New Caledonia',
      displayfields: '',
    },
    {
      value: 'NZL',
      name: 'New Zealand',
      displayfields: '',
    },
    {
      value: 'NIC',
      name: 'Nicaragua',
      displayfields: '',
    },
    {
      value: 'NER',
      name: 'Niger',
      displayfields: '',
    },
    {
      value: 'NGA',
      name: 'Nigeria',
      displayfields: '',
    },
    {
      value: 'NIU',
      name: 'Niue',
      displayfields: '',
    },
    {
      value: 'NFK',
      name: 'Norfolk Island',
      displayfields: '',
    },
    {
      value: 'PRK',
      name: 'North Korea',
      displayfields: '',
    },
    {
      value: 'MNP',
      name: 'Northern Mariana Islands',
      displayfields: '',
    },
    {
      value: 'NOR',
      name: 'Norway',
      displayfields: '',
    },
    {
      value: 'OMN',
      name: 'Oman',
      displayfields: '',
    },
    {
      value: 'PAK',
      name: 'Pakistan',
      displayfields: '',
    },
    {
      value: 'PLW',
      name: 'Palau',
      displayfields: '',
    },
    {
      value: 'PSE',
      name: 'Palestinian Territory, Occupied',
      displayfields: '',
    },
    {
      value: 'PAN',
      name: 'Panama',
      displayfields: '',
    },
    {
      value: 'PNG',
      name: 'Papua New Guinea',
      displayfields: '',
    },
    {
      value: 'PRY',
      name: 'Paraguay',
      displayfields: '',
    },
    {
      value: 'PER',
      name: 'Peru',
      displayfields: '',
    },
    {
      value: 'PHL',
      name: 'Philippines',
      displayfields: '',
    },
    {
      value: 'PCN',
      name: 'Pitcairn',
      displayfields: '',
    },
    {
      value: 'POL',
      name: 'Poland',
      displayfields: '',
    },
    {
      value: 'PRT',
      name: 'Portugal',
      displayfields: '',
    },
    {
      value: 'PRI',
      name: 'Puerto Rico',
      displayfields: '',
    },
    {
      value: 'QAT',
      name: 'Qatar',
      displayfields: '',
    },
    {
      value: 'REU',
      name: 'Reunion',
      displayfields: '',
    },
    {
      value: 'ROU',
      name: 'Romania',
      displayfields: '',
    },
    {
      value: 'RUS',
      name: 'Russian Federation',
      displayfields: '',
    },
    {
      value: 'RWA',
      name: 'Rwanda',
      displayfields: '',
    },
    {
      value: 'SHN',
      name: 'Saint Helena',
      displayfields: '',
    },
    {
      value: 'KNA',
      name: 'Saint Kitts and Nevis',
      displayfields: '',
    },
    {
      value: 'MAF',
      name: 'Saint Lucia',
      displayfields: '',
    },
    {
      value: 'SPM',
      name: 'Saint Pierre and Miquelon',
      displayfields: '',
    },
    {
      value: 'VCT',
      name: 'Saint Vincent and The Grenadines',
      displayfields: '',
    },
    {
      value: 'Samoa',
      name: 'WSM',
      displayfields: '',
    },
    {
      value: 'SMR',
      name: 'San Marino',
      displayfields: '',
    },
    {
      value: 'SMR',
      name: 'Sao Tome and Principe',
      displayfields: '',
    },
    {
      value: 'SAU',
      name: 'Saudi Arabia',
      displayfields: '',
    },
    {
      value: 'SEN',
      name: 'Senegal',
      displayfields: '',
    },
    {
      value: 'SRB',
      name: 'Serbia',
      displayfields: '',
    },
    {
      value: 'SYC',
      name: 'Seychelles',
      displayfields: '',
    },
    {
      value: 'SLE',
      name: 'Sierra Leone',
      displayfields: '',
    },
    {
      value: 'SGP',
      name: 'Singapore',
      displayfields: '',
    },
    {
      value: 'SVK',
      name: 'Slovakia',
      displayfields: '',
    },
    {
      value: 'SVN',
      name: 'Slovenia',
      displayfields: '',
    },
    {
      value: 'SLB',
      name: 'Solomon Islands',
      displayfields: '',
    },
    {
      value: 'SOM',
      name: 'Somalia',
      displayfields: '',
    },
    {
      value: 'ZAF',
      name: 'South Africa',
      displayfields: '',
    },
    {
      value: 'SGS',
      name: 'South Georgia and The South Sandwich Islands',
      displayfields: '',
    },
    {
      value: 'KOR',
      name: 'South Korea',
      displayfields: '',
    },
    {
      value: 'ESP',
      name: 'Spain',
      displayfields: '',
    },
    {
      value: 'LKA',
      name: 'Sri Lanka',
      displayfields: '',
    },
    {
      value: 'SDN',
      name: 'Sudan',
      displayfields: '',
    },
    {
      value: 'SUR',
      name: 'Suriname',
      displayfields: '',
    },
    {
      value: 'SJM',
      name: 'Svalbard and Jan Mayen',
      displayfields: '',
    },
    {
      value: 'SWZ',
      name: 'Swaziland',
      displayfields: '',
    },
    {
      value: 'SWE',
      name: 'Sweden',
      displayfields: '',
    },
    {
      value: 'CHE',
      name: 'Switzerland',
      displayfields: '',
    },
    {
      value: 'SYR',
      name: 'Syrian Arab Republic',
      displayfields: '',
    },
    {
      value: 'TWN',
      name: 'Taiwan, Province of China',
      displayfields: '',
    },
    {
      value: 'TJK',
      name: 'Tajikistan',
      displayfields: '',
    },
    {
      value: 'TLS',
      name: 'Tanzania, United Republic of',
      displayfields: '',
    },
    {
      value: 'THA',
      name: 'Thailand',
      displayfields: '',
    },
    {
      value: 'TLS',
      name: 'Timor-leste',
      displayfields: '',
    },
    {
      value: 'TGO',
      name: 'Togo',
      displayfields: '',
    },
    {
      value: 'TKL',
      name: 'Tokelau',
      displayfields: '',
    },
    {
      value: 'TON',
      name: 'Tonga',
      displayfields: '',
    },
    {
      value: 'TTO',
      name: 'Trinidad and Tobago',
      displayfields: '',
    },
    {
      value: 'TUN',
      name: 'Tunisia',
      displayfields: '',
    },
    {
      value: 'TUR',
      name: 'Turkey',
      displayfields: '',
    },
    {
      value: 'TKM',
      name: 'Turkmenistan',
      displayfields: '',
    },
    {
      value: 'TCA',
      name: 'Turks and Caicos Islands',
      displayfields: '',
    },
    {
      value: 'TUV',
      name: 'Tuvalu',
      displayfields: '',
    },
    {
      value: 'UGA',
      name: 'Uganda',
      displayfields: '',
    },
    {
      value: 'UKR',
      name: 'Ukraine',
      displayfields: '',
    },
    {
      value: 'ARE',
      name: 'United Arab Emirates',
      displayfields: '',
    },
    {
      value: 'GBR',
      name: 'United Kingdom',
      displayfields: '',
    },
    {
      value: 'USA',
      name: 'United States',
      displayfields: '',
    },
    {
      value: 'UMI',
      name: 'United States Minor Outlying Islands',
      displayfields: '',
    },
    {
      value: 'URY',
      name: 'Uruguay',
      displayfields: '',
    },
    {
      value: 'UZB',
      name: 'Uzbekistan',
      displayfields: '',
    },
    {
      value: 'VUT',
      name: 'Vanuatu',
      displayfields: '',
    },
    {
      value: 'VEN',
      name: 'Venezuela',
      displayfields: '',
    },
    {
      value: 'VNM',
      name: 'Viet Nam',
      displayfields: '',
    },
    {
      value: 'VIR',
      name: 'Virgin Islands, British',
      displayfields: '',
    },
    {
      value: 'VIR',
      name: 'Virgin Islands, U.S.',
      displayfields: '',
    },
    {
      value: 'WLF',
      name: 'Wallis and Futuna',
      displayfields: '',
    },
    {
      value: 'ESH',
      name: 'Western Sahara',
      displayfields: '',
    },
    {
      value: 'YEM',
      name: 'Yemen',
      displayfields: '',
    },
    {
      value: 'ZMB',
      name: 'Zambia',
      displayfields: '',
    },
    {
      value: 'ZWE',
      name: 'Zimbabwe',
      displayfields: '',
    },
  ];

  return state;
};

var LoginReducer = (state = true, action) => {
  // need to change it to false
  var state = loadState();

  if (state === undefined || state === 'undefined') {
    state = {
      isLoggedIn: false,
    };
  } else {
  }

  if (action.type === LOGIN) {
    state.isLoggedIn = action.payload;
  }

  if (action.type === ACTION_LOGOUT) {
    state.isLoggedIn = action.payload;
  }

  return state.isLoggedIn;
};

// stores Data for session also on localstorage
var TokensReducer = (state = {}, action) => {
  var state = loadState();

  if (state === undefined || state.session === undefined) {
    state = {
      session: {
        apiKey: APP_API_KEY,
        sessionToken: '',
        emailid: '',
      },
    };
  }
  // Updated session token when pasword updated from settings
  // Updated session token when email updated from accountsettings
  if (
    action.type === SESSION_TOKEN ||
    action.type === SESSION_TOKEN_PASSWORD ||
    action.type === SESSION_TOKEN_ACCOUNTSETTINGS
  ) {
    // console.log("action.payload", action.payload);
    // console.log("action.type", action.type);
    if (action.payload.session_token) {
      state.session.sessionToken = action.payload.session_token;
    }
    state.session.emailid = action.payload.email;
  }

  if (action.type === ACTION_LOGOUT) {
    state.session.sessionToken = '';
    state.session.emailid = '';
    state.session.userid = '';
    reset();
  }

  return state.session;
};

// Stores User Related data
var UserReducer = (state = {}, action) => {
  var state = loadState();
  if (state === undefined || state.user === undefined) {
    state = {
      user: {
        avatar: '',
        clientid: '',
        appuserid: '',
        email: '',
        name: '',
        isactive: '',
        isdeleted: '',
        isverified: '',
        usercredit_by_clientid: '',
        usersubscription_by_clientid: '',
        stripe_custid: '',
        role: '',
      },
    };
  }
  if (action.type === CLIENT_ID) {
    state.user.clientid = action.payload;
  }
  if (action.type === APPUSERID) {
    state.user.appuserid = action.payload;
  }
  if (action.type === AVATAR) {
    state.user.avatar = action.payload;
  }
  if (action.type === USER_EMAIL) {
    state.user.email = action.payload;
  }
  if (action.type === IS_ACTIVE) {
    state.user.isactive = action.payload;
  }
  if (action.type === IS_DELETED) {
    state.user.isdeleted = action.payload;
  }
  if (action.type === IS_VERIFIED) {
    state.user.isverified = action.payload;
  }
  if (action.type === USER_NAME) {
    state.user.name = action.payload;
  }
  if (action.type === USERCREDIT_BY_CLIENTID) {
    state.user.usercredit_by_clientid = action.payload;
  }
  if (action.type === USERSUBSCRIPTION_BY_CLIENTID) {
    state.user.usersubscription_by_clientid = action.payload;
  }

  if (action.type === STRIPE_CUST_ID) {
    state.user.stripe_custid = action.payload;
  }

  if (action.type === ROLE) {
    state.user.role = action.payload;
  }

  if (action.type === ACTION_LOGOUT) {
    state.user.avatar = '';
    state.user.clientid = '';
    state.user.appuserid = '';
    state.user.email = '';
    state.user.isactive = '';
    state.user.isdeleted = '';
    state.user.isverified = '';
    state.user.name = '';
    state.user.usercredit_by_clientid = '';
    state.user.usersubscription_by_clientid = '';
    state.user.stripe_custid = '';
    state.user.role = '';
  }

  return state.user;
};

// Is Forgot password modal open
export var isForgotPasswordOpen = (state = false, action) => {
  if (action.type === IS_FORGOT_PASSWORD_OPEN) {
    state = action.payload;
  }

  return state;
};

// Is Verfication Modal Open
export var isVerficationOpen = (state = false, action) => {
  if (action.type === IS_VERFICATION_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

// Is Linkedin Clicked from Register page
export var isLinkedInClickedFromRegister = (state = false, action) => {
  if (action.type === IS_LINKEDIN_CLICKED) {
    state = action.payload;
  }

  return state;
};

// Locale Language change
export var localeLanguage = (state = 'en', action) => {
  if (action.type === LOCALE_LANGUAGE) {
    state = action.payload;
  }
  return state;
};

export var localCurrency = (state = 'USD', action) => {
  if (action.type === SET_CURRENCY) {
    state = action.payload;
  }
  return state;
};

var deviceSize = (state = {}, action) => {
  if (action.type === DEVICE_SIZE) {
    return action.payload;
  }
  return state;
};

var crmDataReducer = (state = true, action) => {
  // need to change it to false
  var state = loadState();

  if (state === undefined || state === 'undefined') {
    state = {
      savedCRMData: [],
    };
  } else {
  }

  if (action.type === CRM_SELECTED_PEOPLE) {
    state.savedCRMData = action.payload;
  }

  return state.savedCRMData ?? [];
};

var crmTypeReducer = (state = true, action) => {
  // need to change it to false
  var state = loadState();

  if (state === undefined || state === 'undefined') {
    state = {
      crmType: null,
    };
  } else {
  }

  if (action.type === CRM_TYPE) {
    state.crmType = action.payload;
  }

  return state.crmType ?? '';
};

export var openSocialSignupTellusMore = (state = false, action) => {
  if (action.type === SIGNUP_TELLUS_MORE) {
    state = action.payload;
  }

  return state;
};

const socialLoginUsed = (state = false, action) => {
  if (action.type === LOGIN_TYPE) {
    state = action.payload;
  }

  return state;
};

var rootReducer = combineReducers({
  isLoggedIn: LoginReducer,
  session: TokensReducer,
  user: UserReducer,
  isForgotPasswordOpen: isForgotPasswordOpen,
  isVerficationOpen: isVerficationOpen,
  language: localeLanguage,
  currency: localCurrency,
  form: formReducer,
  isLinkedInClickedFromRegister: isLinkedInClickedFromRegister,

  // Appuser data
  appuserData: appuserData,
  //Invited Appuser data
  invitedappuserData: invitedappuserData,
  //Sidebar
  sideBarMenus: SideBarMenus,
  sidebarSelectedMenu: SidebarSelectedMenu,
  searchSelectedSubMenu: SearchSelectedSubMenu,
  enrichSelectedSubMenu: EnrichSelectedSubMenu,
  isNotificationOpen: isNotificationOpen,
  appcounters: appcounters, // STORE notification Values
  notificationList: notificationList, // Notification List
  messageList: messageList, // Messaging List
  orderMessageList, // Order Messaging
  isMessageOpen: isMessageOpen, //STORE Messaging Modal Is open
  storeMessageList: storeMessageList, //STORE Messaging List With Thread Id
  notifiSingleObjectStore: notificationSingleObjectStore, //STORE Notification Object
  notificationDetailsStore: notificationDetailsStore, //STORE Notification Details with [Notification ID]
  selectedMessageTab,
  currentOrderStep,
  storeOrdMessageList,
  basicOrderThreadData,

  // Main Dashboard
  // revealcontactDatalist: revealcontactDatalist,
  // revealcontactDirectDials: revealcontactDirectDials,
  revealedcontacts: revealedcontacts,

  //Upgrade Plan
  subscriptionplanList: subscriptionplanList,
  memberadminListcount: memberadminListcount,
  apikeysListcount: apikeysListcount,

  // Main Settings
  SettingsSideBarMenus: SettingsSideBarMenus,
  SettingsidebarSelectedMenu: SettingsidebarSelectedMenu,
  userexistingplanData: userexistingplanData,

  // My Contacts
  mycontactsDataList: mycontactsDataList,

  // Setting API
  iscreateapimodalOpen: iscreateapimodalOpen,
  istryoutapimodalOpen: istryoutapimodalOpen,
  apikeysDatalist: apikeysDatalist,
  editapiDatalist: editapiDatalist,
  isapidocumentationmodalOpen: isapidocumentationmodalOpen,
  apiEnrichSample: apiEnrichSample,

  // Setting My Account
  myaccountdataList: myaccountdataList,
  iseditemailmodalOpen: iseditemailmodalOpen,
  iseditemailcode: iseditemailcode,
  countryList: countryList,
  newEmailRequestModalStatus: newEmailRequestModalStatus,

  // Settings Manage Seats
  isaddmembermodalOpen: isaddmembermodalOpen,
  memberList: memberList,
  roles: roles,

  // Settings Plan
  subscriptionplanListsettings: subscriptionplanListsettings,

  // Settings Billing
  transactionhistorydataList: transactionhistorydataList,

  // CreditsAddons
  creditaddonList: creditaddonList,
  isaddpaymentmodalOpen: isaddpaymentmodalOpen,
  selectedaddOnData: selectedaddOnData,

  // Settings Usage
  allappuserdataList: allappuserdataList,
  isrevealedbyOpen: isrevealedbyOpen,
  revealedcontactdataList: revealedcontactdataList,
  storedrevealbyappusername: storedrevealbyappusername,
  storedrevealbyappuserdata: storedrevealbyappuserdata,
  apiusagedataList: apiusagedataList,
  allcountryList: allcountryList,
  deviceSize: deviceSize,

  //Search/Filter
  savedFilters: filters,
  recentSearches: recentSearches,
  filteredPeople: filteredPeople,
  filteredFavPeople: filteredFavPeople,
  preloadedPeopleFilterSupportData: preloadedPeopleFilterSupportData,

  //MyList
  filteredMyLists: filteredMyLists,
  filteredListPeople: filteredListPeople,
  activeList: toggleListPeopleScreen,
  mylistPeopleFilters: mylistPeopleFilters,
  availableLabels: availableLabels,
  availableLists: availableLists,
  filteredRevealedContacts: filteredRevealedContacts,
  availableFilters: availableFilters,
  filtersSidePopupStatus: filtersSidePopup,
  filteredListCompany: filteredListCompany,
  mylistCompanyFilters: mylistCompanyFilters,

  filteredCompany: filteredCompany,
  filteredFavCompany: filteredFavCompany,
  availableCompanyLists: availableCompanyLists,
  toggleMinimizeFilters: toggleMinimizeFilters,
  togglingFilters: togglingFilters,
  technologySearch: technologySearch,
  savedCRMData: crmDataReducer,
  crmDetails: crmDetails,
  crmType: crmTypeReducer,

  companyDetails: companyDetails,
  contactDetails: contactDetails,
  ismapFieldModalOpen: ismapFieldModalOpen,
  zohoFields: zohoFields,
  salesforceFields: salesforceFields,
  iswebHookModalOpen: iswebHookModalOpen,

  //CSV ENRICH
  csvEnrichList: csvEnrichList,

  csvSelectionPopupData: toggleCSVSelectionPopupReducer,

  selectMapping: selectMapping,
  peopleStandardFieldSettings: peopleStandardFieldsReducer,
  companyStandardFieldSettings: companyStandardFieldsReducer,
  csvEnrichInfo: csvEnrichInfo,
  csvUploadInfo: csvUploadInfo,
  selectCompanyMapping: selectCompanyMapping,
  csvAnalyzed: toggleCSVAnalysisReducer,

  openSocialSignupTellusMore,
  searchedData,
  socialLoginUsed,
});

export default rootReducer;
