import {useEffect, useState} from 'react';
import LocaleStrings from '@language';
import {connect} from 'react-redux';
import {saveFilter} from '../../../../actions';
import {revenues} from '../../../../constants';
import SelectCombo from '@common/selectcombo';
import Chip from '../../../../../../../common/Chip';
import {toLower, toUpper} from 'lodash';

function Revenue(props) {
  const [selectedValues, setSelectedValues] = useState(
    props.savedFilters.revenue,
  );

  const [curMinSelectedValue, setCurMinSelectedValue] = useState();
  const [curMaxSelectedValue, setCurMaxSelectedValue] = useState();

  const [minHasError, setMinHasError] = useState(false);
  const [maxHasError, setMaxHasError] = useState(false);

  const updateFilterReducer = vals => {
    let filters = props.savedFilters;
    let filterObject = filters;
    filterObject['revenue'] = vals;
    filters = {...filters, ...filterObject};
    props.saveFilter(props.session, filters, () => {});
  };

  useEffect(() => {
    updateFilterReducer(
      selectedValues.map(val => {
        if (val) {
          const parts = val.split('-');
          return `${parts[0]}-${parts[1]}`;
        }
        return '';
      }),
    );
  }, [selectedValues]);

  useEffect(() => {
    let filters = props.savedFilters;
    let filterObject = filters;

    if (filterObject['revenue'].length === 0 && selectedValues.length !== 0) {
      setSelectedValues([]);
      setMaxHasError(false);
      setMinHasError(false);
      setCurMinSelectedValue(undefined);
      setCurMaxSelectedValue(undefined);
    }
  }, [props.savedFilters]);

  return (
    <div
      className={`pb-2 relative ${
        props.savedFilters['revenue'].length > 0 ? 'h-auto' : ''
      }`}>
      {props.savedFilters['revenue'].length > 0 ? (
        <div className="justify-start px-4 pt-2 items-center gap-2 inline-flex">
          <div className="justify-start items-center gap-1.5 flex flex-wrap">
            {props.savedFilters['revenue']
              .map(val => ({title: toUpper(val), action: 'include'}))
              .map((val, index) => (
                <Chip
                  key={`${index}`}
                  {...val}
                  onRemove={() => {
                    let filters = props.savedFilters;
                    let filterObject = filters;
                    const filtered = props.savedFilters['revenue'].filter(
                      item => toLower(item) !== toLower(val.title),
                    );
                    filterObject['revenue'] = filtered;
                    filters = {...filters, ...filterObject};
                    props.saveFilter(props.session, filters, () => {});
                  }}
                />
              ))}
          </div>
        </div>
      ) : null}
      <fieldset>
        <legend className="text-xs leading-6 text-gray-900 pt-2">
          {LocaleStrings.search.companysearch.revenue_placeholder}
        </legend>
        <div className="flex">
          <div className="w-2/5 z-50">
            <SelectCombo
              values={revenues}
              placeholder="$ Min"
              onSelectValue={val => {
                setCurMinSelectedValue(val);
                setMinHasError(false);
              }}
              value={curMinSelectedValue}
              hasError={minHasError}
            />
          </div>
          <div className="px-1 pt-3 text-xs text-gray-500">to</div>
          <div className="w-2/5 z-50">
            <SelectCombo
              values={revenues}
              placeholder="$ Max"
              onSelectValue={val => {
                setCurMaxSelectedValue(val);
                setMaxHasError(false);
              }}
              value={curMaxSelectedValue}
              hasError={maxHasError}
            />
          </div>
          <div className="px-1 items-center flex">
            <button
              className="text-primary ml-2 text-xs"
              onClick={() => {
                if (!curMinSelectedValue) {
                  setMinHasError(true);
                } else if (!curMaxSelectedValue) {
                  setMaxHasError(true);
                } else if (
                  curMinSelectedValue.value > curMaxSelectedValue.value
                ) {
                  setMinHasError(true);
                } else {
                  let newArray = [...selectedValues];
                  newArray.push(
                    `${curMinSelectedValue.type}-${curMaxSelectedValue.type}`,
                  );

                  setSelectedValues(newArray);
                  setMinHasError(false);
                  setMaxHasError(false);
                  setCurMaxSelectedValue(undefined);
                  setCurMinSelectedValue(undefined);
                }
              }}>
              Apply
            </button>
          </div>
        </div>
      </fieldset>
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    savedFilters,
  };
}

export default connect(mapStateToProps, {saveFilter})(Revenue);
