import React, {useLayoutEffect} from 'react';
import {connect} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import {BrowserRouter} from 'react-router-dom';
import thunk from 'redux-thunk';
import {loadingBarMiddleware} from 'react-redux-loading-bar';
import {errorBarMiddleware} from './common/errorbar/index';

import reducers from './reducers';

import {getDeviceSize} from './actions';
import * as Sentry from '@sentry/react';

import {loadState, saveState} from './localStorage';
import AppRoutes from './approutes';

var persistedState = loadState();

var createStoreWithMiddleware = applyMiddleware(
  thunk,
  loadingBarMiddleware(),
  errorBarMiddleware(),
)(createStore);

export var store = createStoreWithMiddleware(reducers, persistedState);

store.subscribe(() => {
  saveState(store.getState());
});

Sentry.init({
  dsn: 'https://efd5159b6353fbc22469c7c22ee799fd@o4506380960923648.ingest.sentry.io/4506381359841280',
  integrations: [new Sentry.BrowserTracing()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend: event => {
    if (window.location.hostname === 'localhost') {
      return null;
    }
    return event;
  },
});

const App = props => {
  // const navigation = useNavigate();
  // constructor(props) {
  //   super(props);
  //   if (!this.props.isLoggedIn) {
  //   }

  //   if (this.props.language === "ar") {
  //     LocaleStrings.setLanguage("ar");
  //   } else {
  //     LocaleStrings.setLanguage("en");
  //   }
  // }

  // const observer = new BrowserFontSizeObserver(state => {
  //   // console.log(state);
  // });

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const updateSize = () => {
    // GETTING DYNAMIC HEIGHT AND WIDTH
    // console.log('SIZE => ', observer.state); // { browserFontSize: 16, ratio: 1 }
    props.getDeviceSize();
  };

  // useEffect(() => {
  //   if (!props.isLoggedIn) {
  //     navigation.navigate('/');
  //   }
  // }, [props.isLoggedIn]);

  return (
    <BrowserRouter basename="/">
      <AppRoutes
        isLoggedIn={props.isLoggedIn}
        isLinkedInClickedFromRegister={props.isLinkedInClickedFromRegister}
        openSocialSignupTellusMore={props.openSocialSignupTellusMore}
      />
    </BrowserRouter>
  );
  // }
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    isLinkedInClickedFromRegister: state.isLinkedInClickedFromRegister,
    openSocialSignupTellusMore: state.openSocialSignupTellusMore,
  };
}

// export default connect(mapStateToProps)(App);

export default connect(mapStateToProps, {getDeviceSize})(App);
