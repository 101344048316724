import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import LocaleStrings from '@language';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {TRIALPLANID} from '@constant';
// Common Functions
import toast, {Toaster} from 'react-hot-toast';
import {fetchAppuser} from '@sidebarActions';
import {fetchAddons} from '@creditAddonActions';
import BaseLoader from '@baseLoader';
import CreditAddonListitems from './addon-item-list';
import AddPaymentmodal from './addpaymentmodal';

class CreditAddons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.addons_loaderdesc,
    };

    // All Binded Functions
  }

  componentDidMount() {
    let {session, user} = this.props;
    this.props.fetchAppuser(session, user.appuserid, callback => {
      //fetch addons
      this.props.fetchAddons(session, callback => {
        this.setState({loading: false});
      });
    });
  }

  rendercreditsAddonList() {
    let {creditaddonList} = this.props;

    var items = _.map(creditaddonList.data, (values, index) => (
      <CreditAddonListitems
        keyindex={index}
        values={values}
        router={this.props.router}
      />
    ));
    return items;
  }

  render() {
    let {appuserData, creditaddonList} = this.props;

    let contactcredit = '';
    let usercurrentplanid = 0;

    if (
      appuserData &&
      appuserData.data &&
      appuserData.data.length > 0 &&
      appuserData.data[0].client_by_clientid
    ) {
      let client_by_clientid = appuserData.data[0]?.client_by_clientid;
      if (
        appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid.length >
          0
      ) {
        let usercredit_by_clientid =
          appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid[0];
        contactcredit = usercredit_by_clientid.contactcredit;
      }

      if (
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
          .length > 0
      ) {
        let usersubscription_by_clientid =
          appuserData.data[0]?.client_by_clientid
            ?.usersubscription_by_clientid[0];

        usercurrentplanid = usersubscription_by_clientid.planid;
      }
    }

    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            {/* commented as per new requirement */}
            {/* {contactcredit == 0 && usercurrentplanid != TRIALPLANID ? ( */}
            {usercurrentplanid != TRIALPLANID ? (
              <>
                {creditaddonList &&
                creditaddonList.data &&
                creditaddonList.data.length > 0 ? (
                  <>
                    <div
                      className="h-screen flex overflow-hidden bg-quaternary"
                      style={{height: 'calc(100vh / var(--zoom))'}}>
                      <div className="flex flex-col w-0 flex-1 overflow-hidden">
                        <main
                          className="main-tag-class- xs:px-6 sm:px-12 lg:px-10 py-10 overflow-y-auto bg-homegray"
                          style={{height: '92%'}}>
                          <div className="pb-10">
                            <div className="text-mainGray text-lg font-medium">
                              {LocaleStrings.addons_heading}
                            </div>
                            <div className="mt-2 text-gray500 text-sm font-normal">
                              {LocaleStrings.addons_description}
                            </div>
                          </div>
                          <ul
                            role="list"
                            className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                            {this.rendercreditsAddonList()}
                          </ul>
                          <Toaster />
                        </main>
                      </div>
                    </div>
                    <AddPaymentmodal router={this.props.router} />
                  </>
                ) : (
                  ''
                )}
              </>
            ) : (
              <div
                className="h-screen flex overflow-hidden bg-quaternary"
                style={{height: 'calc(100vh / var(--zoom))'}}>
                <div className="flex flex-col w-0 flex-1 overflow-hidden">
                  <main
                    className="main-tag-class- xs:px-6 sm:px-12 lg:px-10 py-10 overflow-y-auto bg-homegray"
                    style={{height: '92%'}}>
                    <div className="center-item lg:my-48 text-lg text-ternary bg-quaternary">
                      {LocaleStrings.addons_noaddonstobeadded}
                    </div>
                  </main>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var {session, user} = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    appuserData: state.appuserData,
    creditaddonList: state.creditaddonList,
  };
}

export default connect(mapStateToProps, {
  fetchAppuser,
  fetchAddons,
  // selectedProfileSettingsMenu,
})(CreditAddons);
