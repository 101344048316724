import {useEffect, useState} from 'react';
import {
  companyContactsSettings,
  companyStandardSettings,
  peopleContactsSettings,
  peopleStandardSettings,
} from '../../../../../common/constants';

import MappingField from './mappingfield';
import {connect} from 'react-redux';

function Mapping(props) {
  const [data, setData] = useState(
    props.section === 'people'
      ? {
          ...peopleStandardSettings,
          ...peopleContactsSettings,
        }
      : {
          ...companyStandardSettings,
          ...companyContactsSettings,
        },
  );
  const allKeys = Object.keys(data);
  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-4 px-2">
        <table className="w-full table-fixed divide-y divide-gray-300">
          <thead className="bg-white">
            <MappingField
              key={'header'}
              {...props}
              {...{
                title: 'Kipplo Field',
                mappingTitle: 'CSV Column Header',
                exampleTitle: 'Example Data',
              }}
              header
            />
          </thead>
          <tbody>
            {allKeys.map(key => (
              <MappingField
                key={key}
                {...data[key]}
                {...props}
                dataKey={key}
                onSelection={checked => {
                  data[key].selection = checked;
                  setData(data);
                }}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  section: state.csvSelectionPopupData.section,
});

export default connect(mapStateToProps, null)(Mapping);
