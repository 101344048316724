import {Dialog, Transition} from '@headlessui/react';
import {Fragment, useEffect, useState} from 'react';
import {connect} from 'react-redux';

import LocaleStrings from '../../../../languages';
import IconReveal from './icons/reveal';
import {useNavigate} from 'react-router-dom';
import {massRevealContacts} from '../../actions';

function RevealAllPopup(props) {
  const {selectedPersons} = props;
  const navigate = useNavigate();

  const [processing, setProcessing] = useState(false);

  const closeModal = () => {
    props.onCloseModal();
  };

  const processMassReveal = () => {
    let peopleIdArr = [];
    selectedPersons.map(person => peopleIdArr.push(person.peopleid));
    props.massRevealContacts(
      props.session,
      {peopleids: peopleIdArr.join(',')},
      response => {
        console.log('response => ', response);

        props.onCompletion();
        setTimeout(() => {
          setProcessing(false);
        }, 200);
      },
    );
  };

  const hasEnoughCredits = props.credits
    ? props.credits.personal_emailcredits >= selectedPersons.length &&
      props.credits.business_emailcredits >= selectedPersons.length &&
      props.credits.contactcredits >= selectedPersons.length
    : false;

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-[999] inset-0 overflow-y-auto"
        open={props.open}
        onClose={() => {}}>
        <div
          className="flex items-end justify-center pt-4 px-4 text-center sm:block sm:p-0 content-center"
          style={{height: 'calc(100vh / var(--zoom))'}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div
              className="inline-block rounded-xl align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-1/2"
              style={{padding: 10}}>
              <div className="p-6 border-t-2 border-quaternary">
                <div className="flex items-center gap-4">
                  <div className="w-10 h-10 flex bg-secondary rounded-full justify-center items-center">
                    <div className="justify-center items-center flex">
                      <IconReveal />
                    </div>
                  </div>
                  <div>
                    <div className="text-sm mainGray">
                      {LocaleStrings.bulkRevealTitle}
                    </div>
                    <div className="text-xs text-gray500 font-normal">
                      {LocaleStrings.bulkRevealSubTitle}
                    </div>
                  </div>
                </div>
                {processing ? (
                  <div className="flex justify-center content-center h-full pt-20 pb-5">
                    <div className="flex flex-col justify-center items-center mt-[10px]">
                      <div>
                        <img
                          src={require('./icons/processing.gif')}
                          width={150}
                        />
                      </div>
                      <div className="text-lg font-semibold">
                        {LocaleStrings.bulkProcessing}
                      </div>
                      <div className="px-40 text-sm text-center text-gray-600">
                        {LocaleStrings.bulkProcessingSubtitle}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center content-center h-full pt-20 pb-5">
                    {hasEnoughCredits ? (
                      <div className="flex items-center flex-col">
                        <div className="text-base">
                          {LocaleStrings.proceedBullkReveal}
                        </div>
                        <div className="text-sm text-gray-600">
                          {LocaleStrings.formatString(
                            LocaleStrings.bulkCreditRequirement,
                            selectedPersons.length,
                          )}
                        </div>
                        <div className="flex mt-12 justify-between gap-4">
                          <button
                            type="button"
                            onClick={() => {
                              closeModal();
                            }}
                            className="w-full justify-center flex px-4 py-2 text-sm font-medium border border-gray-200 rounded-lg hover:bg-gray-100 text-black hover:cursor-pointer">
                            {LocaleStrings.cancel}
                          </button>
                          <button
                            type="button"
                            className="w-full justify-center flex px-4 py-2 text-sm font-medium rounded-lg hover:opacity-90 text-white hover:cursor-pointer bg-primary"
                            onClick={() => {
                              setProcessing(true);
                              processMassReveal();
                            }}>
                            {LocaleStrings.proceedBulkReveal}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center flex-col">
                        <div className="text-base">
                          {LocaleStrings.bulkRevealCredits}
                        </div>
                        <div className="text-sm text-gray-600">
                          {LocaleStrings.bulkUpgrade}
                        </div>
                        <div className="flex mt-12 justify-between gap-4">
                          <button
                            type="button"
                            onClick={() => {
                              closeModal();
                            }}
                            className="w-full justify-center flex px-4 py-2 text-sm font-medium border border-gray-200 rounded-lg hover:bg-gray-100 text-black hover:cursor-pointer">
                            {LocaleStrings.cancel}
                          </button>
                          <button
                            type="button"
                            className="w-full justify-center flex px-4 py-2 text-sm font-medium rounded-lg hover:opacity-90 text-white hover:cursor-pointer bg-primary"
                            onClick={() => {
                              navigate('/upgradeplan');
                            }}>
                            {LocaleStrings.upgrade}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function mapStateToProps(state) {
  const {session, user, appuserData} = state;
  return {
    session,
    user,
    credits: appuserData.credits,
  };
}
export default connect(mapStateToProps, {massRevealContacts})(RevealAllPopup);
