import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {BaseComponent} from '@baseComponent';
import LocaleStrings from '@language';
import {classNames} from '@commonFunction';
import toast, {Toaster} from 'react-hot-toast';
import ReactConfirmAlert, {confirmAlert} from 'react-confirm-alert';
import EditApi from './editapi';
import {onEditapi, updateApi, fetchApikeys} from '@settingsApiActions';
import {fetchAppuser} from '@sidebarActions';

class CreateApiItem extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: '',
      selectedUsers: [],
      keyHidden: null,
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  openModal = (e, obj) => {
    this.props.onEditapi(obj);
    this.setState({isOpen: true});
  };
  closeModal = () => this.setState({isOpen: false});

  _deleteApi(e, values) {
    let {session, user} = this.props;
    var data = {
      keyid: values.keyid,
      isdeleted: 1,
      isactive: 0,
      clientid: values.clientid,
    };
    confirmAlert({
      title: LocaleStrings.settings_api_delete_title,
      message: LocaleStrings.settings_api_delete_desc,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.updateApi(this.props.session, data, resource => {
              if (resource.success !== 0) {
                this.props.fetchApikeys(session, callback => {
                  this.props.fetchAppuser(session, user?.appuserid);
                  toast.success(LocaleStrings.settings_api_delete_success);
                });
              } else {
                toast.error(LocaleStrings.common_fail_message);
              }
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  _handlecopy = (e, apikey) => {
    navigator.clipboard.writeText(apikey);
    toast.success(LocaleStrings.copied);
    this.setState({keyHidden: null});
  };

  handleindex = nmbr => {
    if (nmbr % 2 == 0) {
      return false;
    } else {
      return true;
    }
  };

  revealKey = apikey => {
    this.setState({keyHidden: apikey});
  };

  getMaskedValue = value => {
    if (value.length <= 4) {
      return value;
    }
    return value.substring(0, 4) + '*'.repeat(value.length - 4);
  };

  render() {
    var {values, keyindex, selectedTab} = this.props;

    let indexisodd1 = this.handleindex(keyindex);
    const actualKey = values.apikey;
    return (
      <>
        <tr className={classNames(indexisodd1 ? 'bg-white' : 'chatbg', '')}>
          <td className="whitespace-nowrap py-6 px-6 text-sm font-medium flex">
            {this.state.keyHidden != actualKey ? (
              <div className="flex items-center justify-center">
                <div className="text-xs">{this.getMaskedValue(actualKey)}</div>
                <div
                  className="cursor-pointer ml-3"
                  onClick={() => this.revealKey(actualKey)}>
                  <img
                    src="../../images/eye.svg"
                    width={16}
                    style={{opacity: 0.8}}></img>
                </div>
              </div>
            ) : (
              <>
                <div className="text-xs">{actualKey}</div>
                <img
                  onClick={e => this._handlecopy(e, values.apikey)}
                  src="../../images/copysmall.png"
                  className="ml-2 cursor-pointer"
                  width={16}
                  style={{opacity: 0.8}}></img>
              </>
            )}
          </td>
          {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">Social</td> */}
          <td className="whitespace-nowrap px-3 py-2 text-xs text-gray-500">
            {values.purpose}
          </td>

          <td className="whitespace-nowrap py-2 pl-3 pr-4 text-right text-xs font-medium sm:pr-6 flex gap-4 end-item">
            <div
              className="text-primary cursor-pointer"
              onClick={e => this.openModal(e, values)}>
              {LocaleStrings.settings_api_editbtn}
            </div>
            <div
              className="text-gray500 mr-4 cursor-pointer"
              onClick={e => this._deleteApi(e, values)}>
              {LocaleStrings.settings_api_deletebtn}
            </div>
          </td>
        </tr>
        {this.state.isOpen ? (
          <EditApi
            isOpen={this.state.isOpen}
            onClose={this.closeModal}
            data={this.props.values}
            selectedTab={this.props.selectedTab}
          />
        ) : (
          ''
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var {session, user} = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
  };
}

export default connect(mapStateToProps, {
  onEditapi,
  updateApi,
  fetchApikeys,
  fetchAppuser,
})(CreateApiItem);
