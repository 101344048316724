import React, {Component, useEffect, useState} from 'react';
import {classNames} from '@commonFunction';
import {
  convertToInternationalCurrencySystem,
  toTitleCase,
  ENTERPRISE_MONTHLY,
  ENTERPRISE_YEARLY,
} from '@common/constants';
import {useNavigate} from 'react-router-dom';
import LocaleStrings from '@language';
import IconLock from '../../results/cells/icons/lock';

export default function DetailsTechnology(props) {
  const navigate = useNavigate();
  const {technologies} = props.company;
  const techsToShow = technologies.length > 0 ? technologies.join(',') : '';
  return (
    <>
      {props.credits.role === 'client' && !props.onEnterprisePlan ? (
        <div className="flex justify-center items-center h-full">
          <div
            className="relative content-center justify-center items-center flex"
            style={{height: '300px', width: '100%'}}>
            {/* <div className="text-base mb-2 w-96 px-10 text-center blur-sm">
              {techsToShow}
            </div> */}
            <div
              className="absolute flex w-full h-full justify-center items-center px-10 py-6 rounded-lg hover:cursor-pointer hover:scale-105 transition ease-in"
              onClick={() => {
                navigate('/upgradeplan');
              }}>
              <IconLock size={18} />{' '}
              <span className="text-primary ml-2">{LocaleStrings.upgrade}</span>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="relative flex pt-4 gap-4 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent scrollbar-thumb-rounded-full"
          style={{width: '100%'}}>
          <div className="flex flex-wrap gap-2">
            {technologies.map(tech => (
              <div className="p-2 px-4 bg-secondary text-xs flex rounded-lg text-gray-800">
                {tech}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
