export default function IconDownvote(props) {
  return (
    <svg
      className={props.className}
      width="12"
      height="12"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0001 1V12M21.0001 8.8V4.2C21.0001 3.07989 21.0001 2.51984 20.7821 2.09202C20.5903 1.71569 20.2844 1.40973 19.908 1.21799C19.4802 1 18.9202 1 17.8001 1H7.11806C5.65658 1 4.92584 1 4.33563 1.26743C3.81545 1.50314 3.37335 1.88242 3.06129 2.36072C2.70722 2.90339 2.59611 3.62564 2.37388 5.07012L1.8508 8.47012C1.5577 10.3753 1.41114 11.3279 1.69386 12.0691C1.94199 12.7197 2.4087 13.2637 3.01398 13.6079C3.70358 14 4.66739 14 6.59499 14H7.40005C7.96011 14 8.24013 14 8.45404 14.109C8.64221 14.2049 8.79519 14.3578 8.89106 14.546C9.00005 14.7599 9.00005 15.0399 9.00005 15.6V18.5342C9.00005 19.896 10.104 21 11.4659 21C11.7907 21 12.0851 20.8087 12.217 20.5119L15.5778 12.9502C15.7306 12.6062 15.807 12.4343 15.9278 12.3082C16.0346 12.1967 16.1658 12.1115 16.311 12.0592C16.4753 12 16.6635 12 17.0398 12H17.8001C18.9202 12 19.4802 12 19.908 11.782C20.2844 11.5903 20.5903 11.2843 20.7821 10.908C21.0001 10.4802 21.0001 9.9201 21.0001 8.8Z"
        stroke="#A3ABBC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
