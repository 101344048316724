import {postRequest} from '@networkCall';

import {INSTANCE_URL} from '@constant';
import {SEARCHED_DATA} from '../reducers';
import {
  appConstant,
  normalizeLinkedinUrl,
} from '../../../../../common/common-utils';

export function searchUrl(session, params, callback) {
  let linkedinurl = normalizeLinkedinUrl(params.linkedinurl);

  params.linkedinurl = linkedinurl;

  let url = `${INSTANCE_URL}/api/v2/people_search/peoplebylinkedinurl`;
  return dispatch => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      response => {
        callback({success: 1, result: response.data});

        dispatch({
          type: SEARCHED_DATA,
          payload: response.data,
        });
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
}

export function getContact(session, params, callback) {
  let url = `${INSTANCE_URL}/api/v2/getcontact`;
  return dispatch => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      response => {
        if (params.type === 'directdial') {
          appConstant.kFetchedPhone = true;
        } else if (params.type === 'personalemail') {
          appConstant.kFetchedPersonalEmail = true;
        } else if (params.type === 'businessemail') {
          appConstant.kFetchedBusinessEmail = true;
        }
        callback({success: 1, result: response});
        dispatch({
          type: SEARCHED_DATA,
          payload: response.contactdata ? response.contactdata[0] : null,
        });
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
}
