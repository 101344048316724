import React, {Component} from 'react';
import {connect} from 'react-redux';
import LocaleStrings from '@language';
import {Field, reduxForm} from 'redux-form';
import {BaseComponent, LABEL_POSITION_TOP} from '@baseComponent';
import toast, {Toaster} from 'react-hot-toast';
// Functions Import
import {classNames} from '@commonFunction';
// Import constant
import {fetchAppuser} from '@sidebarActions';
import {sendVerificationemail} from '@mainActions';
import IconVerifyEmail from './icons/verifyemail';
import {logGTMEvent} from '@commonFolder/gtm-event';
import BtnLoaderSvg from '@btnLoaderSvg';

class VerifyEmail extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      headerText: '',
      btnLoader: false,
      isBtnDisable: false,
      mouse: false,
      enteredplatform: false,
    };
  }

  componentDidMount() {
    let {session, user} = this.props;
    if (user.email !== '') {
      // this.props.fetchAppuser(session, user.appuserid, (callback) => {
      //   this.setState({ loading: false });
      // });
    }
  }

  onFormSubmit(values) {
    let {session, user} = this.props;
    let obj = {};
    obj.email = user.email;

    this.setState({isBtnDisable: true});
    this.setState({btnLoader: true});

    this.props.sendVerificationemail(session, obj, callback => {
      toast.success(LocaleStrings.verify_email_resend_success);
      this.setState({isBtnDisable: false});
      this.setState({btnLoader: false});
    });
  }
  onMouseEnter() {
    this.refreshappuserData();
  }

  refreshappuserData = () => {
    let {session, user, appuserData} = this.props;
    if (user.email !== '' && this.state.enteredplatform == false) {
      // setTimeout(() => {
      //   window.location.reload();
      // }, 5000);

      if (session.sessionToken != '') {
        this.props.fetchAppuser(session, user.appuserid, callback => {
          if (
            callback.success == 1 &&
            callback.data.resource.length > 0 &&
            (callback.data.resource[0].isverified == '1' ||
              callback.data.resource[0].isverified == 1)
          ) {
            this.setState({enteredplatform: true});
            toast.success(LocaleStrings.verify_email_resend_verified_success);
            this.props.router.navigate('/dashboard');

            logGTMEvent({
              event: 'platformSignUp',
              method: 'email',
            });

            logGTMEvent({
              event: 'platformSubscribed',
              method: 'normal',
              plan: 'free',
            });
          }
        });
      }

      // this.props.history.push("/dashboard/main/dashboardmain");
    }
  };

  render() {
    let {session, user, handleSubmit, appuserData} = this.props;

    let email = null;
    if (user.email !== '') {
      email = user.email;
      // setTimeout(() => {
      //   this.onMouseEnter.bind(this)
      // },50000);
    }

    return (
      <>
        <form
          className="space-y-2"
          onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
          <div
            className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
            onMouseEnter={this.onMouseEnter.bind(this)}
            onMouseLeave={this.onMouseEnter.bind(this)}
            // onFocus={(e) => { this.onMouseEnter.bind(this)}}
          >
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <div className="center-item">
                <div className="w-10 h-10 p-2.5 pt-3 pl-3 bg-secondary rounded-full justify-center items-center flex">
                  <div className="justify-center items-center flex">
                    <IconVerifyEmail />
                  </div>
                </div>
              </div>
              <div className="center-item text-lg font-medium pt-4">
                {LocaleStrings.verify_your_email}
              </div>
              <div
                className="center-item my-4 font-normal text-sm text-ternary px-24"
                style={{textAlign: 'center'}}>
                {/* {LocaleStrings.verify_email_desc_text} */}
                {LocaleStrings.verify_email_title1} {email}{' '}
                {LocaleStrings.verify_email_title2}
              </div>
              <div className="center-item my-4 font-normal text-sm font-semibold text-primary">
                {LocaleStrings.verify_email_title3}
              </div>
              <div className="center-item">
                <div className="w-9/12">
                  <button
                    type="submit"
                    disabled={this.state.isBtnDisable}
                    className={classNames(
                      this.state.isBtnDisable ? 'cursor-not-allowed' : '',
                      'btn-primary',
                    )}>
                    <BtnLoaderSvg loader={this.state.btnLoader} />
                    {LocaleStrings.verify_email_resend}
                  </button>
                </div>
              </div>
              {/* <div className="center-item font-normal text-ternary flex pt-4 cursor-pointer" onClick={this._backtoLogin}>
              <img style={{ width: '6%' }} src="../../images/backicon.png" /><div className="pl-3">{LocaleStrings.backto_login}</div>
            </div> */}
            </div>
          </div>
        </form>
        <Toaster />
      </>
    );
  }
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  appuserData: state.appuserData,
});

export default reduxForm({
  form: 'VerifyEmailForm',
})(
  connect(mapStateToProps, {
    sendVerificationemail,
    fetchAppuser,
  })(VerifyEmail),
);
