import React, {useState} from 'react';
import PeopleFavsTable from '../results/PeopleFavsTable';
import CompanyFavsTable from '../companyresults/CompanyFavsTable';
import LocaleStrings from '../../../languages';
import {connect} from 'react-redux';
import {classNames} from '@commonFunction';

const TAB_PEOPLE = 'people';
const TAB_COMPANY = 'company';
const calculatedHeight = 0;

const Favourites = props => {
  const [selectedTab, setSelectedTab] = useState(TAB_PEOPLE);
  const [searchText, setSearchText] = useState('');
  const [openCreateListPopup, setOpenCreateListPopup] = useState(false);
  return (
    <div className="mx-4">
      {/* <div className="text-2xl font-semibold">
          {LocaleStrings.mylist.heading}
        </div> */}
      <div className="mt-2 flow-root flex mb-14">
        <div className="float-left fixed">
          <div className="w-56 h-10 rounded-lg shadow border border-gray-200 justify-start items-start inline-flex">
            <div
              className={classNames(
                'hover:bg-gray-100 hover:cursor-pointer w-28 self-stretch px-4 py-1 border-r rounded-l-md border-gray-300 justify-center items-center gap-2 flex',
                selectedTab === TAB_PEOPLE ? 'bg-gray-100' : 'bg-white',
              )}
              onClick={() => {
                setSelectedTab(TAB_PEOPLE);
              }}>
              <div className="text-slate-800 text-xs leading-tight">
                {LocaleStrings.mylist.tabcontacts}
              </div>
            </div>
            <div
              className={classNames(
                'hover:bg-gray-100 hover:cursor-pointer w-28 self-stretch px-4 py-1  border-r rounded-r-md border-gray-300 justify-center items-center gap-2 flex',
                selectedTab === TAB_COMPANY ? 'bg-gray-100' : 'bg-white',
              )}
              onClick={() => {
                setSelectedTab(TAB_COMPANY);
              }}>
              <div className="text-slate-700 text-xs leading-tight">
                {LocaleStrings.mylist.tabcompanies}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg my-4 bg-white"
        style={{height: 'calc(100vh - 180px)'}}>
        {selectedTab === TAB_PEOPLE ? <PeopleFavsTable /> : null}
        {selectedTab === TAB_COMPANY ? <CompanyFavsTable /> : null}
      </div>
    </div>
  );
};

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
});

export default connect(mapStateToProps, {})(Favourites);
