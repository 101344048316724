import React, {Component, useEffect, useState} from 'react';
import {connect} from 'react-redux';

import MyList from './MyList';
import ListPeople from './ListPeople';
import {fetchLabels} from './actions';
import ListCompany from './ListCompany';

function MyListHome(props) {
  useEffect(() => {
    props.fetchLabels(props.session, {action: 'availablelabels'}, _ => {});
  }, [props.availablelabels]);

  return (
    <div>
      {props.activeList ? (
        props.activeList.listtype === 'people' ? (
          <ListPeople />
        ) : (
          <ListCompany />
        )
      ) : (
        <MyList />
      )}
    </div>
  );
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  activeList: state.activeList,
  availablelabels: state.availablelabels,
});

export default connect(mapStateToProps, {fetchLabels})(MyListHome);
