import IconCompany from '@assets/images/searchmodule/filter/company.svg';
import IconLocation from '@assets/images/searchmodule/filter/location.svg';
import IconIndustry from '@assets/images/searchmodule/filter/industry.svg';
import IconHeadCount from '@assets/images/searchmodule/filter/headcount.svg';
import IconRevenue from '@assets/images/searchmodule/filter/revenue.svg';
import IconTechnology from '@assets/images/searchmodule/filter/technology.svg';
import IconFounded from '@assets/images/searchmodule/filter/founded.svg';
import IconFilter from '@assets/images/searchmodule/filter/filter.svg';

import IconContactName from '@assets/images/searchmodule/filter/contact.svg';
import IconDepartment from '@assets/images/searchmodule/filter/department.svg';
import IconJob from '@assets/images/searchmodule/filter/job.svg';
import IconSenior from '@assets/images/searchmodule/filter/senior.svg';
import IconSearch from '@assets/images/searchmodule/filter/search.svg';

import IconEmail from '@assets/images/searchmodule/contact/email.svg';
import IconUnknownEmail from '@assets/images/searchmodule/contact/emailunknown.svg';
import IconDirect from '@assets/images/searchmodule/contact/phone.svg';

import LocaleStrings from '@language';

export const companyFilters = [
  // {
  //   icon: IconCompany,
  //   title: LocaleStrings.search.companysearch.company_name,
  //   type: 'company',
  // },
  // {
  //   icon: IconLocation,
  //   title: LocaleStrings.search.companysearch.location,
  //   type: 'location',
  // },
  // {
  //   icon: IconIndustry,
  //   title: LocaleStrings.search.companysearch.industry,
  //   type: 'industry',
  // },
  {
    icon: IconHeadCount,
    title: LocaleStrings.search.companysearch.headcount,
    type: 'headcount',
  },
  {
    icon: IconRevenue,
    title: LocaleStrings.search.companysearch.revenue,
    type: 'revenue',
  },
  {
    icon: IconTechnology,
    title: LocaleStrings.search.companysearch.technology,
    type: 'technology',
  },
  {
    icon: IconFounded,
    title: LocaleStrings.search.companysearch.foundedyear,
    type: 'foundedyear',
  },
  {
    icon: IconFilter,
    title: LocaleStrings.search.companysearch.exclude,
    type: 'excludecompany',
  },
];

export const peopleFilters = [
  {
    icon: IconContactName,
    title: LocaleStrings.search.peoplesearch.contact_name,
    type: 'contactname',
  },
  {
    icon: IconCompany,
    title: LocaleStrings.search.peoplesearch.company_name,
    type: 'peoplecompanyname',
  },
  {
    icon: IconLocation,
    title: LocaleStrings.search.companysearch.location,
    type: 'peoplelocation',
  },
  {
    icon: IconDepartment,
    title: LocaleStrings.search.peoplesearch.department,
    type: 'department',
  },
  {
    icon: IconJob,
    title: LocaleStrings.search.peoplesearch.job_title,
    type: 'jobtitle',
  },
  {
    icon: IconSenior,
    title: LocaleStrings.search.peoplesearch.senority,
    type: 'seniority',
  },
  {
    icon: IconSearch,
    title: LocaleStrings.search.peoplesearch.search_type,
    type: 'searchtype',
  },
  {
    icon: IconFilter,
    title: LocaleStrings.search.peoplesearch.exclude,
    type: 'excludepeople',
  },
];

export const allFilters = [
  {
    icon: IconContactName,
    title: LocaleStrings.search.peoplesearch.contact_name,
    type: 'contactname',
    collapsed: true,
  },
  {
    icon: IconCompany,
    title: LocaleStrings.search.peoplesearch.company_name,
    type: 'companyname',
    collapsed: true,
  },
  {
    icon: IconLocation,
    title: LocaleStrings.search.companysearch.location,
    type: 'location',
    collapsed: true,
  },
  {
    icon: IconHeadCount,
    title: LocaleStrings.search.companysearch.headcount,
    type: 'headcount',
    collapsed: true,
  },
  {
    icon: IconRevenue,
    title: LocaleStrings.search.companysearch.revenue,
    type: 'revenue',
    collapsed: true,
  },
  {
    icon: IconTechnology,
    title: LocaleStrings.search.companysearch.technology,
    type: 'technology',
    collapsed: true,
  },
  {
    icon: IconFounded,
    title: LocaleStrings.search.companysearch.foundedyear,
    type: 'foundedyear',
    collapsed: true,
  },
  {
    icon: IconIndustry,
    title: LocaleStrings.search.companysearch.industry,
    type: 'industry',
    collapsed: true,
  },
  {
    icon: IconDepartment,
    title: LocaleStrings.search.peoplesearch.department,
    type: 'department',
    collapsed: true,
  },
  {
    icon: IconJob,
    title: LocaleStrings.search.peoplesearch.job_title,
    type: 'jobtitle',
    collapsed: true,
  },
  {
    icon: IconSenior,
    title: LocaleStrings.search.peoplesearch.senority,
    type: 'seniority',
    collapsed: true,
  },
  {
    icon: IconSearch,
    title: LocaleStrings.search.peoplesearch.search_type,
    type: 'searchtype',
    collapsed: true,
  },
  {
    icon: IconFilter,
    title: LocaleStrings.search.peoplesearch.exclude,
    type: 'exclude',
    collapsed: true,
  },
];

export const excludePeopleFilters = [
  {title: 'Revealed work emails', type: 'revealedbusinessemails'},
  {title: 'Revealed personal emails', type: 'revealedpersonalemails'},
  {title: 'Revealed cell numbers', type: 'revealedphonenumbers'},
  // {title: 'Revealed contacts', type: 'revealedcontacts'},
  // {title: 'Revealed technology', type: 'revealedtech'},
];

export const excludeCompanyFilters = [
  {title: 'Revealed contacts', type: 'revealedcontacts'},
  {title: 'Revealed technology', type: 'revealedtech'},
];

export const revenues = [
  {title: 'Min', type: 'min', value: 0},
  {title: '1M', type: '1m', value: 1},
  {title: '2M', type: '2m', value: 2},
  {title: '5M', type: '5m', value: 5},
  {title: '10M', type: '10m', value: 10},
  {title: '50M', type: '50m', value: 50},
  {title: '100M', type: '100m', value: 100},
  {title: '500M', type: '500m', value: 500},
];

export const headcount = [
  {title: 'Self Employed', type: 'selfemployed'},
  {title: '1-10', type: '1-10'},
  {title: '11-50', type: '11-50'},
  {title: '51-100', type: '51-100'},
  {title: '101-200', type: '101-200'},
  {title: '201-500', type: '201-500'},
  {title: '501-1000', type: '501-1000'},
  {title: '1001-5000', type: '1001-5000'},
  {title: '5001-10000', type: '5001-10000'},
  {title: '10000+', type: '10000+'},
  {title: 'Unknown', type: 'Unknown'},
];

export const searchTypes = [
  {title: 'Work Emails', type: 'companyemails'},
  {title: 'Personal Emails', type: 'personalemails'},
  {title: 'Cell Numbers', type: 'cellnumbers'},

  // {title: 'Company Number', type: 'companynumber'},
];

export const seniorityLevels = [
  {title: 'Unpaid', type: 'unpaid'},
  {title: 'Training', type: 'training'},
  {title: 'Entry-level', type: 'entrylevel'},
  {title: 'Senior', type: 'senior'},
  {title: 'Manager', type: 'manager'},
  {title: 'Director', type: 'director'},
  {title: 'Vice President(VP)', type: 'vp'},
  {title: 'Chief X Officer (CxO)', type: 'cxo'},
  {title: 'Partner', type: 'partner'},
  {title: 'Owner', type: 'owner'},
];

// export const industryCodes = [
//   'SIC',
//   'FIN',
//   'GOV',
//   'TECH',
//   'CONS',
//   'CORP',
//   'AGR',
// ];

export const industryCodes = ['SIC', 'NAICS'];

export const sampleContactNames = [
  {title: 'Conrad Rice', subtitle: ''},
  {title: 'Shane Rugg', subtitle: ''},
  {title: 'Scott Boal', subtitle: ''},
  {title: 'Joseph Sutton', subtitle: ''},
  {title: 'Kristin Peterson', subtitle: ''},
  {title: 'Chris Knott', subtitle: ''},
  {title: 'Bannon Gallaher', subtitle: ''},
  {title: 'Paul Rinzler', subtitle: ''},
  {title: 'Lauren Otterness', subtitle: ''},
  {title: 'Elsa Fellrath', subtitle: ''},
  {title: 'Chloé Tuffreau', subtitle: ''},
  {title: 'Stephanie Gavardin', subtitle: ''},
  {title: 'Craig Warburton', subtitle: ''},
  {title: 'Stefan Dittforth', subtitle: ''},
  {title: 'Priyanka Ramchurn', subtitle: ''},
  {title: 'Shruti Barve', subtitle: ''},
  {title: 'Vishal Bansod', subtitle: ''},
  {title: 'Srinivas Bhaskara', subtitle: ''},
  {title: 'Kim Coppola', subtitle: ''},
];

export const sampleCompanyNames = [
  {title: 'Amazon', subtitle: 'amazon.com'},
  {title: 'Apple', subtitle: 'apple.com'},
  {title: 'Paypal', subtitle: 'paypal.com'},
  {title: 'Microsoft', subtitle: 'microsoft.com'},
  {title: 'Facebook', subtitle: 'facebook.com'},
  {title: 'Tesla', subtitle: 'tesla.com'},
];

export const sampleDepartments = [
  {title: 'Accounting', subtitle: ''},
  {title: 'Administrative', subtitle: ''},
  {title: 'Advertising', subtitle: ''},
  {title: 'Analyst', subtitle: ''},
  {title: 'Art / Creative', subtitle: ''},
  {title: 'Consulting', subtitle: ''},
];

export const sampleJobTitle = [
  {title: 'Accounting', subtitle: ''},
  {title: 'Administrative', subtitle: ''},
  {title: 'Advertising', subtitle: ''},
  {title: 'Analyst', subtitle: ''},
  {title: 'Art / Creative', subtitle: ''},
  {title: 'Consulting', subtitle: ''},
];

export const arrCompanyInfo = [
  {icon: IconLocation, type: 'location'},
  {icon: IconIndustry, type: 'industry'},
  {icon: IconFounded, type: 'established'},
  {icon: IconRevenue, type: 'revenue'},
  {icon: IconHeadCount, type: 'headcount'},
  {icon: null, type: 'siccodes'},
  {icon: null, type: 'naicscodes'},
];

export const arrContactInfo = [
  {icon: IconEmail, type: 'personalemail'},
  {icon: IconUnknownEmail, type: 'businessemail'},
  {icon: IconDirect, type: 'directdial'},
];
