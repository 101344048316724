// import IconFlag from '../icons/flag';
import IconEmail from '@assets/images/searchmodule/contact/email.svg';
import IconUnknownEmail from '@assets/images/searchmodule/contact/emailunknown.svg';
import IconValidEmail from '@assets/images/searchmodule/contact/emailvalid.svg';
import IconNotAvailableEmail from '@assets/images/searchmodule/contact/emailnotavailable.svg';
import IconDirect from '@assets/images/searchmodule/contact/phone.svg';
import IconCopyNormal from '../icons/copynormal';
import {classNames} from '@commonFunction';
import IconRevealContact from '../icons/revealcontact';
import {connect} from 'react-redux';
import {TRIALPLANID} from '../../../../../../common/constants';
import IconLock from '../icons/lock';
import {useEffect, useState} from 'react';
import {revealContact} from '../../../actions';
import {addCRMLead} from '../../../../search/actions';
import toast, {Toaster} from 'react-hot-toast';
import IconUpvote from '../icons/upvote';
import IconDownvote from '../icons/downvote';
// import IconUpdate from '../icons/update';
// import LocaleStrings from '../../../../../languages';

function SubContact(props) {
  const {
    type,
    values,
    validation,
    available,
    encrypted,
    cardView,
    contacttype,
    person,
  } = props;

  const isvalid = validation === 'valid';

  const [inProcess, setInProcess] = useState(false);

  const renderEmailTitle = () => {
    switch (type) {
      case 'personalemail':
        return 'Email';
      case 'businessemail':
        return 'Work';
    }
  };

  const renderPhoneTitle = () => {
    return 'Cell';
  };

  const renderPhoneIcon = () => {
    return <img src={IconDirect} width={16} height={16} />;
  };

  const renderEmailIcon = () => {
    switch (type) {
      case 'personalemail':
        return available ? (
          <img src={IconEmail} width={16} height={16} />
        ) : (
          <img src={IconNotAvailableEmail} width={16} height={16} />
        );
      case 'businessemail':
        return encrypted ? (
          <img src={IconEmail} width={16} height={16} />
        ) : available ? (
          isvalid ? (
            <img src={IconValidEmail} width={16} height={16} />
          ) : (
            <img src={IconUnknownEmail} width={16} height={16} />
          )
        ) : (
          <img src={IconNotAvailableEmail} width={16} height={16} />
        );

      default:
        return available ? (
          <img src={IconEmail} width={16} height={16} />
        ) : (
          <img src={IconNotAvailableEmail} width={16} height={16} />
        );
    }
  };

  const renderIcon = contacttype => {
    if (
      type === 'personalemail' &&
      props.credits.personal_emailcredits === 0 &&
      encrypted
    ) {
      return <IconLock size={12} />;
    } else if (
      type === 'businessemail' &&
      props.credits.business_emailcredits === 0 &&
      encrypted
    ) {
      return <IconLock size={12} />;
    } else if (
      type === 'directdial' &&
      props.credits.contactcredits === 0 &&
      encrypted
    ) {
      return <IconLock size={12} />;
    }

    return contacttype === 'email' ? renderEmailIcon() : renderPhoneIcon();
  };

  // updgrade plan page redirection
  const _openUpgradPlan = e => {
    props.onUpgradePlan();
  };

  // addon page redirection
  const _handleAddon = e => {
    props.onBuyAddon();
  };

  const renderOptions = value => {
    if (
      type === 'personalemail' &&
      props.credits.personal_emailcredits === 0 &&
      encrypted
    ) {
      return (
        <span
          className="vertical-0 text-primary text-xs font-semibold hover:cursor-pointer"
          onClick={e => _openUpgradPlan(e)}>
          {'Upgrade to reveal'}
        </span>
      );
    } else if (
      type === 'businessemail' &&
      props.credits.business_emailcredits === 0 &&
      encrypted
    ) {
      return (
        <span
          className="vertical-0 text-primary text-xs font-semibold hover:cursor-pointer"
          onClick={e => _openUpgradPlan(e)}>
          {'Upgrade to reveal'}
        </span>
      );
    } else if (
      type === 'directdial' &&
      props.credits.contactcredits === 0 &&
      encrypted
    ) {
      return (
        <span
          className="vertical-0 text-primary text-xs font-semibold hover:cursor-pointer"
          onClick={e => {
            if (
              props.credits.role === 'client' &&
              props.credits.planid != TRIALPLANID
            )
              _handleAddon(e);
            else _openUpgradPlan(e);
          }}>
          {props.credits.role === 'client' &&
          props.credits.planid != TRIALPLANID
            ? 'Buy Addon to reveal'
            : 'Upgrade to reveal'}
        </span>
      );
    }
    return value;
  };

  const availableToReveal = () => {
    if (
      type === 'personalemail' &&
      props.credits.personal_emailcredits === 0 &&
      encrypted
    ) {
      return false;
    } else if (
      type === 'businessemail' &&
      props.credits.business_emailcredits === 0 &&
      encrypted
    ) {
      return false;
    } else if (
      type === 'directdial' &&
      props.credits.contactcredits === 0 &&
      encrypted
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (inProcess) {
      props.onRevealStart();

      setTimeout(() => {
        props.revealContact(
          props.session,
          {peopleid: person.peopleid, type: type, action: 'reveal'},
          res => {
            if (res.success === 1) {
              props.onRevealCompletion(res.data.data);
            }
            setInProcess(false);

            props.onRevealEnd();
          },
        );
      }, 200);
    }
  }, [inProcess]);

  const onRevealContact = () => {
    setInProcess(true);
    props.revealContact(
      props.session,
      {peopleid: person.peopleid, type: type, action: 'reveal'},
      res => {
        if (res.success === 1) {
          props.onRevealCompletion(res.data.data);

          let peopleIdArr = [];
          let obj = {};
          obj.peopleid = person.peopleid;
          peopleIdArr.push(obj);
          //Salesforce Sync
          props.addCRMLead(
            props.session,
            {
              peopleids: peopleIdArr,
              lead_source: 'Reveal',
              type: type,
            },
            'salesforce',
            resp => {},
          );
          //Zoho Sync

          props.addCRMLead(
            props.session,
            {
              peopleids: peopleIdArr,
              lead_source: 'Reveal',
              type: type,
            },
            'zoholead',
            resp => {},
          );

          // props.zapierHook(
          //   props.session,
          //   {
          //     peopleids: peopleIdArr,
          //     lead_source: 'Reveal',
          //   },
          //   resp => {},
          // );
        }
        setInProcess(false);
      },
    );
  };

  return (
    <div className="flex flex-col gap-2">
      {values.map(value => {
        const contact = available ? value : 'Not available';
        return (
          <div
            className="justify-start items-center gap-2 inline-flex"
            key={value}>
            <div className="w-11 px-1.5 py-1 bg-gray-100 flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="text-gray-900 text-xs">
                {contacttype === 'email'
                  ? renderEmailTitle()
                  : renderPhoneTitle()}
              </div>
            </div>
            {inProcess ? (
              <div className="animate-pulse mt-2">
                <div className="w-60 h-2 bg-gray-100 rounded mb-1"></div>
                <div className="w-54 h-2 bg-gray-100 rounded mb-1"></div>
              </div>
            ) : (
              <div className="justify-start items-center gap-2 inline-flex">
                <div>{renderIcon(contacttype)}</div>
                <div className="text-gray-900 text-xs leading-tight truncate">
                  {renderOptions(contact)}
                </div>
                <div
                  className="relative hover:cursor-pointer"
                  onClick={() => {
                    // console.log('props => ', props);
                    props.onClickFlag({
                      contacttype: type,
                      contact: value,
                      action: 'upvote',
                    });
                  }}>
                  {available && !encrypted ? (
                    <IconUpvote className="hover:scale-110" />
                  ) : null}
                </div>
                <div
                  className="relative hover:cursor-pointer"
                  onClick={() => {
                    // console.log('props => ', props);
                    props.onClickFlag({
                      contacttype: type,
                      contact: value,
                      action: 'downvote',
                    });
                  }}>
                  {available && !encrypted ? (
                    <IconDownvote className="hover:scale-110" />
                  ) : null}
                </div>

                {/* <div className="hover:cursor-pointer tooltip">
                  {contacttype === 'email' ? (
                    !encrypted &&
                    type == 'personalemail' &&
                    person.new_personal_email_data_available == 1 ? (
                      <IconUpdate />
                    ) : !encrypted &&
                      type == 'businessemail' &&
                      person.new_business_email_data_available == 1 ? (
                      <IconUpdate />
                    ) : null
                  ) : !encrypted &&
                    person.new_phone_number_data_available == 1 ? (
                    <IconUpdate />
                  ) : null}
                  <span className="tooltiptext tooltip-right">
                    {LocaleStrings.revealedContacts.updateavailable}
                  </span>
                </div> */}
                <div
                  className={classNames(
                    'absolute right-2 hover:cursor-pointer',
                    cardView
                      ? 'transition ease-in duration-300 opacity-0 group-hover:opacity-100'
                      : '',
                  )}>
                  <div
                    className="w-4 h-4 relative"
                    onClick={() => {
                      if (available && !encrypted) {
                        navigator.clipboard.writeText(contact);
                        toast.success('Copied successfully');
                      } else if (availableToReveal()) {
                        onRevealContact();
                      }
                    }}>
                    {available && !encrypted ? (
                      <IconCopyNormal />
                    ) : availableToReveal() ? (
                      <IconRevealContact />
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, appuserData} = state;
  return {
    session,
    user,
    credits: appuserData.credits,
  };
}

export default connect(mapStateToProps, {
  revealContact,
  addCRMLead,
})(SubContact);
