import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {isJSON} from '@commonFunction';
import {BaseComponent} from '@baseComponent';

class RevealedContactListitems extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: '',
      selectedUsers: [],
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  renderphoneList = phonenumberArr => {
    let item = _.map(phonenumberArr, (val, index) => {
      return (
        <div className="flex items-center space-x-4">
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray500">{val}</p>
          </div>
        </div>
      );
    });
    return item;
  };
  renderemailList = emailArr => {
    let item = _.map(emailArr, (val, index) => {
      return (
        <div className="flex items-center space-x-4">
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray500">{val.address}</p>
          </div>
        </div>
      );
    });
    return item;
  };

  renderbusinessemailList = bemailArr => {
    let item = _.map(bemailArr, (val, index) => {
      return (
        <div className="flex items-center space-x-4">
          <div className="group cursor-pointer flex relative">
            <div className="px-2 py-1 text-sm">{val}</div>
          </div>
        </div>
      );
    });
    return item;
  };

  render() {
    var {values, session} = this.props;
    var selectedUsers = this.props.selectedUsers;
    var index = _.findIndex(selectedUsers, function (curuser) {
      return curuser.contactid === values.contactid;
    });

    var checked = false;
    if (index >= 0) {
      checked = true;
    }
    let phoneArr = [];
    let emailArr = [];

    const parsedEmail = values.emails !== null ? values.emails : null;

    let emailsjson = isJSON(parsedEmail); // checking valid json

    const parsedPhone =
      values.phone_numbers !== null ? values.phone_numbers : null;
    let phonejson = isJSON(parsedPhone);

    if (emailsjson) {
      emailArr = JSON.parse(parsedEmail);
      emailArr = emailArr.filter(email => email.type !== 'business');
    }

    if (phonejson) {
      phoneArr = JSON.parse(parsedPhone);
    }

    let business_emails = [];
    const parsedBusinessEmails =
      values.business_emails != null ? values.business_emails : null;
    if (parsedBusinessEmails && parsedBusinessEmails != null) {
      business_emails = _.split(parsedBusinessEmails, ',');
    }

    return (
      <tr className="table table-fixed w-full">
        <td className="whitespace-nowrap py-4 px-6 text-sm font-medium w-fit truncate">
          {values.name}
        </td>
        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">Social</td> */}
        <td
          className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
          style={{width: '20%'}}>
          {phoneArr.length > 0 ? (
            <span>{this.renderphoneList(phoneArr)}</span>
          ) : (
            '-'
          )}
        </td>
        <td
          className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
          style={{width: '40%'}}>
          {business_emails.length > 0 ? (
            <span>{this.renderbusinessemailList(business_emails)}</span>
          ) : (
            ''
          )}
          {business_emails == '' ? '-' : ''}
        </td>

        <td
          className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
          style={{width: '20%'}}>
          {emailArr.length > 0 ? (
            <span>{this.renderemailList(emailArr)}</span>
          ) : (
            ''
          )}

          {emailArr.length == 0 ? '-' : ''}
        </td>
        {/* <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"></td> */}
      </tr>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var {session, user} = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
  };
}

export default connect(mapStateToProps, {})(RevealedContactListitems);
