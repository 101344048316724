import {useEffect, useState} from 'react';
import {ExportMenuTypes} from '../../../../../../common/exportmenu/menudropdown';
import LocaleStrings from '../../../../../languages';
import {
  FILTER_TYPE_MYLIST,
  FILTER_TYPE_PEOPLE,
  FILTER_TYPE_REVEALED_PEOPLE,
} from '../../../actions';
import AddToListBlock from './addtolistblock';
import CompositeContactBlock from './compositecontactblock';
import ExportCRMBlock from './exportcrmblock';
import AnimateSVG from '@common/animatedsvg';
import toast from 'react-hot-toast';
import _ from 'lodash';
import {useNavigate} from 'react-router-dom';
import {classNames} from '@commonFunction';
import {connect} from 'react-redux';
import {fetchAppuser} from '@sidebarActions';
import {confirmAlert} from 'react-confirm-alert';

const noEmail = 'No email found';
const noContact = 'No phone number found';

const ContactDetails = props => {
  const {onOpenCreateList, filterType} = props;

  const [processingExport, setProcessingExport] = useState(false);
  const [revealingAll, setRevealingAll] = useState(false);
  const [person, setPerson] = useState(props.person);

  const {peopleid} = person;

  const [anyRevealed, setAnyRevealed] = useState(false);

  const [revealed, setRevealed] = useState(person.revealed);

  const [addedtolist, setAddedToList] = useState(person.addedtolist);

  const [listid, setListId] = useState(person.listid);

  const navigate = useNavigate();

  useEffect(() => {
    const personalEmails = person.contactdetails.filter(
      contact => contact.type === 'personalemail',
    );
    const businessEmails = person.contactdetails.filter(
      contact => contact.type === 'businessemail',
    );
    const directDials = person.contactdetails.filter(
      contact => contact.type === 'directdial',
    );

    const personalEncrypted =
      personalEmails.length > 0 ? personalEmails[0].encrypted : true;
    const businessEncrypted =
      businessEmails.length > 0 ? businessEmails[0].encrypted : true;
    const phoneEncrypted =
      directDials.length > 0 ? directDials[0].encrypted : true;

    const mayRevealed =
      !personalEncrypted || !businessEncrypted || !phoneEncrypted;

    setAnyRevealed(mayRevealed);
  }, [props.person]);

  useEffect(() => {
    if (addedtolist !== props.person.addedtolist) {
      setAddedToList(props.person.addedtolist);
    }
  }, [props.person.addedtolist]);

  useEffect(() => {
    if (revealed !== props.person.revealed) {
      setRevealed(props.person.revealed);
    }
  }, [props.person.revealed]);

  useEffect(() => {
    if (listid !== props.person.listid) {
      setListId(props.person.listid);
    }
  }, [props.person.listid]);

  const updateUser = callback => {
    let {session, user} = props;
    props.fetchAppuser(session, user.appuserid, _ => {
      callback();
    });
  };

  const connectToZoho = () => {
    setProcessingExport(true);
    let crmDetails = props.crmDetails;

    if (crmDetails.zoho) {
      let peopleIdArr = [{peopleid}];

      props.addCRMLead(
        props.session,
        {
          peopleids: peopleIdArr,
          lead_source: 'Kipplo Reveal Contact',
        },
        'zoholead',
        resp => {
          updateUser(() => {
            setProcessingExport(false);
            if (resp.success === 1) {
              // props.saveSelectedDPeople(props.session, []);
              toast.success(LocaleStrings.zoho_success);
            } else {
              console.log('errorlog res', resp.errorlog);
              console.log('errorlog length', resp.errorlog.length);
              if (resp.errorlog.length > 0) {
                confirmAlert({
                  customUI: ({onClose}) => {
                    return (
                      <div className="react-confirm-alert-body">
                        <h1>{LocaleStrings.enrich.title}</h1>

                        <ul>
                          {_.map(resp.errorlog, (item, index) => {
                            return <li>{item.message}</li>;
                          })}
                        </ul>

                        <div className="react-confirm-alert-button-group">
                          <button onClick={onClose}>
                            {LocaleStrings.enrich.cancel}
                          </button>
                        </div>
                      </div>
                    );
                  },
                });
              } else {
                toast.error(LocaleStrings.common_fail_message);
              }
            }
          });
        },
      );
    } else {
      navigate('/settings/crm');
    }
  };

  const connectToSalesForce = () => {
    setProcessingExport(true);
    let crmDetails = props.crmDetails;
    if (crmDetails.salesforce) {
      let peopleIdArr = [{peopleid}];
      props.addCRMLead(
        props.session,
        {
          peopleids: peopleIdArr,
          lead_source: 'Kipplo Reveal Contact',
        },
        'salesforce',
        resp => {
          console.log('resp', resp);
          updateUser(() => {
            setProcessingExport(false);
            if (resp.success === 1) {
              // props.saveSelectedDPeople(props.session, []);
              toast.success(LocaleStrings.salesforce_success);
            } else {
              toast.error(LocaleStrings.common_fail_message);
            }
          });
        },
      );
    } else {
      navigate('/settings/crm');
    }
  };

  const connectToZapier = () => {
    setProcessingExport(true);
    let crmDetails = props.crmDetails;
    if (crmDetails.zapier) {
      let peopleIdArr = [{peopleid}];
      props.zapierHook(
        props.session,
        {
          peopleids: peopleIdArr,
          lead_source: 'Kipplo Reveal Contact',
        },
        resp => {
          console.log('resp', resp);
          updateUser(() => {
            setProcessingExport(false);
            if (resp.success === 1) {
              toast.success(LocaleStrings.zapier_success);
            } else {
              toast.error(LocaleStrings.common_fail_message);
            }
          });
        },
      );
    } else {
      navigate('/settings/crm');
    }
  };

  const exportToCSV = () => {
    setProcessingExport(true);
    let JSONData = {};
    JSONData['revealedcontacts'] = [person];
    JSONToCSVConvertor(JSONData);
  };
  // called from exportBills for downloading report
  const JSONToCSVConvertor = JSONData => {
    var XLSX = require('xlsx');
    let wb = XLSX.utils.book_new();

    let arrjsonn = [];
    _.forEach(JSONData.revealedcontacts, function (person) {
      const {
        contactname,
        jobtitle,
        company,
        contactdetails,
        linkedin,
        contacttag,
      } = person;

      const personalEmails = contactdetails.filter(
        contact => contact.type === 'personalemail' && !contact.encrypted,
      );
      const businessEmails = contactdetails.filter(
        contact => contact.type === 'businessemail' && !contact.encrypted,
      );

      const directDials = contactdetails.filter(
        contact => contact.type === 'directdial' && !contact.encrypted,
      );

      let personalemailcomaseperated = personalEmails
        .map(contact => contact.values)
        .join(',');
      let businessemailcomaseperated = businessEmails
        .map(contact => contact.values)
        .join(',');

      let phonenumbersomaseperated = directDials
        .map(contact => contact.values)
        .join(',');

      var obj = {
        ContactName: contactname,
        JobTitle: jobtitle,
        Company: company.title,
        Linkedin_url: linkedin,
        Label: contacttag ? contacttag.title : 'Not assigned',
        Personal_Emails: personalemailcomaseperated,
        Business_Emails: businessemailcomaseperated,
        Cell_numbers: phonenumbersomaseperated,
      };

      arrjsonn.push(obj);
    });

    let JSONDataOBJ = {
      revealedcontacts: arrjsonn,
    };
    _.map(JSONDataOBJ, (item, index) => {
      /* Create a worksheet */
      var ws = XLSX.utils.json_to_sheet(item);
      var sheetName = index.replace(/[\/\\:\[\]*?]/g, '_');
      if (sheetName.length > 30) {
        sheetName = sheetName.substr(0, 28) + '...';
      }

      XLSX.utils.book_append_sheet(wb, ws, sheetName);
    });
    XLSX.writeFile(wb, 'revealedcontacts.csv');

    setProcessingExport(false);
  };

  const hasAllCredits = props.credits
    ? props.credits.personal_emailcredits > 0 &&
      props.credits.business_emailcredits > 0 &&
      props.credits.contactcredits > 0
    : false;

  const hasAnyCredits = props.credits
    ? props.credits.personal_emailcredits > 0 ||
      props.credits.business_emailcredits > 0 ||
      props.credits.contactcredits > 0
    : false;

  const revealAll = () => {
    setRevealingAll(true);

    props.revealAllContact(
      props.session,
      {
        peopleid: peopleid,
      },
      resp => {
        console.log('RESPONSE => ', resp);

        updateUser(() => {
          if (resp.success === 1) {
            if (resp.data) {
              let contact = resp.data.contactdetails;

              const personalEmails = contact.filter(
                contact => contact.type === 'personalemail',
              );
              const businessEmails = contact.filter(
                contact => contact.type === 'businessemail',
              );
              const directDials = contact.filter(
                contact => contact.type === 'directdial',
              );

              const emptyContactDetails = [];

              if (personalEmails.length > 0) {
                emptyContactDetails.push(personalEmails[0]);
              } else {
                emptyContactDetails.push({
                  values: [noEmail],
                  validation: 'unknown',
                  encrypted: true,
                  type: 'personalemail',
                  available: false,
                });
              }

              if (businessEmails.length > 0) {
                emptyContactDetails.push(businessEmails[0]);
              } else {
                emptyContactDetails.push({
                  values: [noEmail],
                  validation: 'unknown',
                  encrypted: true,
                  type: 'businessemail',
                  available: false,
                });
              }

              if (directDials.length > 0) {
                emptyContactDetails.push(directDials[0]);
              } else {
                emptyContactDetails.push({
                  values: [noContact],
                  validation: 'unknown',
                  encrypted: true,
                  type: 'directdial',
                  available: false,
                });
              }
              contact = emptyContactDetails;

              const updatedPerson = {...resp.data, contactdetails: contact};

              const contactIndex = props.filteredPeople.data.findIndex(
                person => person.peopleid === updatedPerson.peopleid,
              );

              props.filteredPeople.data[contactIndex] = updatedPerson;

              setPerson(updatedPerson);

              if (filterType === FILTER_TYPE_PEOPLE) {
                props.updatePeople(props.filteredPeople);
              } else if (filterType === FILTER_TYPE_REVEALED_PEOPLE) {
                props.updateRevealedPeople(props.filteredPeople);
              } else {
                props.updateFavPeople(props.filteredPeople);
              }
            }
          } else {
            const {message} = resp.message;
            if (message === 'no_credits') {
              // props.onRefresh();
            }
          }
          setRevealingAll(false);
        });
      },
    );
  };

  return (
    <div className="flex gap-2 relative items-center w-[210px]">
      {!anyRevealed ? (
        revealingAll ? (
          <div className="flex justify-center w-[80px]">
            <AnimateSVG />
          </div>
        ) : !hasAnyCredits ? (
          <div
            className="rounded-md border border-gray-200 border-solid py-1.5 bg-primary text-white items-center text-xs px-2 flex hover:opacity-90 hover:cursor-pointer"
            onClick={() => {
              navigate('/upgradeplan');
            }}>
            {LocaleStrings.upgrade}
          </div>
        ) : (
          <div
            className="rounded-md border border-gray-200 border-solid py-1.5 bg-primary text-white items-center text-xs px-2 flex hover:opacity-90 hover:cursor-pointer"
            onClick={revealAll}>
            {LocaleStrings.revealAll}
          </div>
        )
      ) : (
        <div
          className={classNames(
            'rounded-md border border-gray-200 border-solid items-center flex inline-flex overflow-hidden',
            filterType === FILTER_TYPE_MYLIST ? 'w-[31px]' : ' w-[62px]',
          )}
          style={{marginTop: 2}}>
          {filterType === FILTER_TYPE_MYLIST ? null : (
            <div className="border-r border-gray-200 border-solid items-center justify-center p-1.5 px-2 hover:bg-gray-50 hover:cursor-pointer bg-white">
              <AddToListBlock
                integrated
                revealed={revealed}
                filterType={filterType}
                addedtolist={addedtolist}
                listid={listid}
                person={person}
                availableLists={props.availableLists}
                onOpenCreateList={onOpenCreateList}
                onChangeList={list => {
                  props.addPersonTolist(
                    props.session,
                    {listid: list.id, peopleids: `${props.person.peopleid}`},
                    _ => {},
                  );

                  const newpeople = {
                    ...props.person,
                    addedtolist: true,
                    listid: list.id,
                  };
                  const index = props.filteredPeople.data.findIndex(
                    t => t.peopleid === props.person.peopleid,
                  );
                  props.filteredPeople.data[index] = newpeople;

                  setPerson(newpeople);

                  if (filterType === FILTER_TYPE_PEOPLE) {
                    props.updatePeople(props.filteredPeople);
                  } else if (filterType === FILTER_TYPE_REVEALED_PEOPLE) {
                    props.updateRevealedPeople(props.filteredPeople);
                  } else {
                    props.updateFavPeople(props.filteredPeople);
                  }
                }}
              />
            </div>
          )}
          <div className="items-center justify-center p-1.5 px-2 hover:bg-gray-50 hover:cursor-pointer bg-white">
            {processingExport ? (
              <AnimateSVG />
            ) : (
              <ExportCRMBlock
                {...props.crmDetails}
                onSelectedMenu={menuType => {
                  if (menuType === ExportMenuTypes.CSV) {
                    exportToCSV();
                  } else if (menuType === ExportMenuTypes.ZOHO) {
                    connectToZoho();
                  } else if (menuType === ExportMenuTypes.SALESFORCE) {
                    connectToSalesForce();
                  } else if (menuType === ExportMenuTypes.ZAPIER) {
                    connectToZapier();
                  }
                }}
              />
            )}
          </div>
        </div>
      )}
      <CompositeContactBlock
        {...person}
        credits={props.credits}
        filterType={filterType}
        onUpgradePlan={() => props.onUpgradePlan()}
        onBuyAddon={() => props.onBuyAddon()}
        onClickFlag={contact => {
          props.onClickFlag(contact);
        }}
        onRevealCompletion={updatedPerson => {
          const contactIndex = props.filteredPeople.data.findIndex(
            person => person.peopleid === updatedPerson.peopleid,
          );
          props.filteredPeople.data[contactIndex] = updatedPerson;
          setPerson(updatedPerson);

          if (filterType === FILTER_TYPE_PEOPLE) {
            props.updatePeople(props.filteredPeople);
          } else if (filterType === FILTER_TYPE_REVEALED_PEOPLE) {
            props.updateRevealedPeople(props.filteredPeople);
          } else {
            props.updateFavPeople(props.filteredPeople);
          }
        }}
      />
    </div>
  );
};

function mapStateToProps(state) {
  const {appuserData, session, user} = state;
  return {
    session,
    user,
    credits: appuserData.credits,
  };
}

export default connect(mapStateToProps, {fetchAppuser})(ContactDetails);
