import LocaleStrings from '@language';

export const labelNotAssigned = {
  title: 'Not assigned',
  color: '#175CD3',
  value: '__not__assigned__',
};

export const timeFilters = [
  {title: 'All Time', type: 'alltime'},
  {title: 'Today', type: 'today'},
  {title: 'Yesterday', type: 'yesterday'},
  {title: 'Last 7 days', type: 'last7days'},
  {title: 'Current Month', type: 'curmonth'},
  {title: 'Current Year', type: 'curyear'},
  {title: 'Custom', type: 'custom'},
];
