import React, {useEffect, useState, Fragment} from 'react';
// import Portal from '@/common/Portal';
import TooltipPopover from '@/common/TooltipPopover';
import Labelsdropdown from './labelsdropdown';
import {ChevronDownIcon} from '@heroicons/react/solid';
import {PortalWithState} from 'react-portal';

export default function LabelDropdownBlock(props) {
  const [showInfo, setShowInfo] = useState(false); // toggles dropdown visibility
  const [coords, setCoords] = useState({}); // takes current button coordinates

  const handleNavigation = e => {
    if (showInfo) {
      setShowInfo(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', e => handleNavigation(e), true);

    return () => {
      // return a cleanup function to unregister our function since it will run multiple times
      window.removeEventListener('scroll', e => handleNavigation(e), true);
    };
  });

  const updateTooltipCoords = button => {
    const rect = button.getBoundingClientRect();
    setCoords({
      left: rect.x + rect.width / 2, // add half the width of the button for centering
      top: rect.y + window.scrollY - 12, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
    });
  };

  const btnRef = React.createRef();

  // useClickAway(btnRef, () => {
  //   setShowInfo(false);
  // });

  const {tag, labelNotAssigned} = props;

  return (
    <div>
      <PortalWithState closeOnOutsideClick closeOnEsc>
        {({openPortal, closePortal, isOpen, portal}) => (
          <Fragment>
            <div
              className="px-2 py-1 rounded-2xl justify-start items-center gap-1 flex hover:cursor-pointer hover:scale-105"
              style={{
                backgroundColor: tag
                  ? `${tag['color']}20`
                  : `${labelNotAssigned['color']}20`,
              }}
              ref={btnRef}
              onClick={e => {
                updateTooltipCoords(e.target);
                openPortal(e);
              }}>
              <div
                className="text-center text-xs leading-none"
                style={{
                  color: tag
                    ? `${tag['color']}`
                    : `${labelNotAssigned['color']}`,
                }}>
                {tag ? tag.title : labelNotAssigned.title}
              </div>
              <div className="w-5 h-5 px-0.5 justify-center items-center flex">
                <ChevronDownIcon
                  className="h-4 w-4"
                  aria-hidden="true"
                  style={{
                    color: tag
                      ? `${tag['color']}`
                      : `${labelNotAssigned['color']}`,
                  }}
                />
              </div>
            </div>
            {portal(
              <TooltipPopover
                width={210}
                coords={coords}
                direction={'bottom'}
                updateTooltipCoords={() =>
                  updateTooltipCoords(btnRef.current.buttonNode)
                }>
                <Labelsdropdown
                  onLabelSelection={label => {
                    closePortal();
                    props.onLabelSelection(label);
                  }}
                />
              </TooltipPopover>,
            )}
          </Fragment>
        )}
      </PortalWithState>
    </div>
  );
}
