import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import LocaleStrings from '@language';
import {SearchIcon} from '@heroicons/react/solid';
import {classNames, isJSON} from '@commonFunction';
import {fetchmycontacts} from '@myContactsActions';
import BaseLoader from '@baseLoader';
import MyContactsListitems from './mycontacts-itemlist';
import toast, {Toaster} from 'react-hot-toast';
import BtnLoaderSvg from '@btnLoaderSvg';
class Mycontacts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_mycontacts,
      search: '',
      pageNumber: 1,
      perPage: '25',
      selectedUsers: [],
      allCheck: false,
      actionOption: false,
      btnXlsx: false,
      isBtnDisable: true,
      lastPageNumber: 1,
    };

    // All Binded Functions
  }

  componentDidMount() {
    let {pageNumber, search, perPage} = this.state;
    let {session, user} = this.props;
    // fetching contacts
    this.props.fetchmycontacts(session, perPage, pageNumber, search, res => {
      this.calculateLastPage(res);
      this.setState({loading: false});
    });
  }
  // pagination function
  handlePrevPagination = () => {
    let {pageNumber, search, perPage} = this.state;
    let {session, user} = this.props;
    if (pageNumber <= 1) {
    } else {
      let number = pageNumber > 1 ? pageNumber - 1 : 1;
      this.props.fetchmycontacts(session, perPage, number, search, res => {});
      this.setState({pageNumber: number});
    }
  };
  // navigate first pagination function
  navigateToFirst = () => {
    let {session, user, perPage} = this.props;
    let {search} = this.state;

    this.props.fetchmycontacts(session, 25, 1, search, res => {
      if (res.success) {
        this.calculateLastPage(res);
      }
    });
    this.setState({pageNumber: 1});
  };
  // navigate last pagination function
  navigateToLast = () => {
    let {session, user} = this.props;
    let {lastPageNumber, search, perPage} = this.state;
    this.props.fetchmycontacts(
      session,
      perPage,
      lastPageNumber,
      search,
      res => {},
    );
    this.setState({lastPageNumber: lastPageNumber});
    this.setState({pageNumber: lastPageNumber});
  };

  calculateLastPage = response => {
    let perPage = this.state.perPage;

    let number =
      response.count % parseInt(perPage) > 0
        ? Math.floor(response.count / parseInt(perPage)) + 1
        : Math.floor(response.count / parseInt(perPage));

    this.setState({lastPageNumber: number});
  };

  handleNextPagination = () => {
    let {session, user} = this.props;
    let {lastPageNumber, search, pageNumber, perPage} = this.state;
    if (pageNumber < lastPageNumber) {
      let number = pageNumber + 1;
      this.props.fetchmycontacts(session, perPage, number, search, res => {});
      this.setState({pageNumber: number});
    }
  };
  // render list
  rendermycontactList() {
    let {mycontactsDataList} = this.props;
    let pageDataLength = mycontactsDataList.data.length;
    let {pageNumber} = this.state;
    var items = _.map(mycontactsDataList.data, (values, index) => (
      <MyContactsListitems
        key={values.contactid}
        keyindex={index}
        values={values}
        checkAndUncheckUsers={this.updateSelectedUser}
        selectedUsers={this.state.selectedUsers}
        // pagination={this.paginationCallback}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
        onUpgradePlan={() => this.props.router.navigate('/upgradeplan')}
        onBuyAddon={() => this.props.router.navigate('/creditaddons')}
      />
    ));
    return items;
  }
  // handle search
  handleSearch = event => {
    let {session, user} = this.props;
    let {pageNumber, lastPageNumber, search, perPage} = this.state;
    var searchKey = event.target.value;
    this.setState({search: searchKey});
    if (searchKey !== '') {
      this.props.fetchmycontacts(
        session,
        perPage,
        pageNumber,
        searchKey,
        res => {
          if (res.success) {
            this.calculateLastPage(res);
          }
        },
      );
    } else {
      this.props.fetchmycontacts(session, perPage, pageNumber, '', res => {
        if (res.success) {
          this.calculateLastPage(res);
        }
      });
    }
  };
  // fetching according to limit i.e per page
  _fetchContactsperpage(e, comesFrom) {
    let {user, session} = this.props;
    let {pageNumber, lastPageNumber, search, perPage} = this.state;
    if (comesFrom == '25') {
      this.props.fetchmycontacts(session, 25, pageNumber, '', res => {
        if (res.success) {
          this.calculateLastPage(res);
        }
      });
    } else if (comesFrom == '50') {
      this.props.fetchmycontacts(session, 50, pageNumber, '', res => {
        if (res.success) {
          this.calculateLastPage(res);
        }
      });
    } else {
      this.props.fetchmycontacts(session, 100, pageNumber, '', res => {
        if (res.success) {
          this.calculateLastPage(res);
        }
      });
    }
    this.setState({perPage: comesFrom});
  }

  // This function is used to show or hide action option and check or uncheck master check field
  checkMatchArray = selectedUsers => {
    setTimeout(() => {
      var mycontactsDataList = this.props.mycontactsDataList.data;

      var newArray = [];
      _.map(mycontactsDataList, contactUser => {
        var newSelectedUsers = _.filter(selectedUsers, function (selusers) {
          return selusers.contactid === contactUser.contactid;
        });

        if (newSelectedUsers && newSelectedUsers.length > 0) {
          // newArray = [...newArray, newSelectedUsers];
          newArray.push(newSelectedUsers[0]);
        }
      });

      // Check for current page selection and according to show or hide action option for bulk email send
      if (newArray.length > 0) {
        this.setState({actionOption: true});
      } else {
        this.setState({actionOption: false});
      }

      // Check for all user selected or not according to that check or uncheck master check option
      if (
        newArray.length > 0 &&
        newArray.length === mycontactsDataList.length
      ) {
        this.setState({allCheck: true});
        this.setState({isBtnDisable: false});
      } else {
        this.setState({allCheck: false});
      }
    }, 100);
  };

  selectAllUsers = () => {
    var state = this.state.allCheck;
    var selectedUsers = this.state.selectedUsers;
    var allUsers = this.props.mycontactsDataList.data;
    var newSelectUser = [];
    var newSelectedUsers = [];

    if (state == false) {
      _.map(allUsers, user => {
        var checkUserExitOrNot = _.filter(selectedUsers, function (selusers) {
          return selusers.contactid === user.contactid;
        });

        //Check if user is not exist in selected user that add
        if (checkUserExitOrNot.length == 0) {
          newSelectUser = {...user, checked: true};
          selectedUsers = [...selectedUsers, newSelectUser];
        }

        this.checkMatchArray(selectedUsers);
        this.setState({selectedUsers});
        this.setState({isBtnDisable: false});
      });
    } else {
      var leftUsers = selectedUsers;
      _.map(allUsers, user => {
        newSelectedUsers = _.filter(leftUsers, function (selusers) {
          return selusers.contactid !== user.contactid;
        });
        leftUsers = newSelectedUsers;

        this.checkMatchArray(leftUsers);
        this.setState({selectedUsers: leftUsers});
        this.setState({isBtnDisable: false});
      });
    }
  };

  // Selecting one one user
  updateSelectedUser = user => {
    var selectedUsers = this.state.selectedUsers;
    var index = _.findIndex(selectedUsers, function (curuser) {
      return curuser.contactid === user.contactid;
    });

    if (index >= 0) {
      var newSelectedUsers = _.filter(selectedUsers, function (selusers) {
        return selusers.contactid !== user.contactid;
      });

      this.checkMatchArray(newSelectedUsers);
      this.setState({selectedUsers: newSelectedUsers});
    } else {
      if (!user.deleted) {
        var newSelectUser = {...user, checked: true};
        selectedUsers = [...selectedUsers, newSelectUser];
      }

      this.checkMatchArray(selectedUsers);
      this.setState({selectedUsers});
      this.setState({isBtnDisable: false});
    }
  };
  // exporting selected data
  exportBills = (data, ShowLabel) => {
    // let arr = [];
    // arr.push(data.userdata); //

    if (data.length > 0) {
      this.setState({btnXlsx: true});
      let JSONData = {};
      JSONData['mycontacts'] = data;
      this.JSONToCSVConvertor(JSONData, ShowLabel);
      this.setState({btnXlsx: false});
    } else {
      toast.error('Please Select Contacts to Export');
    }
  };
  // called from exportBills for downloading report
  JSONToCSVConvertor(JSONData, ShowLabel) {
    this.setState({btnXlsx: true});
    var XLSX = require('xlsx');
    let wb = XLSX.utils.book_new();

    let arrjsonn = [];
    _.forEach(JSONData.mycontacts, function (value) {
      // console.log("value", value);
      let emailArr = [];
      let emailsjson = isJSON(value.emails);
      if (emailsjson) {
        emailArr = JSON.parse(value.emails);
      }
      let emailArr2 = [];
      emailArr.map((val, key) => {
        let obj = val.address;
        emailArr2.push(obj);
      });

      let personalemailcomaseperated = '';
      let businessemailcomaseperated = '';

      let businessemailArr = [];
      if (value.business_emails != '' && value.business_emails != null) {
        businessemailArr.push(value.business_emails);
      }

      if (emailArr2.length > 0) {
        personalemailcomaseperated = emailArr2.slice(0, 3).join(',');
      }

      if (businessemailArr.length > 0) {
        businessemailcomaseperated = businessemailArr.slice(0, 3).join(',');
      }

      let phoneArr = [];
      let phonejson = isJSON(value.phone_numbers);
      if (phonejson) {
        phoneArr = JSON.parse(value.phone_numbers);
      }

      let phonenumbersomaseperated = '';
      if (phoneArr.length > 0) {
        phonenumbersomaseperated = phoneArr.slice(0, 3).join(',');
      }

      var obj = {
        Full_name: value.name,
        Linkedin_url: value.linkedinurl,
        Personal_Emails: personalemailcomaseperated,
        Business_Emails: businessemailcomaseperated,
        Phone_numbers: phonenumbersomaseperated,
      };

      arrjsonn.push(obj);
    });

    let JSONDataOBJ = {
      mycontacts: arrjsonn,
    };
    _.map(JSONDataOBJ, (item, index) => {
      /* Create a worksheet */
      var ws = XLSX.utils.json_to_sheet(item);
      // // var ws1 = XLSX.utils.json_to_sheet(JSONData['F2(149426)']);

      var sheetName = index.replace(/[\/\\:\[\]*?]/g, '_'); // Sheet name cannot contain ': \ / ? * [ ]' so replacing with '_' and also can not contain more than 31 chars
      if (sheetName.length > 30) {
        sheetName = sheetName.substr(0, 28) + '...';
      }

      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      // XLSX.utils.book_append_sheet(wb, ws1, "F2(149426)");
    });
    XLSX.writeFile(wb, 'mycontacts.xlsx');
    this.setState({btnXlsx: false});
  }

  render() {
    let {pageNumber, lastPageNumber, perPage, selectedUsers} = this.state;
    let {mycontactsDataList} = this.props;
    return (
      <>
        <div className="px-4">
          <div className="flex pb-6">
            <div>
              <div className="text-mainGray text-lg font-normal">
                {LocaleStrings.mycontacts}
              </div>
            </div>
            {mycontactsDataList &&
            mycontactsDataList.data &&
            mycontactsDataList.data.length > 0 ? (
              <div className="ml-auto">
                <div className="flex">
                  <button
                    type="button"
                    onClick={() => this.exportBills(selectedUsers)}
                    disabled={this.state.isBtnDisable}
                    className={classNames(
                      this.state.isBtnDisable
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer',
                      'btn-primary ml-4',
                    )}>
                    <BtnLoaderSvg loader={this.state.btnXlsx} />
                    <img src="../../images/exportexcel.png" className="pr-2" />
                    {LocaleStrings.mycontacts_headerbtn}
                  </button>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <>
          {this.state.loading ? (
            <>
              <BaseLoader data={this.state.loaderDesc} />
            </>
          ) : (
            <>
              <div className="w-full mx-auto bg-white shadow-lg rounded-md border border-gray-200">
                <header className="px-5 py-2 border-b border-gray-100">
                  <div className="block flex">
                    <div className="relative sm:w-full md:w-4/6 text-ternary focus-within:text-secondary ">
                      <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-3">
                        <SearchIcon className="h-5 w-5" aria-hidden="true" />
                      </div>
                      <input
                        id="search-field"
                        className="block  lg:w-6/12 border-gray-300 xs:w-full sm:w-full h-full pl-8 pr-3 py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                        placeholder={'Search'}
                        type="search"
                        name="search"
                        value={this.state.search}
                        onChange={this.handleSearch}
                      />
                    </div>
                    <div className="ml-auto mt-4 md:mt-0 flex">
                      <div className="">
                        <span className="isolate inline-flex rounded-md shadow-sm">
                          <button
                            type="button"
                            onClick={e => this._fetchContactsperpage(e, '25')}
                            className={classNames(
                              perPage == '25'
                                ? 'bg-gray-50  border-indigo-500 '
                                : 'bg-white',
                              'relative inline-flex items-center rounded-l-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50  focus:outline-none ',
                            )}>
                            25
                          </button>
                          <button
                            type="button"
                            onClick={e => this._fetchContactsperpage(e, '50')}
                            className={classNames(
                              perPage == '50'
                                ? 'bg-gray-50  border-indigo-500'
                                : 'bg-white',
                              'relative -ml-px inline-flex items-center border border-gray-300  px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ',
                            )}>
                            50
                          </button>
                          <button
                            type="button"
                            onClick={e => this._fetchContactsperpage(e, '100')}
                            className={classNames(
                              perPage == '100'
                                ? 'bg-gray-50  border-indigo-500'
                                : 'bg-white',
                              'relative -ml-px inline-flex items-center rounded-r-md border border-gray-300  px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50',
                            )}>
                            100
                          </button>
                        </span>
                      </div>
                      <div className="mt-2 ml-2 text-sm font-medium text-gray500">
                        per page
                      </div>
                    </div>
                  </div>
                </header>
              </div>
              {mycontactsDataList &&
              mycontactsDataList.data &&
              mycontactsDataList.data.length > 0 ? (
                <>
                  <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                          <table className="min-w-full table-fixed divide-y divide-gray-300">
                            <thead className="bg-white">
                              <tr>
                                <th
                                  scope="col"
                                  className="relative w-12 px-6 sm:w-16 sm:px-8">
                                  <input
                                    type="checkbox"
                                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                                    onChange={this.selectAllUsers}
                                    checked={this.state.allCheck}
                                  />
                                </th>
                                <th
                                  scope="col"
                                  className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-medium text-gray500">
                                  {LocaleStrings.mycontacts_th1}
                                </th>
                                {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-medium text-gray500">
                                  {LocaleStrings.mycontacts_th2}
                                </th> */}
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-medium text-gray500">
                                  {LocaleStrings.mycontacts_th3}
                                </th>
                                {/* <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-medium text-gray500">
                                  Business Email
                                </th> */}
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-medium text-gray500">
                                  {LocaleStrings.mycontacts_th4}
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-medium text-gray500">
                                  {LocaleStrings.mycontacts_th5}
                                </th>
                                <th
                                  scope="col"
                                  className="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {this.rendermycontactList()}
                            </tbody>
                          </table>
                          <nav className="flex items-center justify-between border-t border-gray-200 px-4 py-2">
                            <div className="">
                              <button
                                className="btn-white cursor-pointer"
                                disabled={pageNumber <= 1 ? true : false}
                                onClick={this.handlePrevPagination}
                                type="button"
                                style={{
                                  cursor:
                                    pageNumber <= 1 ? 'not-allowed' : 'pointer',
                                }}>
                                <img
                                  src="../../images/previous.png"
                                  className="pt-0.5 pr-2"></img>
                                {LocaleStrings.previous}
                              </button>
                            </div>
                            <div className="flex mt-2 sm:mt-auto mx-auto">
                              <nav aria-label="Table navigation">
                                <ul className="inline-flex items-center">
                                  {/* ----- prev button ----- */}
                                  <li>
                                    <button
                                      className="px-3 py-1 rounded-md rounded-l-lg focus:outline-none"
                                      aria-label="Previous"
                                      disabled={pageNumber <= 1 ? true : false}
                                      onClick={this.handlePrevPagination}
                                      style={{
                                        cursor:
                                          pageNumber <= 1
                                            ? 'not-allowed'
                                            : 'pointer',
                                      }}>
                                      <svg
                                        className="w-4 h-4 fill-current"
                                        aria-hidden="true"
                                        viewBox="0 0 20 20">
                                        <path
                                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                          clipRule="evenodd"
                                          fillRule="evenodd"></path>
                                      </svg>
                                    </button>
                                  </li>

                                  {/* ----- pagination numbers ----- */}
                                  {mycontactsDataList &&
                                  mycontactsDataList.count ? (
                                    <>
                                      {pageNumber == 1 ? (
                                        <></>
                                      ) : pageNumber == 2 ? (
                                        <>
                                          <li>
                                            <button
                                              className="px-3 py-1 rounded-md focus:outline-none"
                                              onClick={this.navigateToFirst}>
                                              1
                                            </button>
                                          </li>
                                        </>
                                      ) : (
                                        <>
                                          <li>
                                            <button
                                              className="px-3 py-1 rounded-md focus:outline-none"
                                              onClick={this.navigateToFirst}>
                                              1
                                            </button>
                                          </li>
                                          <li>
                                            <span className="px-3 py-1">
                                              ...
                                            </span>
                                          </li>
                                        </>
                                      )}
                                      <li>
                                        <button className="px-3 py-1 text-white transition-colors duration-150 bg-primaryHover text-primary  rounded-md focus:outline-none">
                                          {/* <button className="px-3 py-1 text-white transition-colors duration-150 bg-primary border border-r-0 border-primary rounded-md focus:outline-none"> */}
                                          {pageNumber}
                                        </button>
                                      </li>

                                      {pageNumber == lastPageNumber ? (
                                        <></>
                                      ) : pageNumber == lastPageNumber - 1 ? (
                                        <>
                                          <li>
                                            <button
                                              className="px-3 py-1 rounded-md focus:outline-none"
                                              onClick={this.navigateToLast}>
                                              {lastPageNumber}
                                            </button>
                                          </li>
                                        </>
                                      ) : (
                                        <>
                                          <li>
                                            <span className="px-3 py-1">
                                              ...
                                            </span>
                                          </li>
                                          <li>
                                            <button
                                              className="px-3 py-1 rounded-md focus:outline-none"
                                              onClick={this.navigateToLast}>
                                              {lastPageNumber}
                                            </button>
                                          </li>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    ''
                                  )}
                                  {/* ----- next button ----- */}
                                  <li>
                                    <button
                                      className="px-3 py-1 rounded-md rounded-r-lg focus:outline-none"
                                      aria-label="Next"
                                      disabled={
                                        pageNumber == lastPageNumber
                                          ? true
                                          : false
                                      }
                                      onClick={this.handleNextPagination}
                                      style={{
                                        cursor:
                                          pageNumber != lastPageNumber
                                            ? 'pointer'
                                            : 'not-allowed',
                                      }}>
                                      <svg
                                        className="w-4 h-4 fill-current"
                                        aria-hidden="true"
                                        viewBox="0 0 20 20">
                                        <path
                                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                          clipRule="evenodd"
                                          fillRule="evenodd"></path>
                                      </svg>
                                    </button>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                            <div className="justify-end">
                              <button
                                className="btn-white cursor-pointer"
                                disabled={
                                  pageNumber == lastPageNumber ? true : false
                                }
                                onClick={this.handleNextPagination}
                                type="button"
                                style={{
                                  cursor:
                                    pageNumber != lastPageNumber
                                      ? 'pointer'
                                      : 'not-allowed',
                                }}>
                                {LocaleStrings.next}
                                <img
                                  src="../../images/next.png"
                                  className="pt-0.5 pl-2"></img>
                              </button>
                            </div>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="center-item my-24 lg:my-48 text-lg text-ternary">
                  {LocaleStrings.no_data_found}
                </div>
              )}
            </>
          )}
        </>
      </>
    );
  }
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  mycontactsDataList: state.mycontactsDataList,
});

export default connect(mapStateToProps, {
  fetchmycontacts,
})(Mycontacts);
