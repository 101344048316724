import React, {Component} from 'react';
import {connect} from 'react-redux';
import blankImg from '../../assets/images/success_placeholder.svg';
import LocaleStrings from '@language';
import {fetchsessionData} from '@mainActions';
import {fetchAppuser} from '@sidebarActions';
import {logGTMEvent} from '@commonFolder/gtm-event';
import SkeletonLoader from '../../common/skeletonloader';

class PlanSuccess extends Component {
  constructor(props) {
    super(props);

    this.state = {elapsedTime: 5};
    // All Binded Functions
  }

  componentDidMount() {
    let {session, user} = this.props;
    let sessionobj = {};
    if (session && session.sessionToken && session.sessionToken != '') {
      sessionobj.session_token = session.sessionToken;
      this.props.fetchsessionData(sessionobj, '', response => {
        const appuserData = response.data;
        let name = '';

        if (appuserData.client_by_clientid) {
          if (
            appuserData.client_by_clientid?.usersubscription_by_clientid &&
            appuserData.client_by_clientid?.usersubscription_by_clientid
              .length > 0 &&
            appuserData.client_by_clientid?.usersubscription_by_clientid[0]
              ?.subscriptionplan_by_planid
          ) {
            let subscriptionplan_by_planid =
              appuserData.client_by_clientid?.usersubscription_by_clientid[0]
                ?.subscriptionplan_by_planid;

            name = subscriptionplan_by_planid?.name.toLowerCase();

            if (name) {
              logGTMEvent({
                event: 'platformSubscribed',
                method: 'subscribed',
                plan: name,
              });
            }

            const interVal = setInterval(() => {
              const {elapsedTime} = this.state;
              const newTime = elapsedTime - 1;
              this.setState({elapsedTime: newTime});
              if (newTime === 0) {
                clearInterval(interVal);
                this.handlePlanPage();
              }
            }, 1000);
          }
        }
      });
    }
  }

  handlePlanPage() {
    this.props.router.navigate('/dashboard');
  }

  render() {
    const {elapsedTime} = this.state;

    let {appuserData} = this.props;

    let name = '';

    if (
      appuserData &&
      appuserData.data &&
      appuserData.data.length > 0 &&
      appuserData.data[0].client_by_clientid
    ) {
      if (
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
          .length > 0 &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid[0]
          ?.subscriptionplan_by_planid
      ) {
        let subscriptionplan_by_planid =
          appuserData.data[0]?.client_by_clientid
            ?.usersubscription_by_clientid[0]?.subscriptionplan_by_planid;

        name = subscriptionplan_by_planid?.name.toLowerCase();
      }
    }
    return (
      <>
        {name ? (
          <main className="flex items-center justify-center">
            <div
              className="flex items-center justify-center"
              style={{marginTop: '10%'}}>
              <div className="justify-center items-center flex flex-col">
                <img className="inline mb-3" src={blankImg} />
                <div className="text-lg font-normal w-80 text-center">
                  <span className="text-gray-800">
                    {LocaleStrings.pay_success_title1}
                  </span>{' '}
                  <span
                    className="font-bold text-primary"
                    style={{textTransform: 'capitalize'}}>
                    {name} plan
                  </span>
                </div>
                <div className=" grid grid-cols-1 md:grid-cols-3 ">
                  <div></div>
                  <div>
                    <button
                      className="btn-primary mt-6 w-64"
                      onClick={() => this.handlePlanPage()}>
                      {LocaleStrings.pay_btn_gotoplan}
                    </button>
                  </div>
                  <div></div>
                </div>
                <div className="mt-2 text-gray-400">
                  {`Redirecting to dashboard in ${elapsedTime} secs..`}
                </div>
              </div>
            </div>
          </main>
        ) : (
          <SkeletonLoader />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var {session, user} = state;
  return {
    session,
    user,
    subscriptionplanList: state.subscriptionplanList,
    appuserData: state.appuserData,
  };
}
export default connect(mapStateToProps, {
  fetchsessionData,
  fetchAppuser,
})(PlanSuccess);
