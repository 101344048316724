import React from 'react';
import {connect} from 'react-redux';
import {Field, formValues, reduxForm, reset, getFormValues} from 'redux-form';
import LocaleStrings from '@language';
import _ from 'lodash';
import toast, {Toaster} from 'react-hot-toast';
import {classNames} from '@commonFunction';
import {Fragment} from 'react';
import {Transition, Dialog} from '@headlessui/react';
import {BaseComponent, LABEL_POSITION_TOP} from '@baseComponent';
import {
  mapFieldsModal,
  updateMappedFields,
  getallZohoFields,
  getallSFFields,
} from '../../../../search/actions';
import MappingForm from './MappingForm';
import IconApi from '../../api/components/icons/api';

const defaultMappings = [
  {kipplo: '', zoho: ''},
  {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
  // {kipplo: '', zoho: ''},
];

class Mapfields extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      //selectedKipplo: new Array(kipploOptions.length).fill(''),
      //selectedZoho: new Array(zohoOptions.length).fill(''),
    };
    this.closeModal = this.closeModal.bind(this);

    this.formRef = React.createRef(null);
  }

  componentDidMount() {
    this.props.getallZohoFields(this.props.session, res => {});
    this.props.getallSFFields(this.props.session, res => {});
  }

  closeModal() {
    this.props.mapFieldsModal(false);
  }

  onFormSubmit(values) {
    console.log('values', values);
    let mapData = {};
    if (this.props.modalstate == 'zoho') {
      if (
        values.hasOwnProperty('kfirstname') &&
        values.hasOwnProperty('zfirstname')
      ) {
        mapData[values.zfirstname] = values.kfirstname;
      }
      if (
        values.hasOwnProperty('klastname') &&
        values.hasOwnProperty('zlastname')
      ) {
        mapData[values.zlastname] = values.klastname;
      }
      if (values.hasOwnProperty('kemail') && values.hasOwnProperty('zemail')) {
        mapData[values.zemail] = values.kemail;
      }
      if (
        values.hasOwnProperty('kcompany') &&
        values.hasOwnProperty('zcompany')
      ) {
        mapData[values.zcompany] = values.kcompany;
      }
      if (
        values.hasOwnProperty('kcountry') &&
        values.hasOwnProperty('zcountry')
      ) {
        mapData[values.zcountry] = values.kcountry;
      }
      if (values.hasOwnProperty('kphone') && values.hasOwnProperty('zphone')) {
        mapData[values.zphone] = values.kphone;
      }
      if (
        values.hasOwnProperty('kindustry') &&
        values.hasOwnProperty('zindustry')
      ) {
        mapData[values.zindustry] = values.kindustry;
      }
      if (Object.keys(mapData).length > 0) {
        let data = {};
        data.crmtype = 'zoho';
        data.mapped_data = JSON.stringify(mapData);
        this.setState({loading: true});
        this.props.updateMappedFields(this.props.session, data, res => {
          this.setState({loading: false});
          this.closeModal();
          if (res.success == 1) {
            toast.success('Updated Successfully');
          } else {
            toast.error(LocaleStrings.something_went_wrong);
          }
        });
      }
    } else if (this.props.modalstate == 'salesforce') {
      if (
        values.hasOwnProperty('kfirstname') &&
        values.hasOwnProperty('sfirstname')
      ) {
        mapData[values.sfirstname] = values.kfirstname;
      }
      if (
        values.hasOwnProperty('klastname') &&
        values.hasOwnProperty('slastname')
      ) {
        mapData[values.slastname] = values.klastname;
      }
      if (values.hasOwnProperty('kemail') && values.hasOwnProperty('semail')) {
        mapData[values.semail] = values.kemail;
      }
      if (
        values.hasOwnProperty('kcompany') &&
        values.hasOwnProperty('scompany')
      ) {
        mapData[values.scompany] = values.kcompany;
      }
      if (
        values.hasOwnProperty('kcountry') &&
        values.hasOwnProperty('scountry')
      ) {
        mapData[values.scountry] = values.kcountry;
      }
      if (values.hasOwnProperty('kphone') && values.hasOwnProperty('sphone')) {
        mapData[values.sphone] = values.kphone;
      }
      if (
        values.hasOwnProperty('kindustry') &&
        values.hasOwnProperty('sindustry')
      ) {
        mapData[values.sindustry] = values.kindustry;
      }
      if (Object.keys(mapData).length > 0) {
        let data = {};
        data.crmtype = 'salesforce';
        data.mapped_data = JSON.stringify(mapData);

        // console.log('data', data);
        // debugger;
        this.setState({loading: true});
        this.props.updateMappedFields(this.props.session, data, res => {
          this.setState({loading: false});
          this.closeModal();
          if (res.success == 1) {
            toast.success('Updated Successfully');
          } else {
            toast.error(LocaleStrings.something_went_wrong);
          }
        });
      }
    }
  }

  handleFormSubmit = values => {
    const {mappings} = values;
    const jsonResult = mappings.reduce((acc, {kipplo, zoho}) => {
      if (kipplo && zoho) {
        acc[zoho] = kipplo;
      }
      return acc;
    }, {});

    console.log('Generated JSON:', jsonResult);
    alert(JSON.stringify(jsonResult, null, 2)); // Display the JSON in an alert
  };

  render() {
    let {handleSubmit, modalstate, zohoFields, salesforceFields} = this.props;
    let kipploOpts = [];
    let zohoOpts = [];
    let salesforceOpts = [];
    let kipploOptions = [
      {label: 'Name', value: 'name'},
      {label: 'Business Email', value: 'bemail'},
      {label: 'Secondary Email 1', value: 'email1'},
      {label: 'Secondary Email 2', value: 'email2'},
      {label: 'Secondary Email 3', value: 'email3'},
      {label: 'Contact address', value: 'contactaddress'},
      {label: 'Contact City', value: 'cities'},
      {label: 'Contact State', value: 'states'},
      {label: 'Contact Country', value: 'countries'},
      {label: 'Mobile', value: 'mobile'},
      {label: 'Phone Number1', value: 'phone1'},
      {label: 'Phone Number2', value: 'phone2'},
      {label: 'Phone Number3', value: 'phone3'},
      {label: 'Company Name', value: 'companyname'},
      {label: 'Company location', value: 'location'},
      {label: 'Company Headquarters phone', value: 'hqnumbers'},
      {label: 'Company phone', value: 'hqnumbers'},
      {label: 'Industry', value: 'industryname'},
      {label: 'Employee size', value: 'headcount'},
      {label: 'Annual Revenue', value: 'revenue'},
      {label: 'Website', value: 'domain'},
      {label: 'SIC code', value: 'siccodes'},
      {label: 'LinkedIn URL', value: 'linkedinurl'},
      {label: 'Job title', value: 'jobtitle'},
      {label: 'Seniority', value: 'seniority'},
      {label: 'Department', value: 'departments'},
      {label: 'Company address', value: 'companyaddress'},
      {label: 'Company City', value: 'ccities'},
      {label: 'Company State', value: 'cstates'},
      {label: 'Company Country', value: 'ccountries'},
    ];

    _.forEach(zohoFields.fields, function (obj) {
      var obj = {
        label: obj.field_label,
        value: obj.api_name,
      };
      zohoOpts.push(obj);
    });

    _.forEach(salesforceFields.fields, function (obj) {
      var obj = {
        label: obj.label,
        value: obj.name,
      };
      salesforceOpts.push(obj);
    });
    return (
      <>
        <Transition.Root show={this.props.ismapFieldModalOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.ismapFieldModalOpen}
            onClose={this.closeModal}
            initialFocus={this.myRef}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block rounded-xl align-bottom  bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-3/5"
                  style={{padding: 10}}>
                  <div className="p-6 border-t-2 border-quaternary">
                    <div className="flex items-center gap-2">
                      <div className="w-10 h-10 p-2 bg-secondary rounded-full justify-center items-center flex">
                        <div className="justify-center items-center flex">
                          <IconApi />
                        </div>
                      </div>
                      <div className="text-lg mainGray">
                        {LocaleStrings.setting_crm_btn_mapfields}
                      </div>
                    </div>
                    {/* <div className="pb-6 text-sm text-gray500 font-normal">
                      {LocaleStrings.settings_crtapi_modal_subh}
                    </div> */}

                    <div>
                      <MappingForm
                        kipploOptions={kipploOptions}
                        zohoOptions={zohoOpts}
                        salesforceOptions={salesforceOpts}
                        defaultMappings={defaultMappings}
                        modalstate={this.props.modalstate}
                        closeModal={this.closeModal}
                      />
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function validate(values) {}

function mapStateToProps(state, ownProps) {
  //console.log('ownProps', ownProps);
  var {session, user} = state;
  let initVals = {};
  initVals.kfirstname = 'firstname';
  initVals.klastname = 'lastname';
  initVals.kemail = 'businessemails';
  initVals.kcountry = 'country';
  initVals.kcompany = 'company';
  initVals.kindustry = 'industry';
  initVals.kphone = 'phone';

  if (
    ownProps.modalstate == 'zoho' &&
    state.crmDetails &&
    Object.keys(state.crmDetails.zoho_mapped_data).length > 0
  ) {
    console.log('state.crmDetails', state.crmDetails);
    for (const key in state.crmDetails.zoho_mapped_data) {
      if (state.crmDetails.zoho_mapped_data.hasOwnProperty(key)) {
        const value = state.crmDetails.zoho_mapped_data[key];
        initVals[value == 'businessemails' ? 'zemail' : `z${value}`] = key;
      }
    }
  }
  if (
    ownProps.modalstate == 'salesforce' &&
    state.crmDetails &&
    Object.keys(state.crmDetails.sales_mapped_data).length > 0
  ) {
    for (const key in state.crmDetails.sales_mapped_data) {
      if (state.crmDetails.sales_mapped_data.hasOwnProperty(key)) {
        const value = state.crmDetails.sales_mapped_data[key];
        initVals[value == 'businessemails' ? 'zemail' : `s${value}`] = key;
      }
    }
  }
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    ismapFieldModalOpen: state.ismapFieldModalOpen,
    formValues: getFormValues('Mapfields')(state),
    appuserData: state.appuserData,
    initialValues: initVals,
    crmDetails: state.crmDetails,
    zohoFields: state.zohoFields,
    salesforceFields: state.salesforceFields,
  };
}

export default connect(mapStateToProps, {
  mapFieldsModal,
  updateMappedFields,
  getallZohoFields,
  getallSFFields,
})(
  reduxForm({
    validate,
    form: 'Mapfields',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Mapfields),
);
