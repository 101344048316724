import {connect} from 'react-redux';
import TimeLabel from './timelabel';

function TimeDropdown(props) {
  return (
    <div className="w-56 h-auto p-2 bg-white rounded-lg drop-shadow-xl border border-gray-200 flex-col justify-start items-start gap-3 inline-flex">
      <div className="self-stretch justify-start items-start gap-6 inline-flex">
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
          {props.times.map(time => (
            <TimeLabel
              key={time.type}
              {...time}
              selected={
                props.savedFilters && props.savedFilters.time.type === time.type
              }
              onSelectTimeFilter={props.onSelectTimeFilter}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    savedFilters:
      ownProps.type === 'companyfilter'
        ? state.mylistCompanyFilters
        : state.mylistPeopleFilters,
  };
}

export default connect(mapStateToProps, null)(TimeDropdown);
