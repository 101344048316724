import React, {Component} from 'react';
import {connect} from 'react-redux';
import LocaleStrings from '@language';
import SearchFilter from './filter';
import SearchResults from './results';
import {
  fetchLists,
  fetchSavedFilters,
  preloadPeopleSearchData,
  toggleFiltersSidePopup,
  fetchCompanyLists,
  FILTER_TYPE_PEOPLE,
} from './actions';
import SavedFiltersSidePopup from './filter/components/savedfilterssidepopup';
import {classNames} from '@commonFunction';

class MainSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_currency,
    };

    // All Binded Functions
  }

  componentDidMount() {
    this.props.fetchLists(this.props.session, {}, _ => {});
    this.props.fetchCompanyLists(this.props.session, {}, _ => {});
    this.props.preloadPeopleSearchData(this.props.session, _ => {});
    this.props.fetchSavedFilters(this.props.session, {}, _ => {});
  }

  render() {
    const {searchSelectedSubMenu} = this.props;
    const hideFilters =
      searchSelectedSubMenu === 'favs' ||
      searchSelectedSubMenu === 'mylists' ||
      searchSelectedSubMenu === 'linkedinsearch';
    return (
      <div>
        {!hideFilters ? (
          <div className="absolute h-full">
            <SearchFilter headcount={this.props.headcount} />
          </div>
        ) : null}
        <div
          className={classNames(
            'absolute flex transition-all ease-in h-full bg-[#F7F8FF]',
            !hideFilters
              ? !this.props.minimized
                ? 'left-[290px]'
                : 'left-[60px]'
              : 'left-0',
          )}>
          <SearchResults {...this.props} />
        </div>

        <SavedFiltersSidePopup
          open={this.props.openFilterSidePopup}
          onClose={() => {
            this.props.toggleFiltersSidePopup(false);
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var {
    isLoggedIn,
    session,
    user,
    searchSelectedSubMenu,
    filtersSidePopupStatus,
    toggleMinimizeFilters,
  } = state;

  let headcount = null;
  if (searchSelectedSubMenu === 'people') {
    headcount = state.filteredPeople.headcount;
  } else if (searchSelectedSubMenu === 'company') {
    headcount = state.filteredCompany.headcount;
  } else if (searchSelectedSubMenu === 'companyfavs') {
    headcount = state.filteredFavCompany.headcount;
  } else {
    headcount = state.filteredFavPeople.headcount;
  }

  return {
    isLoggedIn,
    session,
    user,
    openFilterSidePopup: filtersSidePopupStatus,
    searchSelectedSubMenu,
    headcount,
    minimized: toggleMinimizeFilters,
  };
}

export default connect(mapStateToProps, {
  preloadPeopleSearchData,
  fetchLists,
  fetchSavedFilters,
  toggleFiltersSidePopup,
  fetchCompanyLists,
})(MainSearch);
