import {classNames} from '@commonFunction';

export default function Chip(props) {
  const {title, action} = props;
  const include = action ? action === 'include' : true;
  return (
    <div
      className={classNames(
        include ? 'bg-[#F7F8FF] border-[#cad0fc]' : 'bg-red-50 border-red-400',
        'pl-2 pr-1 py-1 rounded-md border justify-between items-center gap-0.5 flex',
      )}>
      <div className="justify-start items-center gap-1 flex">
        <div
          className={classNames(
            include ? 'semiGray' : 'text-red-400',
            'text-center text-[9px] leading-none ',
          )}>
          {title}
        </div>
      </div>
      <div className="p-1 rounded-sm flex-col justify-end items-center inline-flex">
        <div
          className="items-center inline-flex"
          onClick={() => {
            props.onRemove(props);
          }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 10 10"
            width="10px"
            height="10px"
            fill="none">
            <path
              d="M7.5 2.5L2.5 7.5M2.5 2.5L7.5 7.5"
              stroke={include ? '#98A2B3' : '#F87171'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
