import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {BaseComponent} from '@baseComponent';
import {addpaymentModal, selectedaddOn} from '@creditAddonActions';
import LocaleStrings from '../../../languages';

class CreditAddonListitems extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: '',
      selectedUsers: [],
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  _handlebuyAddons = (e, values) => {
    this.props.selectedaddOn(values);
    this.props.addpaymentModal(true);
  };
  render() {
    var {values, keyindex} = this.props;

    return (
      <>
        <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow hover:bg-gray-100 transition-all duration-500 transform hover:-translate-y-1 hover:scale-105">
          <div className="flex w-full items-center justify-between space-x-6 p-6">
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="truncate text-sm font-medium text-gray-900">
                  {values.name}
                </h3>
              </div>
              <p className="mt-1 truncate text-sm text-gray-500">
                {LocaleStrings.addons_payment_price} {LocaleStrings.dollar}
                {values.price}
              </p>
              <p className="mt-1 truncate text-sm text-gray-500">
                {values.details}
              </p>
            </div>
            <div>
              <button
                className="btn-primary"
                onClick={e => this._handlebuyAddons(e, values)}>
                {LocaleStrings.buy}
              </button>
            </div>
          </div>
          <div></div>
        </li>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var {session, user} = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
  };
}

export default connect(mapStateToProps, {
  addpaymentModal,
  selectedaddOn,
})(CreditAddonListitems);
