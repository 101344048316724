import React, {useState} from 'react';
import Portal from '../../../../../../common/Portal';
import TooltipPopover from '../../../../../../common/TooltipPopover';
import IconUpdate from '../icons/update';
import CompanyInfo from './companyinfo';
import SocialLinks from './sociallinks';
import {useNavigate} from 'react-router-dom';

export default function CompanyBlock(props) {
  const [showCompanyInfo, setShowCompanyInfo] = useState(false); // toggles dropdown visibility
  const [coords, setCoords] = useState({}); // takes current button coordinates

  const {linkedin, twitter, facebook} = props.company;

  const navigate = useNavigate();

  const updateTooltipCoords = button => {
    const rect = button.getBoundingClientRect();
    setCoords({
      left: rect.x, // add half the width of the button for centering
      top: rect.y, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
    });
  };

  const btnRef = React.createRef();

  // console.log('LOGO => ', props.company.logo);

  return (
    <div
      ref={btnRef}
      // onMouseEnter={e => {
      //   if (!showCompanyInfo) {
      //     updateTooltipCoords(e.target);
      //     setShowCompanyInfo(true);
      //   }
      // }}
      // onMouseLeave={e => {
      //   if (showCompanyInfo) {
      //     updateTooltipCoords(e.target);
      //     setShowCompanyInfo(false);
      //   }
      // }}
      // onMouseDown={e => {
      //   navigate('/companydetails', {params: {companyid: props.companyid}});
      // }}
      // onClick={() => {
      //   navigate('/companydetails', {params: {companyid: props.companyid}});
      // }}
    >
      <div className="flex items-center gap-2">
        {props.company.logo ? (
          <img
            className="h-5"
            src={props.company.logo}
            onError={e => {
              e.target.src = require('../../../../../../assets/images/empty_company.png');
            }}
          />
        ) : (
          <img
            className="h-5"
            src={require('../../../../../../assets/images/empty_company.png')}
          />
        )}
        <div className="text-xs font-medium truncate text-gray-900">
          {props.company.title}
        </div>
      </div>
      {/* {!linkedin && !twitter && !facebook ? null : (
        <div>
          <SocialLinks
            linkedin={linkedin}
            twitter={twitter}
            facebook={facebook}
          />
        </div>
      )} */}
    </div>
  );
}
