import React, {Component, useEffect, useState} from 'react';

import {useNavigate} from 'react-router-dom';
import {classNames} from '@commonFunction';
import {
  toTitleCase,
  ENTERPRISE_MONTHLY,
  ENTERPRISE_YEARLY,
} from '@common/constants';
import OverView from './overview';
import DetailsTechnology from './technology';

function Details(props) {
  const {credits} = props;

  let sections = ['overview'];

  // if (
  //   credits.role === 'client' &&
  //   (credits.planid === ENTERPRISE_YEARLY ||
  //     credits.planid === ENTERPRISE_MONTHLY)
  // ) {
  //   sections.push('technologies');
  // }

  sections.push('technologies');

  const [selectedSection, setSelectedSection] = useState('overview');

  const onSectionSelect = section => {
    setSelectedSection(section);
  };

  return (
    <div className="flex flex-col w-full">
      <div>
        <nav
          className="-mb-px flex space-x-5 min-h-[40px] max-h-[40px]"
          aria-label="Tabs">
          {sections.map(tab => (
            <div
              key={tab}
              className={classNames(
                selectedSection === tab
                  ? 'border-primary text-primary'
                  : 'border-transparent text-slate-400 hover:text-primary',
                'group inline-flex items-center border-b-2 py-4 px-1 text-[14px] cursor-pointer',
              )}
              onClick={() => {
                onSectionSelect(tab);
              }}>
              <span className="text-xs">{toTitleCase(tab)}</span>
            </div>
          ))}
        </nav>
      </div>
      <div className="h-full">
        {selectedSection === 'overview' ? (
          <OverView {...props} />
        ) : (
          <DetailsTechnology {...props} />
        )}
      </div>
    </div>
  );
}

export default Details;
