import {Fragment, useEffect, useRef, useState} from 'react';
import {Dialog, Transition, Combobox} from '@headlessui/react';
import IconCreateList from '../../../results/cells/icons/createlist';
import LocaleStrings from '../../../../../languages';
import {connect} from 'react-redux';
import {CheckIcon, ChevronDownIcon} from '@heroicons/react/solid';
import {classNames} from '@commonFunction';
import {
  addFilterToSavedFilter,
  fetchSavedFilters,
  saveFiltersToServer,
} from '../../../actions';
import IconSaveSearch from '../../../results/cells/icons/savesearch';

function SaveSearchPopup(props) {
  const [open, setOpen] = useState(false);

  const [selected, setSelected] = useState('new');

  const [newListText, setNewListText] = useState('');

  const cancelButtonRef = useRef(null);

  const [query, setQuery] = useState('');
  const [selectedList, setSelectedList] = useState(null);

  const [available, setAvailable] = useState(true);

  useEffect(() => {
    if (open !== props.open) {
      setOpen(props.open);
    }
  }, [props.open]);

  const filteredList =
    query === ''
      ? props.availableFilters
      : props.availableFilters.filter(list => {
          return list.listname.toLowerCase().includes(query.toLowerCase());
        });

  const saveFilter = () => {
    props.saveFiltersToServer(
      props.session,
      {
        filtername: newListText.trim(),
        filtertype: 'people',
        jsondata: props.savedFilters,
      },
      res => {
        if (res.data.message && res.data.message === 'notavailable') {
          setAvailable(false);

          setTimeout(() => {
            setAvailable(true);
          }, 2000);
        } else {
          props.fetchSavedFilters(props.session, {}, _ => {});

          setNewListText('');
          setSelected('new');
          setSelectedList(null);
          setQuery('');
          props.toggleOpen(false, 'saved');
        }
      },
    );
  };

  const addFilterToSelected = () => {
    props.addFilterToSavedFilter(
      props.session,
      {filterid: selectedList.filterid, jsondata: props.savedFilters},
      _ => {
        props.fetchSavedFilters(props.session, {}, _ => {});
        setNewListText('');
        setSelected('new');
        setSelectedList(null);
        setQuery('');
        props.toggleOpen(false, 'addedtoexisting');
      },
    );
  };

  const closePopup = () => {
    props.toggleOpen(false);
    setTimeout(() => {
      setNewListText('');
      setSelected('new');
      setSelectedList(null);
      setQuery('');
    }, 300);
  };

  const checkIfSaveAvailable = () => {
    let available = false;

    if (selected === 'new') {
      available = newListText.trim() !== '';
    } else {
      available = selectedList !== null;
    }

    return !available;
  };

  const disableSaveButton = checkIfSaveAvailable();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[9999]"
        initialFocus={cancelButtonRef}
        onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6 w-[400px]">
                <div>
                  <div className="flex gap-4 items-center">
                    <div className="items-start justify-start">
                      <IconSaveSearch />
                    </div>
                    <div className="text-left">
                      <div className="text-sm font-semibold leading-6 text-gray-900">
                        {LocaleStrings.search.savesearch.title}
                      </div>
                      <div className="text-xs text-gray-500">
                        {LocaleStrings.search.savesearch.subtitle}
                      </div>
                    </div>
                  </div>
                  <div className="py-4">
                    <fieldset>
                      <div
                        className={classNames(
                          'pb-6 pt-2',
                          props.availableFilters.length === 0
                            ? 'opacity-50'
                            : 'opacity-100',
                        )}
                        onClick={() => {
                          if (props.availableFilters.length !== 0) {
                            if (selected === 'new') {
                              setSelected('existing');
                            }
                          }
                        }}>
                        <div className="relative flex items-start">
                          <div className="flex h-6 items-center">
                            <input
                              id="existing"
                              disabled={props.availableFilters.length === 0}
                              aria-describedby={`new-description`}
                              name="addtolistoptions"
                              type="radio"
                              value={'existing'}
                              checked={selected === 'existing'}
                              className="h-3 w-3 border-gray-300 text-primary focus:ring-0"
                              onChange={e => {
                                setSelected(e.target.value);
                              }}
                            />
                          </div>
                          <div className="ml-3 text-xs leading-6">
                            <label htmlFor="existing" className="text-gray-900">
                              {LocaleStrings.search.savesearch.existing}
                            </label>
                          </div>
                        </div>
                        <div
                          className={classNames(
                            'mt-2',
                            props.availableFilters.length !== 0
                              ? 'opacity-100'
                              : 'opacity-50',
                          )}>
                          <Combobox
                            as="div"
                            disabled={props.availableFilters.length === 0}
                            value={selectedList}
                            onChange={event => {
                              setSelectedList(event);
                            }}>
                            <div className="relative mt-2">
                              <Combobox.Button className="cursor-pointer w-full">
                                <Combobox.Input
                                  className="w-full rounded-md border-0 bg-white py-2 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary text-xs"
                                  onChange={event => {
                                    // console.log('QUERY => ', event);
                                  }}
                                  displayValue={list =>
                                    list ? list.filtername : ''
                                  }
                                  placeholder="Choose filter to save"
                                />
                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                  <ChevronDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </Combobox.Button>
                              </Combobox.Button>

                              {filteredList.length > 0 && (
                                <Combobox.Options className="absolute z-10 mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-xs">
                                  {filteredList.map(list => (
                                    <Combobox.Option
                                      key={list.filterid}
                                      value={list}
                                      className={({active}) =>
                                        classNames(
                                          'relative cursor-default select-none py-2 pl-3 pr-9 text-xs',
                                          active
                                            ? 'bg-primary text-white'
                                            : 'text-gray-900',
                                        )
                                      }>
                                      <span className="block truncate text-xs">
                                        {list.filtername}
                                      </span>
                                    </Combobox.Option>
                                  ))}
                                </Combobox.Options>
                              )}
                            </div>
                          </Combobox>
                        </div>
                      </div>
                      <div
                        className="space-y-2 pb-2"
                        onClick={() => {
                          if (selected === 'existing') {
                            setSelected('new');
                          }
                        }}>
                        <div className="relative flex items-start">
                          <div className="flex h-6 items-center">
                            <input
                              id="new"
                              aria-describedby={`new-description`}
                              name="addtolistoptions"
                              type="radio"
                              value={'new'}
                              checked={selected === 'new'}
                              className="h-3 w-3 border-gray-300 text-primary focus:ring-0"
                              onChange={e => {
                                setSelected(e.target.value);
                              }}
                            />
                          </div>
                          <div className="ml-3 text-xs leading-6">
                            <label htmlFor="new" className="text-gray-900">
                              {LocaleStrings.search.savesearch.newlist}
                            </label>
                          </div>
                        </div>
                        <div>
                          <input
                            autoComplete="off"
                            name="newlistname"
                            className="cust-input-field w-full text-xs"
                            placeholder={
                              LocaleStrings.search.savesearch.placeholder
                            }
                            value={newListText}
                            onChange={e => setNewListText(e.target.value)}
                          />
                        </div>
                        <div className="text-xs text-red-600 h-2">
                          {available ? '' : 'Already used'}
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="grid grid-flow-row-dense grid-cols-2 gap-3 mx-4">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-white px-3.5 py-2 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 hover:bg-gray-50"
                    onClick={() => closePopup()}
                    ref={cancelButtonRef}>
                    {LocaleStrings.search.savesearch.cancel}
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      'inline-flex w-full justify-center rounded-md  px-3.5 py-2 text-xs text-white shadow-sm hover:opacity-80',
                      disableSaveButton ? 'bg-gray-300' : 'bg-primary',
                    )}
                    disabled={disableSaveButton}
                    onClick={() => {
                      if (selected === 'new') {
                        saveFilter();
                      } else {
                        addFilterToSelected();
                      }
                    }}>
                    {LocaleStrings.search.savesearch.save}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    availableFilters: state.availableFilters,
    savedFilters,
  };
}

export default connect(mapStateToProps, {
  saveFiltersToServer,
  addFilterToSavedFilter,
  fetchSavedFilters,
})(SaveSearchPopup);
