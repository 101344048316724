import {INSTANCE_URL} from '@constant';
import {postRequest} from '@networkCall';

export var SESSION_TOKEN_PASSWORD = 'SESSION_TOKEN_PASSWORD';

export function changePassword(session, values, callback) {
  return dispatch => {
    var url = `${INSTANCE_URL}/api/v2/user/password?reset=true&login=true`;
    postRequest(
      url,
      values,
      session,
      dispatch,
      response => {
        var returnData = {error: 0, data: 'Password changed successfully!'};
        dispatch({
          type: SESSION_TOKEN_PASSWORD,
          payload: {session_token: response?.session_token},
        });
        callback(returnData);
      },
      error => {
        var returnData = {error: 1, data: error.message};
        callback(returnData);
      },
    );
  };
}
