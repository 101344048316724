import {createContext, useContext, useState} from 'react';

const MappingSelectionContext = createContext();

function MappingSelectionProvider({children}) {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [fields, setFields] = useState([]);

  const addField = selectedValue => {
    setFields([...fields, selectedValue]);
  };

  const removeField = index => {
    const field = fields[index];
    const filtered = fields.filter(item => item.kipplo !== field.kipplo);
    setFields([...filtered]);
  };

  const selectIndex = index => {
    setSelectedIndex(index);
  };

  const updateMappedFields = arrFields => {
    setFields(arrFields);
    selectIndex(arrFields.length);
  };

  return (
    <MappingSelectionContext.Provider
      value={{
        selectedIndex,
        addField,
        selectIndex,
        updateMappedFields,
        fields,
        removeField,
      }}>
      {children}
    </MappingSelectionContext.Provider>
  );
}

export default MappingSelectionProvider;

export const useMappingSelectionContext = () =>
  useContext(MappingSelectionContext);
