import {
  getRequest,
  postRequest,
  deleteRequest,
  fileUploadRequest,
  patchRequest,
  deleteBulkRequest,
} from '@networkCall';

import {
  APP_API_KEY,
  INSTANCE_URL,
  itemCount,
  DEVELOPMENT_TYPE,
} from '@constant';

import LocaleStrings from '@language';

// All Exports

export var ADD_MEMBER_MODAL = 'ADD_MEMBER_MODAL';
export var ALL_MEMBERLIST = 'ALL_MEMBERLIST';

/**** Open add member modal ****/
export function addMembermodal(isOpen) {
  return {type: ADD_MEMBER_MODAL, payload: isOpen};
}

export function fetchMembers(session, callback) {
  let orderBy = `createdon%20DESC`;
  // var filter = encodeURI(`filter=((role=admin)OR(role=member))AND(isdeleted=0)`);
  var filter = encodeURI(`filter=(isdeleted=0)`);
  var url = `${INSTANCE_URL}/api/v2/thomsondata/_table/appuser?${filter}&order=${orderBy}`;
  // console.log(url)
  return dispatch => {
    getRequest(
      url,
      session,
      dispatch,
      data => {
        // console.log('data1', data);
        dispatch({
          type: ALL_MEMBERLIST,
          payload: {data: data.resource},
        });
        callback({success: 1, data: data});
      },
      error => {},
    );
  };
}

export function addMember(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/thomsondata/_table/appuser`;
  // let body = { resource: { ...values } };
  let body = {resource: [values]};
  return dispatch => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      response => {
        // console.log('res',response)
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        callback({success: 0, message: error});
      },
    );
  };
}

export function updateMember(session, obj, callback) {
  let url = `${INSTANCE_URL}/api/v2/thomsondata/_table/appuser`;
  let body = {resource: obj};
  // let body = {resource: [obj] };

  return dispatch => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      response => {
        // console.log('res',response)
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        callback({success: 0, message: error});
      },
    );
  };
}

// export function validateEmailForAppUser(session, values, callback) {
//   let url = `${INSTANCE_URL}/api/v2/checkemailexists`;
//   return (dispatch) => {
//     postRequest(
//       url,
//       values,
//       session,
//       dispatch,
//       (response) => {
//         // console.log('res',response)
//         callback({ success: 1, result: response });
//       },
//       (error) => {
//         // console.log(error)
//         callback({ success: 0, message: error });
//       }
//     );
//   };
// }

export function sendEmailtoAppuser(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/sendappuserinviteemail`;
  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      response => {
        // console.log('res',response)
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        // callback({ success: 0, message: error });
      },
    );
  };
}

export function deleteMembers(session, appuserid, callback) {
  // var url = `${INSTANCE_URL}/api/v2/thomsondata/_table/appuser/${appuserid}`;
  const url = `${INSTANCE_URL}/api/v2/removemember`;

  return dispatch => {
    postRequest(
      url,
      {appuserid},
      session,
      dispatch,
      data => {
        if (data.success) callback({success: 1, data: data});
        else callback({success: 0, data: null});
      },
      error => {
        callback({success: 0, data: error});
      },
    );
  };
}

export function sendClientcredit(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/updateclient_credit`;
  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      response => {
        // console.log('res',response)
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        callback({success: 0, message: error});
      },
    );
  };
}
