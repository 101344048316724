import React, {Fragment, useState} from 'react';
import Portal from '@/common/Portal';
import TooltipPopover from '@/common/TooltipPopover';

// import IconAddedToList from '../icons/addedtolist';
// import IconSaveList from '../icons/save';
import AddToList from './addtolist';
import {FILTER_TYPE_COMPANY, FILTER_TYPE_FAV_COMPANY} from '../../../actions';
import IconAddedToList from '../../../results/cells/icons/addedtolist';
import IconSaveList from '../../../results/cells/icons/save';
import {PortalWithState} from 'react-portal';

export default function AddCompanyToListBlock(props) {
  const [showInfo, setShowInfo] = useState(false); // toggles dropdown visibility
  const [coords, setCoords] = useState({}); // takes current button coordinates

  const updateTooltipCoords = button => {
    const rect = button.getBoundingClientRect();
    setCoords({
      left: rect.x + rect.width / 2 + 45, // add half the width of the button for centering
      top: rect.y + window.scrollY, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
    });
  };

  const btnRef = React.createRef();

  const {revealed, filterType, addedtolist, listid} = props;

  return (
    <div>
      <PortalWithState closeOnOutsideClick closeOnEsc>
        {({openPortal, closePortal, isOpen, portal}) => (
          <Fragment>
            <div
              ref={btnRef}
              onMouseEnter={e => {
                updateTooltipCoords(e.target);
                if (revealed && props.availableLists.length > 0) {
                  openPortal(e);
                }
              }}
              onMouseLeave={e => {
                updateTooltipCoords(e.target);
                if (revealed && props.availableLists.length > 0) {
                  closePortal();
                }
              }}
              onClick={e => {
                updateTooltipCoords(e.target);
                if (props.availableLists.length === 0) {
                  props.onOpenCreateList(props.company);
                  openPortal(e);
                }
              }}>
              <div>
                {revealed && filterType === FILTER_TYPE_COMPANY ? (
                  addedtolist ? (
                    <IconAddedToList />
                  ) : (
                    <IconSaveList />
                  )
                ) : filterType === FILTER_TYPE_FAV_COMPANY ? (
                  addedtolist ? (
                    <IconAddedToList />
                  ) : (
                    <IconSaveList />
                  )
                ) : null}
              </div>
              {portal(
                <TooltipPopover
                  width={200}
                  coords={coords}
                  direction={'left'}
                  updateTooltipCoords={() =>
                    updateTooltipCoords(btnRef.current.buttonNode)
                  }>
                  {props.availableLists.length > 0 && revealed ? (
                    <AddToList
                      listtype="company"
                      addedListId={listid}
                      onCreateNewList={() => {
                        closePortal();
                        props.onOpenCreateList(props.company);
                      }}
                      onChangeList={list => {
                        props.onChangeList(list);
                        closePortal();
                      }}
                    />
                  ) : null}
                </TooltipPopover>,
              )}
            </div>
          </Fragment>
        )}
      </PortalWithState>
    </div>
  );
}
