export default function IconCopyNormal({size}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2671_16048)">
        <path
          d="M5.33398 3.99984V2.79984C5.33398 2.0531 5.33398 1.67973 5.47931 1.39452C5.60714 1.14363 5.81111 0.93966 6.062 0.811829C6.34721 0.666504 6.72058 0.666504 7.46732 0.666504H12.534C13.2807 0.666504 13.6541 0.666504 13.9393 0.811829C14.1902 0.93966 14.3942 1.14363 14.522 1.39452C14.6673 1.67973 14.6673 2.0531 14.6673 2.79984V9.8665C14.6673 10.6132 14.6673 10.9866 14.522 11.2718C14.3942 11.5227 14.1902 11.7267 13.9393 11.8545C13.6541 11.9998 13.2807 11.9998 12.534 11.9998H10.6673M3.46732 15.3332H8.53398C9.28072 15.3332 9.65409 15.3332 9.93931 15.1878C10.1902 15.06 10.3942 14.856 10.522 14.6052C10.6673 14.3199 10.6673 13.2799 10.6673 12.5332V6.13317C10.6673 5.38643 10.6673 5.01307 10.522 4.72785C10.3942 4.47697 10.1902 4.27299 9.93931 4.14516C9.65409 3.99984 9.28072 3.99984 8.53398 3.99984H3.46732C2.72058 3.99984 2.34721 3.99984 2.062 4.14516C1.81111 4.27299 1.60714 4.47697 1.47931 4.72785C1.33398 5.01307 1.33398 5.38643 1.33398 6.13317V12.5332C1.33398 13.2799 1.33398 14.3199 1.47931 14.6052C1.60714 14.856 1.81111 15.06 2.062 15.1878C2.34721 15.3332 2.72058 15.3332 3.46732 15.3332Z"
          stroke="#667085"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2671_16048">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
