import React, {Component, useLayoutEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {classNames} from '@commonFunction';

import IconCopy from '../../search/results/cells/icons/copy';

import AddToList from '../../search/results/cells/components/addtolist';
import IconDelete from '../icons/delete';
import IconExport from '../icons/export';
import {ArrowRightIcon} from '@heroicons/react/solid';
import {openList} from '../actions';
import {formattedDateIntoLocal} from '@commonFunction';

function ListCell(props) {
  const {list, selected, onOpenCreateList} = props;
  const {listid, listname, contacts, updatedon, createdon} = list;

  const [showList, setShowList] = useState(false);
  return (
    <tr
      key={listid}
      className={classNames(
        'table table-fixed w-full',
        selected ? 'bg-gray-50' : undefined,
      )}>
      <td
        className={classNames(
          'group hover:bg-gray-50 hover:cursor-pointer relative pl-10',
        )}
        style={{width: '28%'}}>
        <div
          className="relative flex"
          onClick={() => {
            props.openList(props.list);
          }}>
          <div
            className={classNames(
              'whitespace-nowrap text-xs font-medium mb-2  w-fit',
              selected ? 'text-primary' : 'text-gray-900',
            )}>
            {listname}
          </div>
        </div>
        {/* <div
          className="absolute right-2 top-6 group-hover:opacity-100 opacity-0"
          onClick={() => {
            navigator.clipboard.writeText(listname);
            props.onCopySuccess();
          }}>
          <IconCopy />
        </div> */}
      </td>
      <td
        className="group whitespace-wrap px-3 py-2 relative"
        style={{width: '20%'}}>
        <div className="text-xs text-gray-500">{contacts}</div>
        {/* <div
          className="absolute right-2 top-7 group-hover:opacity-100 opacity-0"
          onClick={() => {
            navigator.clipboard.writeText(`${contacts}`);
            props.onCopySuccess();
          }}>
          <IconCopy />
        </div> */}
      </td>
      <td className="px-2 py-2 relative" style={{width: '26%'}}>
        <div className="relative flex">
          <div className="text-xs text-gray-500">
            {formattedDateIntoLocal(createdon)}
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 justify-items-center">
        <div className="text-xs text-gray-500">
          {formattedDateIntoLocal(updatedon)}
        </div>
      </td>
      <td className="whitespace-nowrap py-2 pl-3 pr-8">
        <div className="group flex gap-2 justify-end relative">
          <div
            onClick={() => {
              props.onDeleteList(props.list);
            }}>
            <IconDelete />
          </div>

          {/* <div onClick={() => {}}>{<IconExport />}</div> */}
          <div className="w-2 h-2 ml-2 mt-2 z-50">
            <div
              className={classNames(
                'absolute -left-[5px] top-10 transition duration-500 ease-in z-50',
                showList ? 'visible' : 'invisible',
              )}>
              <AddToList
                onCreateNewList={() => {
                  setShowList(false);
                  onOpenCreateList();
                }}
              />
            </div>
          </div>
          {/* <div
            className="mx-4 mt-2 hover:cursor-pointer"
            onClick={() => {
              props.openList(props.list);
            }}>
            <ArrowRightIcon
              className="h-5 w-10 text-gray-400"
              aria-hidden="true"
            />
          </div> */}
        </div>
      </td>
    </tr>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    savedFilters,
    searchSelectedSubMenu: state.searchSelectedSubMenu,
  };
}

export default connect(mapStateToProps, {openList})(ListCell);
