import React from 'react';
import {connect} from 'react-redux';
import {Dialog, Transition} from '@headlessui/react';
import LocaleStrings from '@language';
import {Fragment} from 'react';
import {BaseComponent} from '@baseComponent';
import {reduxForm} from 'redux-form';
import _ from 'lodash';
import RevealedContactListitems from './revealedcontacts-item';
import {
  openRevealedmodal,
  fetchrevealedContacts,
  revealedbyappuserdata,
  revealedbyappusername,
} from '@settingsUsageActions';
import PaginationBar from '../../../../../search/results/cells/components/paginationbar';
import SkeletonLoader from '../../../../../../../common/skeletonloader';

function IconCross() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor">
      <path
        fillRule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  );
}

class RevealedBy extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_revealed_contacts,
      btnLoader: false,
      enar: 'en',
      pageNumber: 1,
      numsPerPage: 25,
      perPage: '25',
      paginating: false,
      initialLoad: true,
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
  }

  fetchData() {
    this.setState({loading: true});
    let {session, startDate, endDate} = this.props;
    if (this.props.appuserid && this.props.appuserid !== '') {
      this.props.fetchrevealedContacts(
        session,
        this.props.appuserid,
        this.props.appuserData.role,
        this.state.pageNumber,
        {startDate, endDate},
        res => {
          this.calculateLastPage(res);
          setTimeout(() => {
            this.setState({loading: false});
          }, 200);
          setTimeout(() => {
            this.setState({initialLoad: false});
          }, 400);
        },
      );
    }
  }

  componentDidMount() {}

  shouldComponentUpdate(nextProps) {
    if (nextProps.isrevealedbyOpen !== this.props.isrevealedbyOpen) {
      if (nextProps.isrevealedbyOpen) {
        this.fetchData();
      }
    }
    return true;
  }

  handlePagination = curPage => {
    let {session, startDate, endDate} = this.props;

    if (!this.state.initialLoad) {
      this.props.fetchrevealedContacts(
        session,
        this.props.appuserid,
        this.props.appuserData.role,
        curPage,
        {startDate, endDate},
        res => {
          this.setState({paginating: false});
        },
      );
      this.setState({pageNumber: curPage, paginating: true});
    }
  };

  calculateLastPage = response => {
    let perPage = this.state.numsPerPage;

    let number =
      response.count % parseInt(perPage) > 0
        ? Math.floor(response.count / parseInt(perPage)) + 1
        : Math.floor(response.count / parseInt(perPage));

    this.setState({lastPageNumber: number});
  };
  closeModal() {
    this.props.openRevealedmodal(false);
  }

  renderrevealedcontact() {
    let {revealedcontactdataList} = this.props;
    let pageDataLength = revealedcontactdataList.data.length;
    let {pageNumber} = this.state;
    var items = _.map(revealedcontactdataList.data, (values, index) => (
      <RevealedContactListitems
        key={values.contactid}
        values={values}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
      />
    ));
    return items;
  }

  render() {
    let {revealedcontactdataList, storedrevealbyappusername} = this.props;
    let name = 'No Name';
    if (storedrevealbyappusername) {
      name = storedrevealbyappusername;
    }

    const noData =
      !revealedcontactdataList ||
      !revealedcontactdataList.data ||
      !this.state.numsPerPage ||
      revealedcontactdataList.data.length === 0;

    return (
      <Transition.Root show={this.props.isrevealedbyOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 content-center"
          open={this.props.isrevealedbyOpen}
          onClose={this.closeModal}
          initialFocus={this.myRef}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div
                className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 scrollbar-hide bigmodal"
                style={{
                  padding: 0,
                  height: 'calc(100vh / var(--zoom) - 80px)',
                }}>
                <>
                  {this.state.loading ? (
                    <div className="h-full content-center px-4">
                      <SkeletonLoader small />
                    </div>
                  ) : (
                    <>
                      <div className="p-6 h-full">
                        <div className="text-2xl">
                          {
                            LocaleStrings.setting_usage_reavealedcontact_modal_header
                          }
                        </div>
                        <div className="mt-3 text-sm font-medium text-gray500">
                          {
                            LocaleStrings.setting_usage_reavealedcontact_modal_subheader
                          }{' '}
                          {name}{' '}
                        </div>

                        {!noData ? (
                          <>
                            <div className="flex flex-col mt-6">
                              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                  <div
                                    className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
                                    style={{
                                      height:
                                        'calc(100vh / var(--zoom) - 200px)',
                                    }}>
                                    <table className="min-w-full table-fixed divide-y divide-gray-300">
                                      <thead className="bg-gray-50  table w-full">
                                        <tr className="table table-fixed w-full">
                                          <th
                                            scope="col"
                                            className="min-w-[12rem] py-3.5 px-6 text-left text-sm font-medium text-gray500">
                                            {LocaleStrings.mycontacts_th1}
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-medium text-gray500"
                                            style={{width: '20%'}}>
                                            {LocaleStrings.mycontacts_th5}
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-medium text-gray500"
                                            style={{width: '40%'}}>
                                            {LocaleStrings.mycontacts_th4}
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-medium text-gray500"
                                            style={{width: '20%'}}>
                                            {LocaleStrings.mycontacts_th3}
                                          </th>

                                          {/* <th
                                            scope="col"
                                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"></th> */}
                                        </tr>
                                      </thead>
                                      <tbody
                                        className="divide-y divide-gray-200 bg-white block w-full overflow-auto"
                                        style={{
                                          height:
                                            'calc(100vh / var(--zoom) - 310px)',
                                        }}>
                                        {this.state.paginating ? (
                                          <SkeletonLoader small />
                                        ) : (
                                          this.renderrevealedcontact()
                                        )}
                                      </tbody>
                                    </table>
                                    <nav className="flex items-center justify-between border-t border-gray-200 px-4 bg-white rounded-b-lg">
                                      <div className="w-full">
                                        <PaginationBar
                                          hidden={noData}
                                          curPage={this.state.pageNumber}
                                          numPages={this.state.lastPageNumber}
                                          maxPage={this.state.perPage}
                                          onPageChange={curPage => {
                                            this.handlePagination(curPage);
                                          }}
                                          onNavigateFirst={() => {
                                            this.handlePagination(1);
                                          }}
                                          onNavigateLast={() => {
                                            this.handlePagination(
                                              this.state.lastPageNumber,
                                            );
                                          }}
                                        />
                                      </div>
                                      {noData ? <div className="h-10" /> : null}
                                    </nav>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="h-full flex justify-center content-center">
                            <div className="center-item text-lg text-ternary">
                              {LocaleStrings.no_data_found}
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>

                <div
                  className="absolute top-10 right-10 cursor-pointer z-[99999]"
                  onClick={this.closeModal}>
                  <IconCross />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

function validate(values) {
  // console.log("values", values);
  var errors = {};

  return errors;
}

function mapStateToProps(state, ownProps) {
  var {session, user} = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    isrevealedbyOpen: state.isrevealedbyOpen,
    revealedcontactdataList: state.revealedcontactdataList,
    storedrevealbyappusername: state.storedrevealbyappusername,
  };
}

export default connect(mapStateToProps, {
  openRevealedmodal,
  fetchrevealedContacts,
  revealedbyappuserdata,
  revealedbyappusername,
})(
  reduxForm({
    validate,
    form: 'RevealedByForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(RevealedBy),
);
