import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {connect} from 'react-redux';
import {classNames} from '@commonFunction';

import {FilterIcon} from '@heroicons/react/solid';
import IconExport from '../search/results/cells/icons/export';
import PaginationBar from '../search/results/cells/components/paginationbar';
import AppAlert from '../../../common/appalert';
import toast, {Toaster} from 'react-hot-toast';

import {
  fetchListCompany,
  removeCompanyFromlist,
  updateListCompany,
} from './actions';
import MyListCompanyCell from './cells/MyListCompanyCell';
import LocaleStrings from '../../languages';
import NoResult from '../../../common/noresult';
import SkeletonLoader from '../../../common/skeletonloader';
import useWindowDimensions from '../../../common/useWindowDimensions';
import _ from 'lodash';
import {
  ENTERPRISE_MONTHLY,
  ENTERPRISE_YEARLY,
  convertToInternationalCurrencySystem,
  toTitleCase,
} from '../../../common/constants';

import TechnologyListPopup from '../../dashboard/search/companyresults/technologypopup';
import ExportMenu from '../../../common/exportmenu';
import {ExportMenuTypes} from '../../../common/exportmenu/menudropdown';
import ProcessingIndicator from '../../../common/processingindicator';

const maxRowPerPage = 25;

function ListCompanyTable(props) {
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const {height, width} = useWindowDimensions();

  const [sortBy, setSortBy] = useState('companyname');
  const [sortDirection, setSortDirection] = useState('asc');

  const [cardView, setCardView] = useState(false);

  const [showList, setShowList] = useState(false);

  const [curPage, setCurPage] = useState(1);
  const [rowsPerPage] = useState(maxRowPerPage);

  const [isLoading, setIsLoading] = useState(false);
  const [isPaginating, setIsPaginating] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const [selectedList, setSelectedList] = useState(undefined);

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const [selectedCompanyToDelete, setSelectedCompanyToDelete] =
    useState(undefined);

  const [selectedCompanyForTech, setSelectedCompanyForTech] = useState();

  const [processingExport, setProcessingExport] = useState(false);

  const applyFilter = resetPage => {
    const page = resetPage ? 1 : curPage;
    props.fetchListCompany(
      props.session,
      page,
      rowsPerPage,
      sortBy,
      sortDirection,
      {...props.savedFilters, listid: props.activeList.listid},
      resp => {
        if (resetPage) {
          setCurPage(1);
          setTimeout(() => {
            setIsLoading(false);
            setIsPaginating(false);
            setFirstLoad(false);
          }, 500);
        } else {
          setIsLoading(false);
          setIsPaginating(false);
          setFirstLoad(false);
        }
      },
    );
  };

  useEffect(() => {
    if (isPaginating) {
      applyFilter(false);
    }
  }, [curPage]);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      applyFilter(true);
    }
  }, [props.savedFilters]);

  useLayoutEffect(() => {
    if (props.filteredCompany && props.filteredCompany.length > 0) {
      const isIndeterminate =
        selectedCompanies.length > 0 &&
        selectedCompanies.length !== props.filteredCompany.length;
      setChecked(selectedCompanies.length === props.filteredCompany.length);
      setIndeterminate(isIndeterminate);
      if (checkbox.current) checkbox.current.indeterminate = isIndeterminate;
    } else {
      setChecked(false);
    }
  }, [selectedCompanies, props.filteredCompany]);

  function toggleAll() {
    setSelectedCompanies(checked || indeterminate ? [] : props.filteredCompany);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const renderResultText = () => {
    const startIndex = (curPage - 1) * maxRowPerPage;
    let endIndex = (curPage - 1) * maxRowPerPage + maxRowPerPage;

    if (props.totalcount < maxRowPerPage) {
      endIndex = props.totalcount;
    }

    if (endIndex > props.totalcount) {
      endIndex = props.totalcount;
    }

    if (props.totalcount > 0)
      return `${startIndex + 1} - ${endIndex} of ${props.totalcount} contacts`;

    return '0 contacts';
  };

  const exportToCSV = () => {
    if (selectedCompanies.length > 0) {
      setProcessingExport(true);
      let JSONData = {};
      JSONData['mylists_companies'] = selectedCompanies;
      JSONToCSVConvertor(JSONData);
      // setProcessingExport(false);
    } else {
      setProcessingExport(false);
      toast.error('Please Select Companies to Export');
    }
  };
  // called from exportBills for downloading report
  const JSONToCSVConvertor = JSONData => {
    // setProcessingExport(true);
    var XLSX = require('xlsx');
    let wb = XLSX.utils.book_new();

    let arrjsonn = [];
    _.forEach(JSONData.mylists_companies, function (objCompany) {
      const {
        companyname,
        industry,
        location,
        company,
        hqnumbers,
        technologies,
        createdon,
      } = objCompany;

      let {headcount, revenue, naicscodes, siccodes} = company;
      let techsToShow = technologies.length > 0 ? technologies[0] : '';

      if (
        props.credits.role === 'client' &&
        (props.credits.planid !== ENTERPRISE_YEARLY ||
          props.credits.planid !== ENTERPRISE_MONTHLY)
      ) {
        techsToShow = '';
      }

      headcount =
        headcount === '-1'
          ? 'Self Employed'
          : headcount === '0'
            ? 'Unknown'
            : headcount;

      var obj = {
        CompanyName: companyname,
        Industry: toTitleCase(industry),
        NAICS: naicscodes,
        SIC: siccodes,
        Location: location,
        EmployeeSize: headcount,
        Revenue: convertToInternationalCurrencySystem(revenue),
        Technologies: techsToShow,
      };

      arrjsonn.push(obj);
    });

    let JSONDataOBJ = {
      mylists_companies: arrjsonn,
    };
    _.map(JSONDataOBJ, (item, index) => {
      /* Create a worksheet */
      var ws = XLSX.utils.json_to_sheet(item);
      // // var ws1 = XLSX.utils.json_to_sheet(JSONData['F2(149426)']);

      var sheetName = index.replace(/[\/\\:\[\]*?]/g, '_'); // Sheet name cannot contain ': \ / ? * [ ]' so replacing with '_' and also can not contain more than 31 chars
      if (sheetName.length > 30) {
        sheetName = sheetName.substr(0, 28) + '...';
      }

      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      // XLSX.utils.book_append_sheet(wb, ws1, "F2(149426)");
    });
    XLSX.writeFile(wb, 'mylists_companies.csv');

    setProcessingExport(false);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {props.totalcount > 0 && !isLoading ? (
        <div className="mt-2 flow-root">
          <div className="flex flex-grow">
            <div className="flex -mx-4 mb-4 relative items-center grow shrink basis-0 self-stretch">
              {!processingExport ? (
                <div>
                  <input
                    type="checkbox"
                    className="rounded border-gray-500 text-primary focus:ring-primary hover:cursor-pointer hover:border-primary hover:border-2 hover:bg-gray-100"
                    ref={checkbox}
                    checked={checked}
                    onChange={toggleAll}
                  />
                </div>
              ) : null}
              {!processingExport ? (
                <div className="ml-2 mt-1 text-xs font-semibold">
                  {renderResultText()}
                </div>
              ) : null}
              {!processingExport ? (
                <div
                  className={classNames(
                    'transition ease-in duration-300 absolute left-5 -top-3 flex h-12 items-center space-x-3 bg-white sm:left-5',
                    selectedCompanies.length > 0 ? 'opacity-100' : 'opacity-0',
                  )}>
                  {' '}
                  <div className="ml-2 mt-1 text-xs font-semibold">
                    {`${selectedCompanies.length} selected`}
                  </div>
                  <div>
                    <button
                      className="btn-primary-white"
                      style={{
                        pointerEvents:
                          selectedCompanies.length > 0 ? 'all' : 'none',
                      }}
                      onClick={() => {
                        exportToCSV();
                      }}>
                      Export to CSV
                    </button>
                    {/* <ExportMenu
                      limited
                      onSelectedMenu={menuType => {
                        if (menuType === ExportMenuTypes.CSV) {
                          exportToCSV();
                        }
                      }}
                    /> */}
                  </div>
                </div>
              ) : (
                <div
                  className={classNames(
                    'transition ease-in duration-300 absolute left-5 -top-8 flex h-12 items-center space-x-3 bg-white sm:left-5',
                  )}>
                  <ProcessingIndicator message={'Preparing Export'} />
                </div>
              )}
            </div>
          </div>
          {props.filteredCompany ? (
            <div
              className={classNames(
                '-my-2 overflow-x-auto -ml-16 overflow-visible',
                cardView ? '-mr-16' : '-mr-20',
              )}>
              <div
                className="inline-block min-w-full py-2 align-middle pl-8 pr-4"
                style={{height: 'calc(100vh / var(--zoom) - 290px)'}}>
                {isPaginating ? (
                  <div className="px-5">
                    <SkeletonLoader pagination />
                  </div>
                ) : (
                  <div>
                    <table className="w-full table-fixed divide-y divide-gray-300 sm:px-6 lg:px-8">
                      <thead>
                        <tr className="bg-gray-50">
                          <th
                            scope="col"
                            className="sticky top-0 relative w-12 bg-gray-50 z-50"
                          />
                          <th
                            scope="col"
                            className="sticky top-0  py-3.5 pr-3 text-left text-xs font-normal text-gray-700 relative px-7 bg-gray-50 z-50">
                            {
                              LocaleStrings.search.companysearch.tableHeaders
                                .companyname
                            }
                          </th>
                          <th
                            scope="col"
                            className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
                            {
                              LocaleStrings.search.companysearch.tableHeaders
                                .industry
                            }
                          </th>
                          <th
                            scope="col"
                            className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
                            {
                              LocaleStrings.search.companysearch.tableHeaders
                                .hqlocation
                            }
                          </th>
                          <th
                            scope="col"
                            className="sticky top-0 w-1/12 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
                            {
                              LocaleStrings.search.companysearch.tableHeaders
                                .employeecount
                            }
                          </th>
                          {/* <th
                        scope="col"
                        className="sticky top-0 z-10 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50">
                        {
                          LocaleStrings.search.companysearch.tableHeaders
                            .revenue
                        }
                      </th> */}
                          <th
                            scope="col"
                            className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
                            {
                              LocaleStrings.search.companysearch.tableHeaders
                                .technology
                            }
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-xs font-normal bg-gray-50 text-gray-700 z-50 sticky top-0 ">
                            {LocaleStrings.listpeople.tableHeaders.date}
                          </th>
                          <th className="sticky top-0 z-10 bg-gray-50 z-50" />
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {props.filteredCompany &&
                          props.filteredCompany.map(company => {
                            const filtered = selectedCompanies.filter(
                              item => item.companyid === company.companyid,
                            );
                            const selected = filtered.length > 0;
                            return (
                              <MyListCompanyCell
                                key={company.companyid}
                                company={company}
                                selected={selected}
                                onCheckUnCheck={checked => {
                                  setSelectedCompanies(
                                    checked
                                      ? [...selectedCompanies, company]
                                      : selectedCompanies.filter(
                                          p =>
                                            p.companyid !== company.companyid,
                                        ),
                                  );
                                }}
                                onDeleteFromList={company => {
                                  setShowDeleteAlert(true);
                                  setSelectedCompanyToDelete(company);
                                }}
                                onCopySuccess={() => {
                                  toast.success('Copied successfully');
                                }}
                                onShowTechs={company => {
                                  setSelectedCompanyForTech(company);
                                }}
                              />
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          ) : null}
          {props.filteredCompany && (
            <div className="mt-2">
              <PaginationBar
                curPage={curPage}
                numPages={props.numPages}
                onPageChange={curPage => {
                  if (!firstLoad) {
                    setIsPaginating(true);
                  }
                  setCurPage(curPage);
                }}
              />
            </div>
          )}
        </div>
      ) : isLoading && !isPaginating ? (
        <SkeletonLoader />
      ) : !isPaginating ? (
        <NoResult hasFilters />
      ) : null}

      <AppAlert
        open={showDeleteAlert}
        title={
          selectedCompanyToDelete &&
          `Remove ${selectedCompanyToDelete.companyname} from list`
        }
        message={`Are you sure you want to delete the company from list (${props.activeList.listname})?`}
        primaryButton={{title: 'Delete'}}
        secondaryButton={{title: 'Cancel'}}
        primaryButtonAction={() => {
          setShowDeleteAlert(false);

          props.removeCompanyFromlist(
            props.session,
            {
              listid: props.activeList.listid,
              companyid: selectedCompanyToDelete.companyid,
            },
            _ => {},
          );
          const filtered = props.filteredCompany.filter(
            people => people.companyid !== selectedCompanyToDelete.companyid,
          );
          props.updateListCompany({
            data: filtered,
            totalcount: props.totalcount,
            pages: props.numPages,
          });
        }}
        secondaryButtonAction={() => {
          setShowDeleteAlert(false);
        }}
      />
      {selectedCompanyForTech ? (
        <TechnologyListPopup
          open={true}
          company={selectedCompanyForTech}
          onClose={() => {
            setSelectedCompanyForTech(undefined);
          }}
        />
      ) : null}
      <Toaster />
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, mylistCompanyFilters, appuserData} = state;
  const {totalcount, pages, data} = state.filteredListCompany;
  return {
    session,
    user,
    credits: appuserData.credits,
    savedFilters: mylistCompanyFilters,
    searchSelectedSubMenu: state.searchSelectedSubMenu,
    totalcount,
    numPages: pages,
    filteredCompany: data,
    activeList: state.activeList,
  };
}

export default connect(mapStateToProps, {
  updateListCompany,
  fetchListCompany,
  removeCompanyFromlist,
})(ListCompanyTable);
