export default function IconSavedFilter() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.58398 7.15C4.58398 5.60986 4.58398 4.83978 4.88372 4.25153C5.14737 3.73408 5.56806 3.31338 6.08551 3.04973C6.67377 2.75 7.44384 2.75 8.98398 2.75H13.0173C14.5575 2.75 15.3275 2.75 15.9158 3.04973C16.4332 3.31338 16.8539 3.73408 17.1176 4.25153C17.4173 4.83978 17.4173 5.60986 17.4173 7.15V19.25L11.0007 15.5833L4.58398 19.25V7.15Z"
        stroke="#A3ABBC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
