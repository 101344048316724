import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import ContactsTable from './ContactsTable';
import useWindowDimensions from '@/common/useWindowDimensions';
import {fetchLabels} from '../../mylist/actions';

function RevealedContactsResults(props) {
  const {height, width} = useWindowDimensions();
  const calculatedHeight = height + 160;

  useEffect(() => {
    props.fetchLabels(props.session, {action: 'availablelabels'}, _ => {});
  }, [props.availablelabels]);

  return (
    <div className="overflow-hidden shadow-md shadow-gray-300 mx-6 bg-white content-center border my-2 rounded-xl">
      <ContactsTable calculatedHeight={calculatedHeight} />
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    savedFilters,
    availablelabels: state.availablelabels,
  };
}

export default connect(mapStateToProps, {fetchLabels})(RevealedContactsResults);
