export default function IconCompany() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 11.5H18.3C19.4201 11.5 19.9802 11.5 20.408 11.718C20.7843 11.9097 21.0903 12.2157 21.282 12.592C21.5 13.0198 21.5 13.5799 21.5 14.7V21.5M13.5 21.5V6.7C13.5 5.5799 13.5 5.01984 13.282 4.59202C13.0903 4.21569 12.7843 3.90973 12.408 3.71799C11.9802 3.5 11.4201 3.5 10.3 3.5H6.7C5.5799 3.5 5.01984 3.5 4.59202 3.71799C4.21569 3.90973 3.90973 4.21569 3.71799 4.59202C3.5 5.01984 3.5 5.5799 3.5 6.7V21.5M22.5 21.5H2.5M7 7.5H10M7 11.5H10M7 15.5H10"
        stroke="#8721DE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
