import {
  getRequest,
  postRequest,
  deleteRequest,
  fileUploadRequest,
  patchRequest,
  deleteBulkRequest,
  uploadEncodedFileLarge,
} from '@networkCall';
import _ from 'lodash';
import {
  APP_API_KEY,
  INSTANCE_URL,
  itemCount,
  DEVELOPMENT_TYPE,
  BASE_IMAGES_URL,
} from '@constant';
// All Exports
export var MYACCOUNTDATA = 'MYACCOUNTDATA';
export var SESSION_TOKEN_ACCOUNTSETTINGS = 'SESSION_TOKEN_ACCOUNTSETTINGS';
export var UPDATE_EMAIL_MODAL = 'UPDATE_EMAIL_MODAL';
export var UPDATE_NEW_EMAIL_REQUEST_MODAL = 'UPDATE_NEW_EMAIL_REQUEST_MODAL';
export var UPDATE_EMAIL_CODE = 'UPDATE_EMAIL_CODE';

export const fetchmyaccountData = (
  session,
  session_token,
  appuserid,
  callback,
) => {
  let filter = encodeURI(`(appuserid=${appuserid})`);
  let url = `${INSTANCE_URL}/api/v2/thomsondata/_table/appuser?filter=${filter}&related=client_by_clientid`;
  // console.log("session", session);
  if (session_token != '') {
    session.sessionToken = session_token;
  }
  return dispatch => {
    getRequest(
      url,
      session,
      dispatch,
      res => {
        let obj = {};
        if (res?.resource[0]?.role == 'client') {
          if (res?.resource[0]?.client_by_clientid) {
            let name = '';
            let firstname = '';
            let lastname = '';
            if (res?.resource[0]?.name) {
              name = res?.resource[0]?.name.split(' ');

              if (name.length > 1) {
                lastname = _.last(name);
                let filteredArr = _.dropRight(name);
                firstname = filteredArr.join(' ');
              } else if (name.length == 1) {
                firstname = name[0];
                lastname = '';
              }
            }
            obj.appuserid = res?.resource[0]?.appuserid;
            obj.clientid = res?.resource[0]?.clientid;
            obj.role = res?.resource[0]?.role;
            obj.firstname = firstname;
            obj.lastname = lastname;
            obj.email = res?.resource[0]?.email;
            obj.avatar = res?.resource[0]?.avatar;
            obj.companyname = res?.resource[0]?.client_by_clientid?.companyname;
            obj.phonenumber = res?.resource[0]?.client_by_clientid?.phonenumber;
            obj.country = res?.resource[0]?.client_by_clientid?.country;
            obj.salespeoplecount =
              res?.resource[0]?.client_by_clientid?.salespeoplecount;
            obj.requiredleadcount =
              res?.resource[0]?.client_by_clientid?.requiredleadcount;
            obj.department = res?.resource[0]?.client_by_clientid?.department;
            obj.taxid = res?.resource[0]?.client_by_clientid?.taxid;
            obj.billingaddress =
              res?.resource[0]?.client_by_clientid?.billingaddress;
            obj.afid = res?.resource[0]?.afid;
          } else {
            let name = '';
            let firstname = '';
            let lastname = '';
            if (res?.resource[0]?.name) {
              name = res?.resource[0]?.name.split(' ');
              firstname = name[0];
              lastname = name[1] ? name[1] : '';
            }
            obj.appuserid = res?.resource[0]?.appuserid;
            obj.clientid = res?.resource[0]?.clientid;
            obj.role = res?.resource[0]?.role;
            obj.firstname = firstname;
            obj.lastname = lastname;
            obj.email = res?.resource[0]?.email;
            obj.avatar = res?.resource[0]?.avatar;
            obj.afid = res?.resource[0]?.afid;
          }
        } else {
          let name = '';
          let firstname = '';
          let lastname = '';
          if (res?.resource[0]?.name) {
            name = res?.resource[0]?.name.split(' ');
            firstname = name[0];
            lastname = name[1] ? name[1] : '';
          }
          obj.appuserid = res?.resource[0]?.appuserid;
          obj.clientid = res?.resource[0]?.clientid;
          obj.role = res?.resource[0]?.role;
          obj.firstname = firstname;
          obj.lastname = lastname;
          obj.email = res?.resource[0]?.email;
          obj.avatar = res?.resource[0]?.avatar;
          obj.afid = res?.resource[0]?.afid;
        }
        dispatch({
          type: MYACCOUNTDATA,
          payload: {
            data: obj,
          },
        });
        callback({
          success: 1,
          data: res?.resource[0],
        });
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export function uploadImage(session, uploadFiles, callback) {
  var filecontent = uploadFiles.media;
  var filename = uploadFiles.name;
  var filetype = uploadFiles.type;

  const dataMedia = new FormData();
  if (filetype == 'video') {
    var blob = new Blob([filecontent], {type: 'video/mp4'});
  } else if (filetype == 'audio') {
    var blob = new Blob([filecontent], {type: 'audio/mp3'});
  } else {
    var blob = new Blob([filecontent], {type: 'image/jpeg'});
  }
  // console.log('blobimage',blob)
  dataMedia.append('files', blob, filename);
  // console.log('dataMedia',dataMedia)
  return dispatch => {
    uploadEncodedFileLarge(
      `${BASE_IMAGES_URL}/userimage/`,
      session,
      dataMedia,
      dispatch,
      data => {
        // console.log('done?',data)
        callback({success: 1, message: data});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
}

export function updateAppuser(session, obj, callback) {
  let url = `${INSTANCE_URL}/api/v2/thomsondata/_table/appuser`;
  // console.log('newAddArrdata',newAddArrdata)
  let body = {resource: obj};
  // let body = {resource: [obj] };

  return dispatch => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      response => {
        // console.log('res',response)
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        callback({success: 0, message: error});
      },
    );
  };
}

export function updateuserEmail(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/emailupdate`;
  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      response => {
        // console.log("res", response);
        // callback({ success: 0, result: response });
        if (response.success == false) {
          callback({success: 2, result: response});
        } else {
          callback({success: 1, result: response});
          dispatch({
            type: SESSION_TOKEN_ACCOUNTSETTINGS,
            payload: {session_token: response?.session_token},
          });
        }
      },
      error => {
        // console.log(error)
        callback({success: 0, message: error});
      },
    );
  };
}

export function updateEmailModal(isOpen) {
  return {type: UPDATE_EMAIL_MODAL, payload: isOpen};
}

export function updateNewEmailRequestModal(isOpen) {
  return {type: UPDATE_NEW_EMAIL_REQUEST_MODAL, payload: isOpen};
}

export function updateEmailcode(code) {
  return {type: UPDATE_EMAIL_CODE, payload: code};
}

export function changeEmailwithcode(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/emailcodeverification`;
  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      response => {
        // callback({ success: 0, result: response });
        if (response.success == false) {
          callback({success: 2, result: response});
        } else {
          callback({success: 1, result: response});
        }
      },
      error => {
        // console.log(error)
        callback({success: 0, message: error});
      },
    );
  };
}

export const verifyemailCode = (session, values, callback) => {
  var filter = encodeURI(
    `filter=(email=${values.email})AND(verifytoken=${values.code})`,
  );
  let url = `${INSTANCE_URL}/api/v2/thomsondata/_table/appuser?${filter}`;
  return dispatch => {
    getRequest(
      url,
      session,
      dispatch,
      res => {
        callback({
          success: 1,
          data: res,
        });
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};
