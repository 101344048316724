import {useEffect, useState} from 'react';

import LocaleStrings from '@language';
import IconContact from './icons/iconcontact';
import IconHQ from './icons/iconhq';
import TaggedSearch from '@commonFolder/TaggedSearch';
import {connect} from 'react-redux';
import {fieldSearch} from '../../../../actions';
import debounce from 'lodash/debounce';

const tabs = [
  {
    name: 'Contact',
    type: 'contact',
  },
  {
    name: 'Company HQ',
    type: 'hq',
  },
];

function PeopleLocation(props) {
  const [resultContacts, setResultContacts] = useState([]);
  const [resultHQs, setResultHQs] = useState([]);

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const handleContactSearch = val => {
    if (val !== '' && val.length > 1) {
      props.fieldSearch(
        props.session,
        {action: 'search', searchfield: 'hqlocation', searchtext: val},
        res => {
          setResultContacts(res.data);
        },
      );
    }
  };

  const handleHQSearch = val => {
    if (val !== '' && val.length > 1) {
      props.fieldSearch(
        props.session,
        {action: 'search', searchfield: 'hqlocation', searchtext: val},
        res => {
          setResultHQs(res.data);
        },
      );
    }
  };

  const debouncedContactSearch = debounce(handleContactSearch, 100);
  const debouncedHQSearch = debounce(handleHQSearch, 100);

  useEffect(() => {}, [selectedTab]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const icon = ({type, selected}) => {
    if (type === 'contact') {
      return <IconContact stroke={selected ? '#5E2CED' : '#6B7280'} />;
    } else if (type === 'hq') {
      return <IconHQ stroke={selected ? '#5E2CED' : '#6B7280'} />;
    }
  };

  return (
    <div>
      <div className="pb-2">
        <div className="sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map(tab => (
                <div
                  key={tab.name}
                  className={classNames(
                    tab.type === selectedTab.type
                      ? 'border-primary text-primary'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'group inline-flex items-center border-b-2 py-2 px-1 text-sm font-medium',
                  )}
                  aria-current={
                    tab.type === selectedTab.type ? 'page' : undefined
                  }
                  onClick={() => {
                    setSelectedTab(tab);
                  }}>
                  <div className="mr-1">
                    {icon({
                      type: tab.type,
                      selected: selectedTab.type === tab.type,
                    })}
                  </div>

                  <span className="text-[11px]">{tab.name}</span>
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div>
        {selectedTab.type === 'contact' ? (
          <TaggedSearch
            placeholder={
              LocaleStrings.search.companysearch.location_contact_placeholder
            }
            id={selectedTab.type}
            name={selectedTab.type}
            takesAnyValue={false}
            onSearch={val => {
              debouncedContactSearch(val);
            }}
            onToggled={toggled => {
              if (!toggled) {
                setResultContacts([]);
              }
            }}
            results={
              resultContacts.length > 0 ? resultContacts : props.countries
            }
            showOptions
            subtype={selectedTab.type}
            {...props}
          />
        ) : (
          <TaggedSearch
            placeholder={
              LocaleStrings.search.companysearch.location_hq_placeholder
            }
            id={selectedTab.type}
            name={selectedTab.type}
            takesAnyValue={false}
            onSearch={val => {
              debouncedHQSearch(val);
            }}
            onToggled={toggled => {
              if (!toggled) {
                setResultHQs([]);
              }
            }}
            results={resultHQs.length > 0 ? resultHQs : props.countries}
            showOptions
            subtype={selectedTab.type}
            {...props}
          />
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    savedFilters,
    countries: state.preloadedPeopleFilterSupportData.countries,
  };
}

export default connect(mapStateToProps, {fieldSearch})(PeopleLocation);
