import React, {Component} from 'react';
import {connect} from 'react-redux';
import LocaleStrings from '@language';
import {Field, reduxForm} from 'redux-form';
import BaseLoader from '@baseLoader';
import {BaseComponent, LABEL_POSITION_TOP} from '@baseComponent';
import toast, {Toaster} from 'react-hot-toast';
// Functions Import
import {classNames} from '@commonFunction';
import {fetchinvitedappuser, updatinvitedappuser} from '@sidebarActions';
import IconVerifyEmail from '../verifyemail/icons/verifyemail';
import BtnLoaderSvg from '@btnLoaderSvg';
class AppuserInviteAccept extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      headerText: '',
      btnLoader: false,
      isBtnDisable: false,
      params: {},
      loading: true,
    };
  }

  componentDidMount() {
    let {session} = this.props;
    var url_string = this.props.router.location.search;
    var query = url_string.split('?')[1];

    var params = {};

    if (query) {
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
      if (params && params.email && params.token) {
        this.props.fetchinvitedappuser(session, params, callback => {
          if (callback && callback.success == 1) {
            this.setState({loading: false});
          }
        });
      }
      // console.log("params", params);
      this.setState({params: params});
    }
  }

  /* FORM SUBMIT */
  onFormSubmit(values) {
    let {session, user, invitedappuserData} = this.props;
    let obj = {};
    obj.clientid = invitedappuserData.data[0].clientid;
    obj.appuserid = invitedappuserData.data[0].appuserid;
    obj.isverified = 1;
    obj.isactive = 1;
    obj.invitestatus = 'accepted';
    this.setState({isBtnDisable: true});
    this.setState({btnLoader: true});
    // this.props.history.push('/dashboard/main/dashboardmain');
    this.props.updatinvitedappuser(session, obj, response => {
      if (response.success === 1) {
        toast.success(LocaleStrings.acceptinvite_success);
        this.setState({isBtnDisable: false});
        this.setState({btnLoader: false});
        this.props.router.navigate('/');
        this.props.reset();
      } else {
        // If Un successfull Registration
        toast.error(LocaleStrings.something_went_wrong);
        this.setState({isBtnDisable: false});
        this.setState({btnLoader: false});
        this.props.reset();
        return false;
      }
    });
  }

  render() {
    let {session, user, handleSubmit, invitedappuserData} = this.props;
    let {params} = this.state;
    let isverified = 0;
    let isactive = 0;
    if (
      invitedappuserData &&
      invitedappuserData.data &&
      invitedappuserData.data.length > 0
    ) {
      isverified = invitedappuserData.data[0].isverified;
      isactive = invitedappuserData.data[0].isactive;
    }
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            {invitedappuserData &&
            invitedappuserData.data &&
            invitedappuserData.data.length > 0 ? (
              <>
                {(isactive == 1 || isactive == '1') &&
                isverified == 1 &&
                isverified == '1' ? (
                  <div className="center-item lg:my-48 text-lg text-ternary">
                    {LocaleStrings.acceptinvite_alreadyactiveuser}
                  </div>
                ) : (
                  <form
                    className="space-y-2"
                    onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                      <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <div className="center-item">
                          <div className="w-10 h-10 p-2.5 pt-3 pl-3 bg-secondary rounded-full justify-center items-center">
                            <div className="justify-center items-center flex">
                              <IconVerifyEmail />
                            </div>
                          </div>
                        </div>
                        <div className="center-item text-3xl font-medium pt-4">
                          {LocaleStrings.acceptinvite_thomson}
                        </div>
                        <div
                          className="center-item my-4 font-normal text-base text-ternary px-24"
                          style={{textAlign: 'center'}}>
                          {LocaleStrings.acceptinvite_descriptiontext1}
                        </div>
                        <div className="center-item">
                          <div className="w-9/12">
                            <button
                              type="submit"
                              disabled={this.state.isBtnDisable}
                              className={classNames(
                                this.state.isBtnDisable
                                  ? 'cursor-not-allowed'
                                  : '',
                                'btn-primary',
                              )}>
                              <BtnLoaderSvg loader={this.state.btnLoader} />
                              {LocaleStrings.acceptinvite_btn}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </>
            ) : (
              <div className="center-item lg:my-48 text-lg text-ternary">
                {LocaleStrings.acceptinvite_appusernotfound}
              </div>
            )}
          </>
        )}
        <Toaster />
      </>
    );
  }
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  invitedappuserData: state.invitedappuserData,
});

export default reduxForm({
  form: 'AppuserInviteAcceptForm',
})(
  connect(mapStateToProps, {
    fetchinvitedappuser,
    updatinvitedappuser,
  })(AppuserInviteAccept),
);
