import TagManager from 'react-gtm-module';
import {ENV} from './constants';

export function logGTMEvent(data) {
  if (ENV === 'prod') {
    TagManager.dataLayer({
      dataLayer: data,
    });
  }
}
