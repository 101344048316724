import {useState} from 'react';
import LocaleStrings from '@language';
import TaggedSearch from '@commonFolder/TaggedSearch';
import {connect} from 'react-redux';
import debounce from 'lodash/debounce';

function Department(props) {
  const [results, setResults] = useState([]);

  const handleSearch = val => {
    if (val !== '' && val.length > 1) {
      const filtered = props.departments.filter(
        item => item.title.toLowerCase().indexOf(val.toLowerCase()) > -1,
      );
      setResults(filtered);
    }
  };

  const debouncedSearch = debounce(handleSearch, 100);

  return (
    <div>
      <TaggedSearch
        placeholder={LocaleStrings.search.peoplesearch.department_placeholder}
        id="department"
        name="department"
        takesAnyValue={false}
        results={results.length > 0 ? results : props.departments}
        onSearch={val => {
          debouncedSearch(val);
        }}
        onToggled={toggled => {
          if (!toggled) {
            setResults([]);
          }
        }}
        showOptions
        {...props}
      />
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    savedFilters,
    departments: state.preloadedPeopleFilterSupportData.departments,
  };
}

export default connect(mapStateToProps, null)(Department);
