import {useEffect, useState} from 'react';
import {createPortal} from 'react-dom';

const Portal = ({children}) => {
  const [active, setActive] = useState(true);
  const mount = document.getElementById('portal-root');
  const el = document.createElement('div');
  el.id = '#portal';

  useEffect(() => {
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el, mount]);

  return active ? createPortal(children, el) : null;
};

export default Portal;
