export default function IconLock(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="stroke-primary stroke-2">
      <path
        d="M14.1673 8.33333V6.66667C14.1673 4.36548 12.3018 2.5 10.0007 2.5C7.69946 2.5 5.83398 4.36548 5.83398 6.66667V8.33333M10.0007 12.0833V13.75M7.33398 17.5H12.6673C14.0674 17.5 14.7675 17.5 15.3023 17.2275C15.7727 16.9878 16.1552 16.6054 16.3948 16.135C16.6673 15.6002 16.6673 14.9001 16.6673 13.5V12.3333C16.6673 10.9332 16.6673 10.2331 16.3948 9.69836C16.1552 9.22795 15.7727 8.8455 15.3023 8.60582C14.7675 8.33333 14.0674 8.33333 12.6673 8.33333H7.33398C5.93385 8.33333 5.23379 8.33333 4.69901 8.60582C4.2286 8.8455 3.84615 9.22795 3.60647 9.69836C3.33398 10.2331 3.33398 10.9332 3.33398 12.3333V13.5C3.33398 14.9001 3.33398 15.6002 3.60647 16.135C3.84615 16.6054 4.2286 16.9878 4.69901 17.2275C5.23379 17.5 5.93385 17.5 7.33398 17.5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
