import React, {Component} from 'react';
import {connect} from 'react-redux';
import LocaleStrings from '@language';
import {Field, reduxForm} from 'redux-form';
import toast, {Toaster} from 'react-hot-toast';
import renderHTML from 'react-render-html';
import _ from 'lodash';
import {BaseComponent, LABEL_POSITION_TOP} from '@baseComponent';
import {classNames} from '@commonFunction';
import {changePassword} from '@settingsPasswordAction';
import {validatepassword} from '@constant';
import BtnLoaderSvg from '@btnLoaderSvg';
class Password extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_currency,
      isBtnDisable: false,
      btnLoader: false,
      show: false,
      show1: false,
      show2: false,
    };

    // All Binded Functions
    this.changeShow = this.changeShow.bind(this);
    this.changeShow1 = this.changeShow1.bind(this);
    this.changeShow2 = this.changeShow2.bind(this);
  }

  changeShow() {
    let {show} = this.state;
    this.setState({show: !show});
  }

  changeShow1() {
    let {show1} = this.state;
    this.setState({show1: !show1});
  }
  changeShow2() {
    let {show2} = this.state;
    this.setState({show2: !show2});
  }

  onSubmitForm(values) {
    let {user} = this.props;

    if (values.new_password.length < 8) {
      toast.error(LocaleStrings.settings_password_error_above8letter);
      this.setState({btnLoader: false});
      this.setState({isBtnDisable: false});
      return false;
    }

    if (values.new_password === values.confirm_password) {
      this.setState({btnLoader: true});
      this.setState({isBtnDisable: true});
      let val = {};
      // val.emailtype = 'updateemail';
      val.email = user?.email;
      val.old_password = values.old_password;
      val.new_password = values.new_password;
      // console.log('val',val);
      this.props.changePassword(this.props.session, values, response => {
        if (response.error === 1) {
          toast.error(response.data);
          this.setState({btnLoader: false});
          this.setState({isBtnDisable: false});
        } else {
          toast.success(response.data);
          this.setState({btnLoader: false});
          this.setState({isBtnDisable: false});
        }
      });
    } else {
      this.setState({btnLoader: false});
      this.setState({isBtnDisable: false});
      toast.error(LocaleStrings.settings_password_error_didntmatch);
    }
  }

  render() {
    let {handleSubmit} = this.props;

    return (
      <>
        <form
          onSubmit={handleSubmit(this.onSubmitForm.bind(this))}
          className="xs:pl-4 md:pl-0">
          <div className="block md:flex pb-10 border-b border-gray-200">
            <div>
              <div className="text-mainGray text-base font-medium">
                {LocaleStrings.settings_password}
              </div>
              <div className="text-gray500 text-xs font-normal">
                {LocaleStrings.settings_passwordsubtitle}
              </div>
            </div>
            <div className="ml-auto xs:mt-4 md:mt-0">
              <div className="flex">
                <button
                  type="submit"
                  disabled={this.state.isBtnDisable}
                  className={classNames(
                    this.state.isBtnDisable ? 'cursor-not-allowed' : '',
                    'btn-primary ml-4 cursor-pointer',
                  )}>
                  <BtnLoaderSvg loader={this.state.btnLoader} />
                  {LocaleStrings.settings_myaacounts_btnupdate}
                </button>
              </div>
            </div>
          </div>
          <div className="block lg:flex py-6 ">
            <div className="lg:w-3/12">
              <div className="text-gray700 text-xs font-medium">
                {LocaleStrings.settings_passwordchange}
              </div>
              <div className="text-gray500 text-xs font-normal">
                {LocaleStrings.settings_passwordchange_subtitle}
              </div>
            </div>
            <div className="lg:w-8/12 lg:px-24">
              <div>
                <Field
                  name="old_password"
                  label={LocaleStrings.settings_password_lablecurrentpw}
                  placeholder={LocaleStrings.settings_password_lablecurrentpw}
                  type="password"
                  show={this.state.show}
                  setShow={this.changeShow}
                  component={this.renderFieldText}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-xs font-normal text-secondary"
                />
              </div>
              <div className="mt-4">
                <Field
                  name="new_password"
                  label={LocaleStrings.settings_password_lablenewpw}
                  placeholder={LocaleStrings.settings_password_lablenewpw}
                  type="password"
                  show={this.state.show1}
                  setShow={this.changeShow1}
                  component={this.renderFieldText}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-xs font-normal text-secondary"
                />
                {/* <div className="text-gray500 text-sm text-normal">{LocaleStrings.settings_password_lableminortext}</div> */}
              </div>
              <div className="mt-4">
                <Field
                  name="confirm_password"
                  label={LocaleStrings.settings_password_lableconfirmnewpw}
                  placeholder={
                    LocaleStrings.settings_password_lableconfirmnewpw
                  }
                  type="password"
                  show={this.state.show2}
                  setShow={this.changeShow2}
                  component={this.renderFieldText}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-xs font-normal text-secondary"
                />
              </div>
            </div>
          </div>
        </form>
        <Toaster />
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  var requiredFields = [];
  var new_password = values['new_password'];

  requiredFields = ['old_password', 'new_password', 'confirm_password'];

  requiredFields.forEach(field => {
    if (!values[field] || _.trim(values[field]) === '') {
      errors[field] = LocaleStrings.required;
    }
  });

  let passwordErrorMessage =
    '<div style="width: 350px; display: table;margin-top:10px;"><div style="display: table-row"><div style=" display: table-cell;">&#8226; One lowercase character</div><div style="display: table-cell;">&#8226; Atleast one number</div></div></div><div style="width: 350px; display: table;margin-top:10px"><div style="display: table-row"><div style=" display: table-cell;">&#8226; One uppercase character</div><div style="display: table-cell;"> &#8226; 8 characters minimum</div></div></div>';
  if (new_password && !validatepassword(new_password)) {
    errors['new_password'] = renderHTML(passwordErrorMessage);
  }
  return errors;
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
});

export default reduxForm({
  validate,
  form: 'PasswordForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(mapStateToProps, {
    changePassword,
  })(Password),
);
