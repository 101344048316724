import {
  MYACCOUNTDATA,
  UPDATE_EMAIL_MODAL,
  UPDATE_EMAIL_CODE,
} from '@settingsMyaccountActions';
import {UPDATE_NEW_EMAIL_REQUEST_MODAL} from '../actions';

export var myaccountdataList = (state = {}, action) => {
  switch (action.type) {
    case MYACCOUNTDATA:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var iseditemailmodalOpen = (state = false, action) => {
  if (action.type === UPDATE_EMAIL_MODAL) {
    state = action.payload;
  }

  return state;
};
export var iseditemailcode = (state = {}, action) => {
  if (action.type === UPDATE_EMAIL_CODE) {
    state = action.payload;
  }

  return state;
};

export var newEmailRequestModalStatus = (state = false, action) => {
  if (action.type === UPDATE_NEW_EMAIL_REQUEST_MODAL) {
    state = action.payload;
  }

  return state;
};

export var countryList = (state = [], action) => {
  //iconName is optional
  var state = [
    {
      value: 'AFG',
      name: 'Afghanistan',
      displayfields: '',
    },
    {
      value: 'ALA',
      name: 'Aland Islands',
      displayfields: '',
    },
    {
      value: 'ALB',
      name: 'Albania',
      displayfields: '',
    },
    {
      value: 'DZA',
      name: 'Algeria',
      displayfields: '',
    },
    {
      value: 'ASM',
      name: 'American Samoa',
      displayfields: '',
    },
    {
      value: 'AND',
      name: 'Andorra',
      displayfields: '',
    },
    {
      value: 'AGO',
      name: 'Angola',
      displayfields: '',
    },
    {
      value: 'AIA',
      name: 'Anguilla',
      displayfields: '',
    },
    {
      value: 'ATA',
      name: 'Antarctica',
      displayfields: '',
    },
    {
      value: 'ATG',
      name: 'Antigua and Barbuda',
      displayfields: '',
    },
    {
      value: 'ARG',
      name: 'Argentina',
      displayfields: '',
    },
    {
      value: 'ARM',
      name: 'Armenia',
      displayfields: '',
    },
    {
      value: 'ABW',
      name: 'Aruba',
      displayfields: '',
    },
    {
      value: 'AUS',
      name: 'Australia',
      displayfields: '',
    },
    {
      value: 'AUT',
      name: 'Austria',
      displayfields: '',
    },
    {
      value: 'AZE',
      name: 'Azerbaijan',
      displayfields: '',
    },
    {
      value: 'BHS',
      name: 'Bahamas',
      displayfields: '',
    },
    {
      value: 'BHR',
      name: 'Bahrain',
      displayfields: '',
    },
    {
      value: 'BGD',
      name: 'Bangladesh',
      displayfields: '',
    },
    {
      value: 'BRB',
      name: 'Barbados',
      displayfields: '',
    },
    {
      value: 'BLR',
      name: 'Belarus',
      displayfields: '',
    },
    {
      value: 'BEL',
      name: 'Belgium',
      displayfields: '',
    },
    {
      value: 'BLZ',
      name: 'Belize',
      displayfields: '',
    },
    {
      value: 'BEN',
      name: 'Benin',
      displayfields: '',
    },
    {
      value: 'BMU',
      name: 'Bermuda',
      displayfields: '',
    },
    {
      value: 'BTN',
      name: 'Bhutan',
      displayfields: '',
    },
    {
      value: 'BOL',
      name: 'Bolivia',
      displayfields: '',
    },
    {
      value: 'BIH',
      name: 'Bosnia and Herzegovina',
      displayfields: '',
    },
    {
      value: 'BWA',
      name: 'Botswana',
      displayfields: '',
    },
    {
      value: 'BVT',
      name: 'Bouvet Island',
      displayfields: '',
    },
    {
      value: 'BRA',
      name: 'Brazil',
      displayfields: '',
    },
    {
      value: 'IOT',
      name: 'British Indian Ocean Territory',
      displayfields: '',
    },
    {
      value: 'BRN',
      name: 'Brunei Darussalam',
      displayfields: '',
    },
    {
      value: 'BGR',
      name: 'Bulgaria',
      displayfields: '',
    },
    {
      value: 'BFA',
      name: 'Burkina Faso',
      displayfields: '',
    },
    {
      value: 'BDI',
      name: 'Burundi',
      displayfields: '',
    },
    {
      value: 'KHM',
      name: 'Cambodia',
      displayfields: '',
    },
    {
      value: 'CMR',
      name: 'Cameroon',
      displayfields: '',
    },
    {
      value: 'CAN',
      name: 'Canada',
      displayfields: '',
    },
    {
      value: 'CPV',
      name: 'Cape Verde',
      displayfields: '',
    },
    {
      value: 'CYM',
      name: 'Cayman Islands',
      displayfields: '',
    },
    {
      value: 'CAF',
      name: 'Central African Republic',
      displayfields: '',
    },
    {
      value: 'TCD',
      name: 'Chad',
      displayfields: '',
    },
    {
      value: 'CHL',
      name: 'Burundi',
      displayfields: '',
    },
    {
      value: 'CHN',
      name: 'China',
      displayfields: '',
    },
    {
      value: 'CXR',
      name: 'Christmas Island',
      displayfields: '',
    },
    {
      value: 'CCK',
      name: 'Cocos (Keeling) Islands',
      displayfields: '',
    },
    {
      value: 'COL',
      name: 'Colombia',
      displayfields: '',
    },
    {
      value: 'COM',
      name: 'Comoros',
      displayfields: '',
    },
    {
      value: 'COG',
      name: 'Congo',
      displayfields: '',
    },
    {
      value: 'COD',
      name: 'Congo, The Democratic Republic of The',
      displayfields: '',
    },
    {
      value: 'COK',
      name: 'Cook Islands',
      displayfields: '',
    },
    {
      value: 'CRI',
      name: 'Costa Rica',
      displayfields: '',
    },
    {
      value: 'CIV',
      name: 'Cote Divoire',
      displayfields: '',
    },
    {
      value: 'HRV',
      name: 'Croatia',
      displayfields: '',
    },
    {
      value: 'CUB',
      name: 'Cuba',
      displayfields: '',
    },
    {
      value: 'CYP',
      name: 'Cyprus',
      displayfields: '',
    },
    {
      value: 'CZE',
      name: 'Czech Republic',
      displayfields: '',
    },
    {
      value: 'DNK',
      name: 'Denmark',
      displayfields: '',
    },
    {
      value: 'DJI',
      name: 'Djibouti',
      displayfields: '',
    },
    {
      value: 'DMA',
      name: 'Dominica',
      displayfields: '',
    },
    {
      value: 'DOM',
      name: 'Dominican Republic',
      displayfields: '',
    },
    {
      value: 'ECU',
      name: 'Ecuador',
      displayfields: '',
    },
    {
      value: 'EGY',
      name: 'Egypt',
      displayfields: '',
    },
    {
      value: 'SLV',
      name: 'El Salvador',
      displayfields: '',
    },
    {
      value: 'GNQ',
      name: 'Equatorial Guinea',
      displayfields: '',
    },
    {
      value: 'ERI',
      name: 'Eritrea',
      displayfields: '',
    },
    {
      value: 'EST',
      name: 'Estonia',
      displayfields: '',
    },
    {
      value: 'ETH',
      name: 'Ethiopia',
      displayfields: '',
    },
    {
      value: 'FLK',
      name: 'Falkland Islands (Malvinas)',
      displayfields: '',
    },
    {
      value: 'FRO',
      name: 'Faroe Islands',
      displayfields: '',
    },
    {
      value: 'FJI',
      name: 'Fiji',
      displayfields: '',
    },
    {
      value: 'FIN',
      name: 'Finland',
      displayfields: '',
    },
    {
      value: 'FRA',
      name: 'France',
      displayfields: '',
    },
    {
      value: 'GUF',
      name: 'French Guiana',
      displayfields: '',
    },
    {
      value: 'PYF',
      name: 'French Polynesia',
      displayfields: '',
    },
    {
      value: 'ATF',
      name: 'French Southern Territories',
      displayfields: '',
    },
    {
      value: 'GAB',
      name: 'Gabon',
      displayfields: '',
    },
    {
      value: 'GMB',
      name: 'Gambia',
      displayfields: '',
    },
    {
      value: 'GEO',
      name: 'Georgia',
      displayfields: '',
    },
    {
      value: 'DEU',
      name: 'Germany',
      displayfields: '',
    },
    {
      value: 'GHA',
      name: 'Ghana',
      displayfields: '',
    },
    {
      value: 'GIB',
      name: 'Gibraltar',
      displayfields: '',
    },
    {
      value: 'GRC',
      name: 'Greece',
      displayfields: '',
    },
    {
      value: 'GRL',
      name: 'Greenland',
      displayfields: '',
    },
    {
      value: 'GRD',
      name: 'Grenada',
      displayfields: '',
    },
    {
      value: 'GLP',
      name: 'Guadeloupe',
      displayfields: '',
    },
    {
      value: 'GUM',
      name: 'Guam',
      displayfields: '',
    },
    {
      value: 'GTM',
      name: 'Guatemala',
      displayfields: '',
    },
    {
      value: 'GGY',
      name: 'Guernsey',
      displayfields: '',
    },
    {
      value: 'GIN',
      name: 'Guinea',
      displayfields: '',
    },
    {
      value: 'GNB',
      name: 'Guinea-bissau',
      displayfields: '',
    },
    {
      value: 'GUY',
      name: 'Guyana',
      displayfields: '',
    },
    {
      value: 'HTI',
      name: 'Haiti',
      displayfields: '',
    },
    {
      value: 'HMD',
      name: 'Heard Island and Mcdonald Islands',
      displayfields: '',
    },
    {
      value: 'VAT',
      name: 'Holy See (Vatican City State)',
      displayfields: '',
    },
    {
      value: 'HND',
      name: 'Honduras',
      displayfields: '',
    },
    {
      value: 'HUN',
      name: 'Hong Kong',
      displayfields: '',
    },
    {
      value: 'Hungary',
      name: 'Hungary',
      displayfields: '',
    },
    {
      value: 'ISL',
      name: 'Iceland',
      displayfields: '',
    },
    {
      value: 'IND',
      name: 'India',
      displayfields: '',
    },
    {
      value: 'IDN',
      name: 'Indonesia',
      displayfields: '',
    },
    {
      value: 'IRN',
      name: 'Iran, Islamic Republic of',
      displayfields: '',
    },
    {
      value: 'IRQ',
      name: 'Iraq',
      displayfields: '',
    },
    {
      value: 'IRL',
      name: 'Ireland',
      displayfields: '',
    },
    {
      value: 'IMN',
      name: 'Isle of Man',
      displayfields: '',
    },
    {
      value: 'ISR',
      name: 'Israel',
      displayfields: '',
    },
    {
      value: 'ITA',
      name: 'Italy',
      displayfields: '',
    },
    {
      value: 'JAM',
      name: 'Jamaica',
      displayfields: '',
    },
    {
      value: 'JPN',
      name: 'Japan',
      displayfields: '',
    },
    {
      value: 'JEY',
      name: 'Jersey',
      displayfields: '',
    },
    {
      value: 'JOR',
      name: 'Jordan',
      displayfields: '',
    },
    {
      value: 'KAZ',
      name: 'Kazakhstan',
      displayfields: '',
    },
    {
      value: 'KEN',
      name: 'Kenya',
      displayfields: '',
    },
    {
      value: 'KIR',
      name: 'Kiribati',
      displayfields: '',
    },
    {
      value: 'KWT',
      name: 'Kuwait',
      displayfields: '',
    },
    {
      value: 'KGZ',
      name: 'Kyrgyzstan',
      displayfields: '',
    },
    {
      value: 'LAO',
      name: 'Laos',
      displayfields: '',
    },
    {
      value: 'LVA',
      name: 'Latvia',
      displayfields: '',
    },
    {
      value: 'LBN',
      name: 'Lebanon',
      displayfields: '',
    },
    {
      value: 'LSO',
      name: 'Lesotho',
      displayfields: '',
    },
    {
      value: 'LBR',
      name: 'Liberia',
      displayfields: '',
    },
    {
      value: 'LBY',
      name: 'Libyan Arab Jamahiriya',
      displayfields: '',
    },
    {
      value: 'LIE',
      name: 'Liechtenstein',
      displayfields: '',
    },
    {
      value: 'LTU',
      name: 'Lithuania',
      displayfields: '',
    },
    {
      value: 'LUX',
      name: 'Luxembourg',
      displayfields: '',
    },
    {
      value: 'MCO',
      name: 'Macao',
      displayfields: '',
    },
    {
      value: 'MKD',
      name: 'Macedonia, The Former Yugoslav Republic of',
      displayfields: '',
    },
    {
      value: 'MDG',
      name: 'Madagascar',
      displayfields: '',
    },
    {
      value: 'MWI',
      name: 'Malawi',
      displayfields: '',
    },
    {
      value: 'MYS',
      name: 'Malaysia',
      displayfields: '',
    },
    {
      value: 'MDV',
      name: 'Maldives',
      displayfields: '',
    },
    {
      value: 'MLI',
      name: 'Mali',
      displayfields: '',
    },
    {
      value: 'MLT',
      name: 'Malta',
      displayfields: '',
    },
    {
      value: 'MHL',
      name: 'Marshall Islands',
      displayfields: '',
    },
    {
      value: 'MTQ',
      name: 'Martinique',
      displayfields: '',
    },
    {
      value: 'MRT',
      name: 'Mauritania',
      displayfields: '',
    },
    {
      value: 'MUS',
      name: 'Mauritius',
      displayfields: '',
    },
    {
      value: 'MYT',
      name: 'Mayotte',
      displayfields: '',
    },
    {
      value: 'MEX',
      name: 'Mexico',
      displayfields: '',
    },
    {
      value: 'FSM',
      name: 'Micronesia, Federated States of',
      displayfields: '',
    },
    {
      value: 'MDA',
      name: 'Moldova, Republic of',
      displayfields: '',
    },
    {
      value: 'MCO',
      name: 'Monaco',
      displayfields: '',
    },
    {
      value: 'MNG',
      name: 'Mongolia',
      displayfields: '',
    },
    {
      value: 'MNE',
      name: 'Montenegro',
      displayfields: '',
    },
    {
      value: 'MSR',
      name: 'Montserrat',
      displayfields: '',
    },
    {
      value: 'MAR',
      name: 'Morocco',
      displayfields: '',
    },
    {
      value: 'MOZ',
      name: 'Mozambique',
      displayfields: '',
    },
    {
      value: 'MMR',
      name: 'Myanmar',
      displayfields: '',
    },
    {
      value: 'NAM',
      name: 'Namibia',
      displayfields: '',
    },
    {
      value: 'NRU',
      name: 'Nauru',
      displayfields: '',
    },
    {
      value: 'NPL',
      name: 'Nepal',
      displayfields: '',
    },
    {
      value: 'NLD',
      name: 'Netherlands',
      displayfields: '',
    },
    {
      value: 'ANT',
      name: 'Netherlands Antilles',
      displayfields: '',
    },
    {
      value: 'NCL',
      name: 'New Caledonia',
      displayfields: '',
    },
    {
      value: 'NZL',
      name: 'New Zealand',
      displayfields: '',
    },
    {
      value: 'NIC',
      name: 'Nicaragua',
      displayfields: '',
    },
    {
      value: 'NER',
      name: 'Niger',
      displayfields: '',
    },
    {
      value: 'NGA',
      name: 'Nigeria',
      displayfields: '',
    },
    {
      value: 'NIU',
      name: 'Niue',
      displayfields: '',
    },
    {
      value: 'NFK',
      name: 'Norfolk Island',
      displayfields: '',
    },
    {
      value: 'PRK',
      name: 'North Korea',
      displayfields: '',
    },
    {
      value: 'MNP',
      name: 'Northern Mariana Islands',
      displayfields: '',
    },
    {
      value: 'NOR',
      name: 'Norway',
      displayfields: '',
    },
    {
      value: 'OMN',
      name: 'Oman',
      displayfields: '',
    },
    {
      value: 'PAK',
      name: 'Pakistan',
      displayfields: '',
    },
    {
      value: 'PLW',
      name: 'Palau',
      displayfields: '',
    },
    {
      value: 'PSE',
      name: 'Palestinian Territory, Occupied',
      displayfields: '',
    },
    {
      value: 'PAN',
      name: 'Panama',
      displayfields: '',
    },
    {
      value: 'PNG',
      name: 'Papua New Guinea',
      displayfields: '',
    },
    {
      value: 'PRY',
      name: 'Paraguay',
      displayfields: '',
    },
    {
      value: 'PER',
      name: 'Peru',
      displayfields: '',
    },
    {
      value: 'PHL',
      name: 'Philippines',
      displayfields: '',
    },
    {
      value: 'PCN',
      name: 'Pitcairn',
      displayfields: '',
    },
    {
      value: 'POL',
      name: 'Poland',
      displayfields: '',
    },
    {
      value: 'PRT',
      name: 'Portugal',
      displayfields: '',
    },
    {
      value: 'PRI',
      name: 'Puerto Rico',
      displayfields: '',
    },
    {
      value: 'QAT',
      name: 'Qatar',
      displayfields: '',
    },
    {
      value: 'REU',
      name: 'Reunion',
      displayfields: '',
    },
    {
      value: 'ROU',
      name: 'Romania',
      displayfields: '',
    },
    {
      value: 'RUS',
      name: 'Russian Federation',
      displayfields: '',
    },
    {
      value: 'RWA',
      name: 'Rwanda',
      displayfields: '',
    },
    {
      value: 'SHN',
      name: 'Saint Helena',
      displayfields: '',
    },
    {
      value: 'KNA',
      name: 'Saint Kitts and Nevis',
      displayfields: '',
    },
    {
      value: 'MAF',
      name: 'Saint Lucia',
      displayfields: '',
    },
    {
      value: 'SPM',
      name: 'Saint Pierre and Miquelon',
      displayfields: '',
    },
    {
      value: 'VCT',
      name: 'Saint Vincent and The Grenadines',
      displayfields: '',
    },
    {
      value: 'Samoa',
      name: 'WSM',
      displayfields: '',
    },
    {
      value: 'SMR',
      name: 'San Marino',
      displayfields: '',
    },
    {
      value: 'SMR',
      name: 'Sao Tome and Principe',
      displayfields: '',
    },
    {
      value: 'SAU',
      name: 'Saudi Arabia',
      displayfields: '',
    },
    {
      value: 'SEN',
      name: 'Senegal',
      displayfields: '',
    },
    {
      value: 'SRB',
      name: 'Serbia',
      displayfields: '',
    },
    {
      value: 'SYC',
      name: 'Seychelles',
      displayfields: '',
    },
    {
      value: 'SLE',
      name: 'Sierra Leone',
      displayfields: '',
    },
    {
      value: 'SGP',
      name: 'Singapore',
      displayfields: '',
    },
    {
      value: 'SVK',
      name: 'Slovakia',
      displayfields: '',
    },
    {
      value: 'SVN',
      name: 'Slovenia',
      displayfields: '',
    },
    {
      value: 'SLB',
      name: 'Solomon Islands',
      displayfields: '',
    },
    {
      value: 'SOM',
      name: 'Somalia',
      displayfields: '',
    },
    {
      value: 'ZAF',
      name: 'South Africa',
      displayfields: '',
    },
    {
      value: 'SGS',
      name: 'South Georgia and The South Sandwich Islands',
      displayfields: '',
    },
    {
      value: 'KOR',
      name: 'South Korea',
      displayfields: '',
    },
    {
      value: 'ESP',
      name: 'Spain',
      displayfields: '',
    },
    {
      value: 'LKA',
      name: 'Sri Lanka',
      displayfields: '',
    },
    {
      value: 'SDN',
      name: 'Sudan',
      displayfields: '',
    },
    {
      value: 'SUR',
      name: 'Suriname',
      displayfields: '',
    },
    {
      value: 'SJM',
      name: 'Svalbard and Jan Mayen',
      displayfields: '',
    },
    {
      value: 'SWZ',
      name: 'Swaziland',
      displayfields: '',
    },
    {
      value: 'SWE',
      name: 'Sweden',
      displayfields: '',
    },
    {
      value: 'CHE',
      name: 'Switzerland',
      displayfields: '',
    },
    {
      value: 'SYR',
      name: 'Syrian Arab Republic',
      displayfields: '',
    },
    {
      value: 'TWN',
      name: 'Taiwan, Province of China',
      displayfields: '',
    },
    {
      value: 'TJK',
      name: 'Tajikistan',
      displayfields: '',
    },
    {
      value: 'TLS',
      name: 'Tanzania, United Republic of',
      displayfields: '',
    },
    {
      value: 'THA',
      name: 'Thailand',
      displayfields: '',
    },
    {
      value: 'TLS',
      name: 'Timor-leste',
      displayfields: '',
    },
    {
      value: 'TGO',
      name: 'Togo',
      displayfields: '',
    },
    {
      value: 'TKL',
      name: 'Tokelau',
      displayfields: '',
    },
    {
      value: 'TON',
      name: 'Tonga',
      displayfields: '',
    },
    {
      value: 'TTO',
      name: 'Trinidad and Tobago',
      displayfields: '',
    },
    {
      value: 'TUN',
      name: 'Tunisia',
      displayfields: '',
    },
    {
      value: 'TUR',
      name: 'Turkey',
      displayfields: '',
    },
    {
      value: 'TKM',
      name: 'Turkmenistan',
      displayfields: '',
    },
    {
      value: 'TCA',
      name: 'Turks and Caicos Islands',
      displayfields: '',
    },
    {
      value: 'TUV',
      name: 'Tuvalu',
      displayfields: '',
    },
    {
      value: 'UGA',
      name: 'Uganda',
      displayfields: '',
    },
    {
      value: 'UKR',
      name: 'Ukraine',
      displayfields: '',
    },
    {
      value: 'ARE',
      name: 'United Arab Emirates',
      displayfields: '',
    },
    {
      value: 'GBR',
      name: 'United Kingdom',
      displayfields: '',
    },
    {
      value: 'USA',
      name: 'United States',
      displayfields: '',
    },
    {
      value: 'UMI',
      name: 'United States Minor Outlying Islands',
      displayfields: '',
    },
    {
      value: 'URY',
      name: 'Uruguay',
      displayfields: '',
    },
    {
      value: 'UZB',
      name: 'Uzbekistan',
      displayfields: '',
    },
    {
      value: 'VUT',
      name: 'Vanuatu',
      displayfields: '',
    },
    {
      value: 'VEN',
      name: 'Venezuela',
      displayfields: '',
    },
    {
      value: 'VNM',
      name: 'Viet Nam',
      displayfields: '',
    },
    {
      value: 'VIR',
      name: 'Virgin Islands, British',
      displayfields: '',
    },
    {
      value: 'VIR',
      name: 'Virgin Islands, U.S.',
      displayfields: '',
    },
    {
      value: 'WLF',
      name: 'Wallis and Futuna',
      displayfields: '',
    },
    {
      value: 'ESH',
      name: 'Western Sahara',
      displayfields: '',
    },
    {
      value: 'YEM',
      name: 'Yemen',
      displayfields: '',
    },
    {
      value: 'ZMB',
      name: 'Zambia',
      displayfields: '',
    },
    {
      value: 'ZWE',
      name: 'Zimbabwe',
      displayfields: '',
    },
  ];

  return state;
};
