import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Fragment} from 'react';
import {confirmAlert} from 'react-confirm-alert';
import _ from 'lodash';
import LocaleStrings from '@language';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {PROFESSIONAL_YEARLY, EXTENSION_LINK} from '@constant';
import {Routes, Route} from 'react-router-dom';
import {Menu, Transition} from '@headlessui/react';
// Import Action
import {
  showNotificatonCount,
  isNotificationModalOpen,
  onSidebarMenuSelected,
  onSearchSubMenuSelected,
} from '@sidebarActions';

import toast, {Toaster} from 'react-hot-toast';

import {
  logout,
  fetchsessionData,
  resetStore,
  getDeviceSize,
} from '@mainActions';
import {onsettingsSidebarMenuSelected} from '@mainSettingsActions';
import {fetchAppuser} from '@sidebarActions';
import Sidebar from '@sidebar';
import AppAlert from '../../../common/appalert';
import {isEmpty, classNames} from '@commonFunction';
import {IconUpgrade} from './icon/upgrade';
import IconSettings from './icon/settings';
import IconExtension from './icon/extension';

class TopBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: false,
      selectMenuNum: 1,

      selectedThreadId: '',
      toberemoved: false,
      showLogoutAlert: false,
    };

    // All Binded Functions

    this.onLogoutClicked = this.onLogoutClicked.bind(this);
  }

  componentDidMount() {
    this.props.getDeviceSize();
    let {session} = this.props;
    // From extension the platform is called so below code is for verifying the session and make the user able to enter platform
    var url_string = this.props.router.location.search;
    var historypush = url_string.split('?')[0];
    var query = url_string.split('?')[1];
    var historypushLink = historypush.split('#')[1];

    var params = {};

    if (query) {
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
      this.setState({params: params});

      if (params.session_token) {
        this.props.fetchsessionData(params, this.props.session, response => {
          if (response.success === 0) {
            toast.error(LocaleStrings.invalid_email_phone_password);
            this.props.router.navigate('/');
            this.props.resetStore();
          } else {
            // toast.success(LocaleStrings.login_success);
            this.props.router.navigate(historypushLink);
          }
        });
      } else {
        // check added new
        if (this.props.isLoggedIn !== true) {
          this.props.router.navigate('/');
        }
      }
    } else {
      if (this.props.isLoggedIn === true) {
        //  Checking all the conditions below after login (DELETED USER OR UNVERIFIED, redirecting accordingly)
        if (this.props.user.appuserid) {
          this.props.fetchAppuser(
            session,
            this.props.user.appuserid,
            callback => {
              if (
                callback &&
                callback.success == 1 &&
                callback.data &&
                callback.data.resource &&
                callback.data.resource.length > 0
              ) {
                if (
                  callback.data.resource[0].isdeleted == 1 ||
                  callback.data.resource[0].isdeleted == '1'
                ) {
                  toast.error(LocaleStrings.mainlogin_user_isdeleted);
                  clearInterval(this.interVal);
                  this.props.logout(this.props.session);
                } else if (
                  callback.data.resource[0].isverified == '0' ||
                  callback.data.resource[0].isverified == 0
                ) {
                  toast.error(`Unverfied User`);
                  // this.props.router.navigate("/verifyemail");
                  clearInterval(this.interVal);
                  this.props.logout(this.props.session);
                } else if (
                  callback.data.resource[0].isactive == '0' ||
                  callback.data.resource[0].isverified == 0
                ) {
                  toast.error(LocaleStrings.disabled_by_admin_error);
                  clearInterval(this.interVal);
                  this.props.logout(this.props.session);
                  // clearInterval(this.interVal);
                  // this.props.logout(this.props.session);
                }
              }
            },
          );
        }
      } else {
        this.props.router.navigate('/');
      }
    }
  }

  // Logout Button Click
  onLogoutClicked() {
    this.setState({showLogoutAlert: true});
  }

  // Upgrade Plan Page Redirection
  _openUpgradPlan(e) {
    this.props.router.navigate('/upgradeplan');
  }
  // Setting Page Redirection
  _openSettings(e) {
    // this.props.router.navigate("/settings");
    this.props.onsettingsSidebarMenuSelected(1);
    this.props.router.navigate('/settings/myaccount');
  }

  // Dashboard Page Redirection
  _onLogoClicked(e) {
    this.props.onSidebarMenuSelected('dashboard');
    this.props.router.navigate('/dashboard');
  }

  // Dashboard Page Redirection
  // _onScheduledemoclick(e) {
  //   this.props.router.navigate("/dashboard/scheduledemo");
  // }

  handleDownloadClick = e => {
    window.open(EXTENSION_LINK, '_blank');
  };

  onMenuSelect = item => {
    this.props.onSidebarMenuSelected(item.href);
    // if (item.href === 'revealedcontacts') {
    //   this.props.onSearchSubMenuSelected('people');
    // } else {
    //   this.props.onSearchSubMenuSelected('company');
    // }

    this.props.router.navigate(item.href);
  };

  render() {
    var {user, navigation, appuserData} = this.props;

    let role = appuserData.credits && appuserData.credits.role;
    let planid = appuserData.credits && appuserData.credits.planid;

    var userImageSrc = ``;

    if (appuserData && appuserData.data && appuserData.data.length > 0) {
      userImageSrc = appuserData.data[0].avatar;
    }

    var {navigation, deviceSize} = this.props;

    return (
      <>
        <div className="absolute w-full h-[64px] bg-white flex flex-col justify-center">
          <div className="px-2 py-1 flex">
            <div className="mt-0 mr-10 relative ml-2">
              <img
                className="w-24 cursor-pointer"
                onClick={e => this._onLogoClicked(e)}
                src="../../images/kipplo-logo.webp"></img>
            </div>
            <div className="flex-shrink-0 flex">
              <div className="flex justify-between">
                <div className="flex">
                  <nav
                    className="flex space-x-2 items-center"
                    aria-label="Global">
                    {navigation.map(item => (
                      <span
                        key={item.name}
                        onClick={e => this.onMenuSelect(item)}
                        className={classNames(
                          this.props.sidebarSelectedMenu === item.href
                            ? 'border-primary border-b-2'
                            : '',
                          'text-gray-600 group flex items-center px-2 py-2 text-sm cursor-pointer font-medium',
                        )}
                        aria-current={item.current ? 'page' : undefined}>
                        {item.name}
                      </span>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
            <div className="items-center hidden sm:flex ml-auto right">
              {/* <div
                className="group relative inline-flex items-center hover:cursor-pointer"
                onClick={e => this.handleDownloadClick(e)}>
                <div className="opacity-0 w-max text-center group-hover:opacity-100 absolute bg-gray-900 text-white text-xs py-1 px-2 rounded-lg right-10">
                  Download Extension
                </div>
                
                <span className="mr-2">
                  <IconExtension />
                </span>
              </div> */}
              <div
                className="text-sm items-center cursor-pointer text-primary"
                // onClick={(e) => this._onScheduledemoclick(e)}
              >
                <a href="https://calendly.com/app-kipplo" target="_blank">
                  {LocaleStrings.scheduledemo}
                </a>
              </div>
              {role !== 'client' ? (
                ''
              ) : (
                <div className="w-30 items-center mx-4">
                  <button
                    type="button"
                    className="btn-primary items-center"
                    onClick={e => this._openUpgradPlan(e)}>
                    <span className="mr-2">
                      <IconUpgrade />
                    </span>
                    {/* {LocaleStrings.viewplan} */}
                    {planid == PROFESSIONAL_YEARLY ? 'View Plans' : 'Upgrade'}
                  </button>
                </div>
              )}
              <button
                className="relative mx-2"
                onClick={e => this._openSettings(e)}>
                {/* <img
                    loading="lazy"
                    className={' h-5 w-5 icon-hover-transition'}
                    src={'../../images/setting.png'}
                  /> */}
                <IconSettings />
              </button>
              <Menu as="div" className="relative mx-2">
                {({open}) => (
                  <>
                    <div>
                      <Menu.Button className="max-w-xs bg-custWhite flex items-center text-sm rounded-full">
                        <span className="sr-only">Open user menu</span>
                        <img
                          loading="lazy"
                          id="profile-image-2"
                          className="h-9 w-9 rounded-full border-2 border-white"
                          src={userImageSrc}
                          alt="Image"
                          onError={e => {
                            e.target.onerror = null;
                            e.target.src = '../../images/user.png';
                          }}
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items
                        static
                        className="z-50 absolute mt-2 w-48 rounded-md shadow-lg py-1 bg-custWhite ring-1 ring-black ring-opacity-5 focus:outline-none origin-top-right right-0">
                        <Menu.Item key="sign_out">
                          <a
                            onClick={this.onLogoutClicked}
                            className="block px-4 py-2 text-sm text-secondary cursor-pointer">
                            {LocaleStrings.signout}
                          </a>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
            <div className="items-center mt-3  sm:hidden ml-auto">
              <Menu as="div" className="relative mx-2">
                {({open}) => (
                  <>
                    <div>
                      <Menu.Button className="max-w-xs bg-custWhite flex items-center text-sm rounded-full">
                        <span className="sr-only">Open menu small screen</span>
                        <img
                          loading="lazy"
                          id="profile-image-2"
                          className="h-9 w-9 rounded-full"
                          src={userImageSrc}
                          alt="Image"
                          onError={e => {
                            e.target.onerror = null;
                            e.target.src = '../../images/user.png';
                          }}
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items
                        static
                        className="z-50 origin-top-right right-0 absolute mt-2 w-48 rounded-md shadow-lg py-1 bg-custWhite ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item key="sign_out">
                          <a
                            onClick={e => this.handleDownloadClick(e)}
                            className="block px-4 py-2 text-sm text-secondary cursor-pointer">
                            Download Extension
                          </a>
                        </Menu.Item>
                        <Menu.Item key="sign_out">
                          <a
                            href="https://calendly.com/app-kipplo"
                            target="_blank"
                            className="block px-4 py-2 text-sm text-secondary cursor-pointer">
                            {LocaleStrings.scheduledemo}
                          </a>
                        </Menu.Item>
                        {role !== 'client' ? (
                          ''
                        ) : (
                          <Menu.Item key="sign_out">
                            <a
                              onClick={e => this._openUpgradPlan(e)}
                              className="block px-4 py-2 text-sm text-secondary cursor-pointer">
                              {LocaleStrings.upgrade}
                            </a>
                          </Menu.Item>
                        )}
                        <Menu.Item key="sign_out">
                          <a
                            onClick={e => this._openSettings(e)}
                            className="block px-4 py-2 text-sm text-secondary cursor-pointer">
                            Settings
                          </a>
                        </Menu.Item>

                        <Menu.Item key="sign_out">
                          <a
                            onClick={this.onLogoutClicked}
                            className="block px-4 py-2 text-sm text-secondary cursor-pointer">
                            {LocaleStrings.signout}
                          </a>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </div>
        {/* <main className="main-tag-class- bg-white">
            {this.props.hideTopBar ? (
              this.props.component
            ) : (
              <Sidebar {...this.props} />
            )}
            <Toaster />
          </main> */}
        {/* <main className="main-tag-class- ">
            <Routes>
               <Route
                component={ScheduleDemo}
                history={this.props.history}
                path="/dashboard/scheduledemo"
              /> 
              <Route
                element={PrivacyPolicy}
                history={this.props.history}
                path="/dashboard/privacypolicy"
              />
              <Route
                element={Termsofuse}
                history={this.props.history}
                path="/dashboard/termsofuse"
              />
              <Route
                element={ChromePrivacy}
                history={this.props.history}
                path="/dashboard/chromeprivacy"
              />
            </Routes>
            <Toaster />
          </main> */}
        <div className="absolute left-24 top-2 text-[10px] text-gray-300">{`v${process.env.REACT_APP_VERSION}`}</div>

        <AppAlert
          open={this.state.showLogoutAlert}
          title={LocaleStrings.button_logout}
          message={LocaleStrings.logout_want_to_logout}
          primaryButton={{title: LocaleStrings.button_yes}}
          secondaryButton={{title: LocaleStrings.button_no}}
          primaryButtonAction={() => {
            clearInterval(this.interVal);
            this.props.logout(this.props.session);
            setTimeout(() => {
              this.props.router.navigate('/');
            }, 100);
          }}
          secondaryButtonAction={() => {
            this.setState({showLogoutAlert: false});
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  var {session, user} = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
    navigation: state.sideBarMenus,
    appuserData: state.appuserData,
  };
}

export default connect(mapStateToProps, {
  onSidebarMenuSelected,
  showNotificatonCount,
  isNotificationModalOpen,
  logout,
  fetchsessionData,
  resetStore,
  onsettingsSidebarMenuSelected,
  fetchAppuser,
  getDeviceSize,
  onSearchSubMenuSelected,
  // selectedProfileSettingsMenu,
})(TopBar);
