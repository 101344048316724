import useWindowDimensions from '@/common/useWindowDimensions';
import LocaleStrings from '../../../../../languages';
import {classNames} from '@commonFunction';
import {useNavigate} from 'react-router-dom';

export const SECTION_PEOPLE = 'SECTION_PEOPLE';
export const SECTION_COMPANY = 'SECTION_COMPANY';

export default function BlockTechnology(props) {
  const {height, width} = useWindowDimensions();

  const navigate = useNavigate();
  return (
    <tr>
      <td colSpan={props.colSpan}>
        <div
          className={classNames(
            'bg-white flex flex-col justify-center items-center mt-14',
          )}>
          <span className="text-lg font-bold">
            {LocaleStrings.formatString(
              props.section === SECTION_COMPANY
                ? LocaleStrings.search.technologyBlock.title
                : LocaleStrings.search.technologyBlockPeople.title,
              props.totalcount,
            )}
          </span>
          <span className="text-sm text-center text-gray-600">
            {props.section === SECTION_COMPANY
              ? LocaleStrings.search.technologyBlock.subtitle
              : LocaleStrings.search.technologyBlockPeople.subtitle}
          </span>
          <span className="text-xs text-center text-gray-600">
            {props.section === SECTION_COMPANY
              ? LocaleStrings.search.technologyBlock.subtitle2
              : LocaleStrings.search.technologyBlockPeople.subtitle2}
          </span>
          <span className="mt-2">
            <button
              type="button"
              className="btn-primary items-center"
              onClick={() => {
                navigate('/upgradeplan');
              }}>
              {props.section === SECTION_COMPANY
                ? LocaleStrings.search.technologyBlock.upgradenow
                : LocaleStrings.search.technologyBlockPeople.upgradenow}
            </button>
          </span>
        </div>
      </td>
    </tr>
  );
}
