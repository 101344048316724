import React, {Component} from 'react';
import {connect} from 'react-redux';
import LocaleStrings from '@language';
import IconUpgrade from '../../billing/icons/upgrade';

class Privacy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_currency,
    };

    // All Binded Functions
  }
  _handelredirect = (e, redirect) => {
    this.props.router.navigate('/dashboard/' + redirect);
  };

  render() {
    return (
      <>
        <div className="xs:px-6 md:px-0">
          <div className="block md:flex pb-10">
            <div>
              <div className="text-mainGray text-base font-medium">
                {LocaleStrings.setting_privacy_heading}
              </div>
              <div className="text-gray500 text-xs font-normal">
                {LocaleStrings.setting_privacy_subheading}
              </div>
            </div>
          </div>
          <a href="https://www.kipplo.com/privacy-policy/" target="_blank">
            <div className="block md:flex pb-3 border-b-2 border-gray-100 mb-5 hover:bg-chatbg cursor-pointer">
              <div className="text-gray700 text-xs font-normal">
                {LocaleStrings.setting_privacy_tab1}
              </div>
              <div className="ml-auto">
                <div className="flex">
                  <button
                    type="button"
                    // onClick={(e) => this._handelredirect(e, 'privacypolicy')}
                    className="btn-light-primary  cursor-pointer">
                    {LocaleStrings.setting_privacy_btn_view}
                    {/* <img
                      src="../../images/privacy/view.png"
                      className="mt-1 pl-2"></img> */}
                    <div className="mt-0.5">
                      <IconUpgrade />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </a>
          <a
            href="https://www.kipplo.com/terms-and-conditions/"
            target="_blank">
            <div className="block md:flex pb-3 border-b-2 border-gray-100 mb-5 hover:bg-chatbg cursor-pointer">
              <div className="text-gray700 text-xs font-normal">
                {LocaleStrings.setting_privacy_tab2}
              </div>
              <div className="ml-auto">
                <div className="flex">
                  <button
                    type="button"
                    // onClick={(e) => this._handelredirect(e, 'termsofuse')}
                    className="btn-light-primary cursor-pointer">
                    {LocaleStrings.setting_privacy_btn_view}
                    <div className="mt-0.5">
                      <IconUpgrade />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </a>
          {/* <div className="block md:flex pb-3 border-b-2 border-gray-100" >
                        <div className="text-gray700 text-sm font-normal">
                            {LocaleStrings.setting_privacy_tab3}
                        </div>
                        <div className="ml-auto">
                            <div className="flex">
                                <button
                                    type="button"
                                    // onClick={(e) => this._handelredirect(e, 'chromeprivacy')}
                                    className="btn-light-primary  cursor-pointer">
                                    {LocaleStrings.setting_privacy_btn_view}
                                    <img src="../../images/privacy/view.png" className="mt-1 pl-2"></img>
                                </button>
                            </div>
                        </div>
                    </div> */}
        </div>
      </>
    );
  }
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
});

export default connect(mapStateToProps, {})(Privacy);
