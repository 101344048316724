import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import LocaleStrings from '@language';
import BaseLoader from '@baseLoader';
import {fetchSubscriptionplan} from '@upgradePlanActions';
import UpgradeNewPlanItem from './upgradeplan-item-new';
import SkeletonLoader from '../../../../common/skeletonloader';

class UpgradePricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: false,
      loading: true,
      loaderDesc: LocaleStrings.preparing_subscription_plan,
      selectMenuNum: 1,
      selectedThreadId: '',
      selectedTenure: 30,
    };
    // All Binded Functions
  }

  componentDidMount() {
    this.props.fetchSubscriptionplan(this.props.session, callback => {
      this.setState({loading: false});
    });
  }

  renderplanList() {
    var planList = _.filter(this.props.subscriptionplanList.data, obj => {
      return obj.validity === this.state.selectedTenure;
    });
    var items = _.map(planList, (values, index) => (
      <UpgradeNewPlanItem
        key={index}
        values={values}
        router={this.props.router}
      />
    ));

    return items;
  }

  changePlan(key) {
    this.setState({selectedTenure: key});
  }
  _handelback = () => {
    this.props.router.navigate('/dashboard');
  };

  render() {
    let {subscriptionplanList} = this.props;
    let {selectedTenure} = this.state;
    return (
      <>
        {this.state.loading ? (
          <SkeletonLoader />
        ) : (
          <div>
            <div className="flex mb-4">
              <div className="cursor-pointer" onClick={e => this._handelback()}>
                <img className="pt-1" src="../../images/backicon.png" />
              </div>
              <div
                className="pl-2 cursor-pointer text-sm text-gray500 font-medium"
                onClick={e => this._handelback()}>
                {LocaleStrings.settings_backtodashboard}
              </div>
            </div>
            <div className="text-2xl font-semibold text-mainGray">
              {LocaleStrings.upgradeplan_header}
            </div>
            <div className="text-sm text-gray500 mt-3 font-normal">
              {LocaleStrings.upgradeplan_desc}
            </div>
            {/* Commented for now */}

            <div className="py-6">
              <span className="isolate inline-flex rounded-lg bg-gray-100 p-0.5 sm:mb-8 xs:w-80">
                <button
                  type="button"
                  className={`relative w-1/2 whitespace-nowrap rounded-md  py-2 text-sm font-medium text-gray-900 shadow-sm focus:z-10 focus:outline-none  sm:w-auto sm:px-8 ${
                    selectedTenure == 30 ? 'bg-white border-gray-200' : ''
                  }`}
                  onClick={() =>
                    selectedTenure == 30 ? null : this.changePlan(30)
                  }>
                  {LocaleStrings.upgradeplan_desc_tab_button1}
                </button>
                <button
                  type="button"
                  className={`relative w-1/2 whitespace-nowrap rounded-md  py-2 text-sm font-medium text-gray-900 shadow-sm focus:z-10 focus:outline-none   sm:w-auto sm:px-8 ${
                    selectedTenure == 365 ? 'bg-white border-gray-200' : ''
                  }`}
                  onClick={() =>
                    selectedTenure == 365 ? null : this.changePlan(365)
                  }>
                  {LocaleStrings.upgradeplan_desc_tab_button2}
                </button>
              </span>
            </div>

            {subscriptionplanList &&
            subscriptionplanList.data &&
            subscriptionplanList.data.length > 0 ? (
              <>
                <div className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
                  {this.renderplanList()}
                </div>
              </>
            ) : (
              <div className="center-item lg:my-48 text-lg text-ternary">
                {LocaleStrings.no_data_found}
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var {session, user} = state;
  return {
    session,
    user,
    subscriptionplanList: state.subscriptionplanList,
  };
}
export default connect(mapStateToProps, {
  fetchSubscriptionplan,
})(UpgradePricing);
