import Figma from '@assets/images/searchmodule/figma.png';
import {arrCompanyInfo} from '../../../constants';
import {classNames} from '@commonFunction';
import {
  convertToInternationalCurrencySystem,
  toTitleCase,
} from '@/common/constants';
import {useState} from 'react';
import IconUpdate from '../icons/update';

function InfoComponent(props) {
  const {icon, type} = props;

  let title = props.title;

  if (type === 'revenue') {
    title = convertToInternationalCurrencySystem(title);
  } else if (type === 'industry') {
    title = toTitleCase(title);
  } else if (type === 'headcount') {
    title =
      title === '-1' ? 'Self Employed' : title === '0' ? 'Unknown' : title;
  }

  const renderIcon = () => {
    if (icon) {
      return (
        <div className="w-4 h-4 relative">
          <img className="mr-2" src={icon} width={20} />
        </div>
      );
    } else if (type === 'naicscodes') {
      return <span className="text-[12px] mr-2 text-gray-900 h-4">NAICS</span>;
    } else if (type === 'siccodes') {
      return <span className="text-[12px] mr-2 text-gray-900 h-4">SIC</span>;
    }
  };

  return (
    <div
      className={classNames(
        'justify-start items-center gap-1.5 inline-flex whitespace-wrap relative',
      )}>
      {renderIcon()}
      <div
        className={classNames(
          'truncate pr-2',
          type === 'naicscodes' || type === 'siccodes'
            ? 'text-xs text-slate-500'
            : 'text-xs text-slate-600',
          !props.expanded ? 'min-w-30 max-w-40' : '',
        )}>
        {title}
      </div>
    </div>
  );
}
export default function SubCompany(props) {
  const [readMore, setReadMore] = useState(false);

  const {
    title,
    website,
    cardView,
    cardViewCompany,
    expanded,
    desc,
    logo,
    new_company_data_available,
  } = props;

  let arrComponents = arrCompanyInfo;

  if (cardViewCompany || cardView) {
    arrComponents = arrCompanyInfo.filter(
      info => !(info.type === 'naicscodes' || info.type === 'siccodes'),
    );
  }

  return (
    <div className="overflow-hidden">
      <div className="justify-start items-start gap-2 inline-flex">
        {!cardViewCompany ? (
          logo ? (
            <div className="justify-start items-center flex w-5">
              <img
                className="h-5"
                src={logo}
                onError={e => {
                  e.target.src = require('../../../../../../assets/images/empty_company.png');
                }}
              />
            </div>
          ) : (
            <img
              className="h-5"
              src={require('../../../../../../assets/images/empty_company.png')}
            />
          )
        ) : null}
        {cardViewCompany ? null : (
          <div className="flex-col justify-start items-start gap-0.5 inline-flex">
            <div className="flex">
              <div
                className={classNames(
                  'text-gray-900 text-xs font-semibold truncate',
                  !expanded ? 'min-w-30 max-w-40' : 'w-60',
                )}>
                {title}
              </div>
            </div>
            <div className="text-gray-600 text-xs font-normal">{website}</div>
            {cardView ? null : (
              <div>
                <div
                  className={classNames(
                    'text-gray-400 text-xs font-normal w-full pr-4 h-full max-h-20 h-auto scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent scrollbar-thumb-rounded-full',
                    readMore
                      ? 'line-clamp-none overflow-y-scroll'
                      : 'line-clamp-5 overflow-y-hidden',
                  )}>
                  {desc}
                </div>
                <span
                  onClick={() => {
                    setReadMore(!readMore);
                  }}
                  className="flex text-xs text-primary justify-end mr-4 hover:cursor-pointer">
                  {readMore ? 'Read Less' : 'Read More'}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
      <div
        className={classNames(
          'flex-col justify-start items-start gap-0.5 flex',
          cardViewCompany ? 'pt-0' : 'pt-2',
        )}>
        {arrComponents.map(info => {
          if (props[info.type]) {
            return (
              <InfoComponent
                title={props[info.type]}
                icon={info.icon}
                key={info.key}
                cardView={cardView}
                expanded={expanded}
                {...info}
              />
            );
          }
        })}
      </div>
    </div>
  );
}
