import {
  companyStandardSettings,
  peopleStandardSettings,
} from '../../../../common/constants';
import {
  COMPANY_STANDARD_FIELD_SELECTION,
  CSV_ENRICH_INFO,
  CSV_ENRICH_LISTS,
  HEADER_MAPPING_SELECTION,
  PEOPLE_STANDARD_FIELD_SELECTION,
  COMPANY_HEADER_MAPPING_SELECTION,
  TOGGLE_CSV_SELECTION_POPUP,
  CSV_UPLOAD_INFO,
  TOGGLE_CSV_ANALYSIS,
} from '../actions';

export const defaultPeopleFieldMapping = {
  first_name: '',
  last_name: '',
  full_name: '',
  jobtitle: '',
  linkedinurl: '',
  cities: '',
  states: '',
  countries: '',
  companyname: '',
  domain: '',
  industryname: '',
  revenue: '',
  headcount: '',
  hqnumbers: '',
  linkedinpage: '',
  company_address: '',
};

export const defaultCompanyFieldMapping = {
  companyname: '',
  cities: '',
  states: '',
  countries: '',
  domain: '',
  industryname: '',
  revenue: '',
  headcount: '',
  hqnumbers: '',
  linkedinpage: '',
  // company_postal: '',
  yearfounded: '',
};

export const csvEnrichList = (state = [], action) => {
  switch (action.type) {
    case CSV_ENRICH_LISTS:
      return action.payload;
    default:
      return state;
  }
};

export const csvUploadInfo = (state = {}, action) => {
  switch (action.type) {
    case CSV_UPLOAD_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const csvEnrichInfo = (state = {}, action) => {
  switch (action.type) {
    case CSV_ENRICH_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const toggleCSVSelectionPopupReducer = (
  state = {section: 'people', open: false, file: null, originalFile: null},
  action,
) => {
  switch (action.type) {
    case TOGGLE_CSV_SELECTION_POPUP:
      return action.payload;
    default:
      return state;
  }
};

export const selectMapping = (state = defaultCompanyFieldMapping, action) => {
  switch (action.type) {
    case HEADER_MAPPING_SELECTION:
      return action.payload;
    default:
      return state;
  }
};

export const selectCompanyMapping = (
  state = defaultPeopleFieldMapping,
  action,
) => {
  switch (action.type) {
    case COMPANY_HEADER_MAPPING_SELECTION:
      return action.payload;
    default:
      return state;
  }
};

export const peopleStandardFieldsReducer = (
  state = {...peopleStandardSettings},
  action,
) => {
  switch (action.type) {
    case PEOPLE_STANDARD_FIELD_SELECTION:
      return action.payload;
    default:
      return state;
  }
};

export const companyStandardFieldsReducer = (
  state = {...companyStandardSettings},
  action,
) => {
  switch (action.type) {
    case COMPANY_STANDARD_FIELD_SELECTION:
      return action.payload;
    default:
      return state;
  }
};

export const toggleCSVAnalysisReducer = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_CSV_ANALYSIS:
      return action.payload;
    default:
      return state;
  }
};
