import {UPLOAD_FILES_API_KEY, APP_API_KEY, ZAPIER_API_KEY} from '@constant';

export var getHeaders = session => ({
  headers: {
    'X-ApiFlo-Api-Key': session.apiKey,
    ...(session.sessionToken && session.sessionToken !== ''
      ? {'X-ApiFlo-Session-Token': session.sessionToken}
      : {}),
    contentType: 'application/json; charset=utf-8',
  },
});

export var getHeadersWOSession = session => ({
  headers: {
    'X-ApiFlo-Api-Key': APP_API_KEY,
    contentType: 'application/json; charset=utf-8',
  },
});

export var getFlatHeaders = session => ({
  'X-ApiFlo-Api-Key': session.apiKey,
  'X-ApiFlo-Session-Token': session.sessionToken,
  contentType: 'application/json; charset=utf-8',
});

export var getMultiPartHeaders = session => ({
  headers: {
    'X-ApiFlo-Api-Key': session.apiKey,
    'X-ApiFlo-Session-Token': session.sessionToken,
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export var getHeadersMultiPart = session => ({
  headers: {
    'X-ApiFlo-Api-Key': session.apiKey,
    'X-ApiFlo-Session-Token': session.sessionToken,
    'Content-Type':
      'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
  },
});

export var getHeadersWithParams = (session, params) => ({
  headers: {
    'X-ApiFlo-Api-Key': session.apiKey,
    'X-ApiFlo-Session-Token': session.sessionToken,
    'Content-Type': 'multipart/form-data',
  },
  params: params,
});

export var getImageHeaders = session => ({
  headers: {
    'X-ApiFlo-Api-Key': UPLOAD_FILES_API_KEY,
    'X-ApiFlo-Session-Token': session.sessionToken,
    contentType: 'application/json; charset=utf-8',
  },
});

export var getHeadersZapier = session => ({
  headers: {
    'X-ApiFlo-Api-Key': ZAPIER_API_KEY,
    ...(session.sessionToken && session.sessionToken !== ''
      ? {'X-ApiFlo-Session-Token': session.sessionToken}
      : {}),
    contentType: 'application/json; charset=utf-8',
  },
});

export function normalizeLinkedinUrl(url) {
  let linkedinurl = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '');
  if (linkedinurl.indexOf('?miniProfileUrn=') > -1) return;
  if (linkedinurl.indexOf('#') > -1) linkedinurl = linkedinurl.split('#')[0];
  linkedinurl = linkedinurl.replace(/\/$/, '');
  return linkedinurl;
}

export const appConstant = {
  kFetchedPersonalEmail: false,
  kFetchedBusinessEmail: false,
  kFetchedPhone: false,
};
