export default function IconSaveToList() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="stroke-primary stroke-2">
      <path
        d="M9.99935 2.49984H13.4993C14.8995 2.49984 15.5995 2.49984 16.1343 2.77232C16.6047 3.012 16.9872 3.39446 17.2269 3.86486C17.4993 4.39964 17.4993 5.09971 17.4993 6.49984V13.4998C17.4993 14.9 17.4993 15.6 17.2269 16.1348C16.9872 16.6052 16.6047 16.9877 16.1343 17.2274C15.5995 17.4998 14.8995 17.4998 13.4993 17.4998H6.49935C5.09922 17.4998 4.39915 17.4998 3.86437 17.2274C3.39397 16.9877 3.01152 16.6052 2.77183 16.1348C2.49935 15.6 2.49935 14.9 2.49935 13.4998V9.99984M13.7493 8.33317H9.16602M6.24935 12.4998H13.7493M4.16602 6.6665V1.6665M1.66602 4.1665H6.66602"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
