import {classNames} from '@commonFunction';

export default function TimeLabel(props) {
  const {title, selected} = props;
  return (
    <div
      className={classNames(
        'group w-full rounded-lg p-1',
        selected ? 'bg-gray-50' : 'bg-white',
        'hover:bg-gray-50 hover:cursor-pointer hover:scale-105 transition ease-in duration-100',
      )}
      onClick={() => {
        props.onSelectTimeFilter(props);
      }}>
      <div
        className={classNames('justify-start items-start gap-1 inline-flex')}>
        <div className="px-3 py-2 rounded-2xl justify-start items-center gap-1 flex">
          <div
            className={classNames(
              'text-center text-xs leading-none',
              selected ? 'text-primary' : '',
              'group-hover:text-primary',
            )}>
            {title}
          </div>
        </div>
      </div>
    </div>
  );
}
