import React, {Component} from 'react';
import {connect} from 'react-redux';
import LocaleStrings from '@language';
import BaseLoader from '@baseLoader';
import DashboardHead from './dashboardhead';
import Dashboardsubheader from './dashboardsubheader';
import DashboardBottom from './dashboardbottom';
import {fetchAppuser} from '@sidebarActions';
import SkeletonLoader from '../../../../common/skeletonloader';
import {sessionCookieName} from '../../../../common/constants';
import {withCookies} from 'react-cookie';
import {fetchCRMDetails} from '../../search/actions';
import {getSocialLoginType} from '../../../../actions';

class DashboardMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_dashboard,
    };

    // All Binded Functions
  }

  componentDidMount() {
    let {session, user} = this.props;

    this.props.getSocialLoginType(session, {clientid: user.clientid});

    this.props.fetchCRMDetails(session, resp => {});

    this.props.fetchAppuser(session, user.appuserid, callback => {
      this.setState({loading: false});
    });

    const {cookies} = this.props;
    cookies.set(sessionCookieName, session.sessionToken);
  }

  render() {
    let {user} = this.props;

    return (
      <>
        {this.state.loading ? (
          <SkeletonLoader dashboard />
        ) : (
          <div className="px-4 mt-4">
            {/* Divided the dashboard in the component */}
            <DashboardHead {...this.props} />
            <Dashboardsubheader {...this.props} />
            <DashboardBottom {...this.props} />
          </div>
        )}
      </>
    );
  }
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
});

export default connect(mapStateToProps, {
  fetchAppuser,
  fetchCRMDetails,
  getSocialLoginType,
})(withCookies(DashboardMain));
