import React from 'react';
import {connect} from 'react-redux';
import LocaleStrings from '@language';
import {reduxForm} from 'redux-form';
import {BaseComponent} from '@baseComponent';
import toast from 'react-hot-toast';
// Functions Import
import {
  updateTellusmoreAppuser,
  registrationConfirmationemail,
  linkedinrefreshdata,
} from '@mainActions';
import {fetchinvitedappuser, updatinvitedappuser} from '@sidebarActions';
import BaseLoader from '@baseLoader';

class Emailverification extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      headerText: '',
      btnLoader: false,
      isBtnDisable: false,
      params: {},
      verfiedstate: false,
      loading: true,
      loaderDesc: 'Verifying...',
      link_expired: false,
    };
  }

  componentDidMount() {
    var url_string = this.props.router.location.search;
    var query = url_string.split('?')[1];

    var params = {};
    if (query) {
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
        // params[pair[1]] = decodeURIComponent(pair[1]);
      }

      console.log('Params => ', params);

      if (params && params.email && params.token) {
        let emailobj = {};
        emailobj.email = params.email;
        this.setState({loading: true});
        this.props.fetchinvitedappuser(this.props.session, params, callback => {
          if (callback && callback.success == 1) {
            let obj = {};
            obj.appuserid = callback?.result?.resource[0]?.appuserid;
            obj.isverified = 1;
            this.props.updatinvitedappuser(
              this.props.session,
              obj,
              response => {
                // console.log("response", response);
                if (response.success === 1) {
                  this.props.linkedinrefreshdata(
                    response?.result?.data,
                    callback => {
                      toast.success(
                        LocaleStrings.email_verification_verified_successfully,
                      );
                      this.props.registrationConfirmationemail(
                        this.props.session,
                        emailobj,
                      );
                      this.props.router.navigate('/dashboard');
                      this.setState({isBtnDisable: false});
                      this.setState({btnLoader: false});
                      this.setState({verfiedstate: true});
                      this.setState({loading: false});
                    },
                  );
                } else {
                  // If Un successfull Registration
                  this.setState({loading: false});
                  toast.error(LocaleStrings.something_went_wrong);
                  this.setState({isBtnDisable: false});
                  this.setState({btnLoader: false});
                  this.setState({loading: false});
                  // this.props.reset();
                  return false;
                }
              },
            );
          } else {
            // toast.error(`This Link Has Expired!`);
            this.setState({loading: false});
            this.setState({link_expired: true});
          }
        });
      }
      // console.log("params", params);
      this.setState({params: params});
    }
  }

  /* FORM SUBMIT */
  onFormSubmit(values) {
    let {session, user, invitedappuserData} = this.props;
    let obj = {};

    obj.appuserid = invitedappuserData.data[0].appuserid;
    obj.isverified = 1;
    this.setState({isBtnDisable: true});
    this.setState({btnLoader: true});

    this.props.updatinvitedappuser(session, obj, response => {
      if (response.success === 1) {
        toast.success(LocaleStrings.email_verification_verified_successfully);
        this.setState({isBtnDisable: false});
        this.setState({btnLoader: false});
        this.props.router.navigate('/');
        // this.props.reset();
      } else {
        // If Un successfull Registration
        toast.error(LocaleStrings.something_went_wrong);
        this.setState({isBtnDisable: false});
        this.setState({btnLoader: false});
        // this.props.reset();
        return false;
      }
    });
  }

  gotoLogin = e => {
    this.props.router.navigate('/');
  };

  render() {
    let {session, user, handleSubmit, invitedappuserData} = this.props;
    let {params, verfiedstate, link_expired} = this.state;
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            <div className="w-64 mx-auto">
              {link_expired ? (
                <>
                  <div className="mt-60 text-center text-xl text-primary">
                    Verification Link has expired.
                  </div>
                  <button
                    className="btn-primary mt-20"
                    onClick={e => this.gotoLogin(e)}>
                    Go To Login
                  </button>
                </>
              ) : (
                ''
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  appuserData: state.appuserData,
  invitedappuserData: state.invitedappuserData,
});

export default reduxForm({
  form: 'EmailverificationForm',
})(
  connect(mapStateToProps, {
    updateTellusmoreAppuser,
    fetchinvitedappuser,
    updatinvitedappuser,
    registrationConfirmationemail,
    linkedinrefreshdata,
  })(Emailverification),
);
