import {CheckIcon} from '@heroicons/react/solid';
import {classNames} from '@commonFunction';
import {Combobox} from '@headlessui/react';
import {ChevronDownIcon, XIcon} from '@heroicons/react/outline';
import {selectCompanyMapping, selectMapping} from '../../actions';
import {connect} from 'react-redux';
import {
  companyContactsSettings,
  companyStandardSettings,
  peopleContactsSettings,
  peopleStandardSettings,
} from '../../../../../common/constants';

function MappingField(props) {
  const alreadyAdded = header => {
    const allKeys = Object.keys(props.selectMappingData);
    return (
      allKeys.filter(key => props.selectMappingData[key] === header).length > 0
    );
  };

  const allFields =
    props.section === 'people'
      ? {...peopleStandardSettings, ...peopleContactsSettings}
      : {...companyStandardSettings, ...companyContactsSettings};

  const onChange = val => {
    if (
      !(alreadyAdded(val) && props.selectMappingData[props.dataKey] !== val)
    ) {
      const mappingData = {...props.selectMappingData};
      if (mappingData[props.dataKey] !== val) {
        mappingData[props.dataKey] = val;
      } else {
        mappingData[props.dataKey] = '';
      }
      if (props.section === 'people') {
        props.selectMapping({...mappingData});
      } else {
        props.selectCompanyMapping({...mappingData});
      }
    }
  };

  const clearSelection = () => {
    const mappingData = {...props.selectMappingData};
    mappingData[props.dataKey] = '';
    if (props.section === 'people') {
      props.selectMapping({...mappingData});
    } else {
      props.selectCompanyMapping({...mappingData});
    }
  };

  return props.header ? (
    <tr className=" bg-white">
      <th
        scope="col"
        className="sticky bg-white top-0 w-1/6 z-[999] text-gray-500 text-xs bg-white py-4">
        {props.title}
      </th>
      <th
        scope="col"
        className="sticky bg-white top-0 w-1/4 z-[999] text-gray-500 text-xs bg-white py-4">
        {props.mappingTitle}
      </th>
      <th
        scope="col"
        className="sticky bg-white top-0 w-1/4 z-[999] text-gray-500 text-xs bg-white py-4">
        {props.exampleTitle}
      </th>
    </tr>
  ) : (
    <>
      <tr>
        <td className="text-sm text-gray-600">
          {props.title}
          {allFields[props.dataKey].mandatory ? '*' : ''}
        </td>
        <td>
          <div className="z-50 pr-8">
            <Combobox as="div" value={props.headers} onChange={onChange}>
              <div className="relative mt-2">
                <Combobox.Button className="cursor-pointer w-full">
                  <Combobox.Input
                    className="w-full rounded-lg border-0 bg-white py-2 text-sm pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 cursor-pointer"
                    displayValue={_ =>
                      props.selectMappingData[props.dataKey] !== ''
                        ? props.selectMappingData[props.dataKey]
                        : '--'
                    }
                    autoComplete="new-password"
                    placeholder="--"
                  />
                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    {props.selectMappingData[props.dataKey] !== '' ? (
                      <div onClick={clearSelection}>
                        <XIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    ) : (
                      <ChevronDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                  </Combobox.Button>
                </Combobox.Button>

                {props.headers.length > 0 && (
                  <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent scrollbar-thumb-rounded-full">
                    {props.headers.map(list => (
                      <Combobox.Option
                        key={list}
                        value={list}
                        className={({active}) =>
                          classNames(
                            'relative cursor-pointer py-2 pl-3 pr-9',
                            alreadyAdded(list) &&
                              props.selectMappingData[props.dataKey] !== list
                              ? 'opacity-50'
                              : 'opacity-100',
                          )
                        }>
                        <span className="block truncate text-sm">{list}</span>
                        {props.selectMappingData[props.dataKey] === list ? (
                          <span className="absolute right-2 top-2 mt-1">
                            <CheckIcon
                              className="h-5 w-5 text-primary font-semibold"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}
              </div>
            </Combobox>
          </div>
        </td>
        <td className="text-gray-500 text-xs">
          {allFields[props.dataKey].example}
        </td>
      </tr>
      {props.dataKey === 'last_name' ? (
        <tr>
          <td colSpan={3}>
            <div className="relative py-4">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-b border-gray-300"></div>
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-4 text-xs text-primary">
                  Choose First Name, Last Name OR, Full Name
                </span>
              </div>
            </div>
          </td>
        </tr>
      ) : null}
      {props.dataKey === 'full_name' ? (
        <tr>
          <td colSpan={3}>
            <div className="border-b border-gray-300 border-solid h-2 my-2" />
          </td>
        </tr>
      ) : null}
    </>
  );
}

const mapStateToProps = state => ({
  selectMappingData:
    state.csvSelectionPopupData.section === 'people'
      ? state.selectMapping
      : state.selectCompanyMapping,
  section: state.csvSelectionPopupData.section,
});

export default connect(mapStateToProps, {selectMapping, selectCompanyMapping})(
  MappingField,
);
