import {
  MYLISTS,
  LIST_PEOPLE,
  OPEN_LIST,
  CLOSE_LIST,
  PEOPLE_LIST_FILTERS,
  AVAILABLE_LABELS,
  COMPANY_LIST_FILTERS,
  LIST_COMPANY,
  OPEN_COMPANY_LIST,
  CLOSE_COMPANY_LIST,
} from '../actions';

const defaultFilters = {
  searchtext: '',
  time: {
    type: 'alltime',
    value: null,
  },
  tags: '',
};

const defaultFiltersCompany = {
  searchtext: '',
  time: {
    type: 'alltime',
    value: null,
  },
};

export const mylistPeopleFilters = (state = defaultFilters, action) => {
  switch (action.type) {
    case PEOPLE_LIST_FILTERS:
      return action.payload;
    default:
      return state;
  }
};

export const filteredMyLists = (state = [], action) => {
  switch (action.type) {
    case MYLISTS:
      return action.payload;
    default:
      return state;
  }
};

export const filteredListPeople = (state = [], action) => {
  switch (action.type) {
    case LIST_PEOPLE:
      return action.payload;
    default:
      return state;
  }
};

export const toggleListPeopleScreen = (state = null, action) => {
  switch (action.type) {
    case OPEN_LIST:
      return action.payload;
    case CLOSE_LIST:
      return null;
    default:
      return state;
  }
};

export const availableLabels = (state = [], action) => {
  switch (action.type) {
    case AVAILABLE_LABELS:
      return action.payload;
    default:
      return state;
  }
};

//Company

export const mylistCompanyFilters = (state = defaultFiltersCompany, action) => {
  switch (action.type) {
    case COMPANY_LIST_FILTERS:
      return action.payload;
    default:
      return state;
  }
};

export const filteredListCompany = (state = [], action) => {
  switch (action.type) {
    case LIST_COMPANY:
      return action.payload;
    default:
      return state;
  }
};

export const toggleListCompanyScreen = (state = null, action) => {
  switch (action.type) {
    case OPEN_COMPANY_LIST:
      return action.payload;
    case CLOSE_COMPANY_LIST:
      return null;
    default:
      return state;
  }
};
