import React, {Fragment, useState} from 'react';

import TooltipPopover from '@/common/TooltipPopover';
import {PortalWithState} from 'react-portal';

import IconExport from '../icons/export';
import MenuDropdown from '../../../../../../common/exportmenu/menudropdown';

export default function ExportCRMBlock(props) {
  const [coords, setCoords] = useState({}); // takes current button coordinates

  const btnRef = React.createRef();

  const updateTooltipCoords = _ => {
    const rect = btnRef.current.getBoundingClientRect();
    setCoords({
      left: rect.x + rect.width / 2 + 50, // add half the width of the button for centering
      top: rect.y + window.scrollY + 10, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
    });
  };

  return (
    <div>
      <PortalWithState>
        {({openPortal, closePortal, isOpen, portal}) => (
          <Fragment>
            <div
              ref={btnRef}
              onMouseEnter={e => {
                updateTooltipCoords(e.target);
                openPortal(e);
              }}
              onMouseLeave={e => {
                updateTooltipCoords(e.target);
                closePortal();
              }}>
              <div className="">
                <IconExport />
              </div>

              {portal(
                <TooltipPopover
                  width={180}
                  coords={coords}
                  direction={'left'}
                  updateTooltipCoords={() =>
                    updateTooltipCoords(btnRef.current.buttonNode)
                  }>
                  <MenuDropdown
                    {...props}
                    onSelectedMenu={type => {
                      closePortal();
                      props.onSelectedMenu(type);
                    }}
                  />
                </TooltipPopover>,
              )}
            </div>
          </Fragment>
        )}
      </PortalWithState>
    </div>
  );
}
