import {useState} from 'react';
import LocaleStrings from '../../../languages';
import {BaseComponent} from '@baseComponent';
import {classNames} from '@commonFunction';
import iconvalid from '../../../../assets/images/mycontacts/valid.png';
import iconunknown from '../../../../assets/images/mycontacts/unknown.png';
import toast, {Toaster} from 'react-hot-toast';
import iconvalidinfo from '../../../../assets/images/mycontacts/iconvalid.png';
import iconunknowninfo from '../../../../assets/images/mycontacts/iconunknown.png';

function BusinessContact(props) {
  const [mainvalue] = useState(props.mainvalue);
  const [val] = useState(props.val);
  const [showBusinessEmailInfo, setShowBusinessEmailInfo] = useState(false);

  // copy email/phone
  const _handleCopy = (e, val, mainvalue, type) => {
    if (type == 'email') {
      // console.log("val", val);
      if (!val.address) {
        navigator.clipboard.writeText(val);
      } else {
        navigator.clipboard.writeText(val.address);
      }
      // navigator.clipboard.writeText(val.address);
    } else {
      navigator.clipboard.writeText(val);
    }

    toast.success('Copied');
  };

  // email upvoting
  const _handleemailupvote = (e, val, mainvalue) => {
    let {session} = this.props;
    let obj = {};
    obj.linkedin_url = mainvalue.linkedinurl;
    if (!val.address) {
      obj.info = val;
    } else {
      obj.info = val.address;
    }
    // obj.info = val.address;
    obj.type = 'upvote';
    props.onUpvote(session, obj);
  };

  // email downvoting
  const _handleemaildownvote = (e, val, mainvalue) => {
    let {session} = this.props;
    let obj = {};
    obj.linkedin_url = mainvalue.linkedinurl;
    if (!val.address) {
      obj.info = val;
    } else {
      obj.info = val.address;
    }
    // obj.info = val.address;
    obj.type = 'downvote';
    props.onDownvote(session, obj);
  };

  return (
    <div className="flex items-center space-x-4">
      <div className="group cursor-pointer flex">
        <span
          className="items-center justify-center mt-1 absolute"
          onMouseEnter={() => {
            if (val !== '-') {
              setShowBusinessEmailInfo(true);
            }
          }}
          onMouseLeave={() => {
            setShowBusinessEmailInfo(false);
          }}>
          {mainvalue.bus_email_validation !== null ? (
            mainvalue.bus_email_validation === 'unknown' ? (
              <img src={iconunknown} className="cursor-pointer" width={28} />
            ) : mainvalue.bus_email_validation === 'valid' ? (
              <img src={iconvalid} className="cursor-pointer" width={28} />
            ) : null
          ) : null}
        </span>
        <div className=" px-7 py-1">{val}</div>
        <div
          className="group-hover:opacity-100 transition-opacity  px-1    right-0  
          -translate-x-1/2 translate-y-full opacity-0 mx-auto">
          <div className="">
            <span className="isolate inline-flex rounded-md shadow-sm">
              <button
                type="button"
                onClick={e => _handleCopy(e, val, mainvalue, 'email')}
                className="relative inline-flex items-center rounded-l-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50  focus:outline-none ">
                <img src="../../images/copysmall.png"></img>
              </button>
              <button
                type="button"
                onClick={e => _handleemailupvote(e, val, mainvalue)}
                className="relative -ml-px inline-flex items-center border border-gray-300  px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ">
                <img src="../../images/like.png"></img>
              </button>
              <button
                type="button"
                onClick={e => _handleemaildownvote(e, val, mainvalue)}
                className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300  px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
                <img src="../../images/dislike.png"></img>
              </button>
            </span>
          </div>
        </div>
        {showBusinessEmailInfo ? (
          <div
            className="shadow absolute flex flex-wrap border-gray-200 rounded-lg border border-solid pt-2 pb-2 pl-3 pr-3 ml-6 back bg-gray-600"
            style={{zIndex: 999, width: 380}}>
            {mainvalue.bus_email_validation === 'unknown' ? (
              <div>
                <div className="flex flex-row mt-0 relative">
                  <span className="items-center justify-center mr-2 mt-1">
                    <img src={iconunknowninfo} className="w-full h-auto" />
                  </span>
                  <span className="text-base font-bold text-white">
                    {LocaleStrings.partially_verified}
                  </span>
                  <span className="absolute right-0 text-xs font-normal bg-white p-1 rounded-md text-gray-600">
                    {LocaleStrings.free}
                  </span>
                </div>
                <div className="text-sm font-bold text-white">
                  {LocaleStrings.partially_rate}
                </div>
                <div
                  className="text-xs font-normal text-white leading-tight"
                  style={{whiteSpace: 'initial'}}>
                  {LocaleStrings.partially_text}
                </div>
              </div>
            ) : (
              <div>
                <div className="flex flex-row">
                  <span className="items-center justify-center mr-2 mt-1">
                    <img src={iconvalidinfo} className="w-full h-auto" />
                  </span>
                  <span className="text-base font-bold text-white">
                    {LocaleStrings.fully_verified}
                  </span>
                </div>
                <div className="text-sm font-bold text-white">
                  {LocaleStrings.fully_rate}
                </div>
                <div
                  className="flex text-xs font-normal text-white leading-tight break-normal"
                  style={{whiteSpace: 'initial'}}>
                  {LocaleStrings.fully_text}
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default BusinessContact;
