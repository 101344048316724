export default function IconVerifyEmail() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="stroke-primary stroke-2">
      <path
        d="M18.3327 5.00016C18.3327 4.0835 17.5827 3.3335 16.666 3.3335H3.33268C2.41602 3.3335 1.66602 4.0835 1.66602 5.00016M18.3327 5.00016V15.0002C18.3327 15.9168 17.5827 16.6668 16.666 16.6668H3.33268C2.41602 16.6668 1.66602 15.9168 1.66602 15.0002V5.00016M18.3327 5.00016L9.99935 10.8335L1.66602 5.00016"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
