import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import LocaleStrings from '@language';
import SearchFilter from '../search/filter';
import RevealedContactsResults from './contacts';
import {
  fetchLists,
  fetchSavedFilters,
  preloadPeopleSearchData,
} from '../search/actions';
import {classNames} from '@commonFunction';
import {fetchAppuser} from '../sidebar/actions';

class RevealedContactsHome extends Component {
  constructor(props) {
    super(props);

    // All Binded Functions
  }

  componentDidMount() {
    this.props.fetchAppuser(
      this.props.session,
      this.props.user.appuserid,
      callback => {},
    );

    this.props.fetchLists(this.props.session, {}, _ => {});
    this.props.preloadPeopleSearchData(this.props.session, _ => {});
    this.props.fetchSavedFilters(this.props.session, {}, _ => {});
  }

  render() {
    return (
      <div>
        <div className="absolute h-full">
          <SearchFilter />
        </div>
        <div
          className={classNames(
            'absolute flex transition-all h-full ease-in',
            !this.props.minimized ? 'left-[250px]' : 'left-[40px] ',
          )}>
          <RevealedContactsResults />
        </div>
      </div>
    );
  }
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  minimized: state.toggleMinimizeFilters,
});

export default connect(mapStateToProps, {
  preloadPeopleSearchData,
  fetchLists,
  fetchSavedFilters,
  fetchAppuser,
})(RevealedContactsHome);
