import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {connect} from 'react-redux';
import {classNames} from '@commonFunction';
import {FilterIcon} from '@heroicons/react/solid';

import ListCell from './cells/listcell';

import LocaleStrings from '../../languages';
import PaginationBar from '../search/results/cells/components/paginationbar';
import {
  deleteMyCompanyList,
  deleteMyList,
  fetchMyLists,
  updateMyList,
} from './actions';
import AppAlert from '../../../common/appalert';
import toast, {Toaster} from 'react-hot-toast';
import NoResult from '../../../common/noresult';
import SkeletonLoader from '../../../common/skeletonloader';
import {fetchLists, fetchCompanyLists} from '../search/actions';

const maxRowPerPage = 25;

function MyListTable(props) {
  const [curPage, setCurPage] = useState(1);
  const [rowsPerPage] = useState(maxRowPerPage);
  const [sortBy, setSortBy] = useState('createdon');
  const [sortDirection, setSortDirection] = useState('asc');

  const [selectedList, setSelectedList] = useState(undefined);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const applyFilter = resetPage => {
    setIsLoading(true);
    const page = resetPage ? 1 : curPage;
    props.fetchMyLists(
      props.session,
      page,
      rowsPerPage,
      sortBy,
      sortDirection,
      {action: 'mylists', tab: props.tab, searchtext: props.searchText},
      resp => {
        if (resetPage) {
          setCurPage(1);
          setTimeout(() => {
            setIsLoading(false);
          }, 200);
        } else {
          setIsLoading(false);
        }
      },
    );
  };

  useEffect(() => {
    if (!isLoading) {
      applyFilter(false);
    }
  }, [curPage]);

  useEffect(() => {
    if (!isLoading) {
      applyFilter(false);
    }
  }, [props.tab]);

  useEffect(() => {
    if (!isLoading) {
      applyFilter(true);
    }
  }, [props.searchText]);

  const refreshGlobalLists = () => {
    if (props.tab === 'company') {
      props.fetchCompanyLists(props.session, {}, _ => {});
    } else {
      props.fetchLists(props.session, {}, _ => {});
    }
  };

  return (
    <div className="px-4">
      {props.totalcount > 0 && !isLoading ? (
        <div className="flow-root">
          {props.filteredMyLists ? (
            <div className={classNames('-my-2 overflow-x-auto -ml-16 -mr-20')}>
              <div className="inline-block min-w-full py-2 align-middle px-8">
                <div className="relative overflow-y-auto">
                  <table className="w-full table-fixed divide-y divide-gray-300">
                    <thead className="bg-white table w-full">
                      <tr className="bg-gray-50">
                        <th
                          scope="col"
                          className="sticky top-0 py-3.5 pr-3 text-left text-xs font-normal text-gray-700 relative px-10 bg-gray-50 z-50">
                          {LocaleStrings.mylist.tableHeaders.listname}
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
                          {LocaleStrings.mylist.tableHeaders.contacts}
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
                          {LocaleStrings.mylist.tableHeaders.createddate}
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
                          {LocaleStrings.mylist.tableHeaders.lastupdated}
                        </th>
                        <th className="sticky top-0 bg-gray-50 z-50" />
                      </tr>
                    </thead>
                    <tbody
                      className="divide-y divide-gray-200 bg-white block w-full overflow-auto"
                      style={{height: 'calc(100vh - 290px)'}}>
                      {props.filteredMyLists &&
                        props.filteredMyLists.map(list => (
                          <ListCell
                            list={list}
                            onOpenCreateList={() => {
                              // setOpenCreateListPopup(!openCreateListPopup);
                            }}
                            onDeleteList={list => {
                              setSelectedList(list);
                              setShowDeleteAlert(true);
                            }}
                            onCopySuccess={() => {
                              toast.success('Copied successfully');
                            }}
                          />
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : null}
          <div>
            <PaginationBar
              curPage={curPage}
              numPages={props.numPages}
              onPageChange={curPage => {
                setCurPage(curPage);
              }}
            />
          </div>
        </div>
      ) : isLoading ? (
        <SkeletonLoader />
      ) : (
        <div
          className="flex justify-center items-center"
          style={{height: 'calc(100vh - 200px)'}}>
          <NoResult hasFilters={false} />
        </div>
      )}
      <AppAlert
        open={showDeleteAlert}
        title={selectedList && `Delete list - ${selectedList.listname}`}
        message="Are you sure you want to delete the list?"
        primaryButton={{title: 'Delete'}}
        secondaryButton={{title: 'Cancel'}}
        primaryButtonAction={() => {
          setShowDeleteAlert(false);

          const newlist = props.filteredMyLists.filter(
            list => list.listid !== selectedList.listid,
          );

          props.updateMyList({
            totalcount: props.totalcount - 1,
            pages: props.numPages,
            data: [...newlist],
          });

          if (props.tab === 'company') {
            props.deleteMyCompanyList(
              props.session,
              {listid: selectedList.listid},
              _ => {
                refreshGlobalLists();
              },
            );
          } else {
            props.deleteMyList(
              props.session,
              {listid: selectedList.listid},
              _ => {
                refreshGlobalLists();
              },
            );
          }
        }}
        secondaryButtonAction={() => {
          setShowDeleteAlert(false);
        }}
      />
      <Toaster />
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  const {totalcount, pages, data} = state.filteredMyLists;
  return {
    session,
    user,
    savedFilters,
    searchSelectedSubMenu: state.searchSelectedSubMenu,
    totalcount,
    numPages: pages,
    filteredMyLists: data,
  };
}

export default connect(mapStateToProps, {
  fetchMyLists,
  deleteMyList,
  updateMyList,
  deleteMyCompanyList,
  fetchCompanyLists,
  fetchLists,
})(MyListTable);
