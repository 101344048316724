import {Fragment, useEffect, useRef, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';

import LocaleStrings from '../../../../languages';
import {connect} from 'react-redux';

import {classNames} from '@commonFunction';
import {XIcon} from '@heroicons/react/outline';

function TechnologyListPopup(props) {
  const {companyname, company, technologies} = props.company;

  const [open, setOpen] = useState(false);

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    if (open !== props.open) {
      setOpen(props.open);
    }
  }, [props.open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[9999]"
        initialFocus={cancelButtonRef}
        onClose={() => {
          props.onClose();
        }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6 w-4/5">
                <div>
                  <div className="flex justify-between">
                    <div className="flex items-center justify-center gap-2">
                      {company.logo ? (
                        <img
                          className="mr-1 h-10"
                          src={company.logo}
                          onError={e => {
                            e.target.src = require('../../../../../assets/images/empty_company.png');
                          }}
                        />
                      ) : (
                        <img
                          className="mr-1 h-10"
                          src={require('../../../../../assets/images/empty_company.png')}
                        />
                      )}
                      <div className="text-primary text-sm font-semibold">
                        {companyname}
                      </div>
                    </div>
                    <div
                      className="hover:cursor-pointer"
                      onClick={() => {
                        props.onClose();
                      }}>
                      <XIcon className="h-6 w-6 text-gray-300" />
                    </div>
                  </div>
                  <div className="mt-3 text-left sm:mt-5">
                    <Dialog.Title
                      as="h2"
                      className="text-base font-semibold leading-6 text-gray-900">
                      {LocaleStrings.search.companysearch.techused}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-xs text-gray-500">
                        {LocaleStrings.formatString(
                          LocaleStrings.search.companysearch.techused_subtitle,
                          companyname,
                        )}
                      </p>
                    </div>
                  </div>
                  <div
                    className="pt-8 pb-4 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent scrollbar-thumb-rounded-full"
                    style={{maxHeight: 'calc(100vh - 200px)'}}>
                    <div className="relative flex">
                      <div className="flex flex-wrap gap-4">
                        {technologies.map(tech => (
                          <div className="p-1 px-2 bg-secondary text-xs flex rounded-lg text-gray-600">
                            {tech}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function mapStateToProps(state, ownProps) {
  const {session, user} = state;
  return {
    session,
    user,
  };
}

export default connect(mapStateToProps, null)(TechnologyListPopup);
