import React from 'react';

export default function IconPhone(props) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="phone-call" clipPath="url(#clip0_4742_25059)">
        <path
          id="Icon"
          d="M12.5415 4.16634C13.3555 4.32515 14.1035 4.72322 14.6899 5.30962C15.2763 5.89602 15.6744 6.64406 15.8332 7.45801M12.5415 0.833008C14.2326 1.02087 15.8095 1.77815 17.0134 2.98051C18.2173 4.18287 18.9765 5.75885 19.1665 7.44967M18.3332 14.0997V16.5997C18.3341 16.8318 18.2866 17.0615 18.1936 17.2741C18.1006 17.4868 17.9643 17.6777 17.7933 17.8346C17.6222 17.9915 17.4203 18.1109 17.2005 18.1853C16.9806 18.2596 16.7477 18.2872 16.5165 18.2663C13.9522 17.9877 11.489 17.1115 9.32486 15.708C7.31139 14.4286 5.60431 12.7215 4.32486 10.708C2.91651 8.53401 2.04007 6.05884 1.76653 3.48301C1.7457 3.25256 1.77309 3.02031 1.84695 2.80103C1.9208 2.58175 2.03951 2.38025 2.1955 2.20936C2.3515 2.03847 2.54137 1.90193 2.75302 1.80844C2.96468 1.71495 3.19348 1.66656 3.42486 1.66634H5.92486C6.32928 1.66236 6.72136 1.80557 7.028 2.06929C7.33464 2.333 7.53493 2.69921 7.59153 3.09967C7.69705 3.89973 7.89274 4.68528 8.17486 5.44134C8.28698 5.73961 8.31125 6.06377 8.24478 6.37541C8.17832 6.68705 8.02392 6.9731 7.79986 7.19967L6.74153 8.25801C7.92783 10.3443 9.65524 12.0717 11.7415 13.258L12.7999 12.1997C13.0264 11.9756 13.3125 11.8212 13.6241 11.7548C13.9358 11.6883 14.2599 11.7126 14.5582 11.8247C15.3143 12.1068 16.0998 12.3025 16.8999 12.408C17.3047 12.4651 17.6744 12.669 17.9386 12.9809C18.2029 13.2928 18.3433 13.691 18.3332 14.0997Z"
          stroke={props.selected ? '#17B26A' : '#667085'}
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4742_25059">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
